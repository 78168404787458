import React from 'react';
import PropTypes from 'prop-types';
import DummyUser from '../shared/images/dummy_user.png';

class UserIcon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { user, className, style } = this.props;
    return (
      <img
        alt="user-thumbnail"
        src={user.profile_image_url || DummyUser}
        className={className}
        style={style}
      />
    );
  }
}
UserIcon.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  className: PropTypes.string,
  style: PropTypes.shape({}),
};
UserIcon.defaultProps = {
  className: '',
  style: {},
};
export default UserIcon;
