import React from 'react';
import PropTypes from 'prop-types';
import MemberForm from './members_form/member_form';

class MembersForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      members, departments, departmentOptions, managerialPositionOptions,
    } = this.props;
    return (
      <React.Fragment>
        {members.map((member, index) => (
          <MemberForm
            key={member.id}
            members={members}
            departments={departments}
            member={member}
            index={index}
            departmentOptions={departmentOptions}
            managerialPositionOptions={managerialPositionOptions}
          />
        ))}
      </React.Fragment>
    );
  }
}

MembersForm.propTypes = {
  members: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
  departments: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
  departmentOptions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
  managerialPositionOptions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
};
MembersForm.defaultProps = {
};
export default MembersForm;
