import React from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import ClearIcon from '../../images/ic_clear.svg';
import HiddenForm from '../hidden_form';
import Box from './shared/box';
import { cleanUrl } from '../../../../utils/string_utils';

class PreviewFieldBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.remove = this.remove.bind(this);
  }

  remove() {
    const { onNotSavedImageRemoved, onSavedDepartmentRemoved, fileResource } = this.props;
    if (fileResource.id) {
      onSavedDepartmentRemoved();
    } else {
      onNotSavedImageRemoved();
    }
  }

  render() {
    const {
      scope, fileResource, index, currentUser,
    } = this.props;
    return (
      <React.Fragment>
        {fileResource.id && (
          <HiddenForm
            scope={scope}
            relatedModelName="file_resources"
            index={index}
            columnName="id"
            value={fileResource.id}
          />
        )}
        {currentUser.id && (
          <HiddenForm
            scope={scope}
            relatedModelName="file_resources"
            index={index}
            columnName="user_id"
            value={currentUser.id}
          />
        )}
        <HiddenForm
          scope={scope}
          relatedModelName="file_resources"
          index={index}
          columnName="file_type"
          value="image_file_type"
        />
        <HiddenForm
          scope={scope}
          relatedModelName="file_resources"
          index={index}
          columnName="url"
          value={cleanUrl(fileResource.url)}
        />
        {fileResource.destroyed && (
          <React.Fragment>
            <HiddenForm
              scope={scope}
              relatedModelName="file_resources"
              index={index}
              columnName="_destroy"
              value="1"
            />
            <Box>
              <img
                alt="thumbnail"
                src={fileResource.dataUri || fileResource.url}
                className="w-100 h-100"
                style={{ objectFit: 'cover' }}
              />
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: 'gray',
                  opacity: 0.8,
                  color: 'white',
                  textAlign: 'center',
                }}
              >
                <span style={{ lineHeight: '86px' }}>
                  削除
                </span>
              </div>
            </Box>
          </React.Fragment>
        )}
        {!fileResource.destroyed && (
          <Box>
            <img
              alt="thumbnail"
              src={fileResource.dataUri || fileResource.url}
              className="w-100 h-100"
              style={{ objectFit: 'cover' }}
            />
            <div
              className="link__cover-container position-absolute"
              style={{
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
              }}
            >
              <ReactSVG
                src={ClearIcon}
                beforeInjection={(svg) => {
                  svg.classList.add('cursor__pointer');
                  svg.setAttribute('style', 'position: absolute;top: 0px;right: 2px;width: 17px;');
                }}
                onClick={this.remove}
              />
            </div>
          </Box>
        )}
      </React.Fragment>
    );
  }
}

PreviewFieldBox.propTypes = {
  scope: PropTypes.string.isRequired,
  fileResource: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  index: PropTypes.number.isRequired,
  onNotSavedImageRemoved: PropTypes.func.isRequired,
  onSavedDepartmentRemoved: PropTypes.func.isRequired,
  currentUser: PropTypes.shape({
    id: PropTypes.number,
  }),
};
PreviewFieldBox.defaultProps = {
  currentUser: {},
};
export default PreviewFieldBox;
