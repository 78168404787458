import { format, startOfToday } from "date-fns";
import PropTypes from "prop-types";
import React from "react";
import { setupPickaDate } from "../../../../utils/datetime_utils";
import BaseForm, { ajaxSubmit } from "../../../shared/base_form";
import BaseMoal from "../../../shared/base_modal";
import Button from "../../../shared/button";
import HiddenForm from "../../../shared/form/hidden_form";
import TextForm from "../../../shared/form/text_form";

const todayStr = format(startOfToday(), "yyyy-MM-dd");

class CreateModalBody extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.save = this.save.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.backToMenu = this.backToMenu.bind(this);
  }

  componentDidMount() {
    setupPickaDate();
  }

  save() {
    const {
      onLoadingStarted,
      onLoadingEnded,
      afterCreated,
      onCloseButtonClicked,
    } = this.props;
    const form = document.forms.departmentCreateForm;
    if (form.checkValidity()) {
      onLoadingStarted(() => {
        ajaxSubmit(form)
          .then((r) => {
            const createdDepartment = r.data;
            afterCreated(createdDepartment);
            onCloseButtonClicked();
            window.Alert.success(
              `部署「${createdDepartment.name}」の追加が完了しました`
            );
          })
          .catch((e) => {
            onLoadingEnded();
          });
      });
    } else {
      // 検証結果がfalseなのでsubmitしてhtml5のvalidationメッセージを表示させる
      const submit = document.getElementById("departmentCreateSubmit");
      submit.click();
    }
  }

  closeModal() {
    const { onCloseButtonClicked } = this.props;
    onCloseButtonClicked();
  }

  backToMenu() {
    const { onSwitchToMenu } = this.props;
    onSwitchToMenu();
  }

  render() {
    const { node, onSwitchToMenu } = this.props;
    return (
      <React.Fragment>
        <BaseForm
          name="departmentCreateForm"
          action="/user/departments"
          method="post"
        >
          <BaseMoal.Body>
            {onSwitchToMenu && (
              <Button
                title="戻る"
                onClick={this.backToMenu}
                className="btn btn-link"
                style={{ fontSize: 13, marginTop: -20 }}
              />
            )}
            <div className="container-fluid">
              <div>
                {node && (
                  <HiddenForm
                    scope="department"
                    columnName="parent_id"
                    value={node.id}
                  />
                )}
                <TextForm
                  model={{ name: "" }}
                  scope="department"
                  columnName="name"
                  label="名称"
                  required
                />
              </div>
            </div>
          </BaseMoal.Body>
          <div className="modal-footer">
            <button
              type="submit"
              id="departmentCreateSubmit"
              style={{ border: "none", padding: 0 }}
            />
            <button
              type="button"
              className="btn btn-light"
              onClick={this.closeModal}
            >
              閉じる
            </button>
            <Button
              title="保存"
              className="btn button__primary"
              onClick={this.save}
            />
          </div>
        </BaseForm>
      </React.Fragment>
    );
  }
}

CreateModalBody.propTypes = {
  onSwitchToMenu: PropTypes.func,
  onCloseButtonClicked: PropTypes.func.isRequired,
  onLoadingStarted: PropTypes.func.isRequired,
  onLoadingEnded: PropTypes.func.isRequired,
  node: PropTypes.shape({
    id: PropTypes.number,
  }),
  afterCreated: PropTypes.func.isRequired,
};
CreateModalBody.defaultProps = {
  onSwitchToMenu: undefined,
  node: undefined,
};
export default CreateModalBody;
