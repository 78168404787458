import React, { ReactElement, useState } from "react";
import { QuestionnaireResponse } from "../../models/Questionnaire";
import { QuestionnaireAdviceResponse } from "../../models/QuestionnaireAdvice";
import BaseModal from "../shared/base_modal";
import HappyBirdImage from "../shared/images/happy_bird.png";
import AdviceContainer from "./advice_button/advice_container";

interface Props {
  questionnaire: QuestionnaireResponse;
  comparisonQuestionnaire: QuestionnaireResponse | null;
  title: string;
  comparisonQuestionnaireAdvices: QuestionnaireAdviceResponse[] | null;
  questionnaireAdvices: QuestionnaireAdviceResponse[] | null;
  interactiveWorkPath: string;
  interactiveHappinessAdvicePath: string;
  isConsumer: boolean;
  showModal?: boolean;
}

export default function AdviceButton(props: Props): ReactElement {
  const {
    questionnaire,
    title,
    comparisonQuestionnaire,
    comparisonQuestionnaireAdvices,
    questionnaireAdvices,
    interactiveWorkPath,
    interactiveHappinessAdvicePath,
    isConsumer,
    showModal: initialShowModal = false,
  } = props;
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(initialShowModal);

  return (
    <>
      {showModal && (
        <BaseModal
          show={showModal}
          title={
            <div className="d-flex align-items-center">
              <div>
                <img src={HappyBirdImage} style={{ width: 30 }} />
              </div>
              <div className="ml-2">ハッピーAI</div>
            </div>
          }
          loading={loading}
          contentLabel="questionnaireAdvicesModal"
          onCloseModal={() => setShowModal(false)}
          contentStyle={{ maxWidth: 496, width: "100%" }}
        >
          <BaseModal.Body>
            <AdviceContainer
              questionnaire={questionnaire}
              comparisonQuestionnaire={comparisonQuestionnaire}
              comparisonQuestionnaireAdvices={comparisonQuestionnaireAdvices}
              questionnaireAdvices={questionnaireAdvices}
              interactiveWorkPath={interactiveWorkPath}
              interactiveHappinessAdvicePath={interactiveHappinessAdvicePath}
              isConsumer={isConsumer}
              beforeLoading={() => setLoading(true)}
              afterLoading={() => setLoading(false)}
            />
          </BaseModal.Body>
        </BaseModal>
      )}
      <div
        className="btn button__highlignt w-100"
        onClick={() => setShowModal(true)}
        id="happy_ai_button"
      >
        {title}
      </div>
    </>
  );
}
