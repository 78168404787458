import React from 'react';
import PropTypes from 'prop-types';

class BaseModalBody extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { children, fixed, style } = this.props;
    const s = Object.assign({}, fixed ? { height: '55vh' } : { maxHeight: '55vh' }, style);
    return (
      <div
        className="modal-body"
        style={Object.assign({
          overflow: 'auto',
        }, s)}
      >
        {children}
      </div>
    );
  }
}

BaseModalBody.propTypes = {
  children: PropTypes.node.isRequired,
  fixed: PropTypes.bool,
  style: PropTypes.shape({}),
};
BaseModalBody.defaultProps = {
  fixed: false,
  style: {},
};
export default BaseModalBody;
