import PropTypes from "prop-types";
import React from "react";
import UserIcon from "../../users/user_icon";
import UserNameLink from "../../users/user_name_link";
import EditLink from "./edit_link";

class MemberItem extends React.Component {
  constructor(props) {
    super(props);
    const { member } = props;
    this.state = {
      member,
    };
  }

  render() {
    const {
      activeColumns,
      authOptions,
      eventAuthOptions,
      roleOptions,
      managerialPositionOptions,
      dutyOptions,
      memberActionPath,
      userActionPath,
      departmentOptions,
      departments,
      currentUser,
      showUserLink,
      isEditable,
      company,
    } = this.props;
    const { member } = this.state;
    return (
      <tr>
        {activeColumns.includes("department_name") && (
          <td>{member.department.name}</td>
        )}
        {activeColumns.includes("user_account_name") && (
          <td className="member-index__member-name-td">
            <UserIcon
              user={member.user}
              className="image__thumbnail-small mr-2"
            />
            {showUserLink && <UserNameLink user={member.user} />}
            {!showUserLink && member.user.account_name}
          </td>
        )}
        {activeColumns.includes("managerial_position_name") && (
          <td className="member-index__td">
            {member.managerial_position.name}
          </td>
        )}
        {activeColumns.includes("duty_name") && (
          <td className="member-index__td">{member.duty.name}</td>
        )}
        {activeColumns.includes("member_ref_auth") && (
          <td className="member-index__td">
            {member.isAdmin
              ? window.I18n.t("form_options.member.auth.company_admin_auth")
              : window.I18n.t(`enums.member.ref_auth.${member.ref_auth}`)}
          </td>
        )}
        {activeColumns.includes("member_event_auth") && (
          <td className="member-index__td">
            {window.I18n.t(`enums.member.event_auth.${member.event_auth}`)}
          </td>
        )}
        {isEditable && (
          <td className="member-index__td">
            <EditLink
              memberActionPath={memberActionPath}
              userActionPath={userActionPath}
              currentUser={currentUser}
              member={member}
              departments={departments}
              isEventAuthEditable={activeColumns.includes("member_event_auth")}
              roleOptions={roleOptions}
              authOptions={authOptions}
              eventAuthOptions={eventAuthOptions}
              managerialPositionOptions={managerialPositionOptions}
              dutyOptions={dutyOptions}
              departmentOptions={departmentOptions}
              company={company}
              afterSaved={(m) =>
                this.setState({ member: m }, () => {
                  window.Alert.success(
                    `${m.user.account_name}さんの設定変更を完了しました`
                  );
                })
              }
            />
          </td>
        )}
      </tr>
    );
  }
}

MemberItem.propTypes = {
  memberActionPath: PropTypes.string.isRequired,
  userActionPath: PropTypes.string.isRequired,
  currentUser: PropTypes.shape({
    id: PropTypes.number,
  }),
  member: PropTypes.shape({
    id: PropTypes.number,
  }),
  roleOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  authOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  eventAuthOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  managerialPositionOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  dutyOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  departmentOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  departments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ).isRequired,
  isEditable: PropTypes.bool.isRequired,
  activeColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
  showUserLink: PropTypes.bool.isRequired,
  company: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};
MemberItem.defaultProps = {
  member: {},
};
export default MemberItem;
