import React, { ReactElement } from "react";
import { format, parseISO } from "date-fns";
import Button from "../../../shared/button";
import MultiLineText from "../../../shared/multi_line_text";
import { QuestionnaireNewResponse } from "../../../../models/QuestionnaireApi";
import { EventResponse } from "../../../../models/Event";
import { QuestionnaireNewUserResponse } from "../../../../models/UserApi";

interface Props {
  isReused: boolean;
  isFirstPage: boolean;
  isLastPage: boolean;
  previousQuestionnaire: QuestionnaireNewResponse | null;
  event: EventResponse | null;
  headerAreaIcon: string;
  totalPage: number;
  totalInputCount: number;
  page: number;
  user: QuestionnaireNewUserResponse | null;
  onPreviousPageClicked: () => void;
  onTempFormCleared: () => void;
  isWorkHappinessQuestionPage: boolean;
  allAnswerSelected: boolean;
}

interface HeaderMessageType {
  message: string;
  subMessage?: string;
}

function getHappinessSurveyHeaderMessage(page: number): HeaderMessageType {
  let message = [
    {
      message: window.I18n.t(
        "javascript.questionnaires.happiness_questionnaire.cheering_message1"
      ),
    },
    {
      message: window.I18n.t(
        "javascript.questionnaires.happiness_questionnaire.cheering_message2"
      ),
    },
    {
      message: window.I18n.t(
        "javascript.questionnaires.happiness_questionnaire.cheering_message3"
      ),
    },
    {
      message: window.I18n.t(
        "javascript.questionnaires.happiness_questionnaire.cheering_message4"
      ),
    },
  ][page - 1];
  if (message === undefined) {
    message = {
      message: window.I18n.t(
        "javascript.questionnaires.happiness_questionnaire.cheering_message1"
      ),
    };
  }
  return message;
}

function getWorkHappinessSurveyHeaderMessage(page: number): HeaderMessageType {
  if (page === 1) {
    return {
      message: window.I18n.t(
        "javascript.questionnaires.happiness_questionnaire.only_work_happiness_description1"
      ),
      subMessage: window.I18n.t(
        "javascript.questionnaires.happiness_questionnaire.only_work_happiness_description2"
      ),
    };
  }
  return {
    message: window.I18n.t(
      "javascript.questionnaires.happiness_questionnaire.work_happiness_description1"
    ),
    subMessage: window.I18n.t(
      "javascript.questionnaires.happiness_questionnaire.work_happiness_description2"
    ),
  };
}

export default function QuestionnaireHeader(props: Props): ReactElement {
  const {
    isReused,
    isFirstPage,
    isLastPage,
    previousQuestionnaire,
    event,
    headerAreaIcon,
    page,
    user,
    totalPage,
    totalInputCount,
    onPreviousPageClicked,
    onTempFormCleared,
    isWorkHappinessQuestionPage,
    allAnswerSelected,
  } = props;

  const messageObject = isWorkHappinessQuestionPage
    ? getWorkHappinessSurveyHeaderMessage(page)
    : getHappinessSurveyHeaderMessage(page);

  return (
    <React.Fragment>
      <div className="happiness-questionnaire__fixed-header-container">
        {isFirstPage && previousQuestionnaire !== null && event === null && (
          <span className="happiness-questionnaire__previous-measure-date-container">
            {window.I18n.t(
              "javascript.questionnaires.happiness_questionnaire.previous_measurement_date"
            )}
            :
            {format(
              parseISO(previousQuestionnaire.created_at),
              "yyyy年MM月dd日"
            )}
          </span>
        )}
        {!isFirstPage && (
          <React.Fragment>
            <Button
              className="happiness-questionnaire__previous-page-link-container"
              onClick={onPreviousPageClicked}
              title={window.I18n.t(
                "javascript.questionnaires.happiness_questionnaire.go_back"
              )}
            />
            <Button
              className="happiness-questionnaire__clear-link-container"
              onClick={onTempFormCleared}
              title={window.I18n.t(
                "javascript.questionnaires.happiness_questionnaire.clear_answers"
              )}
            />
          </React.Fragment>
        )}
        <div className="happiness-questionnaire__current-page-container">
          <span>
            {`${window.I18n.t(
              "javascript.questionnaires.happiness_questionnaire.question_count",
              { n: totalInputCount }
            )}: ${page}/${totalPage}`}
          </span>
        </div>
      </div>
      <div className="happiness-questionnaire__header-container">
        <div>
          <div
            dangerouslySetInnerHTML={{ __html: headerAreaIcon }}
            className="pb-2"
          />
          <span
            className="happiness-questionnaire__information"
            style={isWorkHappinessQuestionPage ? { color: "black" } : {}}
          >
            <MultiLineText text={messageObject.message} />
          </span>
          <span
            className="happiness-questionnaire__information"
            style={
              isWorkHappinessQuestionPage
                ? { color: "red", fontWeight: "bold", fontSize: 15 }
                : {}
            }
          >
            {messageObject.subMessage}
          </span>
          {page === 1 &&
            user !== null &&
            !user.has_member_ref_auth &&
            event === null && (
              <div style={{ fontSize: 14, margin: "20px -20px" }}>
                {window.I18n.t(
                  "javascript.questionnaires.happiness_questionnaire.privacy_message1"
                )}
                <br />
                {window.I18n.t(
                  "javascript.questionnaires.happiness_questionnaire.privacy_message2"
                )}
              </div>
            )}
          {isLastPage && isReused && (
            <React.Fragment>
              {previousQuestionnaire !== null && (
                <div style={{ fontSize: 14, margin: "20px -20px" }}>
                  {`前回(測定日:${format(
                    parseISO(previousQuestionnaire.created_at),
                    "yyyy年MM月dd日"
                  )})の結果を紐づけます。`}
                </div>
              )}
              {!allAnswerSelected && (
                <div style={{ fontSize: 14, margin: "20px -20px" }}>
                  追加で回答が必要な以下質問に回答をお願いします。
                </div>
              )}
            </React.Fragment>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
