import React from 'react';
import PropTypes from 'prop-types';
import Button from '../button';
import DepartmentFilterField from './display_department_form/department_filter_field';
import DepartmentSelectModal from '../../departments/department_select_modal';

class DisplayDepartmentForm extends React.Component {
  constructor(props) {
    super(props);
    const { model, defaultDepartments } = this.props;
    let departmentFilters = model.department_filters || [];
    if (departmentFilters.length === 0) {
      departmentFilters = defaultDepartments.map(d => ({
        department: d,
        level: 'single_level',
      }));
    }
    this.state = {
      departmentFilters,
      showModal: false,
    };
    this.add = this.add.bind(this);
  }

  add(department) {
    const { departmentFilters } = this.state;
    let dfs = [];
    if (department.id < 0) {
      // 全社が選択された場合は全ての部署をクリア
      dfs = departmentFilters.map((df) => {
        if (df && df.id) {
          return Object.assign({}, df, { destroyed: true });
        }
        return null;
      });
    } else {
      // 全社が登録済だった場合は削除
      dfs = departmentFilters.map((df) => {
        if (df && df.department.variety === 'whole') {
          if (df.id) {
            return Object.assign({}, df, { destroyed: true });
          }
          return null;
        }
        return df;
      });
      dfs = dfs.concat({ department, level: 'single_level' });
    }
    this.setState({ departmentFilters: dfs, showModal: false });
  }

  remove(index) {
    const { departmentFilters } = this.state;
    const dfs = departmentFilters.concat();
    dfs.splice(index, 1, null);
    this.setState({ departmentFilters: dfs });
  }

  addDestroyFlg(index) {
    const { departmentFilters } = this.state;
    const dfs = departmentFilters.concat();
    dfs.splice(index, 1, Object.assign({}, dfs[index], { destroyed: true }));
    this.setState({ departmentFilters: dfs });
  }

  render() {
    const { scope, renderHeader } = this.props;
    const { departmentFilters, showModal } = this.state;
    const activeDepartmentFilter = departmentFilters.filter(df => (df && !df.destroyed));
    const existsDepartmentFilter = activeDepartmentFilter.length > 0;

    return (
      <div className="mb-3">
        <DepartmentSelectModal
          showModal={showModal}
          onCloseButtonClicked={() => this.setState({ showModal: false })}
          onSelected={this.add}
          hiddenDepartments={activeDepartmentFilter.map(df => df.department)}
          wholeDepartmentSelectLabel="全ての部署に公開:"
          wholeDepartment={{ id: -1 }} // dummyのidを設定
        />
        {renderHeader()}
        {departmentFilters.map((df, i) => {
          if (!df) {
            return null;
          }
          const keyIndex = i;
          return (
            <DepartmentFilterField
              key={keyIndex}
              scope={scope}
              departmentFilter={df}
              index={i}
              onNotSavedDepartmentRemoved={() => this.remove(i)}
              onSavedDepartmentRemoved={() => this.addDestroyFlg(i)}
            />
          );
        })}
        {!existsDepartmentFilter && (
          <div className="d-flex align-items-center p-1">
            <Button
              className="color__black font__size-14 font-weight-bold flex-fill border p-2"
              style={{ lineHeight: '17px' }}
            >
              全社
            </Button>
          </div>
        )}
        <div className="d-flex flex-row-reverse">
          <Button
            className="btn color__gray"
            style={{
              fontSize: 14,
              paddingTop: 3,
              paddingBottom: 3,
            }}
            onClick={() => this.setState({ showModal: true })}
          >
            {existsDepartmentFilter ? '部署追加' : '公開範囲変更'}
          </Button>
        </div>
      </div>
    );
  }
}

DisplayDepartmentForm.propTypes = {
  renderHeader: PropTypes.func,
  scope: PropTypes.string.isRequired,
  model: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  defaultDepartments: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })),
};
DisplayDepartmentForm.defaultProps = {
  renderHeader: () => {},
  model: { file_resources: [] },
  defaultDepartments: [],
};

export default DisplayDepartmentForm;
