import React from 'react';
import PropTypes from 'prop-types';
import TextareaAutosize from 'react-autosize-textarea';
import { fieldId, fieldName, i18nKey } from './utils/attribute_utils';
import { errorMessages } from './utils/message_utils';

class AutosizeTextAreaForm extends React.Component {
  constructor(props) {
    super(props);
    const { innerRef } = this.props;
    this.state = {
    };
    this.textarea = innerRef;
  }

  viewLabel() {
    const {
      label, scope, relatedModelName, columnName,
    } = this.props;
    return label || window.I18n.t(i18nKey(scope, relatedModelName, columnName));
  }

  render() {
    const {
      scope, relatedModelName, columnName,
      rows, index, model, onKeyDown,
      required, placeholder, inputClassName,
      inputStyle, onChange,
    } = this.props;
    return (
      <React.Fragment>
        <div className="w-100">
          <div>
            <TextareaAutosize
              ref={this.textarea}
              id={fieldId(scope, relatedModelName, columnName, index)}
              name={fieldName(scope, relatedModelName, columnName, index)}
              defaultValue={model[columnName]}
              rows={rows}
              required={required}
              placeholder={placeholder}
              className={inputClassName}
              style={inputStyle}
              onChange={e => onChange(e)}
              onKeyDown={e => onKeyDown(e)}
            />
            {errorMessages(model, columnName, this.viewLabel()).map((m, i) => (
              <div key={m} className="invalid-feedback d-block">
                <span>{m}</span>
              </div>
            ))}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

AutosizeTextAreaForm.propTypes = {
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  scope: PropTypes.string.isRequired,
  model: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  relatedModelName: PropTypes.string,
  columnName: PropTypes.string.isRequired,
  label: PropTypes.string,
  rows: PropTypes.number,
  index: PropTypes.number,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  inputClassName: PropTypes.string,
  inputStyle: PropTypes.shape({
    outline: PropTypes.string,
  }),
  onKeyDown: PropTypes.func,
  onChange: PropTypes.func,
};
AutosizeTextAreaForm.defaultProps = {
  innerRef: {},
  relatedModelName: '',
  label: '',
  model: {},
  rows: 3,
  index: -1,
  required: false,
  placeholder: '',
  inputClassName: '',
  inputStyle: {},
  onKeyDown: () => {},
  onChange: () => {},
};
export default React.forwardRef((props, ref) => (
  <AutosizeTextAreaForm {...props} innerRef={ref} />
));
