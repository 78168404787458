import PropTypes from 'prop-types';
import React from 'react';
import Linkify from 'react-linkify';
import reactStringReplace from 'react-string-replace';

const componentDecorator = (href, text, key) => (
  <a href={href} key={key} target="_blank" rel="noopener noreferrer">
    {text}
  </a>
);

class MultiLineText extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { text, keyword } = this.props;
    const renderTexts = () => {
      if (text) {
        return (
          text.split('\n').map((t, i) => {
            const keyIndex = i;
            return (
              <React.Fragment key={keyIndex}>
                {keyword && reactStringReplace(t, keyword, (match, idx) => (
                  <span key={idx} className="text__highlight">{match}</span>
                ))}
                {!keyword && t}
                <br />
              </React.Fragment>
            );
          })
        );
      }
      return '';
    };
    return (
      <span className={this.props.className}>
        <Linkify componentDecorator={componentDecorator}>
          {renderTexts()}
        </Linkify>
      </span>
    );
  }
}
MultiLineText.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  keyword: PropTypes.string,
};
MultiLineText.defaultProps = {
  className: '',
  text: '',
  keyword: '',
};
export default MultiLineText;
