import PropTypes from 'prop-types';
import React from 'react';
import axios from 'axios';
import Button from '../shared/button';
import { isIosSafari } from '../../utils/user_agent';

class DocumentDownloadLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      downloading: false,
    };
    this.download = this.download.bind(this);
  }

  download() {
    const { document: doc } = this.props;
    const { downloading } = this.state;
    if (!doc.id || downloading) {
      return;
    }
    this.setState({ downloading: true }, () => {
      axios({
        method: 'get',
        url: `/documents/${doc.id}`,
      })
        .then((result) => {
          const { data: { url } } = result;
          if (isIosSafari) {
            window.location.href = url;
            return;
          }
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('href', url);
          a.setAttribute('target', '_blank');
          a.style.cssText = 'display: none';
          a.click();
          setTimeout(() => {
            document.body.removeChild(a);
            this.setState({ downloading: false });
          }, 1000);
        })
        .catch((e) => {
          this.setState({ downloading: false }, () => {
            window.alert('エラーが発生しました。大変申し訳ありませんが、しばらく時間をおいて再実行してください');
          });
        });
    });
  }

  render() {
    const {
      document: doc, className, style, title,
    } = this.props;
    return (
      <Button
        className={`${className} cursor__pointer`}
        style={style}
        onClick={this.download}
      >
        {title || doc.file_name}
      </Button>
    );
  }
}
DocumentDownloadLink.propTypes = {
  document: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  className: PropTypes.string,
  style: PropTypes.shape({}),
  title: PropTypes.string,
};
DocumentDownloadLink.defaultProps = {
  className: '',
  style: {},
  title: '',
};
export default DocumentDownloadLink;
