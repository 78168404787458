import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { useDrop, XYCoord } from "react-dnd";
import Label from "./label";
import update from "immutability-helper";
import happinessElementName from "../../../utils/happiness_element_utils";

interface Props {
  dataset: Array<{
    happinessElement: string;
    value: number; // 一般平均との差分
    correlation: number;
  }>;
  xAxisScales: number[];
  yAxisScales: number[];
  scaleHeight: number;
  scaleWidth: number;
}

interface DragItem {
  type: string;
  id: string;
  top: number;
  left: number;
}

function createLabelDataset(
  dataset: Array<{
    happinessElement: string;
    value: number;
    correlation: number;
  }>,
  graphAreaHeight: number,
  graphAreaWidth: number,
  xAxisMin: number,
  xAxisMax: number,
  yAxisMin: number,
  yAxisMax: number
) {
  return Object.fromEntries(
    dataset.map((d) => [
      d.happinessElement,
      {
        data: d,
        top:
          (graphAreaHeight * Math.abs(d.value - yAxisMax)) /
            Math.abs(yAxisMin - yAxisMax) -
          20,
        left:
          (graphAreaWidth * (d.correlation - xAxisMin)) /
          Math.abs(xAxisMax - xAxisMin),
        title: happinessElementName(d.happinessElement),
        value: d.value,
        correlation: d.correlation,
      },
    ])
  );
}

export default function GraphContainer(props: Props): ReactElement {
  const { dataset, xAxisScales, yAxisScales, scaleHeight, scaleWidth } = props;
  const graphAreaHeight = scaleHeight * (yAxisScales.length - 1) + 8;
  const graphAreaWidth = scaleWidth * (xAxisScales.length - 1);
  const xAxisMin = Math.min(...xAxisScales);
  const xAxisMax = Math.max(...xAxisScales);
  const yAxisMin = Math.min(...yAxisScales);
  const yAxisMax = Math.max(...yAxisScales);
  const [labels, setLabels] = useState<{
    [key: string]: {
      top: number;
      left: number;
      title: string;
      data: {
        happinessElement: string;
        value: number;
        correlation: number;
      };
    };
  }>(
    createLabelDataset(
      dataset,
      graphAreaHeight,
      graphAreaWidth,
      xAxisMin,
      xAxisMax,
      yAxisMin,
      yAxisMax
    )
  );

  useEffect(() => {
    setLabels(
      createLabelDataset(
        dataset,
        graphAreaHeight,
        graphAreaWidth,
        xAxisMin,
        xAxisMax,
        yAxisMin,
        yAxisMax
      )
    );
  }, [graphAreaHeight, graphAreaWidth]);

  const moveBox = useCallback(
    (id: string, left: number, top: number) => {
      setLabels(
        update(labels, {
          [id]: {
            $merge: {
              left,
              top,
            },
          },
        })
      );
    },
    [labels, setLabels]
  );
  const [, drop] = useDrop(
    () => ({
      accept: "label",
      drop(item: DragItem, monitor) {
        const delta = monitor.getDifferenceFromInitialOffset() as XYCoord;
        const left = Math.round(item.left + delta.x);
        const top = Math.round(item.top + delta.y);
        moveBox(item.id, left, top);
        return undefined;
      },
    }),
    [moveBox]
  );

  return (
    <div ref={drop} className="driver-chart__table-container">
      {[...yAxisScales].reverse().map((y, yIndex) => {
        if (yIndex === yAxisScales.length - 1) {
          return null;
        }
        const rowClassNameArr = ["driver-chart__table-row"];
        if (y === 0) {
          rowClassNameArr.push("driver-chart__table-row__importance-separator");
        } else if (y % 5 === 0) {
          rowClassNameArr.push("driver-chart__table-row__scale");
        }
        if (yIndex === yAxisScales.length - 2) {
          rowClassNameArr.push("driver-chart__table-row__scale-last");
        }
        return (
          <div key={y} className={rowClassNameArr.join(" ")}>
            {xAxisScales.map((x, xIndex) => {
              if (xIndex === xAxisScales.length - 1) {
                return null;
              }
              const colClassNameArr = ["driver-chart__table-box"];
              if (x === 0.4) {
                colClassNameArr.push(
                  "driver-chart__table-box__importance-separator"
                );
              } else if ((x * 100) % 10 === 0) {
                colClassNameArr.push("driver-chart__table-box__scale");
              }
              if (xIndex === xAxisScales.length - 2) {
                colClassNameArr.push("driver-chart__table-box__scale-last");
              }

              return (
                <div
                  key={x}
                  className={colClassNameArr.join(" ")}
                  style={{ height: scaleHeight, width: scaleWidth }}
                />
              );
            })}
          </div>
        );
      })}
      {Object.keys(labels).map((key) => {
        const label = labels[key];
        const initialTopPercent =
          (100 * Math.abs(yAxisMax - label.data.value)) /
          Math.abs(yAxisMin - yAxisMax);
        const initialLeftPercent =
          (100 * (label.data.correlation - xAxisMin)) /
          Math.abs(xAxisMax - xAxisMin);
        return (
          <div key={key}>
            <Label
              id={key}
              happinessElement={label.data.happinessElement}
              title={label.title}
              left={label.left}
              top={label.top}
            />
            <div
              className="driver-chart__data-dot"
              style={{
                top: `${initialTopPercent}%`,
                left: `${initialLeftPercent}%`,
                backgroundColor: "blue",
              }}
            />
          </div>
        );
      })}
    </div>
  );
}
