import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactElement, useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { formatForMarkdown } from "../../../utils/markdown_formatter";
import HappyBirdImage from "../../shared/images/happy_bird.png";
import Loading from "../../shared/loading";
import MultiLineText from "../../shared/multi_line_text";

interface Props {
  role: "assistant_role" | "user_role";
  body: string;
  loading?: boolean;
}

export default function Chat(props: Props): ReactElement {
  const { role, body, loading = false } = props;
  const [count, setCount] = useState(10);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (loading && count >= 0) {
      timeoutId = setTimeout(() => {
        setCount(count - 1);
      }, 1000);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [loading, count]);

  if (role === "assistant_role") {
    return (
      <div
        className="chat-screen__chat-message-container"
        style={{ maxWidth: "80%" }}
      >
        <div className="d-flex justify-content-between">
          <div className="d-flex justify-content-start">
            <div className="ml-3">
              <img src={HappyBirdImage} style={{ width: 28 }} />
            </div>
          </div>
          <div className="mr-3">
            {!loading && (
              <CopyToClipboard text={body}>
                <button className="btn button__light-slim">
                  <div className="d-flex justify-content-center align-items-center">
                    <FontAwesomeIcon
                      icon={faClipboard}
                      className="font__size-13 color__gray"
                    />
                    <div className="ml-2">Copy</div>
                  </div>
                </button>
              </CopyToClipboard>
            )}
          </div>
        </div>
        <div className="d-flex justify-content-start px-3 pb-3 mt-1">
          <div className="p-3 bgcolor__trivial rounded markdown__container">
            {loading && body === "" && (
              <div className="d-flex justify-content-start align-items-center">
                <div className="position-relative" style={{ width: 25 }}>
                  <Loading loading={true} width={20} height={20} />
                </div>
                <div className="ml-1 color__gray font__size-13">
                  {count > 0
                    ? `あと約${count}秒で回答が開始されます`
                    : "しばらくお待ちください"}
                </div>
              </div>
            )}
            <ReactMarkdown
              children={formatForMarkdown(body)}
              remarkPlugins={[remarkGfm]}
            />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="chat-screen__chat-message-container">
      <div className="d-flex justify-content-end p-3">
        <div
          className="p-3 bgcolor__trivial rounded"
          style={{ maxWidth: "80%" }}
        >
          <MultiLineText text={body} />
        </div>
      </div>
    </div>
  );
}
