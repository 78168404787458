import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../shared/button';
import ManagerialPositionEditModal from '../shared/managerial_position_edit_modal';

class HeaderAction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showEditModal: false,
    };
    this.startSorting = this.startSorting.bind(this);
    this.resetSorting = this.resetSorting.bind(this);
  }

  startSorting() {
    const { onSortStarted } = this.props;
    onSortStarted();
  }

  resetSorting() {
    const { onSortReset } = this.props;
    if (window.confirm('本当に並び替えをリセットしてよろしいですか？')) {
      onSortReset();
    }
  }

  render() {
    const { canSort, action } = this.props;
    const { showEditModal } = this.state;
    return (
      <React.Fragment>
        {showEditModal && (
          <ManagerialPositionEditModal
            action={action}
            showModal={showEditModal}
            onCloseButtonClicked={() => this.setState({ showEditModal: false })}
          />
        )}
        <Button
          title="追加"
          className="btn managerial-position-list__add-managerial-position-button"
          disabled={canSort}
          onClick={() => this.setState({ showEditModal: true })}
        />
        {canSort && (
          <Button
            title="元に戻す"
            className="btn managerial-position-list__sort-button"
            onClick={this.resetSorting}
          />
        )}
        {!canSort && (
          <Button
            title="並び替え"
            className="btn managerial-position-list__sort-button"
            onClick={this.startSorting}
          />
        )}
      </React.Fragment>
    );
  }
}

HeaderAction.propTypes = {
  action: PropTypes.string.isRequired,
  canSort: PropTypes.bool.isRequired,
  onSortStarted: PropTypes.func.isRequired,
  onSortReset: PropTypes.func.isRequired,
};
HeaderAction.defaultProps = {
};
export default HeaderAction;
