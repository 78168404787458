import React from "react";
import PropTypes from "prop-types";
import AnswerItem from "./happiness_questionnaire_answer_list/answer_item";
import QuestionSelectBox from "./happiness_questionnaire_answer_list/question_select_box";

class HappinessQuestionnaireAnswerList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      action,
      answers,
      questions,
      selectedQuestionId,
      additionalFormInputHtml,
      showUserName,
      showDatetime,
      showDepartment,
      showQuestionnaireNote,
      selectedQuestionnaireEventAnswerTiming,
      compLinkUrl,
      compLinkTitle,
    } = this.props;
    return (
      <React.Fragment>
        <QuestionSelectBox
          questions={questions}
          action={action}
          selectedQuestionId={selectedQuestionId}
          selectedQuestionnaireEventAnswerTiming={
            selectedQuestionnaireEventAnswerTiming
          }
          additionalFormInputHtml={additionalFormInputHtml}
          showQuestionnaireNote={showQuestionnaireNote}
          compLinkUrl={compLinkUrl}
          compLinkTitle={compLinkTitle}
        />
        <table className="table user-answer-index__table">
          <thead>
            <tr>
              {showDatetime && (
                <td>
                  {window.I18n.t(
                    "javascript.answers.happiness_questionnaire_answer_list.date_time_title"
                  )}
                </td>
              )}
              <td>
                {window.I18n.t(
                  "javascript.answers.happiness_questionnaire_answer_list.answer_title"
                )}
              </td>
              {showUserName && (
                <td>
                  {window.I18n.t(
                    "javascript.answers.happiness_questionnaire_answer_list.user_title"
                  )}
                </td>
              )}
              {showDepartment && (
                <td>
                  {window.I18n.t(
                    "javascript.answers.happiness_questionnaire_answer_list.department_title"
                  )}
                </td>
              )}
            </tr>
          </thead>
          <tbody>
            {answers.map((answer) => (
              <AnswerItem
                key={answer.id}
                answer={answer}
                showDatetime={showDatetime}
                showUserName={showUserName}
                showDepartment={showDepartment}
              />
            ))}
          </tbody>
        </table>
        {answers.length === 0 && (
          <div style={{ padding: 10, color: "gray" }}>
            {window.I18n.t(
              "javascript.answers.happiness_questionnaire_answer_list.no_answer"
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
}

HappinessQuestionnaireAnswerList.propTypes = {
  action: PropTypes.string.isRequired,
  additionalFormInputHtml: PropTypes.string,
  selectedQuestionId: PropTypes.string.isRequired,
  selectedQuestionnaireEventAnswerTiming: PropTypes.string.isRequired,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ).isRequired,
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ).isRequired,
  showDatetime: PropTypes.bool,
  showUserName: PropTypes.bool,
  showDepartment: PropTypes.bool,
  showQuestionnaireNote: PropTypes.bool,
  compLinkTitle: PropTypes.string,
  compLinkUrl: PropTypes.string,
};
HappinessQuestionnaireAnswerList.defaultProps = {
  additionalFormInputHtml: "",
  showDatetime: false,
  showUserName: false,
  showDepartment: false,
  showQuestionnaireNote: false,
  compLinkTitle: "",
  compLinkUrl: "",
};
export default HappinessQuestionnaireAnswerList;
