import React from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import AddIcon from '../../images/ic_add.svg';
import Button from '../../button';
import Box from './shared/box';

class ImageField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { onClick } = this.props;
    return (
      <React.Fragment>
        <Box>
          <Button className=" w-100 h-100 cursor__pointer" onClick={() => onClick()}>
            <ReactSVG
              src={AddIcon}
              svgStyle={{
                fill: 'gray',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            />
          </Button>
        </Box>
      </React.Fragment>
    );
  }
}

ImageField.propTypes = {
  onClick: PropTypes.func,
};
ImageField.defaultProps = {
  onClick: () => {},
};
export default ImageField;
