export function errorMessages(model, columnName, viewLabelName) {
  if (!model.errors) {
    return [];
  }
  const errors = model.errors[columnName];
  if (!errors) {
    return [];
  }
  const fullErrorMessages = errors.map(m => (
    viewLabelName + m
  ));
  return fullErrorMessages;
}

export default errorMessages;
