import React from "react";
import PropTypes from "prop-types";
import EventCodeLink from "./event_code_link";
import EditLink from "./edit_link";
import MultiLineText from "../../shared/multi_line_text";

class EditItem extends React.Component {
  constructor(props) {
    super(props);
    const { event } = this.props;
    this.state = {
      event,
    };
  }

  render() {
    const {
      answerTimingOptions,
      questionnaireMethodOptions,
      action,
      authenticityToken,
      roleOptions,
      workHappinessQuestionnaireUsable,
      profileInputBirthYearOptions,
      profileInputBirthMonthOptions,
      profileInputSexOptions,
      profileInputForResearchOptions,
      surveyTypeOptions,
    } = this.props;
    const { event } = this.state;
    return (
      <tr key={event.id}>
        <td className="event-index__event-name-td">
          <a href={event.event_overview_url}>{event.title}</a>
        </td>
        <td className="event-index__td">
          {window.I18n.t(`enums.event.role.${event.role}`)}
        </td>
        <td className="event-index__td">
          {event.start_date
            ? window.I18n.l("date.formats.long", event.start_date)
            : "未定"}
        </td>
        <td className="event-index__td">
          {event.start_time ? event.start_time : "未定"}
        </td>
        <td className="event-index__td">
          {event.end_date
            ? window.I18n.l("date.formats.long", event.end_date)
            : "未定"}
        </td>
        <td className="event-index__td">
          {event.end_time ? event.end_time : "未定"}
        </td>
        {workHappinessQuestionnaireUsable && (
          <td className="event-index__td">
            <MultiLineText
              text={window.I18n.t(
                `enums.event.survey_type.${event.survey_type}`
              )}
            />
          </td>
        )}
        <td className="event-index__td">
          {window.I18n.t(`enums.event.answer_timing.${event.answer_timing}`)}
        </td>
        <td className="event-index__td">
          <a
            href={`/companies/events/${event.id}/happiness_questionnaire_questions`}
          >
            {`${event.questions_count}件`}
          </a>
        </td>
        <td className="event-index__td">
          <EventCodeLink event={event} />
        </td>
        <td className="event-index__td">
          {event.answer_timing === "before_after_answer_timing" && (
            <React.Fragment>
              {`前: ${event.before_event_questionnaires_count}人`}
              <br />
              {`後: ${event.after_event_questionnaires_count}人`}
            </React.Fragment>
          )}
          {event.answer_timing === "one_time_answer_timing" && (
            <span>{`${event.unset_questionnaires_count}人`}</span>
          )}
        </td>
        <td className="event-index__td">
          <EditLink
            event={event}
            answerTimingOptions={answerTimingOptions}
            questionnaireMethodOptions={questionnaireMethodOptions}
            roleOptions={roleOptions}
            action={action}
            authenticityToken={authenticityToken}
            afterSaved={(e) =>
              this.setState({ event: e }, () => {
                window.Alert.success(
                  `イベント「${e.title}」の設定変更を完了しました`
                );
              })
            }
            workHappinessQuestionnaireUsable={workHappinessQuestionnaireUsable}
            profileInputBirthYearOptions={profileInputBirthYearOptions}
            profileInputBirthMonthOptions={profileInputBirthMonthOptions}
            profileInputSexOptions={profileInputSexOptions}
            profileInputForResearchOptions={profileInputForResearchOptions}
            surveyTypeOptions={surveyTypeOptions}
          />
        </td>
      </tr>
    );
  }
}

EditItem.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.number,
  }),
  answerTimingOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  questionnaireMethodOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  roleOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  action: PropTypes.string.isRequired,
  authenticityToken: PropTypes.string.isRequired,
  workHappinessQuestionnaireUsable: PropTypes.bool.isRequired,
  profileInputBirthYearOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  profileInputBirthMonthOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  profileInputSexOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  profileInputForResearchOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  surveyTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
};
EditItem.defaultProps = {
  event: {},
};
export default EditItem;
