import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../shared/button';
import BaseMoal from '../../../shared/base_modal';
import BaseForm, { ajaxSubmit } from '../../../shared/base_form';
import TextForm from '../../../shared/form/text_form';
import { deleteRequest } from '../../../../utils/api_client';
import CreateModal from '../shared/create_modal';

class TopLevelDepartmentCreateButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
  }

  render() {
    const {afterCreated} = this.props;
    const { showModal } = this.state;
    return (
      <React.Fragment>
        {showModal && (
          <CreateModal
            showModal={showModal}
            onCloseButtonClicked={() => this.setState({showModal: false})}
            afterCreated={afterCreated}
          />
        )}
        <Button
          title="部署追加"
          onClick={() => this.setState({showModal: true})}
          className="btn react-department-list__department-create-button ml-2"
        />
      </React.Fragment>
    );
  }
}

TopLevelDepartmentCreateButton.propTypes = {
  afterCreated: PropTypes.func.isRequired
};
TopLevelDepartmentCreateButton.defaultProps = {
};
export default TopLevelDepartmentCreateButton;
