import React from "react";
import PropTypes from "prop-types";
import equal from "deep-equal";
import InfiniteScroll from "react-infinite-scroller";
import TimelineItem from "./timeline_center_container/timeline_item";
import { getRequest } from "../../../utils/api_client";
import LoadingImage from "../../shared/images/spin-gray.svg";
import NewPost from "./timeline_center_container/new_post";
import MissionSuggest from "../../missions/mission_suggest";

class TimelineList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timelines: [],
      loading: false,
      hasMore: true,
      query: {},
      renderCount: 0,
    };
    this.loadMore = this.loadMore.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { query } = nextProps;
    const { query: oldQuery, renderCount } = prevState;
    if (!equal(query, oldQuery)) {
      return {
        query,
        timelines: [],
        hasMore: true,
        renderCount: renderCount + 1,
      };
    }
    return null;
  }

  loadMore(page) {
    const { timelines: oldTimelines, query } = this.state;
    this.setState({ loading: true }, () => {
      getRequest("/wb/user/company/timelines", {
        page,
        q: query,
      })
        .then((r) => {
          const { data: newTimelines } = r;
          this.setState({
            loading: false,
            timelines: oldTimelines.concat(newTimelines),
            hasMore: newTimelines.length > 0,
          });
        })
        .catch((e) => {
          this.setState({ loading: false, hasMore: false });
        });
    });
  }

  render() {
    const { currentUser, missions, query, userProfile, belongingDepartments } =
      this.props;
    const { timelines, loading, hasMore, renderCount } = this.state;
    const { keyword_cont: keyword } = query;
    return (
      <div className="company-timeline-index__post-container">
        <NewPost
          currentUser={currentUser}
          belongingDepartments={belongingDepartments}
        />
        <MissionSuggest
          missions={missions}
          userProfile={userProfile}
          belongingDepartments={belongingDepartments}
        />
        <InfiniteScroll
          key={renderCount}
          pageStart={0}
          loadMore={this.loadMore}
          hasMore={!loading && hasMore}
          loader={
            <div className="p-2 color__gray" key={0}>
              Loading ...
            </div>
          }
        >
          {timelines.map((t) => (
            <TimelineItem
              key={t.id}
              currentUser={currentUser}
              timeline={t}
              userProfile={userProfile}
              keyword={keyword}
            />
          ))}
        </InfiniteScroll>
        {timelines.length === 0 && loading && (
          <div className="w-100 text-center py-2">
            <img
              src={LoadingImage}
              style={{
                width: 30,
                height: 30,
              }}
            />
          </div>
        )}
        {timelines.length === 0 && !loading && (
          <div className="p-2 color__gray">タイムラインがありません</div>
        )}
      </div>
    );
  }
}

TimelineList.propTypes = {
  currentUser: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  query: PropTypes.shape({}).isRequired,
  missions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ).isRequired,
  userProfile: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  belongingDepartments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ).isRequired,
};
TimelineList.defaultProps = {};
export default TimelineList;
