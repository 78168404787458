import React from 'react';
import PropTypes from 'prop-types';
import WorkPostEditModal from '../../work_posts/work_post_edit_modal';
import Button from '../../shared/button';
import TagLabels from '../../tags/tag_labels';
import { redirectToTimeline } from '../../../utils/redirect_path';

class WorkItemForPosting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  render() {
    const { work, userProfile, belongingDepartments } = this.props;
    const { showModal } = this.state;

    return (
      <React.Fragment>
        <WorkPostEditModal
          showModal={showModal}
          work={work}
          userProfile={userProfile}
          belongingDepartments={belongingDepartments}
          onCloseButtonClicked={() => this.setState({ showModal: false })}
          afterSaved={() => redirectToTimeline()}
        />
        <div className="p-2 card__div-3">
          <Button
            className="link__container cursor__pointer"
            style={{ textDecoration: 'none' }}
            onClick={() => this.setState({ showModal: true })}
          >
            <div className="card">
              {work.image_url && (
                <img
                  src={work.image_url}
                  className="card-img-top"
                  alt="..."
                />
              )}
              {!work.image_url && (
                <div
                  className="card-img-top bgcolor__lightgray"
                  style={{ height: 170 }}
                />
              )}
              <div className="card-body">
                <div className="mb-2">
                  <TagLabels tags={work.tags} />
                </div>
                <h5 className="card-title color__black">
                  {work.name}
                </h5>
                <p className="card-text color__black">
                  {work.overview}
                </p>
                <p className="card-text">
                  <small className="text-muted float-right">
                    実施する
                  </small>
                </p>
              </div>
            </div>
          </Button>
        </div>
      </React.Fragment>
    );
  }
}

WorkItemForPosting.propTypes = {
  work: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  userProfile: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  belongingDepartments: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
};
WorkItemForPosting.defaultProps = {
};
export default WorkItemForPosting;
