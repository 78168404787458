import React from 'react';
import PropTypes from 'prop-types';
import SelectForm from '../../../shared/form/select_form';
import TextForm from '../../../shared/form/text_form';
import IndustryForm from './user_profile_form/industry_form';
import LivingAreaForm from './user_profile_form/living_area_form';
import MembersForm from './user_profile_form/members_form';

class UserProfileForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const {
      userProfile, sexOptions, birthYearOptions,
      birthMonthOptions, finalEducationOptions, childStatusOptions,
      professionOptions, personalAnnualIncomeOptions, householdAnnualIncomeOptions,
      maritalStatusOptions, prefectureOptions, industryCategories, user,
      departmentOptions, managerialPositionOptions, members, departments,
      countryOptions,
    } = this.props;
    const isJaLocale = window.I18n.locale === 'ja';

    return (
      <React.Fragment>
        <SelectForm
          scope="user_profile_form"
          model={userProfile}
          relatedModelName="user_profile"
          columnName="sex"
          options={sexOptions}
          labelColor="gray"
          required={user.is_consumer || user.profile_input_sex === 'require_profile_input_sex'}
          option={{ include_blank: window.I18n.t('javascript.user_profiles.form.unselected') }}
        />
        {user.profile_input_birth_year !== 'hide_profile_input_birth_year' && (
          <SelectForm
            scope="user_profile_form"
            model={userProfile}
            relatedModelName="user_profile"
            columnName="birth_year"
            options={birthYearOptions}
            labelColor="gray"
            required={user.is_consumer || user.profile_input_birth_year === 'require_profile_input_birth_year'}
            option={{ include_blank: window.I18n.t('javascript.user_profiles.form.unselected') }}
          />
        )}
        {user.profile_input_birth_month !== 'hide_profile_input_birth_month' && (
          <SelectForm
            scope="user_profile_form"
            model={userProfile}
            relatedModelName="user_profile"
            columnName="birth_month"
            options={birthMonthOptions}
            labelColor="gray"
            required={user.is_consumer || user.profile_input_birth_month === 'require_profile_input_birth_month'}
            option={{ include_blank: window.I18n.t('javascript.user_profiles.form.unselected') }}
          />
        )}
        {user.is_consumer && (
          <TextForm
            scope="user_profile_form"
            model={userProfile}
            relatedModelName="user_profile"
            columnName="company_name"
            labelColor="gray"
          />
        )}
        {!isJaLocale && (
          <SelectForm
            scope="user_profile_form"
            model={userProfile}
            relatedModelName="user_profile"
            columnName="country_code"
            options={countryOptions}
            option={{ include_blank: window.I18n.t('javascript.user_profiles.form.unselected') }}
            labelColor="gray"
          />
        )}
        {isJaLocale && user.self_department_changeable && (
          <MembersForm
            members={members}
            departments={departments}
            departmentOptions={departmentOptions}
            managerialPositionOptions={managerialPositionOptions}
          />
        )}
        {user.profile_changeable && (
          <React.Fragment>
            <SelectForm
              scope="user_profile_form"
              model={userProfile}
              relatedModelName="user_profile"
              columnName="final_education"
              options={finalEducationOptions}
              option={{ include_blank: window.I18n.t('javascript.user_profiles.form.unselected') }}
              labelColor="gray"
            />
            <SelectForm
              scope="user_profile_form"
              model={userProfile}
              relatedModelName="user_profile"
              columnName="profession"
              options={professionOptions}
              option={{ include_blank: window.I18n.t('javascript.user_profiles.form.unselected') }}
              labelColor="gray"
            />
            {isJaLocale && (
              <React.Fragment>
                <SelectForm
                  scope="user_profile_form"
                  model={userProfile}
                  relatedModelName="user_profile"
                  columnName="personal_annual_income"
                  options={personalAnnualIncomeOptions}
                  option={{ include_blank: window.I18n.t('javascript.user_profiles.form.unselected') }}
                  labelColor="gray"
                />
                <SelectForm
                  scope="user_profile_form"
                  model={userProfile}
                  relatedModelName="user_profile"
                  columnName="household_annual_income"
                  options={householdAnnualIncomeOptions}
                  option={{ include_blank: window.I18n.t('javascript.user_profiles.form.unselected') }}
                  labelColor="gray"
                />
              </React.Fragment>
            )}
            <SelectForm
              scope="user_profile_form"
              model={userProfile}
              relatedModelName="user_profile"
              columnName="marital_status"
              options={maritalStatusOptions}
              option={{ include_blank: window.I18n.t('javascript.user_profiles.form.unselected') }}
              labelColor="gray"
            />
            <SelectForm
              scope="user_profile_form"
              model={userProfile}
              relatedModelName="user_profile"
              columnName="child_status"
              options={childStatusOptions}
              option={{ include_blank: window.I18n.t('javascript.user_profiles.form.unselected') }}
              labelColor="gray"
            />
            {isJaLocale && (
              <React.Fragment>
                <LivingAreaForm
                  userProfile={userProfile}
                  prefectureOptions={prefectureOptions}
                />
                <IndustryForm
                  userProfile={userProfile}
                  industryCategories={industryCategories}
                />
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

UserProfileForm.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  members: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
  userProfile: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  sexOptions: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  birthYearOptions: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  birthMonthOptions: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  finalEducationOptions: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  professionOptions: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  personalAnnualIncomeOptions: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  householdAnnualIncomeOptions: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  maritalStatusOptions: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  childStatusOptions: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  prefectureOptions: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  countryOptions: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  industryCategories: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    industries: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })),
  })).isRequired,
  departmentOptions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
  managerialPositionOptions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
  departments: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
};
UserProfileForm.defaultProps = {
};
export default UserProfileForm;
