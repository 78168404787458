import React from "react";
import PropTypes from "prop-types";
import Button from "../../shared/button";
import BaseMoal from "../../shared/base_modal";
import ShareIcon from "../../shared/images/ic_share_grey.png";
import { postRequest } from "../../../utils/api_client";
import ShareImageView from "../shared/share_image_view";

class ConsumerShareLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      loading: false,
      instagramSelected: false,
    };
  }

  tracking() {
    const { linkType } = this.props;
    postRequest("/user/tracks", { happiness_structure_link_type: linkType });
  }

  render() {
    const { linkType, questionnaire, snsShareCount } = this.props;
    const { showModal, loading, instagramSelected } = this.state;

    return (
      <React.Fragment>
        <BaseMoal
          show={showModal}
          title={
            instagramSelected
              ? window.I18n.t(
                  "javascript.questionnaires.questionnaire_share_link.share_happiness_structure_by_instagram"
                )
              : window.I18n.t(
                  "javascript.questionnaires.questionnaire_share_link.share_happiness_structure"
                )
          }
          contentLabel="shareQuestionnaire"
          loading={loading}
          onCloseModal={() =>
            this.setState({ showModal: false, instagramSelected: false })
          }
          contentStyle={{ maxWidth: 496, width: "100%" }}
        >
          <ShareImageView
            questionnaire={questionnaire}
            snsShareCount={snsShareCount}
            beforeLoading={(callback) =>
              this.setState({ loading: true }, callback)
            }
            afterLoading={() => this.setState({ loading: false })}
          />
        </BaseMoal>
        {linkType === "link" && (
          <Button
            title={window.I18n.t(
              "javascript.questionnaires.questionnaire_share_link.happiness_structure"
            )}
            className="btn btn-link font__size-14"
            onClick={() =>
              this.setState({ showModal: true }, () => {
                this.tracking();
              })
            }
          />
        )}
        {linkType !== "link" && (
          <Button
            className="btn button__highlignt mt-2 w-100"
            onClick={() =>
              this.setState({ showModal: true }, () => {
                this.tracking();
              })
            }
            style={{ whiteSpace: "normal" }}
          >
            <img
              src={ShareIcon}
              alt="share icon"
              style={{ height: 20, marginTop: -2 }}
            />
            <span className="ml-2">
              {window.I18n.t(
                "javascript.questionnaires.questionnaire_share_link.share_happiness_structure"
              )}
            </span>
          </Button>
        )}
      </React.Fragment>
    );
  }
}

ConsumerShareLink.propTypes = {
  questionnaire: PropTypes.shape({
    id: PropTypes.number,
    share_token: PropTypes.string,
    share_image_url: PropTypes.string,
  }).isRequired,
  linkType: PropTypes.string.isRequired,
  snsShareCount: PropTypes.number.isRequired,
};
ConsumerShareLink.defaultProps = {};
export default ConsumerShareLink;
