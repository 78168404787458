import React from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import TextareaForm from '../shared/form/textarea_form';
import Button from '../shared/button';
import BaseForm, { ajaxSubmit } from '../shared/base_form';
import ErrorContainer from '../shared/error_container';
import ImageForm from '../shared/form/image_form';
import ClipIcon from '../shared/images/ic_clip.svg';
import PhotoIcon from '../shared/images/ic_photo.svg';
import FileForm from '../shared/form/file_form';
import DisplayDepartmentForm from '../shared/form/display_department_form';
import BaseMoal from '../shared/base_modal';

const baseUrl = '/wb/user/posts';
class PostEditForm extends React.Component {
  constructor(props) {
    super(props);
    const { post } = this.props;
    this.state = {
      post,
      errorMessages: [],
      displayImageForm: post.file_resources && post.file_resources.filter(fr => fr.file_type === 'image_file_type').length > 0,
      uploading: false,
    };
    this.inputRef = React.createRef();
    this.imageFormInputRef = React.createRef();
    this.fileFormInputRef = React.createRef();
    this.save = this.save.bind(this);
  }

  componentDidMount() {
    if (this.inputRef.current) {
      this.inputRef.current.focus();
    }
  }

  save() {
    const { beforeSaved, afterSaved, afterFailed } = this.props;
    const form = document.forms.postEditForm;
    if (form.checkValidity()) {
      beforeSaved(() => {
        ajaxSubmit(form)
          .then((r) => {
            const { data: post } = r;
            afterSaved(post);
          })
          .catch((e) => {
            const { data, status } = e.response;
            if (status === 402) {
              const { errors } = data;
              this.setState({ errorMessages: errors }, () => afterFailed());
            } else {
              afterFailed();
            }
          });
      });
    } else {
      // 検証結果がfalseなのでsubmitしてhtml5のvalidationメッセージを表示させる
      const submit = document.getElementById('postEditSubmit');
      submit.click();
    }
  }

  render() {
    const { belongingDepartments } = this.props;
    const {
      post, errorMessages, displayImageForm, uploading,
    } = this.state;
    return (
      <React.Fragment>
        <div>
          <BaseForm
            name="postEditForm"
            action={post.id ? `${baseUrl}/${post.id}` : baseUrl}
            method={post.id ? 'put' : 'post'}
          >
            <BaseMoal.Body>
              <DisplayDepartmentForm
                scope="post"
                model={post}
                defaultDepartments={belongingDepartments}
                renderHeader={() => (
                  <div className="color__gray font__size-13">
                    公開範囲
                  </div>
                )}
              />
              <TextareaForm
                ref={this.inputRef}
                scope="post"
                columnName="body"
                model={post}
                rows={10}
                hideLabel
                placeholder="投稿内容"
              />
              <ImageForm
                ref={this.imageFormInputRef}
                multiple
                scope="post"
                model={post}
                beforeUpload={callback => this.setState({ uploading: true }, () => {
                  callback();
                })}
                afterUpload={() => this.setState({ uploading: false })}
                renderHeader={() => (
                  <div className="color__gray font__size-13">
                    画像
                  </div>
                )}
              />
              <FileForm
                ref={this.fileFormInputRef}
                scope="post"
                model={post}
                beforeUpload={callback => this.setState({ uploading: true }, () => {
                  callback();
                })}
                afterUpload={() => this.setState({ uploading: false })}
                renderHeader={() => (
                  <div className="color__gray font__size-13">
                    添付ファイル
                  </div>
                )}
              />
              <div className="w-100 d-flex justify-content-start mt-4">
                <Button
                  className="btn btn-light"
                  style={{ borderRadius: 15 }}
                  disabled={displayImageForm}
                  onClick={() => {
                    if (this.imageFormInputRef && this.imageFormInputRef.current) {
                      this.imageFormInputRef.current.click();
                    }
                  }}
                >
                  <ReactSVG
                    src={PhotoIcon}
                    svgStyle={{ width: 25, height: 25, fill: 'gray' }}
                  />
                </Button>
                <Button
                  className="btn btn-light ml-2"
                  style={{ borderRadius: 15 }}
                  onClick={() => {
                    if (this.fileFormInputRef && this.fileFormInputRef.current) {
                      this.fileFormInputRef.current.click();
                    }
                  }}
                >
                  <ReactSVG
                    src={ClipIcon}
                    svgStyle={{ width: 25, height: 25, fill: 'gray' }}
                  />
                </Button>
              </div>
              <ErrorContainer errorMessages={errorMessages} />
              <div className="w-100 mb-3" style={{ height: 40 }}>
                <button type="submit" id="postEditSubmit" style={{ border: 'none', padding: 0 }} />
                <Button
                  title="投稿"
                  className="btn button__primary float-right"
                  onClick={this.save}
                  disabled={uploading}
                />
              </div>
            </BaseMoal.Body>
          </BaseForm>
        </div>
      </React.Fragment>
    );
  }
}

PostEditForm.propTypes = {
  belongingDepartments: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })),
  post: PropTypes.shape({
    id: PropTypes.number,
  }),
  beforeSaved: PropTypes.func.isRequired,
  afterSaved: PropTypes.func.isRequired,
  afterFailed: PropTypes.func.isRequired,
};
PostEditForm.defaultProps = {
  post: { file_resources: [] },
  belongingDepartments: [],
};
export default PostEditForm;
