import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format, parseISO } from "date-fns";
import React, { ReactElement, useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { QuestionnaireResponse } from "../../../models/Questionnaire";
import { QuestionnaireAdviceResponse } from "../../../models/QuestionnaireAdvice";
import getRequest from "../../../utils/api_client";
import { formatForMarkdown } from "../../../utils/markdown_formatter";
import Button from "../../shared/button";
import ShareIcon from "../../shared/images/ic_share_grey.png";
import AdviceInstagramShareView from "./advice_instagram_share_view";
import AdviceShareView from "./advice_share_view";

interface Props {
  role: "praise" | "advice";
  questionnaire: QuestionnaireResponse;
  questionnaireAdvice: QuestionnaireAdviceResponse | undefined;
  comparisonQuestionnaire: QuestionnaireResponse | null;
  comparisonQuestionnaireAdvice: QuestionnaireAdviceResponse | undefined;
  isConsumer: boolean;
  beforeLoading: () => void;
  afterLoading: () => void;
}

const hashTags = ["幸福度診断", "WellBeingCircle", "ハッピーAI"];

export default function AdviceContent(props: Props): ReactElement {
  const {
    role,
    questionnaire,
    comparisonQuestionnaire,
    comparisonQuestionnaireAdvice: initialComparisonQuestionnaireAdvice,
    questionnaireAdvice: initialQuestionnaireAdvice,
    isConsumer,
    beforeLoading,
    afterLoading,
  } = props;
  const [target, setTarget] = useState<"latest" | "comparison">("latest");
  const [questionnaireAdvice, setQuestionnaireAdvice] = useState(
    initialQuestionnaireAdvice
  );
  const [comparisonQuestionnaireAdvice, setComparisonQuestionnaireAdvice] =
    useState(initialComparisonQuestionnaireAdvice);
  const [displayMode, setDisplayMode] = useState<
    "message" | "share" | "instagramShare"
  >("message");

  useEffect(() => {
    if (questionnaireAdvice === undefined) {
      load();
    }
  }, []);

  useEffect(() => {
    if (
      target === "comparison" &&
      comparisonQuestionnaireAdvice === undefined
    ) {
      loadComparison();
    }
  }, [target]);

  const load = () => {
    beforeLoading();
    getRequest(
      role === "praise"
        ? `/user/questionnaires/${questionnaire.id}/questionnaire_advices/show_praise`
        : `/user/questionnaires/${questionnaire.id}/questionnaire_advices/show_advice`
    )
      .then((r) => {
        const { data } = r;
        if (data.state !== undefined && data.state === "activated") {
          setQuestionnaireAdvice(data);
        }
        afterLoading();
      })
      .catch((e) => {
        afterLoading();
      });
  };

  const loadComparison = () => {
    if (comparisonQuestionnaire === null) {
      return;
    }
    beforeLoading();
    getRequest(
      role === "praise"
        ? `/user/questionnaires/${comparisonQuestionnaire.id}/questionnaire_advices/show_praise`
        : `/user/questionnaires/${comparisonQuestionnaire.id}/questionnaire_advices/show_advice`
    )
      .then((r) => {
        const { data } = r;
        if (data.state !== undefined && data.state === "activated") {
          setComparisonQuestionnaireAdvice(data);
        }
        afterLoading();
      })
      .catch((e) => {
        afterLoading();
      });
  };

  if (questionnaireAdvice === undefined) {
    return (
      <div className="text-center p-3">
        <div>
          ハッピーAIのメッセージを作成中です。
          <br />
          <br />
          大変申し訳ありませんが
          <br />
          作成に20秒から1分程度かかるので、
          <br />
          幸せのかたちの確認や、
          <br />
          幸せの振り返りを行ってお待ち下さい。
        </div>
        <div className="mt-2">
          <Button
            title="メッセージを読み込む"
            className="btn button__primary m-2"
            onClick={load}
          />
        </div>
      </div>
    );
  }

  if (target === "comparison" && comparisonQuestionnaire !== null) {
    return (
      <>
        <div className="text-right">
          <Button
            title="最新のメッセージを見る"
            className="color__link font__size-14 cursor__pointer"
            onClick={() => setTarget("latest")}
          />
        </div>
        <div className="mt-3">
          <div className="bgcolor__trivial font__size-13 p-1">
            {format(
              parseISO(comparisonQuestionnaire.created_at),
              "yyyy年MM月dd日 HH時mm分"
            )}
          </div>
        </div>
        {comparisonQuestionnaireAdvice === undefined && (
          <div className="text-center p-3">
            <div>
              ハッピーAIのメッセージを作成中です。
              <br />
              <br />
              大変申し訳ありませんが
              <br />
              作成に20から30秒ほどかかるので、
              <br />
              幸せのかたちの確認や、
              <br />
              幸せの振り返りを行ってお待ち下さい。
            </div>
            <div className="mt-2">
              <Button
                title="メッセージを読み込む"
                className="btn button__primary m-2"
                onClick={loadComparison}
              />
            </div>
          </div>
        )}
        {comparisonQuestionnaireAdvice !== undefined &&
          comparisonQuestionnaireAdvice.body !== null && (
            <div className="mt-1 user-happiness__ai-message-body p-2 markdown__container">
              <ReactMarkdown
                children={formatForMarkdown(comparisonQuestionnaireAdvice.body)}
                remarkPlugins={[remarkGfm]}
              />
            </div>
          )}
      </>
    );
  }

  if (displayMode === "share") {
    return (
      <>
        <div>
          <Button
            onClick={() => setDisplayMode("message")}
            className="btn color__link"
          >
            <FontAwesomeIcon icon={faChevronLeft} className="font__size-13" />
            <span className="ml-2">戻る</span>
          </Button>
        </div>
        <AdviceShareView
          questionnaireAdvice={questionnaireAdvice}
          hashTags={hashTags}
          onSelectInstagram={() => {
            setDisplayMode("instagramShare");
          }}
        />
      </>
    );
  }

  if (displayMode === "instagramShare") {
    return (
      <>
        <div>
          <Button
            onClick={() => setDisplayMode("share")}
            className="btn color__link"
          >
            <FontAwesomeIcon icon={faChevronLeft} className="font__size-13" />
            <span className="ml-2">戻る</span>
          </Button>
        </div>
        <AdviceInstagramShareView
          questionnaireAdvice={questionnaireAdvice}
          hashTags={hashTags}
        />
      </>
    );
  }

  return (
    <>
      {comparisonQuestionnaire !== null && (
        <>
          <div className="text-right p-1">
            <Button
              title={`比較時点(${format(
                parseISO(comparisonQuestionnaire.created_at),
                "yyyy年MM月dd日 HH時mm分"
              )})での${role === "praise" ? "メッセージ" : "アドバイス"}を見る`}
              className="color__link font__size-13 cursor__pointer"
              onClick={() => setTarget("comparison")}
            />
          </div>
        </>
      )}
      {isConsumer && (
        <div className="text-right mt-3">
          <Button onClick={() => setDisplayMode("share")} className="btn">
            <img
              src={ShareIcon}
              alt="share icon"
              style={{ height: 20, marginTop: -2 }}
            />
            <span className="ml-1">SNSでシェア</span>
          </Button>
        </div>
      )}
      {comparisonQuestionnaire !== null && (
        <div className="mt-1">
          <div className="bgcolor__trivial font__size-13 p-1">
            {format(
              parseISO(questionnaire.created_at),
              "yyyy年MM月dd日 HH時mm分"
            )}
          </div>
        </div>
      )}
      {questionnaireAdvice.body !== null && (
        <div className="user-happiness__ai-message-body p-2 rounded mt-1 markdown__container">
          <ReactMarkdown
            children={formatForMarkdown(questionnaireAdvice.body)}
            remarkPlugins={[remarkGfm]}
          />
        </div>
      )}
      {isConsumer && (
        <div className="text-center mt-3">
          <Button onClick={() => setDisplayMode("share")} className="btn">
            <img
              src={ShareIcon}
              alt="share icon"
              style={{ height: 20, marginTop: -2 }}
            />
            <span className="ml-1">SNSでシェア</span>
          </Button>
        </div>
      )}
    </>
  );
}
