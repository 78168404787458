export function getQuestionnaireNoteModalOpen() {
  return window.questionnaireNoteOpen;
}
export function setQuestionnaireNoteModalOpen(opened) {
  window.questionnaireNoteOpen = opened;
}
export function getQuestionnaireNoteQuestionnaire() {
  return window.questionnaireNoteQuestionnaire;
}
export function setQuestionnaireNoteQuestionnaire(questionnaire) {
  window.questionnaireNoteQuestionnaire = questionnaire;
}
export function getQuestionnaireNoteCompQuestionnaire() {
  return window.questionnaireNoteCompQuestionnaire;
}
export function setQuestionnaireNoteCompQuestionnaire(questionnaire) {
  window.questionnaireNoteCompQuestionnaire = questionnaire;
}

export function getAfterMeasurementModalLoadCount() {
  return window.loadCount || 0;
}

export function setAfterMeasurementModalLoadCount(c) {
  window.loadCount = c;
}
