import React, { ReactElement, useEffect, useState } from "react";
import SelectForm from "../../../../../../shared/form/select_form";
import { getRequest } from "../../../../../../../utils/api_client";
import { UserProfileMyselfResponse } from "../../../../../../../models/UserProfile";

function codeFormatter(prefectureCode) {
  return `00${prefectureCode}`.slice(-2);
}

interface Props {
  userProfile: UserProfileMyselfResponse | null;
  prefectureOptions: Array<{ key: string; value: string }>;
}

export default function LivingAreaForm(props: Props): ReactElement {
  const { userProfile, prefectureOptions } = props;
  const [cityLoading, setCityLoading] = useState(false);
  const [cityOptions, setCityOptions] = useState([]);

  useEffect(() => {
    if (
      userProfile?.prefecture_code !== undefined &&
      userProfile.prefecture_code !== null
    ) {
      loadCityOptions(userProfile.prefecture_code);
    }
  }, []);

  const loadCityOptions = (prefectureCode: number) => {
    const pc = codeFormatter(prefectureCode);
    setCityLoading(true);
    getRequest("/cities", { q: { prefecture_code_eq: pc } })
      .then((r) => {
        const cityOptions = r.data.map((d) => ({
          key: String(d.id),
          value: d.name,
        }));
        setCityLoading(false);
        setCityOptions(cityOptions);
      })
      .catch((e) => {
        setCityLoading(false);
      });
  };

  return (
    <React.Fragment>
      <SelectForm
        scope="questionnaire"
        model={userProfile}
        relatedModelName="user_profile"
        columnName="prefecture_code"
        options={prefectureOptions}
        option={{ include_blank: "-- 都道府県 --" }}
        onChange={(e) => loadCityOptions(e.target.value)}
      />
      {!cityLoading && cityOptions.length > 0 && (
        <SelectForm
          scope="questionnaire"
          model={userProfile}
          relatedModelName="user_profile"
          columnName="city_id"
          options={cityLoading ? [] : cityOptions}
          option={{ include_blank: "-- 市区町村 --" }}
          label=" "
        />
      )}
    </React.Fragment>
  );
}
