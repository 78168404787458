import React from 'react';
import PropTypes from 'prop-types';
import BaseModal from '../shared/base_modal';
import PostEditForm from './post_edit_form';

class PostEditModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  render() {
    const {
      showModal, onCloseButtonClicked, post, afterSaved,
      belongingDepartments, stopLoadingAfterSaved,
    } = this.props;
    const { loading } = this.state;
    return (
      <React.Fragment>
        <BaseModal
          show={showModal}
          title="投稿"
          contentLabel="editPost"
          onCloseModal={() => onCloseButtonClicked()}
          loading={loading}
          contentStyle={{ maxWidth: 496, width: '100%' }}
        >
          <PostEditForm
            post={post}
            belongingDepartments={belongingDepartments}
            beforeSaved={callback => this.setState({ loading: true }, callback)}
            afterSaved={(editedPost) => {
              if (stopLoadingAfterSaved) {
                this.setState({ loading: false }, afterSaved(editedPost));
              } else {
                afterSaved(editedPost);
              }
            }}
            afterFailed={() => this.setState({ loading: false })}
            onCloseButtonClicked={() => onCloseButtonClicked()}
          />
        </BaseModal>
      </React.Fragment>
    );
  }
}

PostEditModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  post: PropTypes.shape({
    id: PropTypes.number,
  }),
  belongingDepartments: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })),
  onCloseButtonClicked: PropTypes.func.isRequired,
  afterSaved: PropTypes.func.isRequired,
  stopLoadingAfterSaved: PropTypes.bool,
};
PostEditModal.defaultProps = {
  post: {},
  belongingDepartments: [],
  stopLoadingAfterSaved: false,
};
export default PostEditModal;
