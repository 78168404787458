import PropTypes from "prop-types";
import React from "react";

class Link extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { href, className, linkName, target, hideWhenApli } = this.props;
    if (hideWhenApli && window.isApli) {
      return null;
    }
    return (
      <a className={className} target={target} href={href}>
        {linkName}
      </a>
    );
  }
}
Link.propTypes = {
  href: PropTypes.string,
  className: PropTypes.string,
  linkName: PropTypes.string.isRequired,
  hideWhenApli: PropTypes.bool,
  target: PropTypes.string,
};
Link.defaultProps = {
  href: "",
  target: "",
  className: "",
  hideWhenApli: false,
};
export default Link;
