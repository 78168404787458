import React, { ReactElement } from "react";
import { Bar } from "react-chartjs-2";

const options = (opt: {
  chartId: string;
  scaleMin: number;
  scaleMax: number;
  stepSize: number;
  labels: string[];
  tooltipLabels: string[];
}) => {
  return {
    scales: {
      yAxes: [
        {
          ticks: {
            min: opt.scaleMin,
            max: opt.scaleMax,
            stepSize: opt.stepSize,
          },
        },
      ],
    },
    legend: {
      // position: "bottom",
      display: false,
    },
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      enabled: false,
      mode: "index",
      custom: function (tooltipModel) {
        // Tooltip Element
        let tooltipEl = document.getElementById("chartjs-tooltip");

        // Create element on first render
        if (!tooltipEl) {
          tooltipEl = document.createElement("div");
          tooltipEl.id = "chartjs-tooltip";
          tooltipEl.innerHTML = "<table></table>";
          document.body.appendChild(tooltipEl);
        }

        // Hide if no tooltip
        if (tooltipModel.opacity === 0) {
          tooltipEl.style.opacity = "0";
          return;
        }

        // Set caret Position
        tooltipEl.classList.remove("above", "below", "no-transform");
        if (tooltipModel.yAlign) {
          tooltipEl.classList.add(tooltipModel.yAlign);
        } else {
          tooltipEl.classList.add("no-transform");
        }

        function getBody(bodyItem) {
          return bodyItem.lines;
        }

        // Set Text
        if (tooltipModel.body && tooltipModel.dataPoints) {
          const bodyLines = tooltipModel.body.map(getBody);

          let innerHtml = "<thead>";
          innerHtml += "</thead><tbody>";

          bodyLines.forEach(function (body, i) {
            const colors = tooltipModel.labelColors[i];
            let style = "background-color:" + colors.backgroundColor;
            const dataPoint = tooltipModel.dataPoints[i];
            style += "; border-color:" + colors.borderColor;
            style += "; border-width: 2px";
            const span = '<span style="' + style + '"></span>';
            const labels = opt.tooltipLabels;
            const label = labels[dataPoint.index];
            innerHtml +=
              "<tr><td>" +
              span +
              tooltipModel.title +
              ": " +
              label +
              "</td></tr>";
          });
          innerHtml += "</tbody>";

          const tableRoot = tooltipEl.querySelector("table");
          if (tableRoot !== null) {
            tableRoot.innerHTML = innerHtml;
          }
          const baseElement = document.getElementById(opt.chartId);
          if (baseElement !== null) {
            const position = baseElement.getBoundingClientRect();

            // Display, position, and set styles for font
            tooltipEl.style.opacity = "1";
            tooltipEl.style.position = "absolute";
            tooltipEl.style.left =
              position.left + window.pageXOffset + tooltipModel.caretX + "px";
            tooltipEl.style.top =
              position.top + window.pageYOffset + tooltipModel.caretY + "px";
            tooltipEl.style.fontSize = tooltipModel.bodyFontSize + "px";
            tooltipEl.style.padding =
              tooltipModel.yPadding + "px " + tooltipModel.xPadding + "px";
            tooltipEl.style.pointerEvents = "none";
            tooltipEl.style.backgroundColor = "#F8F7F7";
            tooltipEl.style.border = "solid";
            tooltipEl.style.borderWidth = "1";
            tooltipEl.style.borderColor = "lightgray";
          }
        }
      },
    },
  };
};

interface Props {
  chartId: string;
  scaleMin: number;
  scaleMax: number;
  stepSize: number;
  labels: string[];
  tooltipLabels: string[];
  datasets: Array<{
    label: string;
    data: number[];
    color: string;
  }>;
  verticalLinePosition: number | null;
  width?: number;
}

export default function DistributionChart(props: Props): ReactElement {
  const {
    chartId,
    scaleMin,
    scaleMax,
    stepSize,
    labels,
    tooltipLabels,
    datasets,
    verticalLinePosition,
    width = 450,
  } = props;
  return (
    <div id={chartId}>
      <Bar
        width={width}
        height={250}
        data={{
          labels: labels,
          datasets: datasets.map((d) => ({
            label: d.label,
            data: d.data,
            fill: false,
            backgroundColor: d.color,
            borderColor: d.color,
            pointBackgroundColor: d.color,
          })),
          lineAtIndex: 30,
        }}
        options={options({
          chartId,
          scaleMin,
          scaleMax,
          stepSize,
          labels,
          tooltipLabels,
        })}
        plugins={[
          {
            afterDatasetsDraw: (chartInstance, easing) => {
              const { ctx } = chartInstance.chart;
              const index = verticalLinePosition;
              if (index) {
                const xaxis = chartInstance.scales["x-axis-0"];
                const yaxis = chartInstance.scales["y-axis-0"];
                ctx.save();
                ctx.beginPath();
                ctx.moveTo(xaxis.getPixelForValue(undefined, index), yaxis.top);
                ctx.strokeStyle = "gray";
                ctx.lineWidth = 3;
                ctx.lineTo(
                  xaxis.getPixelForValue(undefined, index),
                  yaxis.bottom
                );
                ctx.stroke();
                ctx.restore();
              }
            },
          },
        ]}
      />
    </div>
  );
}
