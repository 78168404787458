import React from "react";
import PropTypes from "prop-types";
import { ReactSVG } from "react-svg";
import EditIcon from "../../shared/images/edit.svg";
import EventEditModal from "../shared/event_edit_modal";

class EditLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  render() {
    const {
      event,
      answerTimingOptions,
      questionnaireMethodOptions,
      action,
      authenticityToken,
      roleOptions,
      afterSaved,
      workHappinessQuestionnaireUsable,
      profileInputBirthYearOptions,
      profileInputBirthMonthOptions,
      profileInputSexOptions,
      profileInputForResearchOptions,
      surveyTypeOptions,
    } = this.props;
    const { showModal } = this.state;
    return (
      <React.Fragment>
        {showModal && (
          <EventEditModal
            showModal={showModal}
            event={event}
            onCloseButtonClicked={() => this.closeModal()}
            action={action}
            authenticityToken={authenticityToken}
            answerTimingOptions={answerTimingOptions}
            questionnaireMethodOptions={questionnaireMethodOptions}
            roleOptions={roleOptions}
            workHappinessQuestionnaireUsable={workHappinessQuestionnaireUsable}
            afterSaved={(e) => {
              this.setState({ showModal: false }, () => afterSaved(e));
            }}
            profileInputBirthYearOptions={profileInputBirthYearOptions}
            profileInputBirthMonthOptions={profileInputBirthMonthOptions}
            profileInputSexOptions={profileInputSexOptions}
            profileInputForResearchOptions={profileInputForResearchOptions}
            surveyTypeOptions={surveyTypeOptions}
          />
        )}
        <ReactSVG
          src={EditIcon}
          beforeInjection={(svg) => {
            svg.classList.add("event-index__edit-icon");
          }}
          onClick={() => this.setState({ showModal: true })}
        />
      </React.Fragment>
    );
  }
}

EditLink.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.number,
  }),
  answerTimingOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  questionnaireMethodOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  roleOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  action: PropTypes.string.isRequired,
  authenticityToken: PropTypes.string.isRequired,
  afterSaved: PropTypes.func.isRequired,
  workHappinessQuestionnaireUsable: PropTypes.bool.isRequired,
  profileInputBirthYearOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  profileInputBirthMonthOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  profileInputSexOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  profileInputForResearchOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  surveyTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
};
EditLink.defaultProps = {
  event: {},
};
export default EditLink;
