import React from "react";
import PropTypes from "prop-types";
import AnswerChoiceSummaryItem from "./answer_choice_summary/answer_choice_summary_item";
import QuestionSelectBox from "./answer_choice_summary/question_select_box";

class AnswerChoiceSummaryList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      action,
      answerChoiceSummaries,
      questions,
      selectedQuestionId,
      additionalFormInputHtml,
      selectedQuestionnaireEventAnswerTiming,
      showQuestionnaireNote,
      compLinkUrl,
      compLinkTitle,
    } = this.props;
    return (
      <React.Fragment>
        <QuestionSelectBox
          questions={questions}
          action={action}
          selectedQuestionId={selectedQuestionId}
          selectedQuestionnaireEventAnswerTiming={
            selectedQuestionnaireEventAnswerTiming
          }
          additionalFormInputHtml={additionalFormInputHtml}
          showQuestionnaireNote={showQuestionnaireNote}
          compLinkUrl={compLinkUrl}
          compLinkTitle={compLinkTitle}
        />
        <table className="table user-answer-index__table">
          <thead>
            <tr>
              <td>回答</td>
              <td>回答数</td>
            </tr>
          </thead>
          <tbody>
            {answerChoiceSummaries.map((answerChoiceSummary) => (
              <AnswerChoiceSummaryItem
                key={answerChoiceSummary.body}
                answerChoiceSummary={answerChoiceSummary}
              />
            ))}
          </tbody>
        </table>
        {answerChoiceSummaries.length === 0 && (
          <div style={{ padding: 10, color: "gray" }}>回答がありません</div>
        )}
      </React.Fragment>
    );
  }
}

AnswerChoiceSummaryList.propTypes = {
  action: PropTypes.string.isRequired,
  additionalFormInputHtml: PropTypes.string,
  selectedQuestionId: PropTypes.string.isRequired,
  selectedQuestionnaireEventAnswerTiming: PropTypes.string.isRequired,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ).isRequired,
  answerChoiceSummaries: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ).isRequired,
  showQuestionnaireNote: PropTypes.bool,
  compLinkTitle: PropTypes.string,
  compLinkUrl: PropTypes.string,
};
AnswerChoiceSummaryList.defaultProps = {
  additionalFormInputHtml: "",
  showQuestionnaireNote: false,
  compLinkTitle: "",
  compLinkUrl: "",
};
export default AnswerChoiceSummaryList;
