import React from 'react';
import PropTypes from 'prop-types';
import SelectForm from '../../../../../shared/form/select_form';
import HiddenForm from '../../../../../shared/form/hidden_form';
import { joinedAncestorName } from '../../../../../../utils/string_utils';

class MemberForm extends React.Component {
  constructor(props) {
    super(props);
    const { member } = this.props;
    this.state = {
      selectedDepartmentId: String(member.department_id),
    };
  }

  ancestorsName() {
    const { departments } = this.props;
    const { selectedDepartmentId } = this.state;
    return joinedAncestorName(departments, selectedDepartmentId);
  }

  render() {
    const {
      members, member, index, departmentOptions, managerialPositionOptions,
    } = this.props;
    return (
      <React.Fragment>
        <HiddenForm
          scope="user_profile_form"
          relatedModelName="members"
          index={index}
          columnName="id"
          value={member.id}
        />
        <div className="row">
          <div className="col-sm-4 col-form-label" />
          <div className="col-sm-8" style={{ fontSize: 13, color: 'gray' }}>
            {this.ancestorsName()}
          </div>
        </div>
        <SelectForm
          scope="user_profile_form"
          model={member}
          relatedModelName="members"
          index={index}
          columnName="department_id"
          options={departmentOptions}
          labelColor="gray"
          label={`部署${members.length > 1 ? index + 1 : ''}`}
          onChange={e => this.setState({ selectedDepartmentId: e.target.value })}
        />
        <SelectForm
          scope="user_profile_form"
          model={member}
          relatedModelName="members"
          index={index}
          columnName="managerial_position_id"
          options={managerialPositionOptions}
          labelColor="gray"
          label={`役職${members.length > 1 ? index + 1 : ''}`}
        />
      </React.Fragment>
    );
  }
}

MemberForm.propTypes = {
  members: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
  member: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  departments: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
  index: PropTypes.number.isRequired,
  departmentOptions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
  managerialPositionOptions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
};
MemberForm.defaultProps = {
};
export default MemberForm;
