import PropTypes from "prop-types";
import React from "react";
import { getS3PostUrls, putFiletoS3 } from "../../utils/aws_s3_service";
import LoadingImage from "./images/spin-gray.svg";

class FileUploadButton extends React.Component {
  constructor(props) {
    super(props);
    const { value } = this.props;
    this.state = {
      uploading: false,
      selectedImageUrl: value,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    const { beforeUploading, afterUploading } = this.props;
    const input = e.target;
    const file = input.files[0];
    if (file) {
      this.setState({ uploading: true }, () => {
        beforeUploading();
      });
      input.value = null;
      getS3PostUrls(1, "user/profile_image")
        .then((results) => {
          const { urls } = results.data;
          return putFiletoS3(file, urls[0]);
        })
        .then((results) => {
          const {
            config: { url },
          } = results;
          const cleanUrl = url.replace(/\?.*$/, "");
          this.setState(
            {
              uploading: false,
              selectedImageUrl: cleanUrl,
            },
            () => {
              afterUploading();
            }
          );
        })
        .catch((error) => {
          this.setState({ uploading: false }, () => {
            afterUploading();
            window.alert("画像の処理に失敗しました");
          });
        });
    }
  }

  render() {
    const {
      scope,
      relatedModelName,
      columnName,
      containerClass,
      containerStyle,
    } = this.props;
    const { selectedImageUrl, uploading } = this.state;

    return (
      <div className={containerClass} style={containerStyle}>
        <input
          type="hidden"
          value={selectedImageUrl}
          id={`${scope}_${relatedModelName}_attributes_${columnName}`}
          name={`${scope}[${relatedModelName}_attributes][${columnName}]`}
        />
        <div>
          {uploading && (
            <img
              src={LoadingImage}
              style={{
                width: 80,
                height: 80,
              }}
            />
          )}
          {!uploading && <span>もお</span>}
        </div>
        <label
          htmlFor="profileImage"
          style={{
            backgroundColor: "white",
            border: "solid 1px #ced4da",
            borderRadius: 5,
            marginTop: 5,
          }}
        >
          <span className="btn btn-default">
            画像選択
            <input
              id="profileImage"
              type="file"
              onChange={this.handleChange}
              accept="image/*"
              style={{ display: "none" }}
            />
          </span>
        </label>
      </div>
    );
  }
}
FileUploadButton.propTypes = {
  scope: PropTypes.string.isRequired,
  relatedModelName: PropTypes.string.isRequired,
  columnName: PropTypes.string.isRequired,
  containerClass: PropTypes.string,
  containerStyle: PropTypes.shape({
    styleKey: PropTypes.string,
  }),
  beforeUploading: PropTypes.func,
  afterUploading: PropTypes.func,
  value: PropTypes.string,
};
FileUploadButton.defaultProps = {
  containerClass: "",
  containerStyle: {},
  value: "",
  beforeUploading: () => {},
  afterUploading: () => {},
};
export default FileUploadButton;
