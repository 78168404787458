import React from 'react';
import PropTypes from 'prop-types';
import AnswerItem from './answer_list/answer_item';

class AnswerList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { answers, keyword } = this.props;
    return (
      <React.Fragment>
        {answers.map(a => (
          <AnswerItem key={a.id} answer={a} keyword={keyword} />
        ))}
      </React.Fragment>
    );
  }
}

AnswerList.propTypes = {
  answers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
  keyword: PropTypes.string,
};
AnswerList.defaultProps = {
  keyword: '',
};
export default AnswerList;
