import React from 'react';
import PropTypes from 'prop-types';
import MenuDialog from '../shared/menu_dailog';
import Button from '../shared/button';
import WorkPostEditModal from './work_post_edit_modal';
import { deleteRequest } from '../../utils/api_client';

const baseUrl = '/wb/user/work_posts';

class WorkPostActionMenuIcon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      showDialog: false,
      loading: false,
    };
    this.deletePost = this.deletePost.bind(this);
    this.toggleDialog = this.toggleDialog.bind(this);
  }

  deletePost() {
    const { workPost, afterDeleted } = this.props;
    if (!workPost.id) {
      return;
    }
    if (window.confirm('本当に投稿を削除してよろしいですか？')) {
      this.setState({ loading: true }, () => {
        deleteRequest(`${baseUrl}/${workPost.id}`)
          .then((r) => {
            afterDeleted();
          })
          .catch((e) => {
            this.setState({ loading: false }, () => {
              window.alert('削除に失敗しました');
            });
          });
      });
    }
  }

  toggleDialog() {
    const { showDialog } = this.state;
    this.setState({ showDialog: !showDialog });
  }

  render() {
    const { showModal, loading, showDialog } = this.state;
    const {
      currentUser, postUser, workPost,
      work, afterSaved, userProfile,
    } = this.props;
    if (postUser.id !== currentUser.id) {
      return null;
    }
    return (
      <React.Fragment>
        <WorkPostEditModal
          showModal={showModal}
          workPost={workPost}
          work={work}
          userProfile={userProfile}
          afterSaved={(editedWp) => {
            this.setState({ showModal: false, showDialog: false }, () => {
              afterSaved(editedWp);
            });
          }}
          onCloseButtonClicked={() => this.setState({ showModal: false })}
          stopLoadingAfterSaved
        />
        <MenuDialog
          loading={loading}
          showDialog={showDialog}
          onMenuClicked={this.toggleDialog}
        >
          <Button
            title="投稿を編集"
            className="dropdown-item"
            onClick={() => this.setState({ showModal: true })}
          />
          <Button
            title="投稿を削除"
            className="dropdown-item"
            onClick={this.deletePost}
          />
        </MenuDialog>
      </React.Fragment>
    );
  }
}

WorkPostActionMenuIcon.propTypes = {
  currentUser: PropTypes.shape({
    id: PropTypes.number,
  }),
  postUser: PropTypes.shape({
    id: PropTypes.number,
  }),
  workPost: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  work: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  afterSaved: PropTypes.func.isRequired,
  afterDeleted: PropTypes.func.isRequired,
  userProfile: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};
WorkPostActionMenuIcon.defaultProps = {
  currentUser: { id: 0 },
  postUser: { id: 0 },
};
export default WorkPostActionMenuIcon;
