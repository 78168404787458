import PropTypes from 'prop-types';
import React from 'react';
import BaseModal from './base_modal';
import ShareIcon from './images/ic_share_grey.png';
import ShareLinkItem from './page_share_link/share_link_item';
import LinkAuthForm from './page_share_link/link_auth_form';

class PageShareLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  openModal() {
    this.setState({ showModal: true });
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  render() {
    const {
      shareLinkItems, modelName, model, linkAuthOptions,
      authUpdatePath,
    } = this.props;
    const { showModal } = this.state;

    return (
      <React.Fragment>
        <BaseModal
          show={showModal}
          title="ページ共有"
          contentLabel="page_share_modal"
          onCloseModal={() => this.closeModal()}
        >
          <div className="modal-body">
            <div className="container-fluid">
              <LinkAuthForm
                model={model}
                modelName={modelName}
                linkAuthOptions={linkAuthOptions}
                authUpdatePath={authUpdatePath}
              />
              <div style={{ padding: '20px 0px' }}>
                以下URLでこのページを共有できます
              </div>
              {shareLinkItems.map(item => (
                <ShareLinkItem
                  key={item.shareUrl}
                  description={item.description}
                  shareUrl={item.shareUrl}
                />
              ))}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => this.closeModal()}
            >
              閉じる
            </button>
          </div>
        </BaseModal>
        <span
          role="button"
          style={{ fontSize: 14, cursor: 'pointer', color: 'gray' }}
          onClick={() => this.openModal()}
        >
          <img src={ShareIcon} alt="share icon" style={{ height: 20, marginTop: -2 }} />
          共有
        </span>
      </React.Fragment>
    );
  }
}
PageShareLink.propTypes = {
  modelName: PropTypes.string.isRequired,
  model: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  shareLinkItems: PropTypes.arrayOf(PropTypes.shape({
    description: PropTypes.string.isRequired,
    shareUrl: PropTypes.string.isRequired,
  })).isRequired,
  linkAuthOptions: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  authUpdatePath: PropTypes.string.isRequired,
};
PageShareLink.defaultProps = {
};
export default PageShareLink;
