import React, { useState } from "react";
import { QuestionnaireResponse } from "../../models/Questionnaire";
import { postRequest } from "../../utils/api_client";
import {
  getAfterMeasurementModalLoadCount,
  setAfterMeasurementModalLoadCount,
} from "../../utils/global_variable_utils";
import BaseMoal from "../shared/base_modal";
import Button from "../shared/button";
import HappinessLookBackSuggestImage from "../shared/images/happiness_look_back_suggest_image.png";
import HappinessStructureExampleImage from "../shared/images/happiness_structure_example.png";
import HappinessStructureExampleImageEn from "../shared/images/happiness_structure_example_en.png";
import AdviceContainer from "./advice_button/advice_container";
import HappinessStructureImageView from "./shared/happiness_structure_image_view";
import ShareImageView from "./shared/share_image_view";

function tracking() {
  postRequest("/user/tracks", {
    happiness_structure_link_type: "after_measurement",
  });
}
const isJaLocale = window.I18n.locale === "ja";

interface Props {
  locale: string;
  questionnaire: QuestionnaireResponse;
  snsShareCount: number;
  isConsumer: boolean;
  interactiveWorkPath: string;
  interactiveHappinessAdvicePath: string;
  questionnaireAdvicesPath: string;
}
export default function AfterMeasurementModal(props: Props) {
  const {
    locale,
    questionnaire,
    snsShareCount,
    isConsumer,
    interactiveWorkPath,
    interactiveHappinessAdvicePath,
    questionnaireAdvicesPath,
  } = props;
  let initialShowModal = false;
  if (getAfterMeasurementModalLoadCount() === 0) {
    setAfterMeasurementModalLoadCount(1);
    initialShowModal = true;
  }
  const [showModal, setShowModal] = useState(initialShowModal);
  const [showShareImage, setShowShareImage] = useState(false);
  const [showHappyAiModal, setShowHappyAiModal] = useState(false);
  const [loading, setLoading] = useState(false);

  let modalTitle = window.I18n.t(
    "javascript.questionnaires.after_measurement_modal.thank_you_for_your_measurement"
  );
  if (showShareImage) {
    if (isConsumer) {
      modalTitle = window.I18n.t(
        "javascript.questionnaires.after_measurement_modal.share_happiness_structure"
      );
    } else {
      modalTitle = window.I18n.t(
        "javascript.questionnaires.questionnaire_share_link.happiness_structure"
      );
    }
  }
  if (locale == "ja") {
    return (
      <React.Fragment>
        <BaseMoal
          show={showModal}
          title={
            showHappyAiModal
              ? "ハッピーAI"
              : "診断完了です。次のステップに進みましょう！"
          }
          contentLabel="afterMeasurementModal"
          loading={loading}
          contentStyle={{ maxWidth: 496, width: "100%" }}
          onCloseModal={() => setShowModal(false)}
        >
          <BaseMoal.Body>
            {showHappyAiModal && (
              <AdviceContainer
                questionnaire={questionnaire}
                comparisonQuestionnaire={null}
                comparisonQuestionnaireAdvices={null}
                questionnaireAdvices={null}
                interactiveWorkPath={interactiveWorkPath}
                interactiveHappinessAdvicePath={interactiveHappinessAdvicePath}
                isConsumer={isConsumer}
                beforeLoading={() => setLoading(true)}
                afterLoading={() => setLoading(false)}
              />
            )}
            {!showHappyAiModal && (
              <div>
                <div>
                  診断結果に対して、ハッピーAIよりメッセージとアドバイスが届いていますので、ご確認下さい。
                </div>
                <div className="d-flex justify-content-center py-3">
                  <a
                    href={questionnaireAdvicesPath}
                    className="btn button__primary"
                    target="_blank"
                  >
                    ハッピーAIからのメッセージ/アドバイスを読む
                  </a>
                </div>
                <React.Fragment>
                  <div className="py-3">
                    また、診断結果を確認した後は、是非「
                    <span className="font-weight-bold">幸せの振り返り</span>
                    」も行ってみて下さい！
                    振り返りを行った方は、幸福度がおどろくほど伸びています😊
                  </div>
                  <img
                    src={HappinessLookBackSuggestImage}
                    alt="幸せの振り返りリンクの場所画像"
                    className="width__100"
                  />
                </React.Fragment>
              </div>
            )}
          </BaseMoal.Body>
        </BaseMoal>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <BaseMoal
        show={showModal}
        title={modalTitle}
        contentLabel="afterMeasurementModal"
        loading={loading}
        contentStyle={{ maxWidth: 496, width: "100%" }}
        onCloseModal={() => setShowModal(false)}
      >
        {isConsumer && showShareImage && (
          <ShareImageView
            questionnaire={questionnaire}
            snsShareCount={snsShareCount}
            beforeLoading={(callback) => {
              setLoading(true);
              callback();
            }}
            afterLoading={() => setLoading(false)}
            showLookBackSuggestMessage
          />
        )}
        {!isConsumer && showShareImage && (
          <div>
            <HappinessStructureImageView
              questionnaire={questionnaire}
              beforeLoading={(callback) => {
                setLoading(true);
                callback();
              }}
              afterLoading={() => setLoading(false)}
              showLookBackSuggestMessage
            />
          </div>
        )}
        {!showShareImage && (
          <BaseMoal.Body style={{ maxHeight: "70vh" }}>
            <div className="container-fluid">
              <div>
                {questionnaire.nickname && (
                  <p>
                    {window.I18n.t(
                      "javascript.questionnaires.after_measurement_modal.message1_with_nickname",
                      { nickname: questionnaire.nickname }
                    )}
                  </p>
                )}
                {!questionnaire.nickname && (
                  <p>
                    {window.I18n.t(
                      "javascript.questionnaires.after_measurement_modal.message1"
                    )}
                  </p>
                )}
              </div>
              <div className="mt-2">
                <p>
                  {window.I18n.t(
                    "javascript.questionnaires.after_measurement_modal.message2"
                  )}
                </p>
              </div>
              <div className="text-center">
                <img
                  src={
                    isJaLocale
                      ? HappinessStructureExampleImage
                      : HappinessStructureExampleImageEn
                  }
                  alt={window.I18n.t(
                    "javascript.questionnaires.after_measurement_modal.share_image"
                  )}
                  className="width__50_75"
                />
              </div>
              <div className="text-center color__gray font__size-13">
                {window.I18n.t(
                  "javascript.questionnaires.after_measurement_modal.example_of_happiness_structure"
                )}
              </div>
              <div className="mt-2">
                {questionnaire.nickname && (
                  <p>
                    {window.I18n.t(
                      "javascript.questionnaires.after_measurement_modal.message3_with_nickname",
                      { nickname: questionnaire.nickname }
                    )}
                  </p>
                )}
                {!questionnaire.nickname && (
                  <p>
                    {window.I18n.t(
                      "javascript.questionnaires.after_measurement_modal.message3"
                    )}
                  </p>
                )}
              </div>
              <Button
                title={window.I18n.t(
                  "javascript.questionnaires.after_measurement_modal.show_happiness_structure"
                )}
                className="btn button__primary d-flex justify-content-center mt-2"
                onClick={() => setShowShareImage(true)}
                style={{ zIndex: 10 }}
              />
            </div>
          </BaseMoal.Body>
        )}
      </BaseMoal>
    </React.Fragment>
  );
}
