import classnames from "classnames";
import MenuIcon from "./images/ic_three_point_leader.png";
import React, { ReactElement, useState } from "react";

interface Props {
  contents: Array<{
    title: string;
    onClick: () => void;
  }>;
}

export default function MenuButton(props: Props): ReactElement {
  const { contents } = props;
  const [showMenu, setShowMenu] = useState(false);

  return (
    <div className="position-relative">
      {showMenu && (
        <>
          <div
            className="position-fixed"
            style={{ top: 0, right: 0, bottom: 0, left: 0, zIndex: 40 }}
            onClick={() => setShowMenu(false)}
          />
          <div
            className="position-absolute bgcolor__white shadow rounded border text-center"
            style={{ top: 25, right: 0, zIndex: 50, width: 200 }}
          >
            {contents.map((c, i) => {
              let className = classnames("d-flex align-items-center p-3");
              if (i !== 0) {
                className = classnames(className, "border-top");
              }
              return (
                <div
                  key={c.title}
                  className={className}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    c.onClick();
                    setShowMenu(false);
                  }}
                >
                  {c.title}
                </div>
              );
            })}
          </div>
        </>
      )}
      <span
        role="button"
        tabIndex={0}
        style={{
          fontSize: 14,
          cursor: "pointer",
          color: "gray",
          marginRight: 5,
        }}
        onClick={() => setShowMenu(true)}
      >
        <img
          src={MenuIcon}
          alt="menu icon"
          style={{ height: 17, marginTop: -2 }}
        />
      </span>
    </div>
  );
}
