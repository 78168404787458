import React from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import { parse } from '../../../../utils/image_parser';
import { getS3PostUrls, putImagetoS3, uploadImage } from '../../../../utils/aws_s3_service';
import Box from './shared/box';
import SpinGray from '../../images/spin-gray.svg';

class ImageField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uploaded: false,
    };
  }

  componentDidMount() {
    this.upload();
  }

  upload() {
    const { file, afterUploaded, afterFailed } = this.props;
    uploadImage(
      file,
      'user/file_resource',
      () => {},
      ({ url, dataUri }) => {
        this.setState({ uploaded: true }, () => {
          afterUploaded(url, dataUri);
        });
      },
      () => afterFailed(),
      { maxWidth: 1000, maxHeight: 1000 },
    );
  }

  render() {
    const { uploaded } = this.state;
    if (uploaded) {
      return null;
    }
    return (
      <React.Fragment>
        <Box>
          <React.Fragment>
            <ReactSVG
              src={SpinGray}
              svgStyle={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            />
          </React.Fragment>
        </Box>
      </React.Fragment>
    );
  }
}

ImageField.propTypes = {
  file: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  afterUploaded: PropTypes.func.isRequired,
  afterFailed: PropTypes.func.isRequired,
};
ImageField.defaultProps = {
};
export default ImageField;
