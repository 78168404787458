import React, { ReactElement } from "react";
import { InteractiveHappinessAdviceChatResponse } from "../../../models/InteractiveHappinessAdviceChat";
import AppStoreBadge from "../../shared/images/appstore-badge.svg";
import GooglePlayBadge from "../../shared/images/google-play-badge.png";
import HappyBirdImage from "../../shared/images/happy_bird.png";

interface Props {
  interactiveHappinessAdviceChats: InteractiveHappinessAdviceChatResponse[];
}

const checkSuicideKeyword = (text: string): boolean => {
  const keywords = ["自殺", "死", "絶望"];
  for (let i = 0; i < keywords.length; i++) {
    const keyword = keywords[i];
    if (text.includes(keyword)) {
      return true;
    }
  }
  return false;
};

const checkThreeGoodThingsKeyword = (text: string): boolean => {
  const keywords = [
    "3つの良かった事",
    "3つのよかったこと",
    "3つのよいこと",
    "3つの良いこと",
    "3つの良い出来事",
    "３つの良かった事",
    "３つのよかったこと",
    "３つのよいこと",
    "３つの良いこと",
    "３つの良い出来事",
  ];
  for (let i = 0; i < keywords.length; i++) {
    const keyword = keywords[i];
    if (text.includes(keyword)) {
      return true;
    }
  }
  return false;
};

export default function LastChat(props: Props): ReactElement {
  const { interactiveHappinessAdviceChats } = props;

  const userChat = interactiveHappinessAdviceChats.filter(
    (ihac) => ihac.role === "user_role"
  )[0];

  const arr: ReactElement[] = [];

  if (userChat !== undefined && checkSuicideKeyword(userChat.message)) {
    arr.push(
      <div className="chat-screen__chat-message-container">
        <div className="d-flex justify-content-start">
          <div className="ml-3">
            <img src={HappyBirdImage} style={{ width: 28 }} />
          </div>
        </div>
        <div className="d-flex justify-content-start px-3 pb-3 mt-1">
          <div
            className="p-3 bgcolor__trivial rounded"
            style={{ maxWidth: "80%" }}
          >
            もしあなたが先の見えない不安や、生きづらさを感じるなどの様々なこころの悩みを抱えていたら、その悩みを相談することができる機関があります。
            <br />
            必要があればご利用ください。
            <br />
            <div className="d-flex justify-content-end align-items-center mt-3">
              <div className="mx-2">
                <div>
                  <a
                    href="https://www.mhlw.go.jp/stf/seisakunitsuite/bunya/hukushi_kaigo/seikatsuhogo/jisatsu/soudan_info.html"
                    target="_blank"
                  >
                    リンク: 相談先一覧｜厚生労働省
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (
    interactiveHappinessAdviceChats.some((ihac) =>
      checkThreeGoodThingsKeyword(ihac.message)
    )
  ) {
    arr.push(
      <div className="chat-screen__chat-message-container">
        <div className="d-flex justify-content-start">
          <div className="ml-3">
            <img src={HappyBirdImage} style={{ width: 28 }} />
          </div>
        </div>
        <div className="d-flex justify-content-start px-3 pb-3 mt-1">
          <div
            className="p-3 bgcolor__trivial rounded"
            style={{ maxWidth: "80%" }}
          >
            なお、良かった事に目を向けていく習慣を作っていくためにも、
            <br />
            継続して3つの良かったこと日記を付けていくことをオススメします。
            <br />
            はぴトレという、無償アプリもあるので、是非やってみてください。
            <br />
            こちらもハッピーAIからのコメントがもらえます。
            <br />
            (通常のハッピーAIに加え、大阪のおばちゃんやお坊さんなど種類も選択可能です。)
            <br />
            <div className="d-flex justify-content-end align-items-center mt-3">
              <div className="mx-2">
                <a
                  href="https://itunes.apple.com/jp/app/id1395327487?mt=8"
                  target="_blank"
                >
                  <img src={AppStoreBadge} style={{ height: "1.8rem" }} />
                </a>
              </div>
              <div>
                <a
                  href="https://play.google.com/store/apps/details?id=jp.happy_training.pro&hl=ja"
                  target="_blank"
                >
                  <img src={GooglePlayBadge} style={{ height: "2.6rem" }} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <>{arr}</>;
}
