import PropTypes from "prop-types";
import React from "react";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";
import BaseForm from "../shared/base_form";
import LoadingMask from "../shared/loading_mask";
import BodyAction from "./duty_list/body_action";
import DutyListItem from "./duty_list/duty_list_item";
import HeaderAction from "./duty_list/header_action";

class DutyList extends React.Component {
  constructor(props) {
    super(props);
    const { duties } = this.props;
    this.state = {
      duties,
      canSort: false,
      loading: false,
    };
    this.initialDuties = duties.concat();
    this.onSortEnd = this.onSortEnd.bind(this);
    this.resetSorting = this.resetSorting.bind(this);
  }

  onSortEnd({ oldIndex, newIndex }) {
    this.setState(({ duties }) => ({
      duties: arrayMove(duties, oldIndex, newIndex),
    }));
  }

  resetSorting() {
    this.setState({
      duties: this.initialDuties,
      canSort: false,
    });
  }

  render() {
    const { action } = this.props;
    const { duties, canSort, loading } = this.state;

    const SortableItem = SortableElement(({ duty, position }) => (
      <DutyListItem
        key={duty.id}
        duty={duty}
        action={action}
        canSort={canSort}
        position={position}
      />
    ));

    const SortableList = SortableContainer(({ items }) => (
      <ul className="duty-list__list-container">
        {items.map((item, index) => (
          <SortableItem
            key={item.id}
            duty={item}
            index={index}
            position={index}
            disabled={!canSort}
          />
        ))}
      </ul>
    ));

    return (
      <React.Fragment>
        <div className="duty-list__header-action-container">
          <HeaderAction
            action={action}
            canSort={canSort}
            onSortStarted={() => this.setState({ canSort: true })}
            onSortReset={this.resetSorting}
          />
        </div>
        {/* 並び順更新に利用するform */}
        <BaseForm
          name="allDutiesEditForm"
          action={`${action}/update_all`}
          method="put"
        >
          <input name="utf8" type="hidden" value="✓" />
          <input type="hidden" name="_method" value="patch" />
          <BodyAction
            canSort={canSort}
            beforeSaved={(callback) =>
              this.setState({ loading: true }, () => {
                callback();
              })
            }
            afterSaved={(callback) =>
              this.setState({ loading: false }, () => {
                callback();
              })
            }
          />
          <SortableList
            items={duties}
            onSortEnd={this.onSortEnd}
            helperClass="duty-list__list-container"
            distance={1}
            lockAxis="y"
          />
        </BaseForm>
        <LoadingMask loading={loading} />
      </React.Fragment>
    );
  }
}

DutyList.propTypes = {
  action: PropTypes.string.isRequired,
  duties: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ).isRequired,
};
DutyList.defaultProps = {};
export default DutyList;
