import PropTypes from 'prop-types';
import React from 'react';
import { ReactSVG } from 'react-svg';
import UpdateIcon from '../shared/images/ic_autorenew.svg';
import LoadingIcon from '../shared/images/spin-gray.svg';
import Button from '../shared/button';
import { getRequest } from '../../utils/api_client';

class StateChecker extends React.Component {
  constructor(props) {
    super(props);
    const { input } = props;
    this.state = {
      input,
      loading: false,
    };
    this.reload = this.reload.bind(this);
  }

  reload() {
    const { input } = this.state;
    this.setState({ loading: true }, () => {
      getRequest(`/user/company/inputs/${input.id}`)
        .then((r) => {
          const { data } = r;
          this.setState({ loading: false, input: data });
        })
        .catch((e) => {
          this.setState({ loading: false });
        });
    });
  }

  render() {
    const { title, finisedTitle } = this.props;
    const { input, loading } = this.state;
    const isFinished = input.outputs.length > 0 && input.outputs.every(o => o.state === 'finished');
    return (
      <div className="d-flex justify-content-start">
        <div>
          <span className="font__size-13 color__gray">
            {isFinished ? finisedTitle : title}
          </span>
        </div>
        {!isFinished && (
          <div>
            <Button className="cursor__pointer" onClick={this.reload}>
              <ReactSVG
                src={loading ? LoadingIcon : UpdateIcon}
                className="ml-2 color__gray"
                beforeInjection={(svg) => {
                  svg.classList.add('');
                  svg.setAttribute('style', 'width: 17px;height: 17px;margin-top: -2px;');
                }}
                style={{ float: 'right' }}
              />
            </Button>
          </div>
        )}
      </div>
    );
  }
}
StateChecker.propTypes = {
  input: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  title: PropTypes.string.isRequired,
  finisedTitle: PropTypes.string.isRequired,
};
StateChecker.defaultProps = {
};
export default StateChecker;
