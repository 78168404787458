import React from 'react';
import PropTypes from 'prop-types';
import TextareaForm from '../../../shared/form/textarea_form';
import HiddenForm from '../../../shared/form/hidden_form';

class SelfIntroduceForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { selfIntroductionQuestions, selfIntroductionAnswers } = this.props;
    const isJaLocale = window.I18n.locale === 'ja';
    if (!isJaLocale) {
      return null;
    }
    return (
      <React.Fragment>
        {selfIntroductionQuestions.map((q, i) => {
          const ans = selfIntroductionAnswers.find(a => a.question_id === q.id);
          return (
            <React.Fragment key={q.id}>
              {ans && (
                <HiddenForm
                  scope="user_profile_form"
                  relatedModelName="answers"
                  columnName="id"
                  value={ans.id}
                  index={i}
                />
              )}
              <HiddenForm
                scope="user_profile_form"
                relatedModelName="answers"
                columnName="question_id"
                value={q.id}
                index={i}
              />
              <TextareaForm
                scope="user_profile_form"
                model={ans}
                relatedModelName="answers"
                columnName="body"
                label={q.body}
                index={i}
                labelColor="gray"
              />
            </React.Fragment>
          );
        })}
      </React.Fragment>
    );
  }
}

SelfIntroduceForm.propTypes = {
  selfIntroductionQuestions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
  selfIntroductionAnswers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
};
SelfIntroduceForm.defaultProps = {
};
export default SelfIntroduceForm;
