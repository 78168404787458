import React, { ReactElement, useEffect, useState } from "react";
import { HappinessElementType } from "../../../models/HappinessQuestion";
import { getRequestAsync } from "../../../utils/api_client";
import DistributionChart from "../../shared/distribution_chart";
import SelectField from "../../shared/form/fields/select_field";
import Loading from "../../shared/loading";

interface Props {
  happinessElement: HappinessElementType;
  title: string;
  dataAcquisitionPath: string;
  labelOptions: Array<{ key: string; value: string }> | null;
  selectedLabelKey: string | null;
  graphLoadParams: {
    [key: string]: string | boolean | null;
  };
}

interface GraphData {
  title: string;
  chartId: string;
  scaleMin: number;
  scaleMax: number;
  stepSize: number;
  labels: string[];
  tooltipLabels: string[];
  datasets: Array<{
    label: string;
    data: number[];
    color: string;
  }>;
  verticalLinePosition: number | null;
}

export default function HappinessElementDistributionChart(
  props: Props
): ReactElement {
  const {
    happinessElement,
    title,
    dataAcquisitionPath,
    labelOptions,
    selectedLabelKey,
    graphLoadParams,
  } = props;
  const [graphData, setGraphData] = useState<GraphData>();
  const [distributionLabel, setDistributionLabel] = useState<string | null>(
    selectedLabelKey
  );

  useEffect(() => {
    setGraphData(undefined);
    loadData();
  }, [distributionLabel]);

  const loadData = async () => {
    const { result } = await getRequestAsync<{ data: GraphData }>(
      dataAcquisitionPath,
      Object.assign({}, graphLoadParams, {
        element: happinessElement,
        distribution_label: distributionLabel,
      })
    );
    if (result !== undefined) {
      setGraphData(result.data);
    }
  };

  return (
    <div className="p-1 w-100">
      <div className="card w-100">
        <div className="card-header distribution-chart__card_header">
          <h4 className="card-title">
            {graphData === undefined ? title : graphData.title}
          </h4>
          {labelOptions !== null && selectedLabelKey !== null && (
            <div className="d-flex justify-content-end mb-2">
              <SelectField
                id={`${happinessElement}-label-select-form-id`}
                name={`${happinessElement}-label-select-form-name`}
                options={labelOptions}
                selectedKey={selectedLabelKey}
                onChange={(e) => setDistributionLabel(e.target.value)}
              />
            </div>
          )}
        </div>
        <div className="distribution-chart__legend-container">
          <div>
            {graphData !== undefined &&
              graphData.verticalLinePosition !== null && (
                <>
                  <div className="distribution-chart__average-icon" />
                  <span>平均</span>
                </>
              )}
            {graphData !== undefined &&
              graphData.datasets.map((d) => (
                <div
                  key={d.label}
                  className="d-flex justify-content-end align-items-center"
                >
                  <div
                    className="distribution-chart__legend-icon"
                    style={{ backgroundColor: d.color }}
                  ></div>
                  <div className="ml-2">{d.label}</div>
                </div>
              ))}
          </div>
        </div>
        <div className="card-body w-100">
          {graphData !== undefined && (
            <div>
              <span className="distribution-chart__y-axis-unit">(%)</span>
            </div>
          )}
          <div className="distribution-chart__transition-container w-100">
            {graphData !== undefined && (
              <DistributionChart
                chartId={graphData.chartId}
                scaleMin={graphData.scaleMin}
                scaleMax={graphData.scaleMax}
                stepSize={graphData.stepSize}
                labels={graphData.labels}
                tooltipLabels={graphData.tooltipLabels}
                datasets={graphData.datasets}
                verticalLinePosition={graphData.verticalLinePosition}
              />
            )}
            {graphData === undefined && (
              <div>
                <Loading loading height={40} width={40} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
