import React, { ReactElement } from "react";
import { QuestionnaireResponse } from "../../../models/Questionnaire";
import { QuestionnaireAdviceResponse } from "../../../models/QuestionnaireAdvice";
import Button from "../../shared/button";
import AdviceContent from "./advice_content";

interface Props {
  questionnaire: QuestionnaireResponse;
  comparisonQuestionnaire: QuestionnaireResponse | null;
  comparisonQuestionnaireAdvices: QuestionnaireAdviceResponse[] | null;
  questionnaireAdvices: QuestionnaireAdviceResponse[] | null;
  interactiveWorkPath: string;
  interactiveHappinessAdvicePath: string;
  isConsumer: boolean;
  showModal?: boolean;
  beforeLoading: () => void;
  afterLoading: () => void;
}

export default function AdviceContainer(props: Props): ReactElement {
  const {
    questionnaire,
    comparisonQuestionnaire,
    comparisonQuestionnaireAdvices,
    questionnaireAdvices,
    interactiveWorkPath,
    interactiveHappinessAdvicePath,
    isConsumer,
    beforeLoading,
    afterLoading,
  } = props;
  const praiseRoleQuestionnaireAdvice = questionnaireAdvices?.find(
    (qa) => qa.role === "praise_role"
  );
  const adviceRoleQuestionnaireAdvice = questionnaireAdvices?.find(
    (qa) => qa.role === "advice_role"
  );
  const praiseRoleComparisonQuestionnaireAdvice =
    comparisonQuestionnaireAdvices?.find((qa) => qa.role === "praise_role");
  const adviceRoleComparisonQuestionnaireAdvice =
    comparisonQuestionnaireAdvices?.find((qa) => qa.role === "advice_role");
  return (
    <div>
      <div className="row p-2">
        <div className="col-md-6 py-1">
          <Button
            className="btn button__highlignt font__size-14 width__100"
            onClick={() => {
              window.location.href = interactiveHappinessAdvicePath;
            }}
          >
            <div>
              ハッピーAIに
              <br />
              相談する
            </div>
          </Button>
        </div>
        <div className="col-md-6 py-1">
          <Button
            className="btn button__highlignt font__size-14 width__100"
            onClick={() => {
              window.location.href = interactiveWorkPath;
            }}
          >
            <div>
              ハッピーAIと
              <br />
              対話ワークを行う
            </div>
          </Button>
        </div>
      </div>
      <div className="mt-2 font-weight-bold font__size-14">
        ハッピーAIからのメッセージ
      </div>
      <AdviceContent
        role="praise"
        questionnaire={questionnaire}
        questionnaireAdvice={praiseRoleQuestionnaireAdvice}
        comparisonQuestionnaire={comparisonQuestionnaire}
        comparisonQuestionnaireAdvice={praiseRoleComparisonQuestionnaireAdvice}
        isConsumer={isConsumer}
        beforeLoading={beforeLoading}
        afterLoading={afterLoading}
      />
      <div className="mt-2 font-weight-bold font__size-14">
        ハッピーAIからのアドバイス
      </div>
      <AdviceContent
        role="advice"
        questionnaire={questionnaire}
        questionnaireAdvice={adviceRoleQuestionnaireAdvice}
        comparisonQuestionnaire={comparisonQuestionnaire}
        comparisonQuestionnaireAdvice={adviceRoleComparisonQuestionnaireAdvice}
        isConsumer={isConsumer}
        beforeLoading={beforeLoading}
        afterLoading={afterLoading}
      />
    </div>
  );
}
