import PropTypes from 'prop-types';
import React from 'react';

class TextareaField extends React.Component {
  constructor(props) {
    super(props);
    const { innerRef } = this.props;
    this.inputRef = innerRef;
    this.state = {
    };
  }

  render() {
    const {
      // model, column,
      containerClass, containerStyle,
      id, name, value, rows, required,
      placeholder, style, readOnly,
    } = this.props;

    return (
      <div className={containerClass} style={containerStyle}>
        <textarea
          ref={this.inputRef}
          className="form-control"
          defaultValue={value}
          name={name}
          id={id}
          rows={rows}
          required={required}
          placeholder={placeholder}
          style={style}
          readOnly={readOnly}
        />
      </div>
    );
  }
}
TextareaField.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  containerClass: PropTypes.string,
  containerStyle: PropTypes.shape({
    styleKey: PropTypes.string,
  }),
  value: PropTypes.string,
  rows: PropTypes.number.isRequired,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  style: PropTypes.shape({
    styleKey: PropTypes.string,
  }),
  readOnly: PropTypes.bool,
};
TextareaField.defaultProps = {
  containerClass: '',
  containerStyle: {},
  value: '',
  required: false,
  placeholder: '',
  innerRef: {},
  style: {},
  readOnly: false,
};
export default React.forwardRef((props, ref) => (
  <TextareaField {...props} innerRef={ref} />
));
