import React from 'react';
import PropTypes from 'prop-types';
import { fieldId, fieldName } from './utils/attribute_utils';
import HiddenField from './fields/hidden_field';

class CheckboxForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const {
      scope, relatedModelName, columnName,
      index, title, defaultChecked, style,
      activeKey, disableKey, onChange, disabled,
      subTitle,
    } = this.props;
    return (
      <div className="form-group form-check" style={style}>
        <input
          name={fieldName(scope, relatedModelName, columnName, index)}
          type="hidden"
          value={disableKey}
        />
        <input
          className="form-check-input"
          type="checkbox"
          value={activeKey}
          name={fieldName(scope, relatedModelName, columnName, index)}
          id={fieldId(scope, relatedModelName, columnName, index)}
          defaultChecked={defaultChecked}
          disabled={disabled}
          onChange={e => onChange(e)}
        />
        {disabled && (
          <HiddenField
            name={fieldName(scope, relatedModelName, columnName, index)}
            id={fieldId(scope, relatedModelName, columnName, index)}
            value={defaultChecked ? activeKey : disableKey}
          />
        )}
        <label
          htmlFor={fieldId(scope, relatedModelName, columnName, index)}
          className="form-check-label"
        >
          {title}
          {subTitle && (
            <span className="color__gray font__size-13">
              {`（${subTitle}）`}
            </span>
          )}
        </label>
      </div>
    );
  }
}

CheckboxForm.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  scope: PropTypes.string.isRequired,
  relatedModelName: PropTypes.string,
  columnName: PropTypes.string.isRequired,
  index: PropTypes.number,
  defaultChecked: PropTypes.bool,
  style: PropTypes.shape({
    textAlign: PropTypes.string,
  }),
  activeKey: PropTypes.string,
  disableKey: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};
CheckboxForm.defaultProps = {
  relatedModelName: '',
  index: -1,
  defaultChecked: false,
  style: {},
  activeKey: '1',
  disableKey: '0',
  onChange: () => {},
  disabled: false,
  subTitle: '',
};
export default CheckboxForm;
