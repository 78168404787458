import React from 'react';
import PropTypes from 'prop-types';
import BaseMoal from '../shared/base_modal';
import FlatDepartmentList from './flat_department_list';
import FlatDepartmentListModeType from './flat_department_list/mode_type';
import Button from '../shared/button';

class DepartmentSelectModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  render() {
    const {
      showModal, onCloseButtonClicked, onSelected,
      wholeDepartmentSelectLabel, wholeDepartment, hiddenDepartments,
    } = this.props;
    const { loading } = this.state;
    return (
      <React.Fragment>
        <BaseMoal
          show={showModal}
          title="部署選択"
          contentLabel="editWorkPost"
          onCloseModal={() => onCloseButtonClicked()}
          loading={loading}
        >
          <BaseMoal.Body fixed>
            <div style={{ height: '100%', overflow: 'auto' }}>
              {wholeDepartment.id && (
                <div className="d-flex justify-content-center">
                  <div style={{ lineHeight: '38px' }}>
                    {wholeDepartmentSelectLabel}
                  </div>
                  <div className="ml-2">
                    <Button
                      title="選択"
                      className="btn btn-light"
                      onClick={() => onSelected(wholeDepartment)}
                    />
                  </div>
                </div>
              )}
              <FlatDepartmentList
                onSelected={d => onSelected(d)}
                useWindow={false}
                mode={FlatDepartmentListModeType.SELECT}
                hiddenDepartments={hiddenDepartments}
              />
            </div>
          </BaseMoal.Body>
        </BaseMoal>
      </React.Fragment>
    );
  }
}

DepartmentSelectModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onCloseButtonClicked: PropTypes.func.isRequired,
  onSelected: PropTypes.func.isRequired,
  hiddenDepartments: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })),
  wholeDepartmentSelectLabel: PropTypes.string,
  wholeDepartment: PropTypes.shape({
    id: PropTypes.number,
  }),
};
DepartmentSelectModal.defaultProps = {
  hiddenDepartments: [],
  wholeDepartmentSelectLabel: '',
  wholeDepartment: {},
};
export default DepartmentSelectModal;
