import React, { ReactElement } from "react";
import { EventResponse } from "../../../models/Event";
import { HappinessQuestionnaireEventAnswerTimingType } from "../shared/HappinessQuestionnareType";

interface Props {
  event: EventResponse;
  eventAnswerTiming: HappinessQuestionnaireEventAnswerTimingType;
}

export default function EventTitle(props: Props): ReactElement {
  const { event, eventAnswerTiming } = props;

  let title = "";
  if (
    event.answer_timing === "before_after_answer_timing" &&
    eventAnswerTiming
  ) {
    title = `『${event.title}』実施${
      eventAnswerTiming === "after_event" ? "後" : "前"
    }計測`;
  } else {
    title = `『${event.title}』計測`;
  }
  return (
    <div style={{ padding: 15, textAlign: "center", marginTop: 50 }}>
      {title}
    </div>
  );
}
