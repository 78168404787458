import React from 'react';
import PropTypes from 'prop-types';
import BaseMoal from '../../../shared/base_modal';
import Button from '../../../shared/button';
import BaseForm, { ajaxSubmit } from '../../../shared/base_form';
import SingleImageForm from '../../../shared/form/single_image_form';

class DepartmentEditModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
    this.save = this.save.bind(this);
  }

  save() {
    const { afterSaved } = this.props;
    const form = document.forms.departmentEditForm;
    this.setState({ loading: true }, () => {
      ajaxSubmit(form)
        .then((r) => {
          afterSaved();
        })
        .catch((e) => {
          this.setState({ loading: false });
        });
    });
  }

  render() {
    const {
      showModal, onCloseButtonClicked, department, currentUser,
      baseUrl,
    } = this.props;
    const { loading } = this.state;
    return (
      <React.Fragment>
        <BaseMoal
          show={showModal}
          title={`${department.name}画像変更`}
          contentLabel="editWorkPost"
          onCloseModal={() => onCloseButtonClicked()}
          loading={loading}
          contentStyle={{ maxWidth: 496, width: '100%' }}
        >
          <BaseForm
            name="departmentEditForm"
            action={`${baseUrl}/${department.id}`}
            method="put"
          >
            <div className="w-75" style={{ margin: 'auto' }}>
              <SingleImageForm
                scope="department"
                model={department}
                uploadPath="user/profile_image"
                currentUser={currentUser}
                defaultUrl={department.file_resource.url}
                renderPreview={(url) => {
                  if (url) {
                    return (
                      <div className="fixed-ratio-box__wrapper-62">
                        <div className="fixed-ratio-box__content">
                          <img
                            alt="department-thubmnail"
                            src={url}
                            className="image__cover"
                          />
                        </div>
                      </div>
                    );
                  }
                  return (
                    <div className="fixed-ratio-box__wrapper-62">
                      <div className="fixed-ratio-box__content bgcolor__lightgray" />
                    </div>
                  );
                }}
              />
            </div>
            <div className="modal-footer">
              <button type="submit" id="submit" style={{ border: 'none', padding: 0 }} />
              <button
                type="button"
                className="btn btn-light"
                onClick={() => onCloseButtonClicked()}
                style={{ zIndex: 10 }}
              >
                閉じる
              </button>
              <Button
                title="保存"
                className="btn button__primary"
                onClick={this.save}
                style={{ zIndex: 10 }}
              />
            </div>
          </BaseForm>
        </BaseMoal>
      </React.Fragment>
    );
  }
}

DepartmentEditModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  department: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  currentUser: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  onCloseButtonClicked: PropTypes.func.isRequired,
  afterSaved: PropTypes.func.isRequired,
  baseUrl: PropTypes.string.isRequired,
};
DepartmentEditModal.defaultProps = {
};
export default DepartmentEditModal;
