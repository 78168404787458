import React from "react";
import PropTypes from "prop-types";
import TextareaForm from "../../../shared/form/textarea_form";
import HappinessActionPlan from "./challenge_form/happiness_action_plan";

class ChallengeForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { questionnaire } = this.props;
    if (questionnaire.before_happiness_actions.length === 0) {
      return null;
    }
    const increaseStrengthHappinessActions =
      questionnaire.before_happiness_actions.filter(
        (ha) => ha.purpose === "increase_strength_purpose"
      );
    const reduceWeaknessesHappinessActions =
      questionnaire.before_happiness_actions.filter(
        (ha) => ha.purpose === "reduce_weaknesses_purpose"
      );
    return (
      <React.Fragment>
        <div className="p-3">
          <div className="color__gray font__size-14">
            {`前回(${window.I18n.l(
              "time.formats.default",
              questionnaire.before_questionnaire.created_at
            )})入力したアクションプラン`}
          </div>
          <div className="p-1 border my-1">
            {increaseStrengthHappinessActions.map((ha, i, arr) => (
              <React.Fragment key={ha.id}>
                {i === 0 && (
                  <div className="color__gray font__size-13 font-weight-bold">
                    更に上げていきたい強み
                  </div>
                )}
                <HappinessActionPlan
                  questionnaire={questionnaire.before_questionnaire}
                  happinessAction={ha}
                  isLast={arr.length - 1 === i}
                />
              </React.Fragment>
            ))}
          </div>
          <div className="p-1 border my-1">
            {reduceWeaknessesHappinessActions.map((ha, i, arr) => (
              <React.Fragment key={ha.id}>
                {i === 0 && (
                  <div className="color__gray font__size-13 font-weight-bold">
                    上げていきたい幸せになる余地のある項目
                  </div>
                )}
                <HappinessActionPlan
                  questionnaire={questionnaire.before_questionnaire}
                  happinessAction={ha}
                  isLast={arr.length - 1 === i}
                />
              </React.Fragment>
            ))}
          </div>
        </div>
        <div className="font-weight-bold mt-2 bgcolor__trivial p-2">
          前回のアクションプランを元にチャレンジできたこと、頑張ったこと。
        </div>
        <div className="p-3">
          <TextareaForm
            scope="questionnaire"
            model={questionnaire}
            columnName="challenge_note"
            rows={4}
            hideLabel
          />
        </div>
      </React.Fragment>
    );
  }
}

ChallengeForm.propTypes = {
  questionnaire: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};
ChallengeForm.defaultProps = {};
export default ChallengeForm;
