import React from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import Button from '../../../../../shared/button';
import ClearIcon from '../../../../../shared/images/ic_clear.svg';

class SelectedCategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      title, categoryName, onEdit, onClear,
    } = this.props;
    return (
      <div className="list-group-item">
        <div className="d-flex align-items-center">
          <div className="flex-fill">
            <div className="font__size-13 color__gray">
              <span>
                {title}
              </span>
              <Button
                className="float-right cursor__pointer"
                onClick={() => onEdit()}
              >
                編集
              </Button>
            </div>
            <div
              className="color__black font__size-14 font-weight-bold d-flex align-items-center p-1"
            >
              <ReactSVG
                src={ClearIcon}
                className="mr-2 cursor__pointer color__gray"
                beforeInjection={(svg) => {
                  svg.classList.add('');
                  svg.setAttribute('style', 'width: 17px;height: 17px;margin-top: -2px;');
                }}
                onClick={() => onClear()}
              />
              <div className="flex-fill">
                {categoryName}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
SelectedCategory.propTypes = {
  title: PropTypes.string.isRequired,
  categoryName: PropTypes.string.isRequired,
  onEdit: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
};
SelectedCategory.defaultProps = {
};
export default SelectedCategory;
