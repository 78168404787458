import React, { ReactElement } from "react";

interface Props {
  locale: "ja" | "en";
  classNamePrefix?: "well_being_circle" | "well_being_circle_in_report";
}

export default function CategoryLabels(props: Props): ReactElement {
  const { locale, classNamePrefix = "well_being_circle" } = props;
  return (
    <div className={`${classNamePrefix}__category-label-container`}>
      <div className={`${classNamePrefix}__category-label-wrapper`}>
        <span
          className={`${classNamePrefix}__category-label-1-per-34th ${classNamePrefix}__category-label-1-per-34th__adjust-${locale}`}
        >
          {window.I18n.t(
            "enums.happiness_question.happiness_category.well_being"
          ).replace(/\n/, "")}
        </span>
        <span
          className={`${classNamePrefix}__category-label-6-per-34th ${classNamePrefix}__category-label-6-per-34th__adjust-${locale}`}
        >
          {window.I18n.t(
            "enums.happiness_question.happiness_category.try"
          ).replace(/\n/, "")}
        </span>
        <span
          className={`${classNamePrefix}__category-label-12-per-34th ${classNamePrefix}__category-label-12-per-34th__adjust-${locale}`}
        >
          {window.I18n.t(
            "enums.happiness_question.happiness_category.thanks"
          ).replace(/\n/, "")}
        </span>
        <span
          className={`${classNamePrefix}__category-label-17-per-34th ${classNamePrefix}__category-label-17-per-34th__adjust-${locale}`}
        >
          {window.I18n.t(
            "enums.happiness_question.happiness_category.work_out"
          ).replace(/\n/, "")}
        </span>
        <span
          className={`${classNamePrefix}__category-label-18-per-34th ${classNamePrefix}__category-label-18-per-34th__adjust-${locale}`}
        >
          {window.I18n.t(
            "enums.happiness_question.happiness_category.as_is"
          ).replace(/\n/, "")}
        </span>
        <span
          className={`${classNamePrefix}__category-label-22-per-34th ${classNamePrefix}__category-label-22-per-34th__adjust-${locale}`}
        >
          {window.I18n.t(
            "enums.happiness_question.happiness_category.big_five"
          ).replace(/\n/, "")}
        </span>
        <span
          className={`${classNamePrefix}__category-label-24-per-34th ${classNamePrefix}__category-label-24-per-34th__adjust-${locale}`}
        >
          {window.I18n.t(
            "enums.happiness_question.happiness_category.health"
          ).replace(/\n/, "")}
        </span>
        <span
          className={`${classNamePrefix}__category-label-25-per-34th ${classNamePrefix}__category-label-25-per-34th__adjust-${locale}`}
        >
          {window.I18n.t(
            "enums.happiness_question.happiness_category.stress"
          ).replace(/\n/, "")}
        </span>
        <span
          className={`${classNamePrefix}__category-label-26-per-34th ${classNamePrefix}__category-label-26-per-34th__adjust-${locale}`}
        >
          {window.I18n.t(
            "enums.happiness_question.happiness_category.social_happiness"
          ).replace(/\n/, "")}
        </span>
        <span
          className={`${classNamePrefix}__category-label-29-per-34th ${classNamePrefix}__category-label-29-per-34th__adjust-${locale}`}
        >
          {window.I18n.t(
            "enums.happiness_question.happiness_category.workplace_happiness"
          ).replace(/\n/, "")}
        </span>
        <span
          className={`${classNamePrefix}__category-label-32-per-34th ${classNamePrefix}__category-label-32-per-34th__adjust-${locale}`}
        >
          {window.I18n.t(
            "enums.happiness_question.happiness_category.status_material"
          ).replace(/\n/, "")}
        </span>
      </div>
    </div>
  );
}
