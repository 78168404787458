import React from "react";
import PropTypes from "prop-types";
import QRCode from "qrcode.react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import BaseMoal from "../../shared/base_modal";

class EventCodeLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  render() {
    const { event } = this.props;
    const { showModal } = this.state;
    return (
      <React.Fragment>
        <BaseMoal
          show={showModal}
          title={event.title}
          contentLabel="show_code"
          onCloseModal={() => this.closeModal()}
        >
          <BaseMoal.Body>
            <div className="container-fluid">
              <div>
                以下のいずれかの方法でイベント参加者にアンケートを入力してもらうことができます。
              </div>
              <div>
                <ul>
                  <li>QRコードを共有</li>
                  <li>URLを共有</li>
                </ul>
              </div>
              <div>
                <div className="event-index-event-code-modal__label">
                  計測ページQRコード
                </div>
                <div>
                  <div className="event-index-event-code-modal__qr-code-container">
                    <QRCode value={event.event_invite_url} />
                  </div>
                </div>
              </div>
              <div style={{ height: 100 }}>
                <div className="event-index-event-code-modal__label">
                  計測ページURL
                </div>
                <div>
                  <div className="event-index-event-code-modal__text-input-container">
                    <input
                      id="event-url-box"
                      type="text"
                      style={{ border: "none", width: "100%" }}
                      value={event.event_invite_url}
                      disabled
                    />
                  </div>
                  <div className="event-index-event-code-modal__copy-button-container">
                    <CopyToClipboard text={event.event_invite_url}>
                      <button className="btn btn-light">コピー</button>
                    </CopyToClipboard>
                  </div>
                </div>
              </div>
            </div>
          </BaseMoal.Body>
          <div className="modal-footer">
            <button
              type="submit"
              id="submit"
              style={{ border: "none", padding: 0 }}
            />
            <button
              type="button"
              className="btn btn-light"
              onClick={() => this.closeModal()}
            >
              閉じる
            </button>
          </div>
        </BaseMoal>
        <span
          role="button"
          className="text-primary"
          style={{ cursor: "pointer" }}
          onClick={() => this.setState({ showModal: true })}
        >
          表示
        </span>
      </React.Fragment>
    );
  }
}

EventCodeLink.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.number,
  }),
};
EventCodeLink.defaultProps = {
  event: {},
};
export default EventCodeLink;
