import React from "react";
import LoadingImage from "../../../../shared/images/spin-gray.svg";
import ReplyBorder from "../../../shared/reply_border";

class ReplyLoading extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <li className="list-group-item py-0 border-0">
        <div className="d-flex align-items-stretch">
          <ReplyBorder />
          <div className="w-100 text-center pb-2">
            <img
              src={LoadingImage}
              style={{
                width: 30,
                height: 30,
              }}
            />
          </div>
        </div>
      </li>
    );
  }
}

ReplyLoading.propTypes = {};
ReplyLoading.defaultProps = {};
export default ReplyLoading;
