import React from 'react';
import PropTypes from 'prop-types';
import CommentItem from './comment_list/comment_item';

class CommentList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { comments, currentUser } = this.props;
    return (
      <React.Fragment>
        {comments.map(c => (
          <CommentItem
            key={c.id}
            comment={c}
            currentUser={currentUser}
          />
        ))}
      </React.Fragment>
    );
  }
}

CommentList.propTypes = {
  currentUser: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  comments: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
};
CommentList.defaultProps = {
};
export default CommentList;
