import React from 'react';
import PropTypes from 'prop-types';
import Button from '../shared/button';
import BaseMoal from '../shared/base_modal';
import BaseForm, { ajaxSubmit } from '../shared/base_form';
import QuestionForm from '../questions/question_form';
import HiddenForm from '../shared/form/hidden_form';
import WorkOverview from '../works/work_overview';
import ProfileList, { suggestMessages } from '../display_profiles/profile_list';
import DisplayDepartmentForm from '../shared/form/display_department_form';

const baseUrl = '/wb/user/work_posts';

class WorkPostForm extends React.Component {
  constructor(props) {
    super(props);
    const { workPost } = this.props;
    this.state = {
      workPost,
      uploading: false,
    };
    this.save = this.save.bind(this);
  }

  save() {
    const { beforeSaved, afterSaved, afterFailed } = this.props;
    const form = document.forms.workPostEditForm;
    if (form.checkValidity()) {
      beforeSaved(() => {
        ajaxSubmit(form)
          .then((r) => {
            const { data: workPost } = r;
            afterSaved(workPost);
          })
          .catch((e) => {
            const { data, status } = e.response;
            if (status === 402) {
              this.setState({ workPost: data }, () => afterFailed());
            } else {
              afterFailed();
            }
          });
      });
    } else {
      // 検証結果がfalseなのでsubmitしてhtml5のvalidationメッセージを表示させる
      const submit = document.getElementById('workPostEditSubmit');
      submit.click();
    }
  }

  render() {
    const {
      onCloseButtonClicked, work, userProfile, belongingDepartments,
    } = this.props;
    const { workPost, uploading } = this.state;
    const canDisplayForm = suggestMessages(work.display_profiles, userProfile).length === 0;
    return (
      <BaseForm
        name="workPostEditForm"
        action={workPost.id ? `${baseUrl}/${workPost.id}` : baseUrl}
        method={workPost.id ? 'put' : 'post'}
      >
        <HiddenForm
          scope="work_post"
          columnName="work_id"
          value={work.id}
        />
        <BaseMoal.Body>
          <div>
            <WorkOverview work={work} />
            <ProfileList
              displayProfiles={work.display_profiles}
              userProfile={userProfile}
            />
            {canDisplayForm && (
              <DisplayDepartmentForm
                scope="work_post"
                model={workPost}
                defaultDepartments={belongingDepartments}
                renderHeader={() => (
                  <div className="color__gray font__size-13">
                    公開範囲
                  </div>
                )}
              />
            )}
            {canDisplayForm && work.questions.map((q, i) => {
              let answer;
              if (workPost.answers) {
                const [ans] = workPost.answers.filter(a => a.question_id === q.id);
                answer = ans;
              }
              return (
                <QuestionForm
                  key={q.id}
                  question={q}
                  scope="work_post"
                  index={i}
                  answer={answer}
                />
              );
            })}
          </div>
        </BaseMoal.Body>
        <div className="modal-footer">
          <button type="submit" id="workPostEditSubmit" style={{ border: 'none', padding: 0 }} />
          <button type="button" className="btn btn-light" onClick={() => onCloseButtonClicked()}>
            閉じる
          </button>
          {canDisplayForm && (
            <Button
              title="保存"
              className="btn button__primary"
              onClick={this.save}
              disabled={uploading}
            />
          )}
        </div>
      </BaseForm>
    );
  }
}

WorkPostForm.propTypes = {
  workPost: PropTypes.shape({
    id: PropTypes.number,
  }),
  work: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  userProfile: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  onCloseButtonClicked: PropTypes.func.isRequired,
  beforeSaved: PropTypes.func.isRequired,
  afterSaved: PropTypes.func.isRequired,
  afterFailed: PropTypes.func.isRequired,
  belongingDepartments: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })),
};
WorkPostForm.defaultProps = {
  workPost: {},
  belongingDepartments: [],
};
export default WorkPostForm;
