import React, { ReactElement } from "react";
import { QuestionnaireResponse } from "../../../models/Questionnaire";
import { jaFormat } from "../../../utils/datetime_utils";
import Button from "../../shared/button";

interface Props {
  questionnaire: QuestionnaireResponse;
  isCurrentAdvice: boolean;
}

export default function ChatHistoryItem(props: Props): ReactElement {
  const { questionnaire, isCurrentAdvice } = props;

  return (
    <div className="mt-2" style={{}}>
      <Button
        className={isCurrentAdvice ? "bgcolor__trivial" : "cursor__pointer"}
        disabled={isCurrentAdvice}
        onClick={() => {
          window.location.href = `/user/questionnaires/${questionnaire.id}/questionnaire_advices`;
        }}
      >
        <div className="border__lightgray rounded font__size-13 p-2">
          <div className="color__gray">{`${jaFormat(
            questionnaire.created_at,
            "yyyy年MM月dd日 HH:mm"
          )}`}</div>
        </div>
      </Button>
    </div>
  );
}
