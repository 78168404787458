import { Sha256 } from "@aws-crypto/sha256-js";
import { fromWebToken } from "@aws-sdk/credential-providers";
import { HttpRequest } from "@aws-sdk/protocol-http";
import { SignatureV4 } from "@aws-sdk/signature-v4";
import { AwsCredentialIdentityProvider } from "@aws-sdk/types/dist-types/identity/AwsCredentialIdentity";
import axios from "axios";
import { add, isBefore } from "date-fns";
import React, { ReactElement, useEffect, useState } from "react";
import { QuestionnaireResponse } from "../../../models/Questionnaire";
import { QuestionnaireAdviceResponse } from "../../../models/QuestionnaireAdvice";
import {
  getRequestAsync,
  postRequestAsync,
  putRequestAsync,
} from "../../../utils/api_client";
import Chat from "./chat";

interface Props {
  title: string;
  role: "praise" | "advice";
  questionnaire: QuestionnaireResponse;
  questionnaireAdvice: QuestionnaireAdviceResponse;
  lambdaFunctionUrlDomain: string;
  awsAccessRoleName: string;
  highHappinessElements: string[];
  lowHappinessElements: string[];
  raisedHappinessElements: string[];
  onChange: () => void;
  onFinish: (questionnaireAdvice: QuestionnaireAdviceResponse) => void;
  afterRegisted: () => void;
  onExcessLengthError: () => void;
  scoreList: Array<{
    happiness_category: string;
    happiness_element: string;
    diff_score_from_general_average: number;
  }>;
}

const getOpenIdToken = async (): Promise<string | undefined> => {
  const { result } = await postRequestAsync<{ token: string }>(
    "/user/aws/get_open_id_token"
  );
  if (result !== undefined) {
    return result.token;
  }
};

export default function ChatInProgress(props: Props): ReactElement | null {
  const {
    title,
    role,
    questionnaire,
    questionnaireAdvice,
    lambdaFunctionUrlDomain,
    awsAccessRoleName,
    highHappinessElements,
    lowHappinessElements,
    raisedHappinessElements,
    scoreList,
    onChange,
    onFinish,
    afterRegisted,
  } = props;
  const [credentialObject, setCredentialObject] = useState<{
    credential: AwsCredentialIdentityProvider;
    expireTime: Date;
  }>();
  const [loading, setLoading] = useState(false);
  const [assistantMessage, setAssistantMessage] = useState("");
  const [isFinished, setIsFinished] = useState(false);
  const [retryCount, setRetryCount] = useState(0);

  useEffect(() => {
    loadAssistantMessage();
  }, []);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (retryCount > 0) {
      if (retryCount >= 3) {
        // リトライ回数超過
        // APIのレスポンスパラメータ仕様がいきなり変わったのかわからないが、この分岐から抜け出せず対話ワーク自体が動かなくなる事象が発生したので、
        // この分岐に入ったらハッピーAIがちゃんと動いていることの確認を促すようなエラーメッセージにしている。
        window.Raven.captureException(
          new Error(
            "ハッピーAIの幸福度向上アドバイスで不具合が起きている可能性があります。確認してください。"
          )
        );
        window.alert(
          "ハッピーAIに問題が発生しました。すみませんがブラウザをリロードして再実行してみてください。"
        );
      } else {
        timeoutId = setTimeout(() => {
          setAssistantMessage("");
          loadAssistantMessage();
        }, 1000);
      }
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [retryCount]);

  useEffect(() => {
    const finished = async () => {
      initStates();
      const edited = Object.assign({}, questionnaireAdvice, {
        body: assistantMessage,
      });
      onFinish(edited);
      await putRequestAsync(
        `/user/questionnaires/${questionnaire.id}/questionnaire_advices/${questionnaireAdvice.id}`,
        {
          questionnaire_advice: {
            body: assistantMessage,
          },
        }
      );
      afterRegisted();
    };
    if (isFinished) {
      finished();
    }
  }, [isFinished]);

  const initStates = () => {
    setIsFinished(false);
    setLoading(false);
    setAssistantMessage("");
    setRetryCount(0);
  };

  const checkConsistency = async (): Promise<boolean> => {
    const { result } = await getRequestAsync<QuestionnaireAdviceResponse>(
      `/user/questionnaires/${questionnaire.id}/questionnaire_advices/${questionnaireAdvice.id}`
    );
    // bodyに値が入っているばあいは別画面で既にチャットが終わっている
    return result !== undefined && result.body === null;
  };

  const getCredential = async (): Promise<
    AwsCredentialIdentityProvider | undefined
  > => {
    if (
      credentialObject !== undefined &&
      isBefore(new Date(), credentialObject.expireTime)
    ) {
      return credentialObject.credential;
    }
    const openIdToken = await getOpenIdToken();
    if (openIdToken === undefined) {
      return;
    }
    const c = fromWebToken({
      roleArn: awsAccessRoleName,
      webIdentityToken: openIdToken,
    });
    setCredentialObject({
      credential: c,
      expireTime: add(new Date(), { minutes: 10 }),
    });
    return c;
  };

  const loadAssistantMessage = async (): Promise<void> => {
    setLoading(true);
    const isValid = await checkConsistency();
    if (!isValid) {
      setLoading(false);
      window.alert(
        "チャットが古くなっている可能性があります。申し訳ありませんが、ブラウザをリロードしてください"
      );
      return;
    }
    const c = await getCredential();
    if (c === undefined) {
      window.alert(
        "認証情報が取得できませんでした。ブラウザをリロードして再度お試しください"
      );
      setLoading(false);
      return;
    }
    const signer = new SignatureV4({
      region: "ap-northeast-1",
      service: "lambda",
      sha256: Sha256,
      credentials: c,
    });

    // const encoder = new TextEncoder();
    const requestBody = {
      role,
      highHappinessElements,
      lowHappinessElements,
      raisedHappinessElements,
      scoreList,
    };
    const req = await signer.sign(
      new HttpRequest({
        method: "POST",
        path: "/",
        hostname: lambdaFunctionUrlDomain,
        headers: {
          "Content-Type": "application/json",
          host: lambdaFunctionUrlDomain,
        },
        body: JSON.stringify(requestBody),
      })
    );
    const h = req.headers;
    delete h["host"];
    try {
      await axios({
        url: `https://${req.hostname}${req.path}`,
        method: "post",
        data: requestBody,
        headers: h,
        timeout: 100000,
        onDownloadProgress: (progressEvent) => {
          if (progressEvent.target.status !== 200) {
            return;
          }
          const dataChunk = progressEvent.target.response;
          setAssistantMessage((_) => dataChunk);
          onChange();
        },
      });
      setIsFinished(true);
    } catch (e) {
      console.log(e);
      window.Raven.captureException(e);
      window.alert(
        "予期しないエラーが発生しました。ブラウザをリロードして再実行してみてください。"
      );
    }
  };

  return (
    <>
      {loading && (
        <Chat
          body={assistantMessage}
          title={title}
          loading
          option={{ paragraphMarginNone: role === "advice" }}
        />
      )}
    </>
  );
}
