function convToIdFormat(scope) {
  return scope.replace('][', '_').replace('[', '_').replace(']', '');
}

function rootScope(scope) {
  return scope.replace(/\[.*/, '');
}

export function baseScope(scope, relatedModelName, index = -1) {
  if (relatedModelName) {
    if (index >= 0) {
      return `${scope}[${relatedModelName}_attributes][${index}]`;
    }
    return `${scope}[${relatedModelName}_attributes]`;
  }
  return scope;
}

export function fieldId(scope, relatedModelName, columnName, index = -1) {
  const scopeId = convToIdFormat(scope);
  if (relatedModelName) {
    if (index >= 0) {
      return `${scopeId}_${relatedModelName}_attributes_${index}_${columnName}`;
    }
    return `${scopeId}_${relatedModelName}_attributes_${columnName}`;
  }
  return `${scopeId}_${columnName}`;
}

export function fieldName(scope, relatedModelName, columnName, index = -1) {
  if (relatedModelName) {
    if (index >= 0) {
      return `${scope}[${relatedModelName}_attributes][${index}][${columnName}]`;
    }
    return `${scope}[${relatedModelName}_attributes][${columnName}]`;
  }
  return `${scope}[${columnName}]`;
}

export function i18nKey(scope, relatedModelName, columnName) {
  if (relatedModelName) {
    return `activerecord.attributes.${relatedModelName}.${columnName}`;
  }
  return `activerecord.attributes.${rootScope(scope)}.${columnName}`;
}
