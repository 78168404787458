import React, { ReactElement, useEffect, useState } from "react";
import { HappinessElementType } from "../../../models/HappinessQuestion";
import { getRequestAsync } from "../../../utils/api_client";
import LineChart from "../../shared/line_chart";
import Loading from "../../shared/loading";

interface Props {
  happinessElement: HappinessElementType;
  title: string;
  dataAcquisitionPath: string;
  graphLoadParams: {
    [key: string]: string | boolean | null;
  };
}

interface GraphData {
  title: string;
  chartId: string;
  scaleMin: number;
  scaleMax: number;
  labels: string[];
  tooltipLabels: string[];
  datasets: Array<{
    label: string;
    data: number[];
    color: string;
  }>;
}

export default function HappinessElementTransitionChart(
  props: Props
): ReactElement {
  const { happinessElement, title, dataAcquisitionPath, graphLoadParams } =
    props;
  const [graphData, setGraphData] = useState<GraphData>();

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    const { result } = await getRequestAsync<{ data: GraphData }>(
      dataAcquisitionPath,
      Object.assign(
        {},
        {
          element: happinessElement,
        },
        graphLoadParams
      )
    );
    if (result !== undefined) {
      setGraphData(result.data);
    }
  };

  return (
    <div className="p-1 w-100">
      <div className="card">
        <div className="card-header line-chart__card_header">
          <h4 className="card-title">
            {graphData === undefined ? title : graphData.title}
          </h4>
        </div>
        <div className="line-chart__legend-container">
          <div>
            {graphData !== undefined &&
              graphData.datasets.map((d) => (
                <div
                  key={d.label}
                  className="d-flex justify-content-end align-items-center"
                >
                  <div
                    className="line-chart__legend-icon"
                    style={{ backgroundColor: d.color }}
                  ></div>
                  <div className="ml-2">{d.label}</div>
                </div>
              ))}
          </div>
        </div>
        <div className="card-body">
          <div className="line-chart__transition-container">
            {graphData !== undefined && (
              <LineChart
                chartId={graphData.chartId}
                scaleMin={graphData.scaleMin}
                scaleMax={graphData.scaleMax}
                labels={graphData.labels}
                tooltipLabels={graphData.tooltipLabels}
                datasets={graphData.datasets}
              />
            )}
            {graphData === undefined && (
              <div>
                <Loading loading height={40} width={40} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
