import PropTypes from 'prop-types';
import React from 'react';
import Button from '../../button';

class RadioList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { radioLinkCategories, onChangeLinkPath, linkPath } = this.props;

    return radioLinkCategories.map((category, index) => (
      <React.Fragment key={category.name}>
        <div className="csv-download-link__category-container">
          <div className="csv-download-link__category-name-container">
            {category.name}
          </div>
          <div className="row">
            {category.contents.map((content, contentIndex) => {
              const key = `${category.name}_content[${contentIndex}]`;
              return (
                <div key={key} className="col-md-6">
                  <div className="csv-download-link__button-container">
                    <Button
                      className={`btn btn-light
                          csv-download-link__button
                          ${linkPath === content.link_path ? 'csv-download-link__button__selected' : ''}
                        `}
                      onClick={() => onChangeLinkPath(content.link_path)}
                    >
                      <span>
                        <span className="csv-download-link__button-text-title">
                          {content.title}
                        </span><br />
                        <span className="csv-download-link__button-text-description">
                          {content.description}
                        </span>
                      </span>
                    </Button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </React.Fragment>
    ));
  }
}
RadioList.propTypes = {
  radioLinkCategories: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    contents: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      link_path: PropTypes.string.isRequired,
    })),
  })).isRequired,
  onChangeLinkPath: PropTypes.func.isRequired,
  linkPath: PropTypes.string.isRequired,
};
RadioList.defaultProps = {
};
export default RadioList;
