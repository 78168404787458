import React from 'react';
import PropTypes from 'prop-types';

export function labelBgColor(questionCategory) {
  const { id, key } = questionCategory;
  if (id) {
    switch (key) {
      case 'both_before_after_event':
        return 'gray';
      case 'only_before_event':
        return '#A8D1FF';
      case 'only_after_event':
        return '#FF6384';
      default:
        return 'gray';
    }
  }
  return 'gray';
}

class QuestionCategoryLabel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { questionCategory, className } = this.props;
    return (
      <span className={className} style={{ backgroundColor: labelBgColor(questionCategory) }}>
        {questionCategory.name}
      </span>
    );
  }
}

QuestionCategoryLabel.propTypes = {
  questionCategory: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  className: PropTypes.string,
};
QuestionCategoryLabel.defaultProps = {
  className: '',
};
export default QuestionCategoryLabel;
