import React, { ReactElement, useEffect, useRef, useState } from "react";
import Button from "../../../shared/button";

interface Props {
  isLastPage: boolean;
  loading: boolean;
  allInputtingFormSelected: boolean;
  onSubmit: () => void;
  onDisabledNextPageClicked: () => void;
  onActiveNextPageClicked: () => void;
}

export default function ActionButton(props: Props): ReactElement {
  const {
    isLastPage,
    loading,
    allInputtingFormSelected,
    onSubmit,
    onDisabledNextPageClicked,
    onActiveNextPageClicked,
  } = props;

  let isActive = false;
  if (isLastPage) {
    if (!loading && allInputtingFormSelected) {
      isActive = true;
    }
  } else if (allInputtingFormSelected) {
    isActive = true;
  }

  return (
    <div className="happiness-questionnaire__action-button-container">
      <div className="happiness-questionnaire__next-button-container">
        {isLastPage && (
          <React.Fragment>
            <button
              type="submit"
              id="submit"
              style={{ border: "none", padding: 0 }}
            />
            <Button
              className="btn happiness-questionnaire__next-button"
              onClick={() => {
                if (isActive) {
                  onSubmit();
                } else {
                  onDisabledNextPageClicked();
                }
              }}
              title={window.I18n.t(
                "javascript.questionnaires.happiness_questionnaire.send"
              )}
            />
          </React.Fragment>
        )}
        {!isLastPage && (
          <Button
            className="btn happiness-questionnaire__next-button"
            onClick={() => {
              if (isActive) {
                onActiveNextPageClicked();
              } else {
                onDisabledNextPageClicked();
              }
            }}
            title={window.I18n.t(
              "javascript.questionnaires.happiness_questionnaire.next"
            )}
          />
        )}
      </div>
    </div>
  );
}
