import React from 'react';
import PropTypes from 'prop-types';
import HappinessActionQuestion from '../../../shared/happiness_action_question';

class HappinessActionPlan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { questionnaire, happinessAction, isLast } = this.props;
    return (
      <div className="p-1">
        <div className="font__size-14 color__gray">
          <HappinessActionQuestion
            questionnaire={questionnaire}
            happinessElement={happinessAction.happiness_element}
          />
        </div>
        <div className={`p-2 font__size-14 ${isLast ? '' : 'border-bottom'}`}>
          {happinessAction.plan}
        </div>
      </div>
    );
  }
}

HappinessActionPlan.propTypes = {
  questionnaire: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  happinessAction: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  isLast: PropTypes.bool.isRequired,
};
HappinessActionPlan.defaultProps = {
};
export default HappinessActionPlan;
