import React from 'react';
import PropTypes from 'prop-types';
import NotificationSettingEditModal from './notification_setting_edit_modal';
import Button from '../shared/button';

class NotificationSettingList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  render() {
    const { user } = this.props;
    const { showModal } = this.state;
    const { department_user_notification_settings: dunSettings } = user;
    return (
      <React.Fragment>
        <NotificationSettingEditModal
          showModal={showModal}
          user={user}
          onCloseButtonClicked={() => this.setState({ showModal: false })}
        />
        <div className="row">
          <div className="col-sm-8 color__gray font__size-13">
            部署のお知らせ
          </div>
          <div className="col-sm-4">
            <div className="float-right">
              <Button
                className="btn button__primary-slim"
                onClick={() => this.setState({ showModal: true })}
              >
                編集
              </Button>
            </div>
          </div>
        </div>
        {dunSettings.map((duns) => {
          const { department } = duns;
          return (
            <div className="row my-2" key={duns.id}>
              <div className="col-sm-8">
                {department.name}
              </div>
              <div className="col-sm-4">
                全ての投稿
              </div>
            </div>
          );
        })}
        {dunSettings.length === 0 && (
          <div className="row">
            <div className="col-sm-12 color__gray">
              部署の投稿に関するお知らせ設定はありません
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

NotificationSettingList.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};
NotificationSettingList.defaultProps = {
};
export default NotificationSettingList;
