import PropTypes from 'prop-types';
import React from 'react';
import { ReactSVG } from 'react-svg';
import ClearIcon from '../../images/ic_clear.svg';
import FileName from '../../../file_resources/file_name';
import HiddenForm from '../hidden_form';
import { cleanUrl } from '../../../../utils/string_utils';
import Box from '../image_form/shared/box';

class FileField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.remove = this.remove.bind(this);
  }

  remove() {
    const { onNotSavedImageRemoved, fileResource } = this.props;
    if (fileResource.id) {
      this.setState({ deleted: true });
    } else {
      onNotSavedImageRemoved();
    }
  }

  render() {
    const {
      scope, fileResource, index, currentUser,
    } = this.props;
    const { deleted } = this.state;
    return (
      <React.Fragment>
        {fileResource.id && (
          <HiddenForm
            scope={scope}
            relatedModelName="file_resources"
            index={index}
            columnName="id"
            value={fileResource.id}
          />
        )}
        {currentUser.id && (
          <HiddenForm
            scope={scope}
            relatedModelName="file_resources"
            index={index}
            columnName="user_id"
            value={currentUser.id}
          />
        )}
        <HiddenForm
          scope={scope}
          relatedModelName="file_resources"
          index={index}
          columnName="file_type"
          value="unknown_file_type"
        />
        <HiddenForm
          scope={scope}
          relatedModelName="file_resources"
          index={index}
          columnName="url"
          value={cleanUrl(fileResource.url)}
        />
        <HiddenForm
          scope={scope}
          relatedModelName="file_resources"
          index={index}
          columnName="file_name"
          value={fileResource.file_name}
        />
        {deleted && (
          <React.Fragment>
            <HiddenForm
              scope={scope}
              relatedModelName="file_resources"
              index={index}
              columnName="_destroy"
              value="1"
            />
            <div className="d-flex align-items-center p-1">
              <FileName
                name={fileResource.file_name}
                deleted={deleted}
              />
            </div>
          </React.Fragment>
        )}
        {!deleted && (
          <div className="d-flex align-items-center p-1">
            <ReactSVG
              src={ClearIcon}
              className="mr-2 cursor__pointer color__gray"
              beforeInjection={(svg) => {
                svg.classList.add('');
                svg.setAttribute('style', 'width: 17px;height: 17px;margin-top: -2px;');
              }}
              onClick={this.remove}
            />
            <FileName name={fileResource.file_name} />
          </div>
        )}
      </React.Fragment>
    );
  }
}
FileField.propTypes = {
  scope: PropTypes.string.isRequired,
  fileResource: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  index: PropTypes.number.isRequired,
  onNotSavedImageRemoved: PropTypes.func.isRequired,
  currentUser: PropTypes.shape({
    id: PropTypes.number,
  }),
};
FileField.defaultProps = {
  currentUser: {},
};
export default FileField;
