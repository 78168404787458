import React from 'react';
import PropTypes from 'prop-types';
import MyComment from './comment_item/my_comment';
import OtherComment from './comment_item/other_comment';
import ReplyList from './comment_item/reply_list';
import ReplyToggleButton from './comment_item/reply_toggle_button';

class CommentItem extends React.Component {
  constructor(props) {
    super(props);
    const { comment } = this.props;
    this.state = {
      showReply: !!(comment.reply_comments && comment.reply_comments.length > 0),
      comment,
    };
    this.replyInput = React.createRef();
    this.focusReplyButton = this.focusReplyButton.bind(this);
  }

  isMyComment() {
    const { comment: { user }, currentUser } = this.props;
    return user.id === currentUser.id;
  }

  focusReplyButton() {
    const { showReply } = this.state;
    if (showReply) {
      this.setState({ showReply: false }, () => {
        this.setState({ showReply: true }, () => {
          setTimeout(() => {
            this.replyInput.current.focus();
          }, 400);
        });
      });
    } else {
      this.setState({ showReply: true }, () => {
        setTimeout(() => {
          this.replyInput.current.focus();
        }, 400);
      });
    }
  }

  render() {
    const { currentUser } = this.props;
    const { comment, showReply } = this.state;
    return (
      <React.Fragment>
        <li className="list-group-item py-0 border-0">
          {this.isMyComment() && (
            <MyComment
              comment={comment}
              currentUser={currentUser}
              onReplyButtonClicked={this.focusReplyButton}
            />
          )}
          {!this.isMyComment() && (
            <OtherComment
              comment={comment}
              onReplyButtonClicked={this.focusReplyButton}
            />
          )}
          {comment.comments_count > 0 && (
            <ReplyToggleButton
              showReply={showReply}
              onClick={() => this.setState({ showReply: !showReply })}
            />
          )}
        </li>
        {showReply && (
          <ReplyList
            ref={this.replyInput}
            currentUser={currentUser}
            comment={comment}
            replyComments={comment.reply_comments}
          />
        )}
      </React.Fragment>
    );
  }
}

CommentItem.propTypes = {
  currentUser: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  comment: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};
CommentItem.defaultProps = {
};
export default CommentItem;
