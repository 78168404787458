import React from 'react';
import PropTypes from 'prop-types';
import Button from '../shared/button';
import NotificationSettingField from './notification_setting_form/notification_setting_field';
import DepartmentSelectModal from '../departments/department_select_modal';
import BaseForm, { ajaxSubmit } from '../shared/base_form';
import BaseMoal from '../shared/base_modal';

function addWholeDepartment(dunSettings, department) {
  let newDunSettings = dunSettings.map((duns) => {
    if (duns.department && duns.department.id === department.id) {
      return Object.assign({}, duns, { destroyed: false });
    } else if (duns.id) {
      return Object.assign({}, duns, { destroyed: true });
    }
    return null;
  });
  if (!newDunSettings.some(duns => duns.department && duns.department.id === department.id)) {
    newDunSettings = newDunSettings.concat([{
      department,
      role: 'post_notification_role',
      level: 'subordinate_level',
    }]);
  }
  return newDunSettings;
}

function addNormalDepartment(dunSettings, department) {
  // 全社を削除して追加
  let newDunSettings = dunSettings.map((duns) => {
    if (duns.level === 'subordinate_level') {
      return Object.assign({}, duns, { destroyed: true });
    }
    return duns;
  });
  newDunSettings = newDunSettings.concat([{
    department,
    role: 'post_notification_role',
    level: 'single_level',
  }]);
  return newDunSettings;
}

class NotificationSettingForm extends React.Component {
  constructor(props) {
    super(props);
    const { user } = this.props;
    const dunSettings = user.department_user_notification_settings || [];
    this.state = {
      dunSettings,
      showModal: false,
    };
    this.add = this.add.bind(this);
    this.save = this.save.bind(this);
    this.loading = false;
  }

  remove(index) {
    const { dunSettings } = this.state;
    let newDunSettings = dunSettings.map((duns) => {
      if (duns.id) {
        return Object.assign({}, duns, { destroyed: true });
      }
      return null;
    });
    newDunSettings = newDunSettings.filter(duns => duns);
    this.setState({ dunSettings: newDunSettings });
  }

  add(department) {
    const { dunSettings } = this.state;
    let newDunSettings = [];
    if (department.variety === 'whole') {
      newDunSettings = addWholeDepartment(dunSettings, department);
    } else {
      newDunSettings = addNormalDepartment(dunSettings, department);
    }
    this.setState({ dunSettings: newDunSettings, showModal: false });
  }

  save() {
    const { beforeSaved, afterSaved, afterFailed } = this.props;
    if (this.loading) {
      return;
    }
    this.loading = true;
    const form = document.forms.notificationSettingEditForm;
    if (form.checkValidity()) {
      beforeSaved(() => {
        ajaxSubmit(form)
          .then((r) => {
            afterSaved();
          })
          .catch((e) => {
            console.log(e);
            afterFailed();
            this.loading = false;
          });
      });
    } else {
      // 検証結果がfalseなのでsubmitしてhtml5のvalidationメッセージを表示させる
      const submit = document.getElementById('notificationSettingEditFormSubmit');
      submit.click();
      this.loading = false;
    }
  }

  render() {
    const { onCloseButtonClicked, user } = this.props;
    const { dunSettings, showModal } = this.state;
    const activeDunsSettings = dunSettings.filter(duns => (duns && !duns.destroyed));
    const existsWholeDepartment = activeDunsSettings.some(duns => duns && duns.department.variety === 'whole');
    return (
      <React.Fragment>
        <DepartmentSelectModal
          showModal={showModal}
          onCloseButtonClicked={() => this.setState({ showModal: false })}
          onSelected={this.add}
          hiddenDepartments={activeDunsSettings.map(dnss => dnss.department)}
          wholeDepartmentSelectLabel="全ての投稿の通知を受け取る:"
          wholeDepartment={existsWholeDepartment ? undefined : user.whole_department}
        />
        <BaseForm
          name="notificationSettingEditForm"
          action="/wb/user/notification_settings_update"
          method="put"
        >
          <BaseMoal.Body>
            <div className="p-3">
              投稿があった際に通知を受け取る部署
            </div>
            {dunSettings.map((duns, i) => {
              if (!duns) {
                return null;
              }
              const keyIndex = i;
              return (
                <NotificationSettingField
                  key={keyIndex}
                  scope="user"
                  departmentUserNotificationSetting={duns}
                  index={i}
                  onDepartmentRemoved={() => this.remove(i)}
                />
              );
            })}
            <div className="d-flex justify-content-center my-3">
              <Button
                className="btn btn-light w-50"
                onClick={() => this.setState({ showModal: true })}
              >
                部署追加
              </Button>
            </div>
            <div className="modal-footer">
              <button type="submit" id="notificationSettingEditFormEditSubmit" style={{ border: 'none', padding: 0 }} />
              <button type="button" className="btn btn-light" onClick={() => onCloseButtonClicked()}>
                閉じる
              </button>
              <Button
                title="保存"
                className="btn button__primary"
                onClick={this.save}
              />
            </div>
          </BaseMoal.Body>
        </BaseForm>
      </React.Fragment>
    );
  }
}

NotificationSettingForm.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  onCloseButtonClicked: PropTypes.func.isRequired,
  beforeSaved: PropTypes.func.isRequired,
  afterSaved: PropTypes.func.isRequired,
  afterFailed: PropTypes.func.isRequired,
};
NotificationSettingForm.defaultProps = {
  user: { department_user_notification_settings: [] },
};

export default NotificationSettingForm;
