import React from 'react';
import PropTypes from 'prop-types';
import CategoryButton from './shared/category_button';
import DepartmentSelectModal from '../../../../departments/department_select_modal';
import SelectedCategory from './shared/selected_category';

class DepartmentCategoryButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      department: null,
    };
    this.setDepartment = this.setDepartment.bind(this);
  }

  setDepartment(d = null) {
    const { onSelected } = this.props;
    this.setState({ showModal: false, department: d }, () => {
      onSelected(d);
    });
  }

  render() {
    const { showModal, department } = this.state;
    return (
      <React.Fragment>
        <DepartmentSelectModal
          showModal={showModal}
          onCloseButtonClicked={() => this.setState({ showModal: false })}
          onSelected={this.setDepartment}
        />
        {!department && (
          <CategoryButton
            title="部署で絞る"
            onClick={() => this.setState({ showModal: true })}
          />
        )}
        {department && (
          <SelectedCategory
            title="選択済部署"
            categoryName={department.name}
            onEdit={() => this.setState({ showModal: true })}
            onClear={() => this.setDepartment()}
          />
        )}
      </React.Fragment>
    );
  }
}
DepartmentCategoryButton.propTypes = {
  onSelected: PropTypes.func.isRequired,
};
DepartmentCategoryButton.defaultProps = {
};
export default DepartmentCategoryButton;
