import React from 'react';
import PropTypes from 'prop-types';
import { postRequest } from '../../utils/api_client';

class TrackPage extends React.Component {
  componentDidMount() {
    this.tracking();
  }

  tracking() {
    const { trackingData } = this.props;
    postRequest('/tracks', trackingData);
  }

  render() {
    return null;
  }
}

TrackPage.propTypes = {
  trackingData: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
};
TrackPage.defaultProps = {
};
export default TrackPage;
