import React from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
import { getRequest } from '../../utils/api_client';
import Loading from '../shared/loading';
import DepartmentItemForSelecting from './flat_department_list/department_item_for_selecting';
import DepartmentItemToMember from './flat_department_list/department_item_to_member';
import ModeType from './flat_department_list/mode_type';

class FlatDepartmentList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      departments: [],
      loading: false,
      hasMore: true,
    };
    this.loadMore = this.loadMore.bind(this);
    this.renderCount = 0;
  }

  loadMore(page) {
    const { hiddenDepartments } = this.props;
    const { departments: oldDepartments } = this.state;
    this.setState({ loading: true }, () => {
      getRequest(
        '/wb/user/company/departments',
        {
          page,
          q: {
            id_not_in: hiddenDepartments.map(d => d.id),
          },
        },
      )
        .then((r) => {
          const { data: newDepartments } = r;
          this.setState({
            loading: false,
            departments: oldDepartments.concat(newDepartments),
            hasMore: newDepartments.length > 0,
          });
        })
        .catch((e) => {
          this.setState({ loading: false, hasMore: false });
        });
    });
  }

  render() {
    const {
      onSelected, useWindow, mode, baseUrl,
      currentUser, belongingDepartments,
    } = this.props;
    const {
      departments, loading, hasMore,
    } = this.state;
    return (
      <React.Fragment>
        <InfiniteScroll
          key={this.renderCount}
          className="d-flex flex-row flex-wrap justify-content-center"
          pageStart={0}
          loadMore={this.loadMore}
          hasMore={!loading && hasMore}
          useWindow={useWindow}
          loader={<div className="p-2 color__gray" key={0}>Loading ...</div>}
        >
          {departments.map((d) => {
            if (mode === ModeType.SELECT) {
              return (
                <DepartmentItemForSelecting
                  key={d.id}
                  department={d}
                  onClick={() => onSelected(d)}
                />
              );
            }
            return (
              <DepartmentItemToMember
                key={d.id}
                baseUrl={baseUrl}
                department={d}
                currentUser={currentUser}
                belongingDepartments={belongingDepartments}
              />
            );
          })}
        </InfiniteScroll>
        <Loading loading={departments.length === 0 && loading} />
        {(departments.length === 0 && !loading) && (
          <div className="p-2 color__gray">
            部署がありません
          </div>
        )}
      </React.Fragment>
    );
  }
}

FlatDepartmentList.propTypes = {
  useWindow: PropTypes.bool,
  mode: PropTypes.string,
  // ↓ mode: SELECTのみ利用できるprop
  onSelected: PropTypes.func,
  // ↓ mode: TO_MEMBERのみ利用できるprop.
  baseUrl: PropTypes.string,
  currentUser: PropTypes.shape({
    id: PropTypes.number,
  }),
  hiddenDepartments: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })),
  belongingDepartments: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })),
};
FlatDepartmentList.defaultProps = {
  useWindow: true,
  mode: ModeType.TO_MEMBER,
  onSelected: () => {},
  baseUrl: '',
  currentUser: {},
  hiddenDepartments: [],
  belongingDepartments: [],
};
export default FlatDepartmentList;
