import React from 'react';
import PropTypes from 'prop-types';
import ProfileItem from './profile_list/profile_item';
import MultiLineText from '../shared/multi_line_text';

export function suggestMessages(displayProfiles, userProfile) {
  return displayProfiles.map((dp) => {
    if (dp.role === 'required' && !userProfile[dp.column_name]) {
      return dp.suggest_message;
    }
    return null;
  }).filter(mes => mes);
}

class ProfileList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  suggestMessages() {
    const { displayProfiles, userProfile } = this.props;
    return displayProfiles.map((dp) => {
      if (dp.role === 'required' && !userProfile[dp.column_name]) {
        return {
          message: dp.suggest_message,
          url: dp.suggest_url,
        };
      }
      return null;
    }).filter(mes => mes);
  }

  render() {
    const { displayProfiles, userProfile } = this.props;

    return (
      <React.Fragment>
        {displayProfiles.map(dp => (
          <ProfileItem
            key={dp.id}
            displayProfile={dp}
            userProfile={userProfile}
          />
        ))}
        {this.suggestMessages().map(({ message, url }) => (
          <div key={message}>
            <MultiLineText text={message} />
            <br />
            {url && (
              <a href={url}>
                リンク
              </a>
            )}
          </div>
        ))}
      </React.Fragment>
    );
  }
}

ProfileList.propTypes = {
  displayProfiles: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
  userProfile: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};
ProfileList.defaultProps = {
};
export default ProfileList;
