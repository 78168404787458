import React from "react";
import PropTypes from "prop-types";
import Button from "../shared/button";
import EventEditModal from "./shared/event_edit_modal";
import EventItem from "./event_management_list/event_item";

class NewEventButton extends React.Component {
  constructor(props) {
    super(props);
    const { event } = this.props;
    this.state = {
      showModal: false,
      event,
    };
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  render() {
    const {
      action,
      authenticityToken,
      roleOptions,
      answerTimingOptions,
      questionnaireMethodOptions,
      workHappinessQuestionnaireUsable,
      profileInputBirthYearOptions,
      profileInputBirthMonthOptions,
      profileInputSexOptions,
      profileInputForResearchOptions,
      surveyTypeOptions,
    } = this.props;
    const { showModal, event } = this.state;
    return (
      <React.Fragment>
        <EventEditModal
          showModal={showModal}
          onCloseButtonClicked={() => this.closeModal()}
          event={event}
          action={action}
          authenticityToken={authenticityToken}
          answerTimingOptions={answerTimingOptions}
          questionnaireMethodOptions={questionnaireMethodOptions}
          roleOptions={roleOptions}
          workHappinessQuestionnaireUsable={workHappinessQuestionnaireUsable}
          afterSaved={() => {
            window.location.pathname = "/companies/events";
          }}
          profileInputBirthYearOptions={profileInputBirthYearOptions}
          profileInputBirthMonthOptions={profileInputBirthMonthOptions}
          profileInputSexOptions={profileInputSexOptions}
          profileInputForResearchOptions={profileInputForResearchOptions}
          surveyTypeOptions={surveyTypeOptions}
        />
        <Button
          title="追加"
          className="btn button__primary"
          onClick={() => this.setState({ showModal: true })}
        />
      </React.Fragment>
    );
  }
}

NewEventButton.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.number,
  }),
  answerTimingOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  questionnaireMethodOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  roleOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  action: PropTypes.string.isRequired,
  authenticityToken: PropTypes.string.isRequired,
  workHappinessQuestionnaireUsable: PropTypes.bool,
  profileInputBirthYearOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  profileInputBirthMonthOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  profileInputSexOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  profileInputForResearchOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  surveyTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
};
NewEventButton.defaultProps = {
  event: {},
  workHappinessQuestionnaireUsable: false,
};
export default NewEventButton;
