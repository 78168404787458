import { format, parseISO, startOfToday } from "date-fns";
import PropTypes from "prop-types";
import React from "react";
import { setupPickaDateWithCallback } from "../../../../utils/datetime_utils";
import { joinedAncestorName } from "../../../../utils/string_utils";
import SelectForm from "../../../shared/form/select_form";
import TextForm from "../../../shared/form/text_form";

const todayStr = format(startOfToday(), "yyyy-MM-dd");

class MemberEditForm extends React.Component {
  constructor(props) {
    super(props);
    const { member, isAdmin } = this.props;
    this.state = {
      selectedDepartmentId: String(member.department_id),
      selectedRefAuth: isAdmin ? "company_admin_auth" : member.ref_auth,
    };
    this.changeDepartment = this.changeDepartment.bind(this);
  }

  componentDidMount() {
    const { onFormChanged } = this.props;
    setupPickaDateWithCallback({}, onFormChanged);
  }

  changeDepartment(e) {
    const { onFormChanged } = this.props;
    this.setState(
      {
        selectedDepartmentId: e.target.value,
      },
      onFormChanged
    );
  }

  ancestorsName() {
    const { departments } = this.props;
    const { selectedDepartmentId } = this.state;
    return joinedAncestorName(departments, selectedDepartmentId);
  }

  render() {
    const {
      company,
      currentUser,
      authOptions,
      eventAuthOptions,
      managerialPositionOptions,
      dutyOptions,
      departmentOptions,
      roleOptions,
      isAdmin,
      isEventAuthEditable,
      member,
      onFormChanged,
    } = this.props;
    const { selectedRefAuth } = this.state;
    const ancestorsName = this.ancestorsName();
    const startApplyingDateStr = format(
      parseISO(member.start_applying_date),
      "yyyy-MM-dd"
    );
    return (
      <React.Fragment>
        <div className="form-group row">
          <span className="col-sm-4 col-form-label">
            {window.I18n.t("activerecord.attributes.user.account_name")}
          </span>
          <div className="col-sm-8">
            <span style={{ lineHeight: "38px" }}>
              {member.user.account_name}
            </span>
          </div>
        </div>
        {ancestorsName && (
          <div className="row">
            <div className="col-sm-4 col-form-label" />
            <div className="col-sm-8" style={{ fontSize: 13, color: "gray" }}>
              {ancestorsName}
            </div>
          </div>
        )}
        <SelectForm
          model={member}
          scope="member"
          columnName="department_id"
          options={departmentOptions}
          onChange={this.changeDepartment}
        />
        <SelectForm
          model={member}
          scope="member"
          columnName="managerial_position_id"
          options={managerialPositionOptions}
          onChange={onFormChanged}
        />
        <SelectForm
          model={member}
          scope="member"
          columnName="duty_id"
          options={dutyOptions}
          onChange={onFormChanged}
        />
        {currentUser && currentUser.id === member.user.id && (
          <div className="row">
            <div className="col-sm-4 col-form-label" />
            <div className="col-sm-8" style={{ fontSize: 13, color: "gray" }}>
              自分自身の管理者権限は変更できません
            </div>
          </div>
        )}
        <SelectForm
          model={member}
          scope="member"
          label={
            <div>
              <div>
                {window.I18n.t("activerecord.attributes.member.ref_auth")}
              </div>
              <div className="font__size-13 color__gray">
                全社管理者：メンバー単位での結果参照
                {company.member_ref_auth === "all_member_ref_auth"
                  ? "可"
                  : "不可"}
                <br />
                部署リーダー：メンバー単位での結果参照
                {company.member_ref_auth_of_leaders ===
                "all_member_ref_auth_of_leaders"
                  ? "可"
                  : "不可"}
              </div>
            </div>
          }
          columnName="auth"
          options={authOptions}
          selectedKey={isAdmin ? "company_admin_auth" : member.ref_auth}
          disabled={isAdmin && currentUser && currentUser.id === member.user.id}
          onChange={(e) => {
            this.setState({ selectedRefAuth: e.target.value });
            onFormChanged();
          }}
        />
        {selectedRefAuth === "subordinate_ref_auth" && (
          <SelectForm
            model={member}
            scope="member"
            columnName="role"
            options={roleOptions}
            onChange={onFormChanged}
          />
        )}
        {isEventAuthEditable && (
          <SelectForm
            model={member}
            scope="member"
            columnName="event_auth"
            options={eventAuthOptions}
            onChange={onFormChanged}
          />
        )}
        <TextForm
          model={member}
          scope="member"
          columnName="start_applying_date"
          value={
            startApplyingDateStr === "1970-01-01"
              ? todayStr
              : startApplyingDateStr
          }
          className="datepicker_withcallback"
        />
        <div className="font__size-13 color__gray">
          上記の設定は、適用開始日以降に対して反映されます。
          <br />
          過去の診断結果への参照権限を付与する為には、診断開始日以前を適用開始日に設定してください。
        </div>
      </React.Fragment>
    );
  }
}

MemberEditForm.propTypes = {
  currentUser: PropTypes.shape({
    id: PropTypes.number,
  }),
  member: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  authOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  eventAuthOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  managerialPositionOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  dutyOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  departmentOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  departments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ).isRequired,
  roleOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isEventAuthEditable: PropTypes.bool.isRequired,
  onFormChanged: PropTypes.func.isRequired,
  company: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};
MemberEditForm.defaultProps = {};
export default MemberEditForm;
