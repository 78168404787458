import React, { ReactElement, useState } from "react";
import BaseModal from "../shared/base_modal";
import HappyBirdImage from "../shared/images/happy_bird.png";
import MultiLineText from "../shared/multi_line_text";
import { ContractPeriodDepartmentAdviceResponse } from "../../models/ContractPeriodDepartmentAdvice";

interface Props {
  title: string;
  contractPeriodDepartmentAdvice: ContractPeriodDepartmentAdviceResponse;
}

export default function DepartmentAdviceButton(props: Props): ReactElement {
  const { title, contractPeriodDepartmentAdvice } = props;
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      {showModal && (
        <BaseModal
          show={showModal}
          title={
            <div className="d-flex align-items-center">
              <div>
                <img src={HappyBirdImage} style={{ width: 30 }} />
              </div>
              <div className="ml-2">ハッピーAIからのメッセージ</div>
            </div>
          }
          contentLabel="questionnaireAdvicesModal"
          onCloseModal={() => setShowModal(false)}
          contentStyle={{ maxWidth: 496, width: "100%" }}
        >
          <BaseModal.Body>
            <div className="department-overview__ai-message-body p-2 rounded mt-2">
              <MultiLineText text={contractPeriodDepartmentAdvice.body} />
            </div>
          </BaseModal.Body>
        </BaseModal>
      )}
      <div
        className="btn button__highlignt mt-2 w-100"
        onClick={() => setShowModal(true)}
      >
        {title}
      </div>
    </>
  );
}
