import React from 'react';
import PropTypes from 'prop-types';
import BaseMoal from '../shared/base_modal';
import WorkPostForm from './work_post_form';

class WorkPostEditModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  render() {
    const {
      showModal, onCloseButtonClicked, work, workPost,
      afterSaved, userProfile, belongingDepartments, stopLoadingAfterSaved,
    } = this.props;
    const { loading } = this.state;
    return (
      <React.Fragment>
        <BaseMoal
          show={showModal}
          title="投稿"
          contentLabel="editWorkPost"
          onCloseModal={() => onCloseButtonClicked()}
          loading={loading}
          contentStyle={{ maxWidth: 496, width: '100%' }}
        >
          <WorkPostForm
            workPost={workPost}
            work={work}
            userProfile={userProfile}
            belongingDepartments={belongingDepartments}
            beforeSaved={callback => this.setState({ loading: true }, callback)}
            afterSaved={(wp) => {
              if (stopLoadingAfterSaved) {
                this.setState({ loading: false }, afterSaved(wp));
              } else {
                afterSaved(wp);
              }
            }}
            afterFailed={() => this.setState({ loading: false })}
            onCloseButtonClicked={() => onCloseButtonClicked()}
          />
        </BaseMoal>
      </React.Fragment>
    );
  }
}

WorkPostEditModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  workPost: PropTypes.shape({
    id: PropTypes.number,
  }),
  work: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  userProfile: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  onCloseButtonClicked: PropTypes.func.isRequired,
  afterSaved: PropTypes.func.isRequired,
  belongingDepartments: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })),
  stopLoadingAfterSaved: PropTypes.bool,
};
WorkPostEditModal.defaultProps = {
  workPost: {},
  belongingDepartments: [],
  stopLoadingAfterSaved: false,
};
export default WorkPostEditModal;
