import React from 'react';
import PropTypes from 'prop-types';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import ManagerialPositionListItem from './managerial_position_list/managerial_position_list_item';
import HeaderAction from './managerial_position_list/header_action';
import BodyAction from './managerial_position_list/body_action';
import LoadingMask from '../shared/loading_mask';
import BaseForm from '../shared/base_form';

class ManagerialPositionList extends React.Component {
  constructor(props) {
    super(props);
    const { managerialPositions } = this.props;
    this.state = {
      managerialPositions,
      canSort: false,
      loading: false,
    };
    this.initialManagerialPositions = managerialPositions.concat();
    this.onSortEnd = this.onSortEnd.bind(this);
    this.resetSorting = this.resetSorting.bind(this);
  }

  onSortEnd({ oldIndex, newIndex }) {
    this.setState(({ managerialPositions }) => ({
      managerialPositions: arrayMove(managerialPositions, oldIndex, newIndex),
    }));
  }

  resetSorting() {
    this.setState({
      managerialPositions: this.initialManagerialPositions,
      canSort: false,
    });
  }

  render() {
    const { action } = this.props;
    const { managerialPositions, canSort, loading } = this.state;

    const SortableItem = SortableElement(({ managerialPosition, position }) => (
      <ManagerialPositionListItem
        key={managerialPosition.id}
        managerialPosition={managerialPosition}
        action={action}
        canSort={canSort}
        position={position}
      />
    ));

    const SortableList = SortableContainer(({ items }) => (
      <ul className="managerial-position-list__list-container">
        {items.map((item, index) => (
          <SortableItem
            key={item.id}
            managerialPosition={item}
            index={index}
            position={index}
            disabled={!canSort}
          />
        ))}
      </ul>
    ));

    return (
      <React.Fragment>
        <div className="managerial-position-list__header-action-container">
          <HeaderAction
            action={action}
            canSort={canSort}
            onSortStarted={() => this.setState({ canSort: true })}
            onSortReset={this.resetSorting}
          />
        </div>
        {/* 並び順更新に利用するform */}
        <BaseForm
          name="allDutiesEditForm"
          action={`${action}/update_all`}
          method="put"
        >
          <input name="utf8" type="hidden" value="✓" />
          <input type="hidden" name="_method" value="patch" />
          <BodyAction
            canSort={canSort}
            beforeSaved={callback => this.setState({ loading: true }, () => {
              callback();
            })}
            afterSaved={callback => this.setState({ loading: false }, () => {
              callback();
            })}
          />
          <SortableList
            items={managerialPositions}
            onSortEnd={this.onSortEnd}
            helperClass="managerial-position-list__list-container"
            distance={1}
            lockAxis="y"
          />
        </BaseForm>
        <LoadingMask
          loading={loading}
        />
      </React.Fragment>
    );
  }
}

ManagerialPositionList.propTypes = {
  action: PropTypes.string.isRequired,
  managerialPositions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
};
ManagerialPositionList.defaultProps = {
};
export default ManagerialPositionList;
