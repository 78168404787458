import React from 'react';
import Button from '../shared/button';
import { setQuestionnaireNoteModalOpen } from '../../utils/global_variable_utils';

function setQuestionnaireNoteOpen() {
  const position = $('#questionnaire_note_area').offset().top;
  $('body,html').animate({ scrollTop: position }, 400, 'swing');
  setTimeout(() => {
    setQuestionnaireNoteModalOpen(true);
    ReactRailsUJS.unmountComponents();
    ReactRailsUJS.mountComponents();
  }, 400);
}

class QuestionnaireNoteOpenLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <Button
        title={window.I18n.t('activerecord.attributes.questionnaire.note')}
        className="btn btn-link"
        onClick={setQuestionnaireNoteOpen}
      />
    );
  }
}

QuestionnaireNoteOpenLink.propTypes = {
};
QuestionnaireNoteOpenLink.defaultProps = {
};
export default QuestionnaireNoteOpenLink;
