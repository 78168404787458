import React from 'react';
import PropTypes from 'prop-types';
import ReplyItem from './reply_list/reply_item';
import { getRequest } from '../../../../utils/api_client';
import ReplyLoading from './reply_list/reply_loading';
import CommentInput from '../../comment_input';

class ReplyList extends React.Component {
  constructor(props) {
    super(props);
    const { innerRef, comment, replyComments } = this.props;
    this.state = {
      loading: replyComments.length === 0 && comment.comments_count > 0,
      replyComments,
      replyTargetComment: comment,
    };
    this.replyInput = innerRef;
    this.addReplyComment = this.addReplyComment.bind(this);
  }

  componentDidMount() {
    const { comment } = this.props;
    const { replyComments } = this.state;
    if (replyComments.length === 0 && comment.comments_count > 0) {
      getRequest(
        `/wb/user/company/comments/${comment.id}/comments/descendants_index`,
        {},
      )
        .then((r) => {
          const { data } = r;
          this.setState({
            loading: false,
            replyComments: data,
          });
        })
        .catch((e) => {
          this.setState({
            loading: false,
          }, () => {
            // window.alert('エラーが発生しました');
          });
        });
    }
  }

  addReplyComment(comment) {
    const { replyComments } = this.state;
    this.setState({
      replyComments: replyComments.concat([comment]),
    });
  }

  render() {
    const { currentUser } = this.props;
    const { replyComments, loading, replyTargetComment } = this.state;
    return (
      <React.Fragment>
        {loading && (
          <ReplyLoading />
        )}
        {!loading && replyComments.map(rc => (
          <ReplyItem
            key={rc.id}
            currentUser={currentUser}
            comment={rc}
            onReplyButtonClicked={(c) => {
              this.setState({ replyTargetComment: c }, () => {
                this.replyInput.current.focus();
              });
            }}
          />
        ))}
        <CommentInput
          ref={this.replyInput}
          baseUrl={`/wb/user/company/comments/${replyTargetComment.id}/comments`}
          currentUser={currentUser}
          afterSaved={this.addReplyComment}
          replyMode
          placeholder={`${replyTargetComment.user.account_name}さんに返信する..`}
        />
      </React.Fragment>
    );
  }
}

ReplyList.propTypes = {
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  currentUser: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  comment: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  replyComments: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })),
};
ReplyList.defaultProps = {
  innerRef: {},
  replyComments: [],
};

export default React.forwardRef((props, ref) => (
  <ReplyList {...props} innerRef={ref} />
));
