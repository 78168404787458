import React, { ReactElement } from "react";
import { Radar } from "react-chartjs-2";
import CategoryLabels from "./well_being_circle/category_labels";
import ElementLabels from "./well_being_circle/element_labels";

const options = (opt: {
  scaleMin: number;
  scaleMax: number;
  stepSize: number;
  labels: string[];
}) => {
  return {
    scale: {
      ticks: {
        display: true,
        min: opt.scaleMin,
        max: opt.scaleMax,
        stepSize: opt.stepSize,
        fontSize: 14,
        minor: {
          callback: function (value) {
            return "";
          },
        },
      },
      angleLines: {
        color: "#F3F3F3",
        // display: false,
      },
      gridLines: {
        color: "#F3F3F3",
        circular: true,
      },
    },
    legend: {
      position: "bottom",
      display: false,
    },
    responsive: true,
    animation: {
      duration: 0,
    },
    tooltips: {
      // Disable the on-canvas tooltip
      enabled: false,
      mode: "index",
      axis: "y",
      // intersect: false,
      custom: function (tooltipModel) {
        // Tooltip Element
        let tooltipEl = document.getElementById("chartjs-tooltip");

        // Create element on first render
        if (!tooltipEl) {
          tooltipEl = document.createElement("div");
          tooltipEl.id = "chartjs-tooltip";
          tooltipEl.innerHTML = "<table></table>";
          document.body.appendChild(tooltipEl);
        }

        // Hide if no tooltip
        if (tooltipModel.opacity === 0) {
          tooltipEl.style.opacity = "0";
          return;
        }

        // Set caret Position
        tooltipEl.classList.remove("above", "below", "no-transform");
        if (tooltipModel.yAlign) {
          tooltipEl.classList.add(tooltipModel.yAlign);
        } else {
          tooltipEl.classList.add("no-transform");
        }

        function getBody(bodyItem) {
          return bodyItem.lines;
        }

        // Set Text
        if (tooltipModel.body && tooltipModel.dataPoints) {
          const titleLines = tooltipModel.title || [];
          const bodyLines = tooltipModel.body.map(getBody);

          let innerHtml = "<thead>";

          titleLines.forEach(function (title) {
            innerHtml += "<tr><th></th></tr>";
          });
          innerHtml += "</thead><tbody>";

          bodyLines.forEach(function (body, i) {
            const colors = tooltipModel.labelColors[i];
            let style = "background-color:" + colors.backgroundColor;
            const dataPoint = tooltipModel.dataPoints[i];
            style += "; border-color:" + colors.borderColor;
            style += "; border-width: 2px";
            const span = '<span style="' + style + '"></span>';
            if (i === 0) {
              const labels = opt.labels;
              const label = labels[dataPoint.index];
              innerHtml += "<tr><td>" + span + label + "</td></tr>";
            }
            innerHtml += "<tr><td>" + span + body + "</td></tr>";
          });
          innerHtml += "</tbody>";

          const tableRoot = tooltipEl.querySelector("table");
          if (tableRoot !== null) {
            tableRoot.innerHTML = innerHtml;
          }
        }

        // `this` will be the overall tooltip
        const baseElement = document.getElementById(
          "well-being-circle-container"
        );

        if (baseElement !== null) {
          const position = baseElement.getBoundingClientRect();
          // Display, position, and set styles for font
          tooltipEl.style.opacity = "1";
          tooltipEl.style.position = "absolute";
          tooltipEl.style.left =
            position.left +
            (window.scrollX || window.pageXOffset) +
            tooltipModel.caretX +
            60 +
            "px";
          tooltipEl.style.top =
            position.top +
            (window.scrollY || window.pageYOffset) +
            tooltipModel.caretY -
            60 +
            "px";
          tooltipEl.style.fontSize = tooltipModel.bodyFontSize + "px";
          tooltipEl.style.padding =
            tooltipModel.yPadding + "px " + tooltipModel.xPadding + "px";
          tooltipEl.style.pointerEvents = "none";
          tooltipEl.style.backgroundColor = "#F8F7F7";
          tooltipEl.style.border = "solid";
          tooltipEl.style.borderWidth = "1";
          tooltipEl.style.borderColor = "#ADD3FF";
          tooltipEl.style.zIndex = "2";
        }
      },
    },
  };
};

interface Props {
  scaleMin: number;
  scaleMax: number;
  stepSize: number;
  labels: string[];
  locale: "ja" | "en";
  datasets: Array<{
    label: string;
    data: number[];
    color: string;
  }>;
}

export default function WellBeingCircle(props: Props): ReactElement {
  const { scaleMin, scaleMax, stepSize, labels, datasets, locale } = props;

  return (
    <div id="WellBeingCircleRadar">
      <div className="well_being_circle__area">
        <div
          className="well_being_circle__backgroud__image"
          style={{
            backgroundImage: 'url("/images/radar_chart_background.png")',
          }}
        />
        <CategoryLabels locale={locale} />
        <ElementLabels locale={locale} />
        <div
          id="well-being-circle-container"
          className="well_being_circle__container"
        >
          <Radar
            width={450}
            height={450}
            data={{
              labels: labels.map((l) => ""),
              datasets: datasets.map((d) => ({
                label: d.label,
                data: d.data,
                fill: false,
                backgroundColor: d.color,
                borderColor: d.color,
                pointBackgroundColor: d.color,
                borderWidth: 3,
                pointRadius: 5,
                pointHoverRadius: 10,
                pointStyle: "circle",
                pointHitRadius: 10,
              })),
            }}
            options={options({
              scaleMin,
              scaleMax,
              stepSize,
              labels,
            })}
          />
        </div>
      </div>
    </div>
  );
}
