import React from 'react';
import PropTypes from 'prop-types';
import TextForm from '../../shared/form/text_form';
import ProfileImageField from './form/shared/profile_image_field';
import CoverImageField from './form/shared/cover_image_field';
import UserProfileForm from './form/user_profile_form';
import SelfIntroduceForm from './form/self_introduce_form';
import ErrorContainer from '../../shared/error_container';

class EditForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      preparing: false,
    };
  }

  render() {
    const {
      user, selfIntroductionQuestions, selfIntroductionAnswers, errorMessages,
    } = this.props;
    const { preparing } = this.state;
    return (
      <React.Fragment>
        <div className="form-group row">
          <div className="col-sm-4 color__gray">
            {window.I18n.t('activerecord.attributes.user.cover_image_url')}
          </div>
          <div className="col-sm-8">
            <div className="text-center">
              <CoverImageField
                scope="user_profile_form"
                relatedModelName="user"
                columnName="cover_image_url"
                value={user.cover_image_url}
                beforeUploading={() => this.setState({ preparing: true })}
                afterUploading={() => this.setState({ preparing: false })}
              />
            </div>
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-4 color__gray">
            {window.I18n.t('activerecord.attributes.user.profile_image_url')}
          </div>
          <div className="col-sm-8">
            <div className="text-center">
              <ProfileImageField
                scope="user_profile_form"
                relatedModelName="user"
                columnName="profile_image_url"
                value={user.profile_image_url}
                beforeUploading={() => this.setState({ preparing: true })}
                afterUploading={() => this.setState({ preparing: false })}
              />
            </div>
          </div>
        </div>
        <TextForm
          scope="user_profile_form"
          model={user}
          relatedModelName="user"
          columnName="account_name"
          labelColor="gray"
          disabled={!user.is_consumer}
        />
        <UserProfileForm {...this.props} />
        <SelfIntroduceForm
          selfIntroductionQuestions={selfIntroductionQuestions}
          selfIntroductionAnswers={selfIntroductionAnswers}
        />
        <ErrorContainer errorMessages={errorMessages} />
        <div className="user-user-profile-edit__submit-button-container">
          <input
            type="submit"
            name="commit"
            value={window.I18n.t('javascript.user_profiles.form.save')}
            className="btn btn-block button__primary"
            data-disable-with={window.I18n.t('javascript.user_profiles.form.saving')}
            disabled={preparing}
          />
        </div>
      </React.Fragment>
    );
  }
}

EditForm.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  members: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
  userProfile: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  sexOptions: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  birthYearOptions: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  birthMonthOptions: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  finalEducationOptions: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  professionOptions: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  personalAnnualIncomeOptions: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  householdAnnualIncomeOptions: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  maritalStatusOptions: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  childStatusOptions: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  prefectureOptions: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  countryOptions: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  selfIntroductionQuestions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
  selfIntroductionAnswers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
  industryCategories: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    industries: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })),
  })).isRequired,
  departmentOptions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
  managerialPositionOptions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
  errorMessages: PropTypes.arrayOf(PropTypes.string),
};
EditForm.defaultProps = {
  errorMessages: [],
};
export default EditForm;
