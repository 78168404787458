import PropTypes from 'prop-types';
import React from 'react';

class HiddenField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const {
      id, name, value,
    } = this.props;

    return (
      <input
        className="form-control"
        type="hidden"
        value={value}
        name={name}
        id={id}
      />
    );
  }
}
HiddenField.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
HiddenField.defaultProps = {
  value: '',
};
export default HiddenField;
