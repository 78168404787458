function createAncestorNamesRecursively(departments, baseDepartmentId) {
  if (!baseDepartmentId) {
    return [];
  }
  const baseDepartment = departments.find(d => String(d.id) === baseDepartmentId);
  const parentDepartment = departments.find(d => d.id === baseDepartment.parent_id);
  if (parentDepartment) {
    return createAncestorNamesRecursively(departments, String(parentDepartment.id))
      .concat([parentDepartment.name]);
  }
  return [];
}

export function joinedAncestorName(departments, baseDepartmentId) {
  const arr = createAncestorNamesRecursively(departments, baseDepartmentId);
  return arr.join('>');
}

export function cleanUrl(url) {
  if (!url) {
    return '';
  }
  return url.replace(/\?.*$/, '');
}

export default joinedAncestorName;
