import React, { ReactElement, useEffect, useState } from "react";
import { animateScroll } from "react-scroll";
import MultiLineText from "../../../shared/multi_line_text";

interface Props {
  baseInputId: string;
  baseInputName: string;
  defaultPoint: number;
  questionIdName: string;
  questionIdValue: number;
  showCheckbox?: boolean;
  onHappinessAnswerSelected: () => void;
  choices?: string[];
}

function scrollMore() {
  animateScroll.scrollMore(250, { duration: 500 });
}

export default function CheckboxInput(props: Props): ReactElement {
  const {
    baseInputId,
    baseInputName,
    defaultPoint,
    questionIdName,
    questionIdValue,
    onHappinessAnswerSelected,
    showCheckbox = true,
    choices = [
      window.I18n.t(
        "javascript.questionnaires.happiness_questionnaire.check_label1"
      ),
      window.I18n.t(
        "javascript.questionnaires.happiness_questionnaire.check_label2"
      ),
      window.I18n.t(
        "javascript.questionnaires.happiness_questionnaire.check_label3"
      ),
      window.I18n.t(
        "javascript.questionnaires.happiness_questionnaire.check_label4"
      ),
      window.I18n.t(
        "javascript.questionnaires.happiness_questionnaire.check_label5"
      ),
      window.I18n.t(
        "javascript.questionnaires.happiness_questionnaire.check_label6"
      ),
      window.I18n.t(
        "javascript.questionnaires.happiness_questionnaire.check_label7"
      ),
    ],
  } = props;
  const [point, setPoint] = useState(defaultPoint);
  useEffect(() => {
    if (point > 0) {
      onHappinessAnswerSelected();
    }
  }, [point]);

  const updatePoint = (itemIndex: number) => {
    setPoint(itemIndex + 1);
  };
  return (
    <div>
      <input
        id={`${baseInputId}_${questionIdName}`}
        name={`${baseInputName}[${questionIdName}]`}
        type="hidden"
        value={questionIdValue}
      />
      <input
        id={`${baseInputId}_point`}
        name={`${baseInputName}[point]`}
        type="hidden"
        value={point}
      />
      {showCheckbox && (
        <div className="happiness-questionnaire__happiness_answer-form-container">
          <div className="happiness-questionnaire__happiness_answer-checkbox-container">
            <div className="happiness-questionnaire__happiness_answer-checkbox-items-container">
              {choices.map((choice, index) => {
                const keyIndex = index;
                return (
                  <div
                    role="button"
                    key={keyIndex}
                    className="happiness-questionnaire__happiness_answer-checkbox-item-container"
                    onClick={() => {
                      updatePoint(index);
                      scrollMore();
                    }}
                  >
                    <div
                      className={`
                      happiness-questionnaire__happiness_answer-checkbox-item
                      ${
                        point === index + 1
                          ? "happiness-questionnaire__happiness_answer-checkbox-item__active"
                          : ""
                      }
                    `}
                    >
                      <span className="happiness-questionnaire__happiness_answer-checkbox-item-text">
                        {index + 1}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="happiness-questionnaire__happiness_answer-checkbox-item-labels-container">
              {choices.map((choice, index) => {
                const keyIndex = index;
                return (
                  <div
                    key={keyIndex}
                    className="happiness-questionnaire__happiness_answer-checkbox-item-label"
                  >
                    <MultiLineText text={choice} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
