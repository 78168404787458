import React, { ReactElement, useState } from "react";
import Button from "./button";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BaseModal from "./base_modal";

export default function DeviationDescription(): ReactElement {
  const [showModal, setShowModal] = useState(false);

  return (
    <div className="position-relative">
      {showModal && (
        <>
          <BaseModal
            title="偏差値とは？"
            show={showModal}
            contentLabel="DeviationDescription"
            onCloseModal={() => setShowModal(false)}
          >
            <BaseModal.Body>
              <div>
                <div>
                  偏差値は50を基準として、平均からどれくらいの差があるかを表した数値です。
                </div>
                <div className="font__size-14 mt-3 color__gray">
                  平均からの差の目安
                </div>
                <div className="mt-1" style={{ lineHeight: 2 }}>
                  {/*参考: https://mathwords.net/hensati#i*/}
                  偏差値：70 上位2.3%
                  <br />
                  偏差値：60 上位15.9%
                  <br />
                  偏差値：55 上位30.9%
                  <br />
                  偏差値：50 一般平均
                  <br />
                  偏差値：45 下位30.9%
                  <br />
                  偏差値：40 下位15.9%
                </div>
              </div>
            </BaseModal.Body>
          </BaseModal>
        </>
      )}
      <Button
        className="font__size-14 color__gray cursor__pointer"
        onClick={() => setShowModal(true)}
      >
        <div className="d-flex align-items-center">
          <span className="px-1">偏差値とは</span>
          <span>
            <FontAwesomeIcon
              icon={faQuestionCircle}
              className="font__size-13"
            />
          </span>
        </div>
      </Button>
    </div>
  );
}
