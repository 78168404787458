import React, { ReactElement } from "react";
import Button from "../../shared/button";
import {
  InteractiveWorkResponse,
  viewWorkType,
} from "../../../models/InteractiveWork";
import { convRelativeTime } from "../../../utils/datetime_utils";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BaseForm from "../../shared/base_form";

interface Props {
  interactiveWork: InteractiveWorkResponse;
  isCurrentWork: boolean;
}

export default function ChatHistoryItem(props: Props): ReactElement {
  const { interactiveWork, isCurrentWork } = props;

  const destroy = async (): Promise<void> => {
    if (window.confirm("このワークを削除してよろしいですか？")) {
      const submit = document.getElementById("chatDestroySubmit");
      if (submit !== null) {
        submit.click();
      }
    }
  };

  return (
    <div className="mt-2" style={{}}>
      <Button
        className={isCurrentWork ? "bgcolor__trivial" : "cursor__pointer"}
        disabled={isCurrentWork}
        onClick={() => {
          window.location.href = `/user/interactive_works/${interactiveWork.id}`;
        }}
      >
        <div className="border__lightgray rounded font__size-13 p-2">
          {isCurrentWork && (
            <>
              <BaseForm
                name="chatDestroyFormOfPc"
                action={`/user/interactive_works/${interactiveWork.id}`}
                method="delete"
                type="normal"
                style={{ height: 20 }}
              >
                <div className="text-right">
                  <Button
                    className="cursor__pointer"
                    onClick={() => {
                      destroy().catch((e) => console.log(e));
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faTrash}
                      className="font__size-13 color__gray"
                    />
                  </Button>
                </div>
                <button
                  type="submit"
                  id="chatDestroySubmit"
                  style={{ border: "none", padding: 0 }}
                />
              </BaseForm>
            </>
          )}
          {interactiveWork.finished_at !== null && (
            <div className="d-flex justify-content-end">
              <div className="border rounded px-2 font__size-13">完了</div>
            </div>
          )}
          <div className="color__gray">{`${convRelativeTime(
            interactiveWork.created_at
          )}`}</div>
          <div>{viewWorkType(interactiveWork.work_type)}</div>
        </div>
      </Button>
    </div>
  );
}
