import React from 'react';
import PropTypes from 'prop-types';
import UserIcon from '../users/user_icon';

class UserOverviewCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { user } = this.props;
    return (
      <div
        className="w-100 position-relative border__lightgray"
        style={{ borderRadius: 5, overflow: 'auto', height: 230 }}
      >
        <div className="w-100 bgcolor__trivial" style={{ height: 130 }}>
          {user.cover_image_url && (
            <img
              alt="department_thumbnail"
              src={user.cover_image_url}
              style={{ objectFit: 'cover', width: '100%', height: '100%' }}
            />
          )}
        </div>
        <div className="bgcolor__white position-relative p-1" style={{ height: 100 }}>
          <div
            className="position-absolute"
            style={{
              top: 6, left: 85, right: 15, overflow: 'hidden',
            }}
          >
            <span className="font__size-15">
              {user.account_name}
            </span><br />
            <span className="font__size-13 color__gray">
              {user.organizational_departments.map(d => d.name).join('・')}
            </span>
          </div>
        </div>
        <div className="position-absolute" style={{ top: 90, left: 4 }}>
          <UserIcon
            user={user}
            style={{
              borderRadius: 40, objectFit: 'cover', width: 74, height: 74,
            }}
          />
        </div>
      </div>
    );
  }
}
UserOverviewCard.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};
UserOverviewCard.defaultProps = {
};
export default UserOverviewCard;
