import React from 'react';
import PropTypes from 'prop-types';
import TextForm from '../../shared/form/text_form';
import HiddenForm from '../../shared/form/hidden_form';
import { baseScope } from '../../shared/form/utils/attribute_utils';
import TextareaForm from '../../shared/form/textarea_form';
import SelectForm from '../../shared/form/select_form';

class SelectWithTextForm extends React.Component {
  constructor(props) {
    super(props);
    const { answerChoice, question } = this.props;
    const [choice] = question.choices
      .filter(c => answerChoice.choice && c.id === answerChoice.choice.id);
    this.state = {
      choiceRole: choice ? choice.role : '',
    };
  }

  setChoiceRole(choiceId) {
    const { question } = this.props;
    if (choiceId) {
      const [choice] = question.choices.filter(c => String(c.id) === choiceId);
      this.setState({ choiceRole: choice.role });
      return;
    }
    this.setState({ choiceRole: '' });
  }

  render() {
    const {
      answerChoice, scope, index,
      answer, question, onChange, choices,
    } = this.props;
    const { choiceRole } = this.state;
    const selectedChoiceId = answerChoice && answerChoice.choice && answerChoice.choice.id;
    const [blankChoice] = question.choices.filter(c => c.role === 'blank_role');
    return (
      <React.Fragment>
        {answerChoice && answerChoice.id && (
          <HiddenForm
            scope={baseScope(scope, 'answers', index)}
            relatedModelName="answer_choices"
            columnName="id"
            index={0}
            value={answerChoice.id}
          />
        )}
        <SelectForm
          scope={baseScope(scope, 'answers', index)}
          model={answer}
          relatedModelName="answer_choices"
          columnName="choice_id"
          index={0}
          hideLabel
          defaultKey={String(selectedChoiceId)}
          option={{ include_blank: blankChoice ? blankChoice.body : '' }}
          options={(choices || question.choices)
            .filter(c => !(c.disabled && c.id !== selectedChoiceId))
            .filter(c => !(c.role === 'blank_role'))
            .map(c => (
              { key: String(c.id), value: c.body }
            ))
          }
          onChange={(e) => {
            this.setChoiceRole(e.target.value);
            onChange(e);
          }}
        />
        {(choiceRole === 'with_string_role') && (
          <TextForm
            scope={baseScope(scope, 'answers', index)}
            model={answerChoice || {}}
            relatedModelName="answer_choices"
            columnName="body"
            index={0}
            hideLabel
            placeholder={window.I18n.t('javascript.questions.question_form.free_entry_field')}
          />
        )}
        {(choiceRole === 'with_text_role') && (
          <TextareaForm
            scope={baseScope(scope, 'answers', index)}
            model={answerChoice || {}}
            relatedModelName="answer_choices"
            columnName="body"
            index={0}
            hideLabel
            placeholder={window.I18n.t('javascript.questions.question_form.free_entry_field')}
          />
        )}
      </React.Fragment>
    );
  }
}

SelectWithTextForm.propTypes = {
  answerChoice: PropTypes.shape({
    id: PropTypes.number,
  }),
  scope: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  answer: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  question: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  onChange: PropTypes.func,
  choices: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })),
};
SelectWithTextForm.defaultProps = {
  answerChoice: {},
  onChange: () => {},
  choices: null,
};
export default SelectWithTextForm;
