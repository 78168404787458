import React from 'react';
import PropTypes from 'prop-types';
import TextareaForm from '../../../shared/form/textarea_form';
import { happinessCategoryName } from '../../../../utils/happiness_category_utils';
import { happinessElementName, happinessElementColor } from '../../../../utils/happiness_element_utils';
import HiddenForm from '../../../shared/form/hidden_form';

class RaisedRateHappinessRecipeForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { questionnaire } = this.props;
    const { before_questionnaire: beforeQuestionnaire } = questionnaire;
    if (questionnaire.raised_happiness_elements.length === 0) {
      return null;
    }
    return (
      <div>
        <div className="font-weight-bold bgcolor__trivial p-2">
          前回の診断から、以下の項目がグッと伸びました！その理由を考えてみて下さい
        </div>
        <div className="p-3">
          {questionnaire.raised_happiness_elements.map(([he, v], i) => {
            const { before_percent: befPercent, percent, raised_percent: raisedPercent } = v;
            const formIndex = 10 + i;
            const happinessRecipe = questionnaire.happiness_recipes.filter(hr => (
              hr.category === 'raised_rate_category' && hr.happiness_element === he
            ))[0];
            return (
              <React.Fragment key={he}>
                {happinessRecipe && (
                  <HiddenForm
                    scope="questionnaire"
                    relatedModelName="happiness_recipes"
                    columnName="id"
                    index={formIndex}
                    value={happinessRecipe.id}
                  />
                )}
                <HiddenForm
                  scope="questionnaire"
                  relatedModelName="happiness_recipes"
                  columnName="category"
                  index={formIndex}
                  value="raised_rate_category"
                />
                <HiddenForm
                  scope="questionnaire"
                  relatedModelName="happiness_recipes"
                  columnName="happiness_element"
                  index={formIndex}
                  value={he}
                />
                <TextareaForm
                  scope="questionnaire"
                  model={happinessRecipe}
                  relatedModelName="happiness_recipes"
                  columnName="body"
                  index={formIndex}
                  rows={3}
                  labelAreaStyle={{ backgroundColor: happinessElementColor(he) }}
                  subLabel={happinessCategoryName(he)}
                  label={
                    <div>
                      {happinessElementName(he)}<br />
                      <span className="font__size-12">
                        {`${Math.round(befPercent)}pt → ${Math.round(percent)}pt(+${Math.round(raisedPercent)}pt)`}
                      </span>
                    </div>
                  }
                />
              </React.Fragment>
            );
          })}
        </div>
      </div>
    );
  }
}

RaisedRateHappinessRecipeForm.propTypes = {
  questionnaire: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};
RaisedRateHappinessRecipeForm.defaultProps = {
};
export default RaisedRateHappinessRecipeForm;
