import React from 'react';
import PropTypes from 'prop-types';
import MultiLineText from '../../../shared/multi_line_text';

class StandardFormat extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { question, children } = this.props;
    return (
      <div>
        <div className="font__size-13 font-weight-bold mt-2">
          <MultiLineText text={question.body} />
        </div>
        <div className="pl-2 font__size-15 clearfix mt-1">
          {children}
        </div>
      </div>
    );
  }
}

StandardFormat.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  children: PropTypes.node.isRequired,
};
StandardFormat.defaultProps = {
};
export default StandardFormat;
