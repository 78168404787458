import { faChartBar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactElement, useEffect, useState } from "react";
import { animateScroll } from "react-scroll";
import { HappinessElementType } from "../../models/HappinessQuestion";
import Button from "../shared/button";
import HappinessElementDistributionChart from "./happiness_element_bar_chart/happiness_element_distribution_chart";
import HappinessElementTransitionChart from "./happiness_element_bar_chart/happiness_element_transition_chart";

interface Props {
  happinessElement: HappinessElementType;
  happinessElementName: string;
  happinessElementDesicription: string;

  barChartData: Array<{
    value: string;
    leftPercent: number;
    widthPercent: number;
    color: string;
  }>;
  graphs: Array<{
    type: "transition" | "distribution";
    title: string;
    dataAcquisitionPath: string;
    labelOptions: Array<{ key: string; value: string }> | null;
    selectedLabelKey: string | null;
  }>;
  graphLoadParams: {
    deviation_value_mode: boolean;
    period?: string | null;
  };
}

function scrollMore(): void {
  animateScroll.scrollMore(200, { duration: 100 });
}

export default function HappinessElementBarChart(props: Props): ReactElement {
  const {
    happinessElement,
    happinessElementName,
    happinessElementDesicription,
    graphLoadParams,
    barChartData,
    graphs,
  } = props;
  const [showGraphs, setShowGraphs] = useState(false);

  useEffect(() => {
    if (showGraphs) {
      scrollMore();
    }
  }, [showGraphs]);

  return (
    <div className="row happiness-element-bar-chart__container">
      {graphs.length > 0 && (
        <div className="col-md-12 d-flex justify-content-end">
          <div className="py-1 rounded">
            <Button
              onClick={() => setShowGraphs(!showGraphs)}
              className="cursor__pointer"
            >
              <FontAwesomeIcon icon={faChartBar} className="color__gray" />
            </Button>
          </div>
        </div>
      )}
      <div className="col-md-12">
        <div className="row">
          <div className="col-md-5 happiness-element-bar-chart__label-container">
            <div className="happiness-element-bar-chart__label">
              {happinessElementName}
            </div>
          </div>
          <div className="col-md-7">
            {barChartData.map((d, i) => (
              <div
                key={i}
                className="row  happiness-element-bar-chart__chart-container"
              >
                <div className="happiness-element-bar-chart__point-container">
                  {d.value}
                </div>
                <div className="happiness-element-bar-chart__chart-bar-box">
                  <div
                    className="happiness-element-bar-chart__chart-bar"
                    style={{
                      marginLeft: `${d.leftPercent}%`,
                      width: `${d.widthPercent}%`,
                      backgroundColor: d.color,
                    }}
                  ></div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 happiness-element-bar-chart__description-container">
            {happinessElementDesicription}
          </div>
        </div>
      </div>
      {showGraphs && (
        <div className="w-100">
          {graphs.map((graph) => {
            if (graph.type === "distribution") {
              return (
                <HappinessElementDistributionChart
                  key={graph.dataAcquisitionPath}
                  happinessElement={happinessElement}
                  title={graph.title}
                  dataAcquisitionPath={graph.dataAcquisitionPath}
                  graphLoadParams={graphLoadParams}
                  labelOptions={graph.labelOptions}
                  selectedLabelKey={graph.selectedLabelKey}
                />
              );
            } else if (graph.type === "transition") {
              return (
                <HappinessElementTransitionChart
                  key={graph.dataAcquisitionPath}
                  happinessElement={happinessElement}
                  title={graph.title}
                  dataAcquisitionPath={graph.dataAcquisitionPath}
                  graphLoadParams={graphLoadParams}
                />
              );
            }
          })}
        </div>
      )}
    </div>
  );
}
