import React from "react";
import PropTypes from "prop-types";
import QuestionListModal from "../../questions/question_list_modal";
import OtherAnswersNavigationLink from "../../shared/other_answers_navigation_link";

class QuestionSelectBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  render() {
    const {
      questions,
      action,
      additionalFormInputHtml,
      showQuestionnaireNote,
      compLinkUrl,
      compLinkTitle,
    } = this.props;
    const { showModal } = this.state;
    return (
      <React.Fragment>
        <QuestionListModal
          showModal={showModal}
          questions={questions}
          action={action}
          additionalFormInputHtml={additionalFormInputHtml}
          onCloseButtonClicked={() => this.setState({ showModal: false })}
          showQuestionnaireNote={showQuestionnaireNote}
        />
        <div className="card">
          <div className="card-body">
            <OtherAnswersNavigationLink
              linkUrl={compLinkUrl}
              linkTitle={compLinkTitle}
            />
            <div className="answer-list__question-container">
              <div className="answer-list__question-label">
                自由記入質問事項
              </div>
              <div
                role="button"
                tabIndex="0"
                className="answer-list__question-select-box custom-select"
                onClick={() => this.setState({ showModal: true })}
              >
                振り返りシート
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

QuestionSelectBox.propTypes = {
  action: PropTypes.string.isRequired,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ).isRequired,
  additionalFormInputHtml: PropTypes.string.isRequired,
  showQuestionnaireNote: PropTypes.bool,
  compLinkTitle: PropTypes.string,
  compLinkUrl: PropTypes.string,
};
QuestionSelectBox.defaultProps = {
  showQuestionnaireNote: false,
  compLinkTitle: "",
  compLinkUrl: "",
};
export default QuestionSelectBox;
