import React from 'react';
import EventListener from 'react-event-listener';
import { postRequest } from '../../utils/api_client';

// アプリから"apliloaded"イベントをdispatchした時に処理される
function registToken() {
  // アプリ側で設定される変数
  const token = window.notificationToken;
  const os = window.platformOS;
  if (token && os) {
    let notificationService = 'android';
    if (os === 'ios') {
      notificationService = 'apns';
    }
    postRequest(
      '/wb/user/notification_devices',
      { token, notification_service: notificationService },
    );
  }
}
class NotificationDeviceListener extends React.Component {
  componentDidMount() {
    window.apliloadedEvent = new CustomEvent('apliloaded');
  }

  render() {
    return (
      <EventListener target="window" onApliloaded={registToken} />
    );
    return null;
  }
}

NotificationDeviceListener.propTypes = {
};
NotificationDeviceListener.defaultProps = {
};
export default NotificationDeviceListener;
