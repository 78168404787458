import React from 'react';
import PropTypes from 'prop-types';
import MultiLineText from '../../shared/multi_line_text';

class AnswerChoiceSummaryItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { answerChoiceSummary } = this.props;
    return (
      <React.Fragment>
        <tr>
          <td>
            <MultiLineText text={answerChoiceSummary.body} />
          </td>
          <td>
            {answerChoiceSummary.count}
          </td>
        </tr>
      </React.Fragment>
    );
  }
}

AnswerChoiceSummaryItem.propTypes = {
  answerChoiceSummary: PropTypes.shape({
    id: PropTypes.number,
    answered_user_name: PropTypes.string,
  }).isRequired,
};
AnswerChoiceSummaryItem.defaultProps = {
};
export default AnswerChoiceSummaryItem;
