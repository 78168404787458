import React from 'react';
import PropTypes from 'prop-types';
import BaseMoal from '../shared/base_modal';
import Button from '../shared/button';
import TextareaForm from '../shared/form/textarea_form';
import BaseForm from '../shared/base_form';

function save() {
  if (window.confirm(window.I18n.t('javascript.users.account_deletion_modal.delete_confirmation_message'))) {
    const submit = document.getElementById('accountDeletionFormSubmit');
    submit.click();
  }
}
class AccountDeletionModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
    this.close = this.close.bind(this);
  }

  close() {
    this.setState({ showModal: false });
  }

  render() {
    const { authenticityToken } = this.props;
    const { showModal } = this.state;
    return (
      <React.Fragment>
        <BaseMoal
          show={showModal}
          title={window.I18n.t('javascript.users.account_deletion_modal.title')}
          contentLabel="accountDeletion"
          onCloseModal={this.close}
        >
          <BaseForm
            name="accountDeletionForm"
            action="/users"
            method="delete"
            type="normal"
            authenticityToken={authenticityToken}
          >
            <BaseMoal.Body>
              <TextareaForm
                scope="user"
                columnName="account_deletion_reason"
                rows={5}
                hideLabel
                placeholder={window.I18n.t('javascript.users.account_deletion_modal.reason_message')}
              />
            </BaseMoal.Body>
            <div className="modal-footer">
              <button type="submit" id="accountDeletionFormSubmit" style={{ border: 'none', padding: 0 }} />
              <button type="button" className="btn btn-light" onClick={this.close}>
                {window.I18n.t('javascript.users.account_deletion_modal.close')}
              </button>
              <Button
                title={window.I18n.t('javascript.users.account_deletion_modal.save')}
                className="btn button__primary"
                onClick={save}
              />
            </div>
          </BaseForm>
        </BaseMoal>
        <Button
          title={window.I18n.t('javascript.users.account_deletion_modal.delete')}
          className="btn button__primary"
          onClick={() => this.setState({ showModal: true })}
        />
      </React.Fragment>
    );
  }
}

AccountDeletionModal.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
};
AccountDeletionModal.defaultProps = {
};
export default AccountDeletionModal;
