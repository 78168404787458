import React from 'react';
import PropTypes from 'prop-types';

class Box extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { children } = this.props;
    return (
      <React.Fragment>
        <div className="p-1" style={{ width: 96, height: 96, flex: '0 0 96px' }}>
          <div className="w-100 h-100 border__dashed-lightgray position-relative">
            {children}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Box.propTypes = {
  children: PropTypes.node.isRequired,
};
Box.defaultProps = {
};
export default Box;
