import React from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import Button from '../../../../../shared/button';
import NavigateNextIcon from '../../../../../shared/images/ic_navigate_next.svg';

class CategoryButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { title, onClick, children } = this.props;
    return (
      <Button
        className="list-group-item cursor__pointer"
        onClick={() => onClick()}
      >
        <div className="d-flex align-items-center">
          <div className="flex-fill">
            {children || title}
          </div>
          <div>
            <ReactSVG
              src={NavigateNextIcon}
              className="float-right"
              beforeInjection={(svg) => {
                svg.classList.add('company-timeline-index__navigate-next-icon float-right');
              }}
            />
          </div>
        </div>
      </Button>
    );
  }
}
CategoryButton.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
};
CategoryButton.defaultProps = {
  title: '',
  children: null,
  onClick: () => {},
};
export default CategoryButton;
