import React from "react";
import PropTypes from "prop-types";
import { ReactSVG } from "react-svg";
import TreePointLeaderIcon from "./images/ic_three_point_leader.svg";
import LoadingImage from "./images/spin-gray.svg";

class MenuDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { children, loading, showDialog, onMenuClicked } = this.props;
    return (
      <div className="dropdown">
        <ReactSVG
          src={TreePointLeaderIcon}
          beforeInjection={(svg) => {
            svg.classList.add("icon___middle icon__gray cursor__pointer");
          }}
          onClick={() => onMenuClicked()}
          style={{ marginLeft: 30 }}
        />
        {showDialog && !loading && (
          <div
            className="dropdown-menu"
            style={{ left: "unset", right: 0, display: "block" }}
          >
            {children}
          </div>
        )}
        {showDialog && loading && (
          <div
            className="dropdown-menu"
            style={{
              left: "unset",
              right: 0,
              display: "block",
              height: 50,
            }}
          >
            <img
              src={LoadingImage}
              style={{
                width: 30,
                height: 30,
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
          </div>
        )}
      </div>
    );
  }
}

MenuDialog.propTypes = {
  showDialog: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  onMenuClicked: PropTypes.func.isRequired,
};
MenuDialog.defaultProps = {
  loading: false,
};
export default MenuDialog;
