import React, { ReactElement } from "react";
import { ContractPeriodResponse } from "../../models/ContractPeriod";
import { ContractPeriodDepartmentAdviceResponse } from "../../models/ContractPeriodDepartmentAdvice";
import { DepartmentResponse } from "../../models/Department";
import Button from "../shared/button";
import Chat from "./chat_screen/chat";
import ChatScreenMenuArea from "./chat_screen/chat_screen_menu_area";

interface Props {
  department: DepartmentResponse;
  contractPeriod: ContractPeriodResponse;
  contractPeriods: ContractPeriodResponse[];
  praiseRoleContractPeriodDepartmentAdvice: ContractPeriodDepartmentAdviceResponse | null;
  adviceRoleContractPeriodDepartmentAdvice: ContractPeriodDepartmentAdviceResponse | null;
  interactiveHappinessAdvicePath: string;
}

export default function ChatScreen(props: Props): ReactElement {
  const {
    department,
    contractPeriod,
    contractPeriods,
    praiseRoleContractPeriodDepartmentAdvice,
    adviceRoleContractPeriodDepartmentAdvice,
    interactiveHappinessAdvicePath,
  } = props;

  return (
    <div className="chat-screen__container">
      <ChatScreenMenuArea
        department={department}
        contractPeriod={contractPeriod}
        contractPeriods={contractPeriods}
      />
      <div className="chat-screen__chat-container">
        <div className="chat-screen__chat-title-container">
          ハッピーAIからのメッセージ・アドバイス
        </div>
        <div
          id="chatContentArea"
          className="py-5 chat-screen__message-advice-chat-content-container"
        >
          {(praiseRoleContractPeriodDepartmentAdvice == null ||
            adviceRoleContractPeriodDepartmentAdvice == null) && (
            <div className="text-center p-3">
              <div>
                現在診断期間中です。
                <br />
                {`(${contractPeriod.view_period})`}
              </div>
              <div className="mt-2">
                診断終了後に
                <br />
                メッセージ・アドバイスが表示されます
              </div>
            </div>
          )}
          {praiseRoleContractPeriodDepartmentAdvice !== null &&
            adviceRoleContractPeriodDepartmentAdvice !== null && (
              <>
                <Chat
                  department={department}
                  contractPeriod={contractPeriod}
                  contractPeriodDepartmentAdvice={
                    praiseRoleContractPeriodDepartmentAdvice
                  }
                  title="ハッピーAIからのメッセージ"
                />
                <Chat
                  department={department}
                  contractPeriod={contractPeriod}
                  contractPeriodDepartmentAdvice={
                    adviceRoleContractPeriodDepartmentAdvice
                  }
                  option={{ paragraphMarginNone: true }}
                  title="ハッピーAIからのアドバイス"
                />
              </>
            )}
        </div>
        <div className="chat-screen__chat-input-container">
          <div className="p-3 mb-md-4 text-center color__gray">
            {
              <div>
                <div className="row p-md-2">
                  <div className="col-3"></div>
                  <div className="col-6 py-1">
                    <a href={interactiveHappinessAdvicePath} target="_blank">
                      <Button className="btn button__highlignt font__size-14 width__100">
                        <div>
                          ハッピーAIに
                          <br />
                          相談する
                        </div>
                      </Button>
                    </a>
                  </div>
                  <div className="col-3"></div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
}
