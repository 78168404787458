import React, { ReactElement, useEffect, useState } from "react";
import { UserResponse } from "../../../../../models/User";
import {
  getRequestAsync,
  postRequestAsync,
} from "../../../../../utils/api_client";
import BaseMoal from "../../../../shared/base_modal";
import Button from "../../../../shared/button";
import SelectField from "../../../../shared/form/fields/select_field";

interface Props {
  userActionPath: string;
  user: UserResponse;
  showModal: boolean;
  onClose: () => void;
}

export default function InitialSettingMailResendModal(
  props: Props
): ReactElement {
  const { userActionPath, user, showModal, onClose } = props;
  const [loading, setLoading] = useState(false);
  const [mailTemplate, setMailTemplate] = useState({ subject: "", body: "" });
  const [contractPeriodOptions, setContractPeriodOptions] =
    useState<Array<{ key: string; value: string }>>();
  const [selectedContractPeriodId, setSelectedContractPeriodId] =
    useState<string>();
  const [mailSended, setMailSended] = useState(false);

  useEffect(() => {
    loadTemplate();
  }, []);

  const loadTemplate = async (contractPeriodId?: string) => {
    setLoading(true);
    const { result } = await getRequestAsync<{
      subject: string;
      body: string;
      contract_period_options: Array<{ key: string; value: string }>;
      selected_contract_period_id: string;
    }>(`${userActionPath}/${user.id}/show_initial_setting_mail`, {
      contract_period_id: contractPeriodId,
    });
    if (result !== undefined) {
      setMailTemplate({
        subject: result.subject,
        body: result.body,
      });
      setContractPeriodOptions(result.contract_period_options);
      setSelectedContractPeriodId(result.selected_contract_period_id);
      setLoading(false);
    }
  };

  const send = async () => {
    if (
      window.confirm(
        `${user.account_name}さんに診断依頼メールを再送信してよろしいですか？`
      )
    ) {
      setLoading(true);
      const { result } = await postRequestAsync<object>(
        `${userActionPath}/${user.id}/send_initial_setting_mail`,
        {
          contract_period_id: selectedContractPeriodId,
        }
      );
      if (result === undefined) {
        setLoading(false);
        window.alert("エラーが発生しました");
        return;
      }
      setLoading(false);
      setMailSended(true);
    }
  };

  return (
    <BaseMoal
      show={showModal}
      title="診断依頼メール再送信"
      contentLabel="initial_setting_mail_resend"
      onCloseModal={onClose}
      loading={loading}
    >
      <BaseMoal.Body fixed>
        {mailSended && (
          <div>
            <div className="d-flex justify-content-center">
              <div>送信が完了しました</div>
            </div>
            <div className="d-flex justify-content-center">
              <div>
                {`数分以内に${user.account_name}さんにメールが届きます`}
              </div>
            </div>
            <div className="d-flex justify-content-center mt-3">
              <button type="button" className="btn btn-light" onClick={onClose}>
                戻る
              </button>
            </div>
          </div>
        )}
        {!mailSended && (
          <React.Fragment>
            {contractPeriodOptions !== undefined &&
              selectedContractPeriodId !== undefined &&
              contractPeriodOptions.length > 1 && (
                <div className="row pt-2 pb-4">
                  <div className="col-sm-4 col-form-label">診断期間</div>
                  <div className="col-sm-8">
                    <SelectField
                      id="InitialSettingMailResendModalId"
                      name="InitialSettingMailResendModalName"
                      options={contractPeriodOptions}
                      selectedKey={selectedContractPeriodId}
                      containerStyle={{ marginLeft: -2 }}
                      onChange={(e) => loadTemplate(e.target.value)}
                    />
                  </div>
                </div>
              )}
            <div className="row">
              <div className="col-sm-4 col-form-label">件名</div>
              <div className="col-sm-8">{mailTemplate.subject}</div>
            </div>
            <div className="row">
              <div className="col-sm-4 col-form-label">本文</div>
              <div className="col-sm-8">
                <div
                  className="mail-template-body"
                  dangerouslySetInnerHTML={{ __html: mailTemplate.body }}
                />
              </div>
            </div>
          </React.Fragment>
        )}
      </BaseMoal.Body>
      {!mailSended && (
        <div className="modal-footer">
          <button
            type="submit"
            id="memberEditSubmit"
            style={{ border: "none", padding: 0 }}
          />
          <button type="button" className="btn btn-light" onClick={onClose}>
            戻る
          </button>
          <Button
            title="送信"
            className="btn button__primary"
            onClick={send}
            disabled={loading}
          />
        </div>
      )}
    </BaseMoal>
  );
}
