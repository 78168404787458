import React from 'react';
import PropTypes from 'prop-types';
import { happinessElementName } from '../../../utils/happiness_element_utils';

class HappinessActionQuestion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { happinessElement } = this.props;

    // if (isBefore(questionnaire.created_at, new Date(2021, 5, 21))) {
    //   return (
    //     <React.Fragment>
    //       {`${happinessElementName(happinessElement)}を上げるために、私は↓を行ってみます`}
    //       <span role="img" aria-label="">😄</span>
    //     </React.Fragment>
    //   );
    // }
    return (
      <React.Fragment>
        {`${happinessElementName(happinessElement)}: `}
        {window.I18n.t(`javascript.questionnaires.questionnaire_looking_back_note.action_plan_question_${happinessElement}`)}
      </React.Fragment>
    );
  }
}

HappinessActionQuestion.propTypes = {
  questionnaire: PropTypes.shape({
    id: PropTypes.number,
  }),
  happinessElement: PropTypes.string.isRequired,
};
HappinessActionQuestion.defaultProps = {
  questionnaire: {},
};
export default HappinessActionQuestion;
