export function setQuestionnaireForm(): void {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const form = $("#happinessQuestionnaireForm");
  try {
    const storeForm = {
      elements: form.serializeArray(),
      formAction: form.attr("action"),
    };
    window.localStorage.setItem("questionnaireForm", JSON.stringify(storeForm));
  } catch (e) {
    // out of memoryなどのエラー発生時は保存を諦める
  }
}

export function getQuestionnaireForm(): any | undefined {
  const val = window.localStorage.getItem("questionnaireForm");
  if (val === null) {
    return undefined;
  } else {
    return JSON.parse(val);
  }
}

export function removeQuestionnaireForm() {
  window.localStorage.removeItem("questionnaireForm");
}

export function getQuestionnaireMethod() {
  const form = getQuestionnaireForm();
  if (!form) {
    return "";
  }
  const element = form.elements.filter(
    (f) => f.name === "questionnaire[selected_questionnaire_method]"
  )[0];
  if (element) {
    return element.value;
  }
  return "";
}

export function getEventAnswerTiming() {
  const form = getQuestionnaireForm();
  if (!form) {
    return "";
  }
  const element = form.elements.filter(
    (f) => f.name === "questionnaire[event_answer_timing]"
  )[0];
  if (element) {
    return element.value;
  }
  return "";
}

// localStorageに保存されたformをアンケートに反映して良い場合はtrue
export function checkConsistency(event, eventAnswerTiming) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const form = $("#happinessQuestionnaireForm");
  const tempForm = getQuestionnaireForm();
  if (!tempForm) {
    return false;
  }
  if (form.attr("action") !== tempForm.formAction) {
    return false;
  }
  if (event !== null) {
    const storedEventAnswerTiming = getEventAnswerTiming();
    if (eventAnswerTiming !== storedEventAnswerTiming) {
      return false;
    }
  }
  return true;
}
