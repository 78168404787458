import React from "react";
import PropTypes from "prop-types";
import LoadingImage from "./images/spin-gray.svg";

class Loading extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { loading, width, height } = this.props;
    if (!loading) {
      return null;
    }

    return (
      <img
        src={LoadingImage}
        style={{
          width: width,
          height: height,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />
    );
  }
}
Loading.propTypes = {
  loading: PropTypes.bool.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
};
Loading.defaultProps = {
  width: 80,
  height: 80,
};
export default Loading;
