import React, { ReactElement } from "react";
import Button from "../../../shared/button";

interface Props {
  index: number;
  activeIndex: number;
}

export default function HappinessInputErrorMessage(props: Props): ReactElement {
  const { index, activeIndex } = props;

  return (
    <div>
      {index === 0 && (
        <div className="color__error text-center mt-5 mb-3 font-weight-bold">
          <span>
            {window.I18n.t(
              "javascript.questionnaires.happiness_questionnaire.required_happiness_question_error_message"
            )}
          </span>
        </div>
      )}
      <div className="d-flex justify-content-center align-items-center p-1">
        <div className="color__error">
          {window.I18n.t(
            "javascript.questionnaires.happiness_questionnaire.required_happiness_question_error_number",
            { number: activeIndex }
          )}
        </div>
        <div className="pl-4">
          <Button
            title={window.I18n.t(
              "javascript.questionnaires.happiness_questionnaire.required_happiness_question_answer_button"
            )}
            className="btn button__light-slim"
            onClick={() => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              $(window).scrollTop(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                $(`#question_${activeIndex}`).position().top - 70
              );
            }}
          />
        </div>
      </div>
    </div>
  );
}
