import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactElement } from "react";
import { InteractiveHappinessAdviceResponse } from "../../../models/InteractiveHappinessAdvice";
import { convRelativeTime } from "../../../utils/datetime_utils";
import BaseForm from "../../shared/base_form";
import Button from "../../shared/button";

interface Props {
  interactiveHappinessAdvice: InteractiveHappinessAdviceResponse;
  message?: string;
  isCurrentAdvice: boolean;
}

export default function ChatHistoryItem(props: Props): ReactElement {
  const { interactiveHappinessAdvice, isCurrentAdvice, message } = props;

  const destroy = async (): Promise<void> => {
    if (window.confirm("このアドバイスを削除してよろしいですか？")) {
      const submit = document.getElementById("chatDestroySubmit");
      if (submit !== null) {
        submit.click();
      }
    }
  };

  return (
    <div className="mt-2" style={{}}>
      <Button
        className={isCurrentAdvice ? "bgcolor__trivial" : "cursor__pointer"}
        disabled={isCurrentAdvice}
        onClick={() => {
          window.location.href = `/user/interactive_happiness_advices/${interactiveHappinessAdvice.id}`;
        }}
      >
        <div className="border__lightgray rounded font__size-13 p-2">
          {isCurrentAdvice && (
            <>
              <BaseForm
                name="chatDestroyFormOfPc"
                action={`/user/interactive_happiness_advices/${interactiveHappinessAdvice.id}`}
                method="delete"
                type="normal"
                style={{ height: 20 }}
              >
                <div className="text-right">
                  <Button
                    className="cursor__pointer"
                    onClick={() => {
                      destroy().catch((e) => console.log(e));
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faTrash}
                      className="font__size-13 color__gray"
                    />
                  </Button>
                </div>
                <button
                  type="submit"
                  id="chatDestroySubmit"
                  style={{ border: "none", padding: 0 }}
                />
              </BaseForm>
            </>
          )}
          <div className="color__gray">{`${convRelativeTime(
            interactiveHappinessAdvice.finished_at === null
              ? interactiveHappinessAdvice.created_at
              : interactiveHappinessAdvice.finished_at
          )}`}</div>
          {message !== undefined && (
            <div>
              {message.slice(0, 20)}
              {message.length > 20 ? "..." : ""}
            </div>
          )}
          {message === undefined && <div>入力中..</div>}
        </div>
      </Button>
    </div>
  );
}
