import PropTypes from 'prop-types';
import React from 'react';
import { joinedAncestorName } from '../../../../utils/string_utils';

class DepartmentSelectField extends React.Component {
  constructor(props) {
    super(props);
    const { departmentId } = this.props;
    this.state = {
      selectedDepartmentId: departmentId,
    };
  }

  ancestorsName() {
    const { departments } = this.props;
    const { selectedDepartmentId } = this.state;
    return joinedAncestorName(departments, selectedDepartmentId);
  }

  changeDepartment(e) {
    this.setState({ selectedDepartmentId: e.target.value }, () => {
      const submit = document.getElementById('departmentSelectFieldSubmit');
      submit.click();
    });
  }

  render() {
    const {
      containerClass, containerStyle, includeBlank,
      id, name, options,
    } = this.props;
    const { selectedDepartmentId } = this.state;

    return (
      <div className={containerClass} style={containerStyle}>
        <div style={{ fontSize: 13, color: 'gray' }}>
          {this.ancestorsName()}
        </div>
        <select
          className="form-control"
          style={{ fontSize: 14 }}
          name={name}
          id={id}
          defaultValue={selectedDepartmentId}
          onChange={e => this.changeDepartment(e)}
        >
          {includeBlank && (
            <option key="include_blank" value="">
              {includeBlank}
            </option>
          )}
          {options.map(({ key, value }) => (
            <option key={key} value={key}>
              {value}
            </option>
          ))}
        </select>
        <button type="submit" id="departmentSelectFieldSubmit" style={{ border: 'none', padding: 0, float: 'right' }} />
      </div>
    );
  }
}
DepartmentSelectField.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  containerClass: PropTypes.string,
  containerStyle: PropTypes.shape({
    styleKey: PropTypes.string,
  }),
  departments: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
  departmentId: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
  includeBlank: PropTypes.string,
};
DepartmentSelectField.defaultProps = {
  containerClass: '',
  containerStyle: {},
  departmentId: '',
  includeBlank: '全ての部署',
};
export default DepartmentSelectField;
