import React from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import Button from '../../../shared/button';
import UserIcon from '../../../users/user_icon';
import EditIcon from '../../../shared/images/edit.svg';

class DepartmentItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const {
      department, onClick, onEditButtonClicked, belongingDepartments,
    } = this.props;
    const { members, file_resource: fileResource } = department;
    const myselfDepartment = belongingDepartments.some(bd => bd.id === department.id);
    return (
      <React.Fragment>
        <div className="p-2 card__div-3">
          <Button
            className="cursor__pointer"
            style={{ textDecoration: 'none' }}
            onClick={() => onClick()}
          >
            <div className="card">
              <div className="fixed-ratio-box__wrapper-62">
                <div className="fixed-ratio-box__content">
                  {fileResource.url && (
                    <img
                      src={fileResource.url}
                      className="image__cover"
                      alt="departmentPhoto"
                    />
                  )}
                  {!fileResource.url && (
                    <div className="w-100 h-100 bgcolor__lightgray" />
                  )}
                </div>
                {myselfDepartment && (
                  <Button
                    className="link__container"
                    style={{
                      position: 'absolute',
                      top: 5,
                      right: 5,
                      width: 40,
                      height: 40,
                      backgroundColor: 'rgba(211,211,211,0.8)',
                      borderRadius: 10,
                    }}
                    onClick={e => onEditButtonClicked(e)}
                  >
                    <ReactSVG
                      src={EditIcon}
                      svgStyle={{
                        width: 30,
                        height: 30,
                        fill: 'gray',
                        position: 'absolute',
                        top: 18,
                        left: 23,
                        transform: 'translate(-50%, -50%)',
                      }}
                    />
                  </Button>
                )}
              </div>
              <div className="card-body pb-2">
                <h5 className="mb-3">
                  {department.name}
                </h5>
                <div>
                  {members.map((member, i) => {
                    const { user } = member;
                    return (
                      <UserIcon
                        key={member.id}
                        user={user}
                        className={`image__thumbnail-small ${i > 0 ? 'image__thumbnail-small__overwrap' : ''}`}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </Button>
        </div>
      </React.Fragment>
    );
  }
}

DepartmentItem.propTypes = {
  department: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  currentUser: PropTypes.shape({
    id: PropTypes.number,
  }),
  onClick: PropTypes.func.isRequired,
  onEditButtonClicked: PropTypes.func,
  belongingDepartments: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })),
};
DepartmentItem.defaultProps = {
  currentUser: {},
  onEditButtonClicked: null,
  belongingDepartments: [],
};
export default DepartmentItem;
