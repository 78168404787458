import React from 'react';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns';
import MultiLineText from '../shared/multi_line_text';
import PostActionMenuIcon from './post_action_menu_icon';
import PostReaction from '../reactions/post_reaction';
import CommentList from '../comments/comment_list';
import CommentInput from '../comments/comment_input';
import UserNameLink from '../users/user_name_link';
import UserIcon from '../users/user_icon';
import DisplayFileResources from '../file_resources/display_file_resources';
import DepartmentFilterNames from '../department_filters/department_filter_names';

class PostItem extends React.Component {
  constructor(props) {
    super(props);
    const { post } = this.props;
    const { comments } = post;
    this.state = {
      post,
      comments,
      reactionCount: post.good_reactions_count,
    };
    this.commentInputRef = React.createRef();
    this.addComment = this.addComment.bind(this);
  }

  addComment(comment) {
    const { comments } = this.state;
    this.setState({
      comments: comments.concat([comment]),
    });
  }

  render() {
    const {
      currentUser, user, keyword,
    } = this.props;
    const { reactionCount, post, comments } = this.state;
    if (!post) {
      return null;
    }
    const { organizational_departments: organizationalDepartments } = user;
    return (
      <React.Fragment>
        <div className="card mb-3">
          <ul className="list-group list-group-flush">
            <li className="list-group-item border-bottom-0">
              <div className="d-flex">
                <div>
                  <UserIcon
                    user={user}
                    className="image__thumbnail-small"
                  />
                </div>
                <div className="flex-fill pl-2" style={{ wordBreak: 'break-all' }}>
                  <div className="font__size-13 color__gray">
                    {`所属部署: ${organizationalDepartments.map(d => d.name).join('・')}`}
                  </div>
                  <div>
                    <UserNameLink
                      user={user}
                      className="color__dark-blue link__textd-none"
                    />
                  </div>
                </div>
                <div style={{ width: 80 }} />
              </div>
              <div className="mt-2 color__gray font__size-12">
                {format(parseISO(post.actual_time), 'MM月dd日 HH:mm')}
              </div>
              <DepartmentFilterNames
                caption="公開範囲"
                departmentFilters={post.department_filters}
              />
            </li>
            <li className="list-group-item border-top-0">
              <div className="font__size-16" style={{ wordBreak: 'break-all' }}>
                <MultiLineText text={post.body} keyword={keyword} />
              </div>
              <DisplayFileResources
                fileResources={post.file_resources}
              />
              <div className="font__size-13 color__gray mt-2">
                <span>
                  {`いいね ${reactionCount}件`}
                </span>
                <span className="float-right">
                  {`コメント件数 ${post.comments_count}件`}
                </span>
              </div>
            </li>
            <li className="list-group-item py-0 mb-2">
              <PostReaction
                baseUrl={`/wb/user/company/posts/${post.id}`}
                reactedGood={post.reacted_good}
                onGoodReactionButtonClicked={isReacted => this.setState({
                  reactionCount: isReacted ? reactionCount + 1 : reactionCount - 1,
                })}
                onCommentButtonClicked={() => this.commentInputRef.current.focus()}
              />
            </li>
            {(comments.length > 0) && (
              <CommentList
                comments={comments}
                currentUser={currentUser}
              />
            )}
            <CommentInput
              ref={this.commentInputRef}
              currentUser={currentUser}
              baseUrl={`/wb/user/company/posts/${post.id}/comments`}
              afterSaved={this.addComment}
            />
          </ul>
          <div
            style={{
              position: 'absolute',
              top: 15,
              right: 20,
              zIndex: 1,
            }}
          >
            <PostActionMenuIcon
              currentUser={currentUser}
              postUser={user}
              post={post}
              afterSaved={editedPost => this.setState({ post: editedPost })}
              afterDeleted={() => this.setState({ post: null })}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

PostItem.propTypes = {
  currentUser: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  post: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  keyword: PropTypes.string.isRequired,
};
PostItem.defaultProps = {
};
export default PostItem;
