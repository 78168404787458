import React from 'react';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns';
import MultiLineText from '../../shared/multi_line_text';
import LookingBackNoteView from '../shared/looking_back_note_view';

class QuestionnaireItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const {
      questionnaire, showUser, showDatetime, showDepartment,
    } = this.props;
    return (
      <React.Fragment>
        <tr>
          {showDatetime && (
            <React.Fragment>
              <td>
                {format(parseISO(questionnaire.created_at), 'yyyy年MM月dd日 HH時mm分')}
              </td>
            </React.Fragment>
          )}
          <td>
            <LookingBackNoteView
              questionnaire={questionnaire}
            />
          </td>
          {showUser && (
            <td>
              {questionnaire.user.account_name}
            </td>
          )}
          {showDepartment && (
            <td>
              <div>{questionnaire.department_names}</div>
            </td>
          )}
        </tr>
      </React.Fragment>
    );
  }
}

QuestionnaireItem.propTypes = {
  questionnaire: PropTypes.shape({
    id: PropTypes.number,
    answered_user_name: PropTypes.string,
  }).isRequired,
  showDatetime: PropTypes.bool.isRequired,
  showUser: PropTypes.bool.isRequired,
  showDepartment: PropTypes.bool.isRequired,
};
QuestionnaireItem.defaultProps = {
};
export default QuestionnaireItem;
