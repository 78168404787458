import React from 'react';
import PropTypes from 'prop-types';
import ReplyBorder from '../../../../shared/reply_border';
import ReplyBody from './shared/reply_body';
import CommentReaction from '../../shared/comment_reaction';

class OtherReply extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { comment, onReplyButtonClicked } = this.props;
    return (
      <React.Fragment>
        <ReplyBody comment={comment} />
        <div className="d-flex">
          <ReplyBorder />
          <div style={{ width: 55 }} />
          <div className="flex-fill font__size-14 ml-2 pb-3">
            <div className="position-relative w-100">
              <CommentReaction
                comment={comment}
                onReplyButtonClicked={() => onReplyButtonClicked()}
                onGoodButtonClicked={() => {}}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

OtherReply.propTypes = {
  comment: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  onReplyButtonClicked: PropTypes.func.isRequired,
};
OtherReply.defaultProps = {
};
export default OtherReply;
