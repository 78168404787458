import React from 'react';
import PropTypes from 'prop-types';
import Button from '../shared/button';
import { putRequest } from '../../utils/api_client';

class NotificationAllReadedButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.allReaded = this.allReaded.bind(this);
  }

  allReaded() {
    const { afterSaved } = this.props;
    if (window.confirm('全て既読にしてよろしいですか？')) {
      putRequest('/wb/user/notifications/all_readed')
        .then(() => {
          if (afterSaved) {
            afterSaved();
          } else {
            window.location.href = '/wb/user/notifications';
          }
        })
        .catch(e => console.log(e));
    }
  }

  render() {
    const { className } = this.props;
    return (
      <Button className={`${className} color__link cursor__pointer`} onClick={this.allReaded}>
        全て既読にする
      </Button>
    );
  }
}

NotificationAllReadedButton.propTypes = {
  className: PropTypes.string,
  afterSaved: PropTypes.func,
};
NotificationAllReadedButton.defaultProps = {
  className: '',
  afterSaved: null,
};
export default NotificationAllReadedButton;
