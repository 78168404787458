import PropTypes from 'prop-types';
import React from 'react';
import EmailRegistModal from './email_regist_modal';
import Button from './button';

class EmailRegistrationSuggestMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }
  render() {
    const { user, authenticityToken, errorMessages } = this.props;
    const { showModal } = this.state;
    return (
      <React.Fragment>
        {showModal && (
          <EmailRegistModal
            showModal={showModal}
            user={user}
            authenticityToken={authenticityToken}
            errorMessages={errorMessages}
            onCloseButtonClicked={() => this.setState({ showModal: false })}
          />
        )}
        <div
          className="alert alert-danger d-flex justify-content-between align-items-center"
          role="alert"
          style={{ padding: '0.25rem 1.25rem' }}
        >
          {user.temp_email_confirmation_sent_at && (
            <React.Fragment>
              <div style={{ wordBreak: 'break-word' }}>
                {window.I18n.t('javascript.shared.email_regstration_suggest_message.status', { temp_email: user.temp_email })}
              </div>
              <Button
                title={window.I18n.t('javascript.shared.email_regstration_suggest_message.ressetting')}
                className="btn btn-light"
                onClick={() => this.setState({ showModal: true })}
              />
            </React.Fragment>
          )}
          {!user.temp_email_confirmation_sent_at && (
            <React.Fragment>
              <div>
                {window.I18n.t('javascript.shared.email_regstration_suggest_message.request_for_registing_email')}
              </div>
              <Button
                title={window.I18n.t('javascript.shared.email_regstration_suggest_message.save')}
                className="btn font__size-13"
                style={{ color: 'white', backgroundColor: 'red' }}
                onClick={() => this.setState({ showModal: true })}
              />
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    );
  }
}
EmailRegistrationSuggestMessage.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  authenticityToken: PropTypes.string.isRequired,
  errorMessages: PropTypes.arrayOf(PropTypes.string),
};
EmailRegistrationSuggestMessage.defaultProps = {
  errorMessages: [],
};
export default EmailRegistrationSuggestMessage;
