import React from 'react';
import PropTypes from 'prop-types';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import QuestionListItem from './question_list/question_list_item';
import HeaderAction from './question_list/header_action';
import BodyAction from './question_list/body_action';
import LoadingMask from '../shared/loading_mask';
import BaseForm from '../shared/base_form';

class QuestionList extends React.Component {
  constructor(props) {
    super(props);
    const { questions } = this.props;
    this.state = {
      questions,
      canSort: false,
      loading: false,
    };
    this.initialquestions = questions.concat();
    this.onSortEnd = this.onSortEnd.bind(this);
    this.resetSorting = this.resetSorting.bind(this);
  }

  onSortEnd({ oldIndex, newIndex }) {
    this.setState(({ questions }) => ({
      questions: arrayMove(questions, oldIndex, newIndex),
    }));
  }

  resetSorting() {
    this.setState({
      questions: this.initialquestions,
      canSort: false,
    });
  }

  render() {
    const {
      action, questionFormOptions, questionCategoryLabel, questionCategoryOptions,
    } = this.props;
    const { questions, canSort, loading } = this.state;

    const SortableItem = SortableElement(({ question, position }) => {
      if (question.answer_format === 'child_select_format') {
        return <div />;
      }
      return (
        <QuestionListItem
          key={question.id}
          questions={questions}
          question={question}
          action={action}
          canSort={canSort}
          position={position}
          questionFormOptions={questionFormOptions}
          questionCategoryLabel={questionCategoryLabel}
          questionCategoryOptions={questionCategoryOptions}
        />
      );
    });

    const SortableList = SortableContainer(({ items }) => (
      <ul className="question-list__list-container">
        {items.map((item, index) => (
          <SortableItem
            key={item.id}
            question={item}
            index={index}
            position={index}
            disabled={!canSort}
          />
        ))}
      </ul>
    ));

    return (
      <React.Fragment>
        <div className="question-list__header-action-container">
          <HeaderAction
            action={action}
            canSort={canSort}
            onSortStarted={() => this.setState({ canSort: true })}
            onSortReset={this.resetSorting}
            questionFormOptions={questionFormOptions}
            questionCategoryLabel={questionCategoryLabel}
            questionCategoryOptions={questionCategoryOptions}
          />
        </div>
        {/* 並び順更新に利用するform */}
        <BaseForm
          name="allDutiesEditForm"
          action={`${action}/update_all`}
          method="put"
        >
          <input name="utf8" type="hidden" value="✓" />
          <input type="hidden" name="_method" value="patch" />
          <BodyAction
            canSort={canSort}
            beforeSaved={callback => this.setState({ loading: true }, () => {
              callback();
            })}
            afterSaved={callback => this.setState({ loading: false }, () => {
              callback();
            })}
          />
          <SortableList
            items={questions}
            onSortEnd={this.onSortEnd}
            helperClass="question-list__list-container"
            distance={1}
            lockAxis="y"
          />
          {questions.length === 0 && (
            <div style={{ padding: 10, color: 'gray' }}>
              アンケートが設定されていません。
            </div>
          )}
        </BaseForm>
        <LoadingMask
          loading={loading}
        />
      </React.Fragment>
    );
  }
}

QuestionList.propTypes = {
  action: PropTypes.string.isRequired,
  questionCategoryLabel: PropTypes.string.isRequired,
  questionCategoryOptions: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  questions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
  questionFormOptions: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
};
QuestionList.defaultProps = {
};
export default QuestionList;
