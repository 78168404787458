import React from 'react';
import PropTypes from 'prop-types';
import { happinessCategoryName } from '../../../utils/happiness_category_utils';
import { happinessElementName } from '../../../utils/happiness_element_utils';
import HappinessActionQuestion from './happiness_action_question';

class LookingBackNoteView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  displayComponents() {
    const { questionnaire } = this.props;
    const displayComponents = [];
    const raisedRateHappinessRecipes = questionnaire.happiness_recipes.filter(hr => hr.category === 'raised_rate_category');
    if (raisedRateHappinessRecipes.length > 0) {
      displayComponents.push([
        <div className="mt-3" key="happiness_recipe_raised_rate_category">
          <div className="font-weight-bold font__size-14">
            ■幸せ力が伸びた理由
          </div>
          {raisedRateHappinessRecipes.map(hr => (
            <div className="mt-1 border-bottom" key={hr.happiness_element}>
              <div className="font__size-12 color__gray">
                {`${happinessElementName(hr.happiness_element)}（${happinessCategoryName(hr.happiness_element)}）`}<br />
                {`${Math.round(hr.before_happiness_percent)}pt → ${Math.round(hr.happiness_percent)}pt(+${Math.round(hr.happiness_percent - hr.before_happiness_percent)}pt)`}
              </div>
              <div className="p-1">
                {hr.body}
              </div>
            </div>
          ))}
        </div>,
      ]);
    }
    const highRateHappinessRecipes = questionnaire.happiness_recipes.filter(hr => hr.category === 'high_rate_category');
    if (highRateHappinessRecipes.length > 0) {
      displayComponents.push([
        <div className="mt-3" key="happiness_recipe_high_rate_category">
          <div className="font-weight-bold font__size-14">
            ■幸せ力が高い理由
          </div>
          {highRateHappinessRecipes.map(hr => (
            <div className="mt-1 border-bottom" key={hr.happiness_element}>
              <div className="font__size-12 color__gray">
                {`${happinessElementName(hr.happiness_element)}（${happinessCategoryName(hr.happiness_element)}）`}
              </div>
              <div className="p-1">
                {hr.body}
              </div>
            </div>
          ))}
        </div>,
      ]);
    }
    if (questionnaire.looking_back_note) {
      displayComponents.push([
        <div className="mt-3" key="looking_back_note">
          <div className="font-weight-bold font__size-14">
            ■結果に対して感じたこと
          </div>
          <div className="mt-1 p-1 border-bottom">
            {questionnaire.looking_back_note}
          </div>
        </div>,
      ]);
    }
    if (questionnaire.challenge_note) {
      displayComponents.push([
        <div className="mt-3" key="note">
          <div className="font-weight-bold font__size-14">
            ■前回のアクションプランを元にチャレンジできたこと、頑張ったこと
          </div>
          <div className="mt-1 p-1 border-bottom">
            {questionnaire.challenge_note}
          </div>
        </div>,
      ]);
    }
    const increaseStrengthActions = questionnaire.happiness_actions.filter(ha => ha.purpose === 'increase_strength_purpose');
    if (increaseStrengthActions.length > 0) {
      displayComponents.push([
        <div className="mt-3" key="happiness_action_increase_strength_purpose">
          <div className="font-weight-bold font__size-14">
            ■強みを伸ばすアクションプラン
          </div>
          {increaseStrengthActions.map(ha => (
            <div key={ha.happiness_element}>
              <div className="color__gray font__size-12 mt-1">
                <HappinessActionQuestion
                  questionnaire={questionnaire}
                  happinessElement={ha.happiness_element}
                />
              </div>
              <div className="mt-1 p-1 border-bottom">
                {ha.plan}
              </div>
            </div>
          ))}
        </div>,
      ]);
    }
    const reduceWeaknessActions = questionnaire.happiness_actions.filter(ha => ha.purpose === 'reduce_weaknesses_purpose');
    if (reduceWeaknessActions.length > 0) {
      displayComponents.push([
        <div className="mt-3" key="happiness_action_increase_strength_purpose">
          <div className="font-weight-bold font__size-14">
            ■幸せになる余地のある項目を伸ばすアクションプラン
          </div>
          {reduceWeaknessActions.map(ha => (
            <div key={ha.happiness_element}>
              <div className="color__gray font__size-12 mt-1">
                <HappinessActionQuestion
                  questionnaire={questionnaire}
                  happinessElement={ha.happiness_element}
                />
              </div>
              <div className="mt-1 p-1 border-bottom">
                {ha.plan}
              </div>
            </div>
          ))}
        </div>,
      ]);
    }
    if (questionnaire.note) {
      displayComponents.push([
        <div className="mt-3" key="note">
          <div className="font-weight-bold font__size-14">
            ■メモ
          </div>
          <div className="mt-1 p-1 border-bottom">
            {questionnaire.note}
          </div>
        </div>,
      ]);
    }
    return displayComponents;
  }

  render() {
    const { emptyComponent } = this.props;
    const displayComponents = this.displayComponents();
    if (displayComponents.length === 0) {
      return emptyComponent;
    }
    return displayComponents;
  }
}

LookingBackNoteView.propTypes = {
  questionnaire: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  emptyComponent: PropTypes.node,
};
LookingBackNoteView.defaultProps = {
  emptyComponent: null,
};
export default LookingBackNoteView;
