import PropTypes from "prop-types";
import React from "react";
import Modal from "react-modal";
import BaseModalBody from "./base_modal/base_modal_body";
import LoadingImage from "./images/spin-gray.svg";

const defaultStyles = {
  overlay: {
    backgroundColor: "rgba(0,0,0,0.5)",
    zIndex: 2000,
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -55%)",
    maxHeight: "70%",
    padding: 0,
    border: "none",
    width: "80%",
    overflow: "unset",
  },
};
class BaseModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  costomStyles() {
    const { contentStyle } = this.props;
    return Object.assign(defaultStyles, {
      content: Object.assign(defaultStyles.content, contentStyle),
    });
  }

  render() {
    const { show, title, onCloseModal, children, contentLabel, loading } =
      this.props;

    return (
      <Modal
        isOpen={show}
        contentLabel={contentLabel}
        ariaHideApp={false}
        style={this.costomStyles()}
      >
        <div className="modal-content">
          {!!title && (
            <div className="modal-header">
              <h5 className="modal-title">{title}</h5>
              {onCloseModal && (
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => onCloseModal()}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              )}
            </div>
          )}
          <div style={{ position: "relative" }}>
            {children}
            {loading && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                  zIndex: 1000,
                }}
              >
                <img
                  src={LoadingImage}
                  style={{
                    width: 80,
                    height: 80,
                    position: "absolute",
                    top: "calc(50% - 65px)",
                    transform: "translate(-50%)",
                    left: "50%",
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}
// BaseModal.Header = BaseModalHeader;
BaseModal.Body = BaseModalBody;
// BaseModal.Footer = BaseModalFooter;
BaseModal.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  contentLabel: PropTypes.string.isRequired,
  onCloseModal: PropTypes.func,
  children: PropTypes.node.isRequired,
  contentStyle: PropTypes.shape({
    styleKey: PropTypes.string,
  }),
  loading: PropTypes.bool,
};
BaseModal.defaultProps = {
  show: false,
  title: "",
  contentStyle: {},
  loading: false,
  onCloseModal: null,
};
export default BaseModal;
