import React from 'react';
import PropTypes from 'prop-types';
import WorkCategoryButton from './timeline_filter/work_category_button';
import DepartmentCategoryButton from './timeline_filter/department_category_button';
import UserCategoryButton from './timeline_filter/user_category_button';
import KeywordInput from '../../../shared/keyword_input';

class TimelineFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      work: null,
      user: null,
      department: null,
      keyword: '',
    };
    this.changeWork = this.changeWork.bind(this);
    this.changeUser = this.changeUser.bind(this);
    this.changeDepartment = this.changeDepartment.bind(this);
    this.changeKeyword = this.changeKeyword.bind(this);
  }

  changeWork(w = null) {
    this.setState({ work: w }, () => this.updateQuery());
  }

  changeUser(u = null) {
    this.setState({ user: u }, () => this.updateQuery());
  }

  changeDepartment(d = null) {
    this.setState({ department: d }, () => this.updateQuery());
  }

  changeKeyword(keyword) {
    this.setState({ keyword }, () => this.updateQuery());
  }

  updateQuery() {
    const { onQueryUpdated } = this.props;
    const {
      work, user, department, keyword,
    } = this.state;
    const query = {
      work_id_eq: work ? work.id : '',
      user_id_eq: user ? user.id : '',
      department_id_eq: department ? department.id : '',
      keyword_cont: keyword,
    };
    onQueryUpdated(query);
  }

  render() {
    return (
      <React.Fragment>
        <KeywordInput
          onSubmitted={this.changeKeyword}
          placeholder="キーワード"
          className="w-100 position-relative border__lightgray mt-4 bgcolor__white p-1"
        />
        <div
          className="w-100 position-relative border__lightgray mt-2"
          style={{ borderRadius: 5, overflow: 'hidden' }}
        >
          <div className="card" style={{ border: 'none' }}>
            <div className="list-group list-group-flush color__gray">
              <WorkCategoryButton
                onSelected={this.changeWork}
              />
              <DepartmentCategoryButton
                onSelected={this.changeDepartment}
              />
              <UserCategoryButton
                onSelected={this.changeUser}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
TimelineFilter.propTypes = {
  onQueryUpdated: PropTypes.func.isRequired,
};
TimelineFilter.defaultProps = {
};
export default TimelineFilter;
