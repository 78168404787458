import React from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import { v4 as uuidv4 } from 'uuid';
import { isMobile } from 'react-device-detect';
import SendIcon from '../shared/images/ic_send.svg';
import PhotoIcon from '../shared/images/ic_photo.svg';
import ImageForm from '../shared/form/image_form';
import BaseForm, { ajaxSubmit } from '../shared/base_form';
import AutosizeTextareaForm from '../shared/form/autosize_textarea_form';
import ReplyBorder from './shared/reply_border';
import UserIcon from '../users/user_icon';
import LoadingImage from '../shared/images/spin-gray.svg';


class CommentInput extends React.Component {
  constructor(props) {
    super(props);
    const { innerRef, comment } = this.props;
    this.state = {
      comment,
      refreshing: false,
      saving: false,
    };
    this.textarea = innerRef;
    this.sendCommand = this.sendCommand.bind(this);
    this.formId = uuidv4();
    this.imageFormInputRef = React.createRef();
  }

  send() {
    const { afterSaved } = this.props;
    const { saving } = this.state;
    let form = null;
    for (let i = 0; i < document.forms.length; i += 1) {
      if (document.forms[i].name === this.formId) {
        form = document.forms[i];
        break;
      }
    }
    if (!form || saving) {
      return;
    }
    const commentBodyInput = form.elements['comment[body]'];
    const imageUrlInput = form.elements['comment[file_resources_attributes][0][url]'];
    if (!commentBodyInput.value && (!imageUrlInput || !imageUrlInput.value)) {
      // 画像・テキストのどちらも存在しない場合は送信しない
      return;
    }
    this.setState({ saving: true }, () => {
      ajaxSubmit(form)
        .then((r) => {
          const { data: comment } = r;
          this.setState({ refreshing: true }, () => {
            this.setState({ comment: {}, refreshing: false }, () => {
              if (this.textarea && this.textarea.current) {
                this.textarea.current.value = '';
              }
              afterSaved(comment);
              this.setState({ saving: false });
            });
          });
        })
        .catch((e) => {
          this.setState({ saving: false }, () => {
            window.alert('エラーが発生しました。しばらく時間をおいて実行をお願いします');
          });
        });
    });
  }

  sendCommand(e) {
    const ENTER = 13;
    if (!isMobile && e.keyCode === ENTER && !e.nativeEvent.shiftKey) {
      e.preventDefault();
      this.send();
    }
  }

  render() {
    const {
      currentUser, baseUrl, replyMode, placeholder,
    } = this.props;
    const { comment, refreshing, saving } = this.state;

    if (refreshing) {
      return null;
    }

    return (
      <BaseForm
        name={this.formId}
        action={comment.id ? `${baseUrl}/${comment.id}` : baseUrl}
        method={comment.id ? 'put' : 'post'}
        className="w-100"
      >
        <li className={`list-group-item py-0 border-top-0 ${replyMode ? 'pb-3' : ''}`}>
          <div className="d-flex align-items-stretch" style={{ minHeight: '50px' }}>
            {replyMode && (
              <ReplyBorder />
            )}
            <div>
              <UserIcon
                user={currentUser}
                className="image__thumbnail-small mr-2"
              />
            </div>
            <div className="flex-fill h-75">
              <div className="bgcolor__trivial border d-flex align-items-center h-100 px-2" style={{ borderRadius: '20px' }}>
                <AutosizeTextareaForm
                  ref={this.textarea}
                  scope="comment"
                  model={comment}
                  columnName="body"
                  rows={1}
                  placeholder={placeholder}
                  inputClassName="bgcolor__transparent w-100 border-0 py-2"
                  inputStyle={{ resize: 'none', minHeight: 38, outline: 'none' }}
                  onChange={this.changeText}
                  onKeyDown={this.sendCommand}
                />
                <div style={{ width: 40, padding: 7 }}>
                  <ReactSVG
                    src={PhotoIcon}
                    beforeInjection={(svg) => {
                      svg.classList.add('comment__send-icon cursor__pointer');
                      svg.setAttribute('style', 'width: 22px;height: 22px;fill: gray;');
                    }}
                    onClick={() => {
                      if (this.imageFormInputRef && this.imageFormInputRef.current) {
                        this.imageFormInputRef.current.click();
                      }
                    }}
                  />
                </div>
                {!isMobile && saving && (
                  <div style={{ width: 40, padding: 7 }}>
                    <ReactSVG
                      src={LoadingImage}
                      beforeInjection={(svg) => {
                        svg.classList.add('comment__send-icon cursor__pointer');
                      }}
                      onClick={() => {}}
                    />
                  </div>
                )}
                {isMobile && (
                  <div style={{ width: 40, padding: 7 }}>
                    <ReactSVG
                      src={saving ? LoadingImage : SendIcon}
                      beforeInjection={(svg) => {
                        svg.classList.add('comment__send-icon cursor__pointer');
                      }}
                      onClick={() => this.send()}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <ImageForm
            ref={this.imageFormInputRef}
            scope="comment"
            model={comment}
            beforeUpload={(callback) => { callback(); }}
            afterUpload={() => {}}
          />
        </li>

      </BaseForm>
    );
  }
}

CommentInput.propTypes = {
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  currentUser: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  comment: PropTypes.shape({
    id: PropTypes.number,
  }),
  baseUrl: PropTypes.string.isRequired,
  afterSaved: PropTypes.func.isRequired,
  replyMode: PropTypes.bool,
  placeholder: PropTypes.string,
};
CommentInput.defaultProps = {
  innerRef: {},
  comment: {
    file_resources: [],
  },
  replyMode: false,
  placeholder: 'コメントする..',
};
export default React.forwardRef((props, ref) => (
  <CommentInput {...props} innerRef={ref} />
));
