import React from 'react';
import PropTypes from 'prop-types';
import TimelineLeftContainer from './timeline_page/timeline_left_container';
import TimelineRightContainer from './timeline_page/timeline_right_container';
import TimelineCenterContainer from './timeline_page/timeline_center_container';

class TimelinePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: {},
    };
  }

  render() {
    const {
      currentUser, sharingActions, missions, userProfile,
      belongingDepartments,
    } = this.props;
    const { query } = this.state;
    return (
      <div className="company-timeline-index__container d-flex justify-content-center align-items-start">
        <TimelineLeftContainer
          onQueryUpdated={q => this.setState({ query: q })}
          currentUser={currentUser}
        />
        <TimelineCenterContainer
          currentUser={currentUser}
          query={query}
          missions={missions}
          userProfile={userProfile}
          belongingDepartments={belongingDepartments}
        />
        <TimelineRightContainer
          sharingActions={sharingActions}
        />
      </div>
    );
  }
}

TimelinePage.propTypes = {
  currentUser: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  sharingActions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
  missions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
  userProfile: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  belongingDepartments: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
};
TimelinePage.defaultProps = {
};
export default TimelinePage;
