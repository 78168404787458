import PropTypes from 'prop-types';
import React from 'react';
import { ReactSVG } from 'react-svg';
import axios from 'axios';
import DocumentIcon from '../shared/images/ic_document.svg';
import DownloadIcon from '../shared/images/ic_download.svg';
import LoadingIcon from '../shared/images/spin-gray.svg';
import Button from '../shared/button';

class FileName extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      downloading: false,
    };
    this.download = this.download.bind(this);
  }

  download() {
    const { fileResource } = this.props;
    const { downloading } = this.state;
    if (!fileResource.id || downloading) {
      return;
    }
    this.setState({ downloading: true }, () => {
      axios({
        method: 'get',
        url: `/wb/user/company/file_resources/${fileResource.id}/file_download_url`,
      })
        .then((result) => {
          const { data: { url } } = result;
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('href', url);
          a.style.cssText = 'display: none';
          a.click();
          setTimeout(() => {
            document.body.removeChild(a);
            this.setState({ downloading: false });
          }, 1000);
        })
        .catch((e) => {
          this.setState({ downloading: false }, () => {
            window.alert('エラーが発生しました。大変申し訳ありませんが、しばらく時間をおいて再実行してください');
          });
        });
    });
  }

  render() {
    const {
      fileResource, name, downloadable, deleted,
    } = this.props;
    const { downloading } = this.state;
    return (
      <Button
        className={`position-relative color__black font__size-14 font-weight-bold flex-fill border p-2 ${downloadable ? 'cursor__pointer' : ''}`}
        style={{ lineHeight: '17px' }}
        onClick={this.download}
      >
        <ReactSVG
          src={downloading ? LoadingIcon : DocumentIcon}
          className="mr-2 color__gray"
          beforeInjection={(svg) => {
            svg.classList.add('');
            svg.setAttribute('style', 'width: 17px;height: 17px;margin-top: -2px;');
          }}
          style={{ float: 'left' }}
        />
        {fileResource.file_name || name}
        {downloadable && (
          <ReactSVG
            src={DownloadIcon}
            className="ml-2 color__gray"
            beforeInjection={(svg) => {
              svg.classList.add('');
              svg.setAttribute('style', 'width: 17px;height: 17px;margin-top: -2px;');
            }}
            style={{ float: 'right' }}
          />
        )}
        {deleted && (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'gray',
              opacity: 0.8,
              color: 'white',
              textAlign: 'center',
            }}
          >
            <div>
              削除
            </div>
          </div>
        )}
      </Button>
    );
  }
}
FileName.propTypes = {
  fileResource: PropTypes.shape({
    id: PropTypes.number,
  }),
  name: PropTypes.string,
  downloadable: PropTypes.bool,
  deleted: PropTypes.bool,
};
FileName.defaultProps = {
  fileResource: {},
  name: '',
  downloadable: false,
  deleted: false,
};
export default FileName;
