import React from 'react';
import PropTypes from 'prop-types';

function bodyComponent(profile) {
  if (Array.isArray(profile)) {
    return profile.map(p => (
      <span key={p} className="pl-2">
        {p}
      </span>
    ));
  }
  return (
    <div>
      {profile}
    </div>
  );
}

class ProfileItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { displayProfile, userProfile } = this.props;
    const body = userProfile[displayProfile.column_name];

    if (!body) {
      return null;
    }

    return (
      <React.Fragment>
        <div className="font__size-13 font-weight-bold mt-2">
          {window.I18n.t(`activerecord.attributes.user_profile.${displayProfile.column_name}`)}
        </div>
        <div className="pl-2 font__size-15 clearfix mt-1 mb-3">
          {bodyComponent(body)}
        </div>
      </React.Fragment>
    );
  }
}

ProfileItem.propTypes = {
  userProfile: PropTypes.shape({}).isRequired,
  displayProfile: PropTypes.shape({}).isRequired,
};
ProfileItem.defaultProps = {
};
export default ProfileItem;
