import React, { ReactElement } from "react";
import QuestionaryCheckboxInput from "./checkbox_input";
import { HappinessAnswerIndexResponse } from "../../../../models/HappinessAnswerApi";

interface Props {
  happinessAnswer: HappinessAnswerIndexResponse;
  showQuestionary: boolean;
  index: number;
  activeIndex: number;
  isConsumer: boolean;
  onHappinessAnswerSelected: () => void;
}

export default function HappinessAnswerInput(props: Props): ReactElement {
  const {
    happinessAnswer,
    showQuestionary,
    index,
    activeIndex,
    isConsumer,
    onHappinessAnswerSelected,
  } = props;
  const isJaLocale = window.I18n.locale === "ja";
  return (
    <div>
      {showQuestionary && (
        <div>
          <div className="happiness-questionnaire__happiness-question">
            <div className="font-weight-bold">
              <div className="happiness-questionnaire__question-index">
                {activeIndex}.
              </div>
              <span>{happinessAnswer.happiness_question.body}</span>
            </div>
            {isConsumer &&
              happinessAnswer.happiness_question.happiness_element ===
                "workplace_happiness_safe_and_secure" &&
              isJaLocale && (
                <div className="color__gray font__size-13">
                  ※職場がない方は、
                  現在参加しているコミュニティ(学校、家庭、団体等)を想定してご記載ください。
                </div>
              )}
          </div>
        </div>
      )}
      <QuestionaryCheckboxInput
        baseInputId={`questionnaire_happiness_answers_attributes_${index}`}
        baseInputName={`questionnaire[happiness_answers_attributes][${index}]`}
        questionIdName="happiness_question_id"
        questionIdValue={happinessAnswer.happiness_question.id}
        defaultPoint={happinessAnswer.point}
        showCheckbox={showQuestionary}
        onHappinessAnswerSelected={onHappinessAnswerSelected}
      />
    </div>
  );
}
