import React from 'react';
import PropTypes from 'prop-types';
import BaseMoal from '../../shared/base_modal';
import QuestionnaireNoteForm from './questionnaire_note_form';

class QuestionnaireNoteEditModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  render() {
    const {
      showModal, onCloseButtonClicked, questionnaire, stopLoadingAfterSaved,
      afterSaved, currentUser,
    } = this.props;
    const { loading } = this.state;
    return (
      <React.Fragment>
        <BaseMoal
          show={showModal}
          title={window.I18n.t('javascript.questionnaires.questionnaire_notes.edit_note')}
          contentLabel="editQuestionnaireNote"
          onCloseModal={() => onCloseButtonClicked()}
          loading={loading}
          contentStyle={{ maxWidth: 496, width: '100%' }}
        >
          <QuestionnaireNoteForm
            questionnaire={questionnaire}
            currentUser={currentUser}
            beforeSaved={callback => this.setState({ loading: true }, callback)}
            afterSaved={(q) => {
              if (stopLoadingAfterSaved) {
                this.setState({ loading: false }, afterSaved(q));
              } else {
                afterSaved(q);
              }
            }}
            afterFailed={() => this.setState({ loading: false })}
            onCloseButtonClicked={() => onCloseButtonClicked()}
          />
        </BaseMoal>
      </React.Fragment>
    );
  }
}

QuestionnaireNoteEditModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  questionnaire: PropTypes.shape({
    id: PropTypes.number,
  }),
  onCloseButtonClicked: PropTypes.func.isRequired,
  afterSaved: PropTypes.func.isRequired,
  stopLoadingAfterSaved: PropTypes.bool,
  currentUser: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};
QuestionnaireNoteEditModal.defaultProps = {
  questionnaire: {},
  stopLoadingAfterSaved: false,
};
export default QuestionnaireNoteEditModal;
