import React from 'react';
import PropTypes from 'prop-types';
import BaseModal from '../shared/base_modal';
import MultiLineText from '../shared/multi_line_text';

class RegistrationSuggestModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: true,
    };
  }

  render() {
    const { registrationPath, email } = this.props;
    const { showModal } = this.state;
    return (
      <React.Fragment>
        <BaseModal
          show={showModal}
          contentLabel="registrationSuggest"
          contentStyle={{ maxWidth: 500 }}
        >
          <div className="font__size-18 p-3">
            <p>
              {window.I18n.t('javascript.users.registration_suggest_modal.message1', { email })}
            </p>
            <p className="mt-2 font-weight-bold">
              {window.I18n.t('javascript.users.registration_suggest_modal.message2')}
            </p>
            <p className="mt-2 font__size-14 color__gray">
              <MultiLineText
                text={window.I18n.t('javascript.users.registration_suggest_modal.message3')}
              />
            </p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-link"
              style={{ position: 'absolute', left: 20, color: 'gray' }}
              onClick={() => this.setState({ showModal: false })}
            >
              {window.I18n.t('javascript.users.registration_suggest_modal.close')}
            </button>
            <a className="btn button__primary" href={registrationPath}>
              {window.I18n.t('javascript.users.registration_suggest_modal.create_account')}
            </a>
          </div>
        </BaseModal>
      </React.Fragment>
    );
  }
}

RegistrationSuggestModal.propTypes = {
  registrationPath: PropTypes.string.isRequired,
  email: PropTypes.string,
};
RegistrationSuggestModal.defaultProps = {
  email: '',
};
export default RegistrationSuggestModal;
