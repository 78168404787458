import loadImage from 'blueimp-load-image';

export function parse(file, opt, callback) {
  const { crop } = opt;
  let { maxHeight, maxWidth } = opt;
  maxHeight = maxHeight || 1000;
  maxWidth = maxWidth || 1000;
  loadImage.parseMetaData(file, (data) => {
    const options = {
      canvas: true,
    };
    if (crop) {
      options.crop = true;
      options.aspectRatio = 1;
    }
    if (data.exif) {
      options.orientation = data.exif.get('Orientation');
    }
    loadImage(file, (canvas) => {
      const scaledCanvas = loadImage.scale(
        canvas, // img or canvas element
        { maxHeight, maxWidth },
      );
      scaledCanvas.toBlob((parsedFile) => {
        callback(parsedFile);
      }, file.type, 0.9);
    }, options);
  });
}

function parseRecursive(files, fileIndex, parsedFiles, opt, callback) {
  const file = files[fileIndex];
  if (!file) {
    callback(parsedFiles);
  }
  parse(file, opt, (parsedFile) => {
    parsedFiles.push(parsedFile);
    parseRecursive(
      files,
      fileIndex + 1,
      parsedFiles,
      opt,
      callback,
    );
  });
}
export function parseMultiple(files, opt, callback) {
  parseRecursive(files, 0, [], opt, callback);
}

export default parse;
