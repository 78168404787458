import React from 'react';
import PropTypes from 'prop-types';

class UserNameLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { user, className, targetBlank } = this.props;
    return (
      <a
        href={`/wb/user/company/users/${user.id}`}
        className={className}
        target={targetBlank ? '_blank' : ''}
        rel="noopener noreferrer"
      >
        {user.account_name}
      </a>
    );
  }
}
UserNameLink.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  className: PropTypes.string,
  targetBlank: PropTypes.bool,
};
UserNameLink.defaultProps = {
  className: '',
  targetBlank: true,
};
export default UserNameLink;
