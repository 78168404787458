import PropTypes from "prop-types";
import React from "react";
import { deleteRequest } from "../../../../../utils/api_client";
import BaseMoal from "../../../../shared/base_modal";
import Button from "../../../../shared/button";

class MenuModalBody extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.addNewDepartment = this.addNewDepartment.bind(this);
    this.editDepartment = this.editDepartment.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.delete = this.delete.bind(this);
  }

  addNewDepartment() {
    const { onCreationSelected } = this.props;
    onCreationSelected();
  }

  editDepartment() {
    const { onEditSelected } = this.props;
    onEditSelected();
  }

  closeModal() {
    const { onCloseButtonClicked } = this.props;
    onCloseButtonClicked();
  }

  delete() {
    const {
      onLoadingStarted,
      onLoadingEnded,
      afterDeleted,
      onCloseButtonClicked,
      node: { id, name, members_count: membersCount, children },
    } = this.props;
    if (children.length > 0) {
      window.alert(
        "配下の部署が存在します。配下の部署を削除した後にこの部署を削除してください。"
      );
      return;
    }
    if (membersCount !== 0) {
      window.alert(
        "この部署にはメンバーが存在するため削除できません。メンバーを他の部署に割り当ててから削除をお願いします。"
      );
      return;
    }
    if (window.confirm("本当に部署を削除してよろしいですか？")) {
      onLoadingStarted(() => {
        deleteRequest(`/user/departments/${id}`)
          .then((r) => {
            const { node_path } = r.data;
            afterDeleted(node_path);
            onCloseButtonClicked();
            window.Alert.success(`部署「${name}」の削除が完了しました`);
          })
          .catch((e) => {
            window.Raven.captureException(e);
            onLoadingEnded();
            window.alert(
              "エラーが発生しました。しばらく時間を置いて再度お試しください"
            );
          });
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <BaseMoal.Body>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6" style={{ padding: 10 }}>
                <Button
                  title="配下に新しい部署を作成"
                  className="btn btn-light btn-block"
                  onClick={this.addNewDepartment}
                  style={{ padding: 20 }}
                />
              </div>
              <div className="col-md-6" style={{ padding: 10 }}>
                <Button
                  title="編集"
                  className="btn btn-light btn-block"
                  onClick={this.editDepartment}
                  style={{ padding: 20 }}
                />
              </div>
            </div>
          </div>
        </BaseMoal.Body>
        <div className="modal-footer">
          <Button
            title="削除"
            className="btn btn-link"
            style={{ position: "absolute", left: 20, color: "gray" }}
            onClick={this.delete}
          />
          <button
            type="button"
            className="btn btn-light"
            onClick={this.closeModal}
          >
            閉じる
          </button>
        </div>
      </React.Fragment>
    );
  }
}

MenuModalBody.propTypes = {
  onCreationSelected: PropTypes.func.isRequired,
  onEditSelected: PropTypes.func.isRequired,
  onCloseButtonClicked: PropTypes.func.isRequired,
  onLoadingStarted: PropTypes.func.isRequired,
  onLoadingEnded: PropTypes.func.isRequired,
  node: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  afterDeleted: PropTypes.func.isRequired,
};
MenuModalBody.defaultProps = {};
export default MenuModalBody;
