import PropTypes from 'prop-types';
import React from 'react';

class ComponentSwitcher extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  render() {
    const { apliComponent, nonApliComponent } = this.props;
    if (window.isApli) {
      return (
        <div dangerouslySetInnerHTML={{ __html: apliComponent }} />
      );
    }
    return (
      <div dangerouslySetInnerHTML={{ __html: nonApliComponent }} />
    );
  }
}
ComponentSwitcher.propTypes = {
  apliComponent: PropTypes.string.isRequired,
  nonApliComponent: PropTypes.string.isRequired,
};
ComponentSwitcher.defaultProps = {
};
export default ComponentSwitcher;
