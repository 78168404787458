import React, { ReactElement } from "react";
import RelatedEventManagementListItem from "./shared/related_list_item";
import { EventResponse } from "../../models/Event";
import { EventRelationshipIndexResponse } from "../../models/EventRelationshipApi";

interface Props {
  event: EventResponse;
  eventRelationships: EventRelationshipIndexResponse[];
}

export default function RelatedEventManagementList(props: Props): ReactElement {
  const { event, eventRelationships } = props;
  return (
    <div className="table-responsive-xl" style={{ paddingBottom: 100 }}>
      <table className="table">
        <thead>
          <tr className="bgcolor__trivial font__size-13">
            <td>{window.I18n.t("activerecord.attributes.event.title")}</td>
            <td>{window.I18n.t("activerecord.attributes.event.start_date")}</td>
            <td>{window.I18n.t("activerecord.attributes.event.start_time")}</td>
            <td>{window.I18n.t("activerecord.attributes.event.end_date")}</td>
            <td>{window.I18n.t("activerecord.attributes.event.end_time")}</td>
            <td />
          </tr>
        </thead>
        <tbody>
          {eventRelationships.map((er) => (
            <RelatedEventManagementListItem
              key={er.id}
              event={event}
              eventRelationship={er}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}
