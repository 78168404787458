import PropTypes from 'prop-types';
import React from 'react';
import { ReactSVG } from 'react-svg';
import ClearIcon from '../../shared/images/ic_clear.svg';
import HiddenForm from '../../shared/form/hidden_form';
import Button from '../../shared/button';

class NotificationSettingField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.remove = this.remove.bind(this);
  }

  remove() {
    const { onDepartmentRemoved } = this.props;
    onDepartmentRemoved();
  }

  render() {
    const {
      scope, departmentUserNotificationSetting: dunSetting, index,
    } = this.props;
    const { department } = dunSetting;
    return (
      <React.Fragment>
        {dunSetting.id && (
          <HiddenForm
            scope={scope}
            relatedModelName="department_user_notification_settings"
            index={index}
            columnName="id"
            value={dunSetting.id}
          />
        )}
        <HiddenForm
          scope={scope}
          relatedModelName="department_user_notification_settings"
          index={index}
          columnName="role"
          value={dunSetting.role}
        />
        <HiddenForm
          scope={scope}
          relatedModelName="department_user_notification_settings"
          index={index}
          columnName="level"
          value={dunSetting.level}
        />
        <HiddenForm
          scope={scope}
          relatedModelName="department_user_notification_settings"
          index={index}
          columnName="department_id"
          value={department.id}
        />
        {dunSetting.destroyed && (
          <HiddenForm
            scope={scope}
            relatedModelName="department_user_notification_settings"
            index={index}
            columnName="_destroy"
            value="1"
          />
        )}
        {!dunSetting.destroyed && (
          <div className="d-flex align-items-center p-1">
            <ReactSVG
              src={ClearIcon}
              className="mr-2 cursor__pointer color__gray"
              beforeInjection={(svg) => {
                svg.classList.add('');
                svg.setAttribute('style', 'width: 17px;height: 17px;margin-top: -2px;');
              }}
              onClick={this.remove}
            />
            <Button
              className="color__black font__size-14 font-weight-bold flex-fill border p-2"
              style={{ lineHeight: '17px' }}
            >
              {department.name}
            </Button>
          </div>
        )}
      </React.Fragment>
    );
  }
}
NotificationSettingField.propTypes = {
  scope: PropTypes.string.isRequired,
  departmentUserNotificationSetting: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  index: PropTypes.number.isRequired,
  onDepartmentRemoved: PropTypes.func.isRequired,
};
NotificationSettingField.defaultProps = {
};
export default NotificationSettingField;
