import React from 'react';
import PropTypes from 'prop-types';
import MultiLineText from '../../shared/multi_line_text';
import CheckIcon from '../../shared/images/ic_check.png';

class QuestionListItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const {
      question, onClick, showCheckIcon, labelComponent,
    } = this.props;
    return (
      <div
        role="button"
        tabIndex="0"
        className="list-group-item"
        style={{ cursor: 'pointer', paddingRight: 80 }}
        onClick={onClick}
      >
        {labelComponent}
        <MultiLineText
          text={question.body}
        />
        {showCheckIcon && (
          <img
            src={CheckIcon}
            alt="check icon"
            className="answer-list__question-item-checkicon"
          />
        )}
      </div>
    );
  }
}

QuestionListItem.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  showCheckIcon: PropTypes.bool.isRequired,
  labelComponent: PropTypes.node,
};
QuestionListItem.defaultProps = {
  labelComponent: null,
};
export default QuestionListItem;
