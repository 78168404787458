import React from 'react';
import PropTypes from 'prop-types';
// import FbImageLibrary from 'react-fb-image-grid';
import MultiLineText from '../../../../shared/multi_line_text';
import UserNameLink from '../../../../users/user_name_link';
import UserIcon from '../../../../users/user_icon';

class CommentBody extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { comment } = this.props;
    const { user } = comment;
    return (
      <React.Fragment>
        <div className="d-flex align-items-stretch" style={{ minHeight: 50 }}>
          <div>
            <UserIcon
              user={user}
              className="image__thumbnail-small mr-2"
            />
          </div>
          <div className="flex-fill">
            <div
              className="bgcolor__trivial h-100 px-2"
              style={{
                borderRadius: 20,
                minHeight: 38,
                paddingTop: 6,
                paddingBottom: 6,
                width: '100%',
                wordBreak: 'break-all',
              }}
            >
              <div style={{ width: '90%' }}>
                <span
                  className="font__size-14 mr-2 font-weight-bold"
                  style={{ wordBreak: 'break-all' }}
                >
                  <UserNameLink user={user} className="link__textd-none color__dark-blue" />
                </span>
                <span className="font__size-14">
                  <MultiLineText text={comment.body} />
                </span>
              </div>
            </div>
          </div>
        </div>
        {comment.file_resources.length > 0 && (
          <div className="d-flex">
            <div style={{ width: 48 }} />
            <div className="w-100">
              {/*<FbImageLibrary*/}
              {/*  images={comment.file_resources.map(fr => fr.url)}*/}
              {/*  renderOverlay={() => 'preview'}*/}
              {/*/>*/}
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

CommentBody.propTypes = {
  comment: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};
CommentBody.defaultProps = {
};
export default CommentBody;
