import React from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import EditIcon from '../../../shared/images/edit.svg';
import ManagerialPositionEditModal from '../../shared/managerial_position_edit_modal';

class EditLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  render() {
    const { managerialPosition, action } = this.props;
    const { showModal } = this.state;
    return (
      <React.Fragment>
        <ManagerialPositionEditModal
          showModal={showModal}
          managerialPosition={managerialPosition}
          onCloseButtonClicked={() => this.closeModal()}
          action={action}
        />
        <ReactSVG
          src={EditIcon}
          beforeInjection={(svg) => {
            svg.classList.add('member-index__edit-icon');
          }}
          onClick={() => this.setState({ showModal: true })}
        />
      </React.Fragment>
    );
  }
}

EditLink.propTypes = {
  action: PropTypes.string.isRequired,
  managerialPosition: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};
EditLink.defaultProps = {
};
export default EditLink;
