import PropTypes from 'prop-types';
import React from 'react';
import BaseMoal from './base_modal';
import BaseForm from './base_form';
import Button from './button';
import TextForm from './form/text_form';
import PasswordForm from './form/password_form';
import ErrorContainer from './error_container';

class EmailRegistModal extends React.Component {
  constructor(props) {
    super(props);
    const { user, showModal } = this.props;
    this.state = {
      showModal,
      loading: false,
      user,
    };
    this.close = this.close.bind(this);
    this.save = this.save.bind(this);
  }

  save() {
    const form = document.forms.tempEmailRegistForm;
    const submit = document.getElementById('tempEmailRegistFormSubmit');
    if (form.checkValidity()) {
      this.setState({ loading: true }, () => {
        submit.click();
      });
    } else {
      // 検証結果がfalseなのでsubmitしてhtml5のvalidationメッセージを表示させる
      submit.click();
    }
  }

  close() {
    const { onCloseButtonClicked } = this.props;
    if (onCloseButtonClicked) {
      onCloseButtonClicked();
    } else {
      this.setState({ showModal: false });
    }
  }

  render() {
    const { authenticityToken, errorMessages } = this.props;
    const { showModal, loading, user } = this.state;
    return (
      <React.Fragment>
        <BaseMoal
          show={showModal}
          title={window.I18n.t('javascript.shared.email_regist_modal.title')}
          contentLabel="tempEmailRegist"
          loading={loading}
        >
          <BaseForm
            name="tempEmailRegistForm"
            action="/user/temp_email_update"
            method="put"
            type="normal"
            authenticityToken={authenticityToken}
          >
            <BaseMoal.Body>
              <div className="container-fluid">
                <div className="mb-2">
                  <div className="font__size-14 mb-3">
                    <span className="font-weight-bold">
                      {window.I18n.t('javascript.shared.email_regist_modal.effect_of_email_registration')}
                    </span>
                  </div>
                  <div className="font__size-14">
                    {window.I18n.t('javascript.shared.email_regist_modal.caption_for_existing_account_user')}
                  </div>
                  <div className="font__size-14 ml-4">
                    {window.I18n.t('javascript.shared.email_regist_modal.message_1_for_existing_account_user')}
                    <br />
                    {window.I18n.t('javascript.shared.email_regist_modal.message_2_for_existing_account_user')}
                  </div>
                </div>
                <ErrorContainer
                  errorMessages={errorMessages}
                  className="mb-2"
                />
                <TextForm
                  model={user}
                  scope="user"
                  columnName="temp_email"
                  required
                />
                <PasswordForm
                  model={user}
                  scope="user"
                  columnName="temp_password"
                  required
                />
              </div>
            </BaseMoal.Body>
            <div className="modal-footer">
              <Button
                title={window.I18n.t('javascript.shared.email_regist_modal.continue_without_registing')}
                className="btn btn-link"
                style={{ position: 'absolute', left: 20, color: 'gray' }}
                onClick={this.close}
              />
              <button type="submit" id="tempEmailRegistFormSubmit" style={{ border: 'none', padding: 0 }} />
              <Button
                title={window.I18n.t('javascript.shared.email_regist_modal.save')}
                className="btn button__primary"
                onClick={this.save}
                style={{ zIndex: 10 }}
              />
            </div>
          </BaseForm>
        </BaseMoal>
      </React.Fragment>
    );
  }
}
EmailRegistModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  authenticityToken: PropTypes.string.isRequired,
  errorMessages: PropTypes.arrayOf(PropTypes.string),
  onCloseButtonClicked: PropTypes.func,
};
EmailRegistModal.defaultProps = {
  errorMessages: [],
  onCloseButtonClicked: null,
};
export default EmailRegistModal;
