import React from "react";
import PropTypes from "prop-types";
import CheckBoxForm from "../../../shared/form/checkbox_form";
import TextareaForm from "../../../shared/form/textarea_form";
import { happinessCategoryName } from "../../../../utils/happiness_category_utils";
import {
  happinessElementGuidePath,
  happinessElementName,
} from "../../../../utils/happiness_element_utils";
import HappinessActionQuestion from "../../shared/happiness_action_question";
import HiddenForm from "../../../shared/form/hidden_form";

class LookingBackActionPlanForm extends React.Component {
  constructor(props) {
    super(props);
    const { questionnaire, purpose } = this.props;
    const happinessActions = questionnaire.happiness_actions.filter(
      (ha) => ha.purpose === purpose
    );
    this.state = {
      happinessActions,
      selectedElements: happinessActions.map((ha) => ha.happiness_element),
    };
  }

  render() {
    const { questionnaire, purpose } = this.props;
    const { happinessActions, selectedElements } = this.state;
    let happinessElementlist = [];
    if (purpose === "increase_strength_purpose") {
      happinessElementlist =
        questionnaire.high_happiness_element_deviations.map(([he, v]) => he);
    } else {
      happinessElementlist = questionnaire.low_happiness_element_deviations.map(
        ([he, v]) => he
      );
    }
    return (
      <div>
        <div className="font-weight-bold bgcolor__trivial p-2">
          {purpose === "increase_strength_purpose"
            ? "あなたの強みのうち、どの項目を更に上げていきたいですか？"
            : "あなたの幸せになる余地のある項目のうち、どの項目を更に上げていきたいですか？"}
        </div>
        <div className="mt-2 p-3">
          {happinessElementlist.map((he) => (
            <CheckBoxForm
              key={he}
              subTitle={happinessCategoryName(he)}
              title={happinessElementName(he)}
              scope="questionnaire"
              columnName={`${purpose}_${he}`}
              defaultChecked={selectedElements.some((se) => se === he)}
              onChange={(e) => {
                if (e.target.checked) {
                  this.setState({
                    selectedElements: selectedElements.concat(he),
                  });
                } else {
                  this.setState({
                    selectedElements: selectedElements.filter(
                      (se) => se !== he
                    ),
                  });
                }
              }}
            />
          ))}
          <div>
            {selectedElements.map((se, i) => {
              const formIndex =
                i + (purpose === "increase_strength_purpose" ? 10 : 0);
              const happinessAction = happinessActions.filter(
                (ha) => ha.happiness_element === se
              )[0];
              return (
                <React.Fragment key={se}>
                  {happinessAction && (
                    <HiddenForm
                      scope="questionnaire"
                      relatedModelName="happiness_actions"
                      columnName="id"
                      index={formIndex}
                      value={happinessAction.id}
                    />
                  )}
                  <HiddenForm
                    scope="questionnaire"
                    relatedModelName="happiness_actions"
                    columnName="purpose"
                    index={formIndex}
                    value={purpose}
                  />
                  <HiddenForm
                    scope="questionnaire"
                    relatedModelName="happiness_actions"
                    columnName="happiness_element"
                    index={formIndex}
                    value={se}
                  />
                  <HiddenForm
                    scope="questionnaire"
                    relatedModelName="happiness_actions"
                    columnName="position"
                    index={formIndex}
                    value={i}
                  />
                  <div>
                    <HappinessActionQuestion
                      questionnaire={questionnaire}
                      happinessElement={se}
                    />
                    <div className="text-right my-1">
                      <a
                        href={`https://www.lp.well-being-circle.com/post/${happinessElementGuidePath(
                          se
                        )}`}
                        rel="noopener noreferrer"
                        target="_blank"
                        className="font__size-13"
                      >
                        {`${happinessElementName(se)}向上のためのガイド`}
                      </a>
                    </div>
                  </div>
                  <TextareaForm
                    scope="questionnaire"
                    model={happinessAction}
                    relatedModelName="happiness_actions"
                    columnName="plan"
                    index={formIndex}
                    rows={3}
                    hideLabel
                  />
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

LookingBackActionPlanForm.propTypes = {
  questionnaire: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  purpose: PropTypes.string.isRequired,
};
LookingBackActionPlanForm.defaultProps = {};
export default LookingBackActionPlanForm;
