import PropTypes from "prop-types";
import React from "react";
import BaseMoal from "../../../shared/base_modal";
import CreateModalBody from "./create_modal_body";

class CreateModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  render() {
    const { showModal, onCloseButtonClicked, afterCreated } = this.props;
    const { loading } = this.state;
    return (
      <React.Fragment>
        <BaseMoal
          show={showModal}
          title="部署追加"
          contentLabel="create_department"
          onCloseModal={() => onCloseButtonClicked()}
          loading={loading}
        >
          <CreateModalBody
            onCloseButtonClicked={onCloseButtonClicked}
            onLoadingStarted={(afterLoadingStarted) =>
              this.setState(
                {
                  loading: true,
                },
                afterLoadingStarted
              )
            }
            onLoadingEnded={() => this.setState({ loading: false })}
            afterCreated={afterCreated}
          />
        </BaseMoal>
      </React.Fragment>
    );
  }
}

CreateModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onCloseButtonClicked: PropTypes.func.isRequired,
  afterCreated: PropTypes.func.isRequired,
};
CreateModal.defaultProps = {};
export default CreateModal;
