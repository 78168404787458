import React from 'react';
import PropTypes from 'prop-types';
import EditLink from './question_list_item/edit_link';
import SorableIcon from '../../shared/images/ic_sortable.png';
import HiddenForm from '../../shared/form/hidden_form';
import QuestionCategoryLabel from '../../question_categories/question_category_label';
import MultiLineText from '../../shared/multi_line_text';
import ParentSelectFormatLabel from './question_list_item/parent_select_format_label';

class QuestionListItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const {
      question, action, canSort, position, questions,
      questionFormOptions, questionCategoryLabel, questionCategoryOptions,
    } = this.props;
    return (
      <li className="question-list__question-item d-flex" style={{ cursor: canSort ? 'pointer' : '' }}>
        <HiddenForm
          scope="parent_question"
          relatedModelName="questions"
          index={position}
          columnName="id"
          value={question.id}
        />
        <HiddenForm
          scope="parent_question"
          relatedModelName="questions"
          index={position}
          columnName="position"
          value={position}
        />
        {question.question_category.id && (
          <QuestionCategoryLabel
            questionCategory={question.question_category}
            className="label__default mr-3"
          />
        )}
        <div className="flex-grow-1">
          {['title_format'].includes(question.answer_format) && (
            <MultiLineText
              className="font__size-16 font-weight-bold"
              text={question.body}
            />
          )}
          {['description_format'].includes(question.answer_format) && (
            <MultiLineText
              className="color__gray font__size-14"
              text={question.body}
            />
          )}
          {['text_format', 'select_format', 'check_format'].includes(question.answer_format) && (
            <MultiLineText
              text={question.body}
            />
          )}
          {['parent_select_format'].includes(question.answer_format) && (
            <div>
              <ParentSelectFormatLabel questions={questions} question={question} />
            </div>
          )}
        </div>
        {!canSort && (
          <div>
            <EditLink
              question={question}
              action={action}
              questionFormOptions={questionFormOptions}
              questionCategoryLabel={questionCategoryLabel}
              questionCategoryOptions={questionCategoryOptions}
            />
          </div>
        )}
        {canSort && (
          <img
            src={SorableIcon}
            alt="sortable icon"
            style={{ height: 17, marginTop: 2 }}
          />
        )}
      </li>
    );
  }
}

QuestionListItem.propTypes = {
  action: PropTypes.string.isRequired,
  questions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
  question: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  canSort: PropTypes.bool.isRequired,
  position: PropTypes.number.isRequired,
  questionFormOptions: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  questionCategoryLabel: PropTypes.string.isRequired,
  questionCategoryOptions: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
};
QuestionListItem.defaultProps = {
};
export default QuestionListItem;
