import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../shared/button';
import UserIcon from '../../user_icon';

class UserItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { user, onClick } = this.props;
    return (
      <React.Fragment>
        <Button className="list-group-item d-flex cursor__pointer" onClick={() => onClick()}>
          <div className="mr-2">
            <UserIcon
              user={user}
              style={{ width: 50, height: 50, borderRadius: 25 }}
            />
          </div>
          <div className="flex-fill">
            <div className="color__gray font__size-13">
              {user.organizational_departments.map(d => d.name).join('・')}
            </div>
            <div className="color__black font__size-14">
              {user.account_name}
            </div>
          </div>
        </Button>
      </React.Fragment>
    );
  }
}

UserItem.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};
UserItem.defaultProps = {
};
export default UserItem;
