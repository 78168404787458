import React from 'react';
import PropTypes from 'prop-types';
import Button from '../shared/button';
import { postRequest } from '../../utils/api_client';

class TestMailSendButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
    this.sendEmail = this.sendEmail.bind(this);
  }

  async sendEmail() {
    const { url, locale } = this.props;
    if (!window.confirm('メールを送信しますがよろしいですか？')) {
      return;
    }
    this.setState({ loading: true });
    try {
      await postRequest(url, { email_locale: locale });
      this.setState({ loading: false }, () => {
        window.Alert.success('メール送信完了しました');
      });
    } catch (e) {
      console.log(e);
      this.setState({ loading: false }, () => {
        window.Alert.error('メール送信に失敗しました');
      });
    }
  }

  render() {
    const { loading } = this.state;
    return (
      <Button
        title={loading ? '送信中..' : '自分宛にテストメール送信'}
        className="btn btn-light"
        disabled={loading}
        onClick={this.sendEmail}
      />
    );
  }
}

TestMailSendButton.propTypes = {
  url: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
};
TestMailSendButton.defaultProps = {
};
export default TestMailSendButton;
