import React, { ReactElement, useEffect, useState } from "react";
import Button from "./button";
// import html2pdf from "html2pdf.js";

// interface Props {
// }

// https://github.com/eKoopmans/html2pdf.js/issues/311
const downloadPdf = (fileName: string, afterCallback: () => void): void => {
  scrollTo(0, 0);
  const elements = [document.querySelector("body")];
  const opt = {
    filename: `${fileName}.pdf`,
    image: { type: "jpeg", quality: 0.8 },
    html2canvas: {
      windowWidth: "1585px",
      width: 1585,
      height: 1100,
      // backgroundColor: "red",
      x: 0,
      y: 0,
      scale: 1.5,
      useCORS: true,
    },
    jsPDF: {
      unit: "in",
      orientation: "landscape",
      format: "a3",
    },
    enableLinks: false,
    pagebreak: { mode: ["css", "legacy"], before: ".page-break" },
  };
  let worker = window.html2pdf().set(opt).from(elements[0]);
  if (elements.length > 1) {
    worker = worker.toPdf();
    elements.slice(1).forEach((element, _) => {
      worker = worker
        .get("pdf")
        .then((pdf) => {
          pdf.addPage();
        })
        .from(element)
        .toContainer()
        .toCanvas()
        .toPdf();
    });
  }
  worker.save().then(() => afterCallback());
};

interface Props {
  fileName: string;
}

export default function ReportDownloadButton(props: Props): ReactElement {
  const { fileName = "report" } = props;
  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    $("[data-html2canvas-ignore=true]")
      .not("[data-html2canvas-show-temporary=true]")
      .css({
        cssText: "display: none !important;",
      });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    $("[data-html2canvas-ignore-wrap=true]").css({
      cssText: "flex-wrap: nowrap !important;",
    });
  }, []);

  const download = (): void => {
    if (downloading) {
      return;
    }
    setDownloading(true);
    downloadPdf(fileName, () => {
      setDownloading(false);
    });
  };

  return (
    <>
      <div className="w-100 p-3" style={{ zIndex: 2001, position: "relative" }}>
        <div>
          <div className="text-center">
            プレビュー画面です
            <br />
            以下ボタンよりダウンロードしてください
          </div>
          <div className="d-flex justify-content-center align-items-end mt-2">
            <Button
              onClick={download}
              className="button__primary px-4 py-2 rounded cursor__pointer"
              title="PDFダウンロード"
              disabled={downloading}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          position: "fixed",
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
          zIndex: 1000,
        }}
      />
    </>
  );
}
