import React, { ReactElement } from "react";

interface Props {
  linkUrl: string;
  linkTitle: string;
}

export default function OtherAnswersNavigationLink(props: Props): ReactElement {
  const { linkUrl, linkTitle } = props;

  if (linkTitle === "" || linkUrl === "") {
    return <></>;
  }

  return (
    <div className="py-2 text-right font__size-13">
      <span className="color__gray">{`${linkTitle}のその他回答は`}</span>
      <a href={linkUrl} target="_blank">
        こちら
      </a>
    </div>
  );
}
