import React from 'react';
import PropTypes from 'prop-types';
import SharingActionSlide from '../../sharing_actions/sharing_action_slide';

class TimelineRightContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { sharingActions } = this.props;
    return (
      <div className="company-timeline-index__right-container">
        <SharingActionSlide sharingActions={sharingActions} />
      </div>
    );
  }
}
TimelineRightContainer.propTypes = {
  sharingActions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
};
TimelineRightContainer.defaultProps = {
};
export default TimelineRightContainer;
