import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Button from '../shared/button';
import MultiLineText from '../shared/multi_line_text';
import { isIosSafari } from '../../utils/user_agent';

class HappinessGuideItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.openDocument = this.openDocument.bind(this);
  }


  openDocument() {
    const { document: doc } = this.props;
    axios({
      method: 'get',
      url: `/documents/${doc.id}`,
    })
      .then((result) => {
        const { data: { url } } = result;
        if (isIosSafari) {
          window.location.href = url;
          return;
        }
        const a = document.createElement('a');
        a.setAttribute('href', url);
        a.setAttribute('target', '_blank');
        a.style.cssText = 'cursor: pointer;';
        document.body.appendChild(a);
        a.click();
        setTimeout(() => {
          document.body.removeChild(a);
        }, 10000);
      })
      .catch((e) => {
        window.alert('エラーが発生しました。大変申し訳ありませんが、しばらく時間をおいて再実行してください');
      });
  }

  render() {
    const { document } = this.props;
    const {
      category, title, image_url: imageUrl, overview,
    } = document;

    return (
      <React.Fragment>
        <div className="p-2 card__div-3">
          <Button
            className="link__container cursor__pointer"
            style={{ textDecoration: 'none' }}
            onClick={this.openDocument}
          >
            <div className="card">
              {imageUrl && (
                <img
                  src={imageUrl}
                  className="card-img-top bgcolor__lightgray"
                  style={{ minHeight: 169.8 }}
                  alt="..."
                />
              )}
              {!imageUrl && (
                <div
                  className="card-img-top bgcolor__lightgray"
                  style={{ height: 169.8 }}
                />
              )}
              <div className="card-body">
                {category && (
                  <div className="mb-2 color__gray">
                    {category}
                  </div>
                )}
                <h5 className="card-title color__black">
                  {title}
                </h5>
                <p className="card-text color__black">
                  <MultiLineText text={overview} />
                </p>
              </div>
            </div>
          </Button>
        </div>
      </React.Fragment>
    );
  }
}

HappinessGuideItem.propTypes = {
  document: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};
HappinessGuideItem.defaultProps = {
};
export default HappinessGuideItem;
