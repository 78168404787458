import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../shared/button';
import { getRequest } from '../../../utils/api_client';
import BaseMoal from '../../shared/base_modal';
import HappinessLookBackSuggestImage from '../../shared/images/happiness_look_back_suggest_image.png';

const isJaLocale = window.I18n.locale === 'ja';

class HappinessStructureImageView extends React.Component {
  constructor(props) {
    super(props);
    const { questionnaire } = this.props;
    this.state = {
      questionnaire,
    };
    this.load = this.load.bind(this);
  }

  componentDidMount() {
    const { questionnaire } = this.state;
    if (!questionnaire.share_image_url) {
      this.load();
    }
  }

  load() {
    const { beforeLoading, afterLoading } = this.props;
    const { questionnaire } = this.state;
    beforeLoading(() => {
      getRequest(`/questionnaires/${questionnaire.share_token}`)
        .then((r) => {
          const { data } = r;
          this.setState({ questionnaire: data }, afterLoading);
        })
        .catch((e) => {
          afterLoading();
        });
    });
  }

  render() {
    const { showLookBackSuggestMessage } = this.props;
    const { questionnaire } = this.state;
    const sharable = !!questionnaire.share_image_url;

    return (
      <BaseMoal.Body style={{ maxHeight: '70vh' }}>
        <div className="text-center p-3">
          {!sharable && (
            <React.Fragment>
              <div>
                {window.I18n.t('javascript.questionnaires.questionnaire_share_link.creating_image_message1')}<br />
                {window.I18n.t('javascript.questionnaires.questionnaire_share_link.creating_image_message2')}
              </div>
              <Button
                title={window.I18n.t('javascript.questionnaires.questionnaire_share_link.reload_image')}
                className="btn button__primary m-2"
                onClick={this.load}
              />
            </React.Fragment>
          )}
          {sharable && (
            <img src={questionnaire.share_image_url} alt={window.I18n.t('javascript.questionnaires.questionnaire_share_link.share_image')} className="w-75" />
          )}
        </div>
        {isJaLocale && showLookBackSuggestMessage && (
          <React.Fragment>
            <div className="p-3">
              診断結果を確認した後に、是非「<span className="font-weight-bold">幸せの振り返り</span>」も行ってみて下さい！ 振り返りを行った方は、幸福度がおどろくほど伸びています☺︎
            </div>
            <img
              src={HappinessLookBackSuggestImage}
              alt="幸せの振り返りリンクの場所画像"
              className="width__100"
            />
          </React.Fragment>
        )}
      </BaseMoal.Body>
    );
  }
}

HappinessStructureImageView.propTypes = {
  questionnaire: PropTypes.shape({
    id: PropTypes.number,
    share_token: PropTypes.string,
    share_image_url: PropTypes.string,
  }).isRequired,
  beforeLoading: PropTypes.func.isRequired,
  afterLoading: PropTypes.func.isRequired,
  showLookBackSuggestMessage: PropTypes.bool,
};
HappinessStructureImageView.defaultProps = {
  showLookBackSuggestMessage: false,
};
export default HappinessStructureImageView;
