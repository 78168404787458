import React, { ReactElement } from "react";
import { QuestionnaireResponse } from "../../../models/Questionnaire";
import Button from "../../shared/button";
import ChatHistoryItem from "./chat_history_item";

interface Props {
  questionnaire: QuestionnaireResponse;
  questionnaires: QuestionnaireResponse[];
}

export default function ChatScreenMenuArea(props: Props): ReactElement {
  const { questionnaire: currentQuestionnaire, questionnaires } = props;

  return (
    <div className="chat-screen__menu-container">
      <div className="chat-screen__menu-top-container-only-mobile">
        <div>
          <Button
            title="過去のメッセージ・アドバイス"
            className="btn button__light chat-screen__interactive-work-history-button"
            onClick={() => {
              window.location.href = `/user/questionnaires/${currentQuestionnaire.id}/questionnaire_advices/histories`;
            }}
          />
        </div>
      </div>
      <div className="chat-screen__menu-history-container">
        <div className="font__size-13 color__gray mt-3">
          過去のメッセージ・アドバイス
        </div>
        {questionnaires.map((q) => {
          return (
            <ChatHistoryItem
              key={q.id}
              questionnaire={q}
              isCurrentAdvice={currentQuestionnaire.id === q.id}
            />
          );
        })}
        {questionnaires.length > 19 && (
          <div className="text-right mt-2">
            <Button
              className="color__link font__size-13 cursor__pointer"
              title="もっとみる"
              onClick={() => {
                window.location.href = `/user/questionnaires/${currentQuestionnaire.id}/questionnaire_advices/histories`;
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}
