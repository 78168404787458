import React from 'react';
import PropTypes from 'prop-types';

class TagLabels extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { tags } = this.props;
    return (
      <React.Fragment>
        {tags.map(t => (
          <span
            className="badge p-1 px-3 border__square"
            style={{
              fontWeight: 'unset',
              color: '#333333',
              backgroundColor: t.color,
            }}
            key={t.id}
          >
            {t.body}
          </span>
        ))}
      </React.Fragment>
    );
  }
}

TagLabels.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
};
TagLabels.defaultProps = {
};
export default TagLabels;
