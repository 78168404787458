import PropTypes from "prop-types";
import React from "react";
import { putRequest } from "../../../../utils/api_client";
import Button from "../../../shared/button";

class UnlockAccessForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isUnlocked: false,
    };
    this.unlock = this.unlock.bind(this);
  }

  unlock() {
    const {
      member: { user },
      beforeApiRequest,
      afterApiRequest,
      userActionPath,
    } = this.props;
    if (
      window.confirm(
        `${user.account_name}さん(${user.email})の凍結を解除してよろしいですか？`
      )
    ) {
      beforeApiRequest(() => {
        putRequest(`${userActionPath}/${user.id}/unlock_access`)
          .then((r) => {
            afterApiRequest(() => {
              window.alert(
                `${user.account_name}さん(${user.email})のアカウント凍結解除を完了しました`
              );
              this.setState({ isUnlocked: true });
            });
          })
          .catch((e) => {
            afterApiRequest(() => {
              window.alert("エラーが発生しました");
            });
          });
      });
    }
  }

  render() {
    const {
      member: { user },
    } = this.props;
    const { isUnlocked } = this.state;
    if (!user.dummy_email_flg) {
      return null;
    }
    if (!user.is_access_locked) {
      return null;
    }
    if (isUnlocked) {
      return null;
    }
    return (
      <div
        className="alert alert-light d-flex justify-content-between align-items-center"
        role="alert"
        style={{ padding: "0.25rem 0rem" }}
      >
        <div className="font__size-15">
          このメンバーのアカウントは凍結されています
        </div>
        <Button
          title="凍結を解除"
          className="btn btn-light btn-sm"
          onClick={this.unlock}
        />
      </div>
    );
  }
}

UnlockAccessForm.propTypes = {
  userActionPath: PropTypes.string.isRequired,
  member: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  beforeApiRequest: PropTypes.func.isRequired,
  afterApiRequest: PropTypes.func.isRequired,
};
UnlockAccessForm.defaultProps = {};
export default UnlockAccessForm;
