import PropTypes from 'prop-types';
import React from 'react';
import axios from 'axios';
import BaseMoal from '../../base_modal';
import Button from '../../button';
import FileCreationAcceptedModalContent from '../../file_creation_accepted_modal_content';

class SingleContentLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      loading: false,
      creationRequestSuccessed: false,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.requestCsvCreation = this.requestCsvCreation.bind(this);
  }

  openModal() {
    this.setState({ showModal: true });
  }

  closeModal() {
    this.setState({ showModal: false, creationRequestSuccessed: false });
  }

  requestCsvCreation() {
    const { content } = this.props;
    const { link_path: linkPath } = content;
    this.setState({ loading: true }, () => {
      axios({
        method: 'get',
        url: linkPath,
        params: {
          format: 'csv',
        },
      })
        .then((result) => {
          this.setState({ loading: false, creationRequestSuccessed: true });
        })
        .catch((e) => {
          this.setState({ loading: false }, () => {
            window.alert('エラーが発生しました。大変申し訳ありませんが、しばらく時間をおいて再実行してください');
          });
        });
    });
  }

  render() {
    const { content } = this.props;
    const {
      showModal, loading, creationRequestSuccessed,
    } = this.state;
    const { title } = content;

    if (showModal) {
      return (
        <BaseMoal
          show={showModal}
          title={title}
          contentLabel="csv_link_select_modal"
          onCloseModal={this.closeModal}
          loading={loading}
          contentStyle={{ maxWidth: 496, width: '100%' }}
        >
          {creationRequestSuccessed && (
            <BaseMoal.Body>
              <FileCreationAcceptedModalContent />
            </BaseMoal.Body>
          )}
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={this.closeModal}
            >
              キャンセル
            </button>
            {!creationRequestSuccessed && (
              <Button
                title="作成"
                className="btn button__primary button__link"
                onClick={this.requestCsvCreation}
              />
            )}
          </div>
        </BaseMoal>
      );
    }
    return (
      <span
        role="button"
        style={{ fontSize: 14, cursor: 'pointer' }}
        onClick={this.openModal}
      >
        CSVダウンロード
      </span>
    );
  }
}
SingleContentLink.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    link_path: PropTypes.string.isRequired,
  }).isRequired,
};
SingleContentLink.defaultProps = {
};
export default SingleContentLink;
