import React, { ReactElement } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Button from "../../shared/button";
import { QuestionnaireAdviceResponse } from "../../../models/QuestionnaireAdvice";
import FacebookIcon from "../../shared/images/ic_facebook.png";
import TwitterIcon from "../../shared/images/ic_twitter.png";
import LineIcon from "../../shared/images/ic_line.png";
import InstagramIcon from "../../shared/images/ic_instagram.png";
import LinkIcon from "../../shared/images/ic_link.png";

interface Props {
  questionnaireAdvice: QuestionnaireAdviceResponse;
  onSelectInstagram: () => void;
  hashTags: string[];
}

export default function AdviceShareView(props: Props): ReactElement {
  const { questionnaireAdvice, onSelectInstagram, hashTags } = props;

  return (
    <>
      <div className="p-2">
        <img src={questionnaireAdvice.ogp_image_url} className="width__100" />
      </div>
      <div className="mx-2">
        <div
          className="font__size-13 border p-2"
          style={{ wordBreak: "break-all" }}
        >
          {hashTags.map((h) => `#${h}`).join(" ")}
          <br />
          {questionnaireAdvice.share_link}
        </div>
      </div>
      <div className="d-flex justify-content-around p-3">
        {[
          {
            icon: FacebookIcon,
            alt: "facebook share link",
            href: `https://www.facebook.com/sharer/sharer.php?u=${questionnaireAdvice.share_link}`,
          },
          {
            icon: TwitterIcon,
            alt: "twitter share link",
            href: encodeURI(
              `https://twitter.com/intent/tweet?text=&url=${
                questionnaireAdvice.share_link
              }&hashtags=${hashTags.join(",")}`
            ),
          },
          {
            icon: LineIcon,
            alt: "line share link",
            href: `https://social-plugins.line.me/lineit/share?url=${questionnaireAdvice.share_link}`,
          },
          {
            icon: InstagramIcon,
            alt: "instagram share link",
            onClick: () => {
              onSelectInstagram();
            },
          },
          {
            icon: LinkIcon,
            alt: "copy link",
            copyText: `${hashTags.map((h) => `#${h}`).join(" ")}\n${
              questionnaireAdvice.share_link
            }`,
          },
        ].map((l) => {
          return (
            <div className="text-center" key={l.alt}>
              {l.href && (
                <a href={l.href} rel="noopener noreferrer" target="_blank">
                  <img src={l.icon} alt={l.alt} style={{ width: 50 }} />
                </a>
              )}
              {!l.href && !l.copyText && l.onClick && (
                <Button onClick={l.onClick} className="cursor__pointer">
                  <img src={l.icon} alt={l.alt} style={{ width: 50 }} />
                </Button>
              )}
              {l.copyText && (
                <CopyToClipboard
                  text={l.copyText}
                  onCopy={() => {
                    window.alert(
                      window.I18n.t(
                        "javascript.questionnaires.shared.copied_clipboad"
                      )
                    );
                  }}
                >
                  <img
                    src={l.icon}
                    alt={l.alt}
                    style={{ width: 50 }}
                    className="cursor__pointer"
                  />
                </CopyToClipboard>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
}
