import PropTypes from "prop-types";
import React from "react";
import BaseMoal from "../../../shared/base_modal";
import CreateModalBody from "../shared/create_modal_body";
import EditModalBody from "../shared/edit_modal_body";
import MenuModalBody from "./action_modal/menu_modal_body";

class ActionModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: "menu",
      loading: false,
    };
    this.closeModal = this.closeModal.bind(this);
  }

  closeModal() {
    const { onCloseButtonClicked } = this.props;
    onCloseButtonClicked();
  }

  subTitle() {
    const { mode } = this.state;
    switch (mode) {
      case "create":
        return "(配下部署作成)";
      case "edit":
        return "(編集)";
      default:
        return "";
    }
  }

  render() {
    const {
      showModal,
      onCloseButtonClicked,
      node,
      afterCreated,
      afterEdited,
      afterDeleted,
    } = this.props;
    const { loading, mode } = this.state;
    return (
      <React.Fragment>
        <BaseMoal
          show={showModal}
          title={`${node.name}${this.subTitle()}`}
          contentLabel="control_department"
          onCloseModal={() => onCloseButtonClicked()}
          loading={loading}
        >
          {mode === "menu" && (
            <MenuModalBody
              node={node}
              onCreationSelected={() => this.setState({ mode: "create" })}
              onEditSelected={() => this.setState({ mode: "edit" })}
              onCloseButtonClicked={this.closeModal}
              onLoadingStarted={(afterLoadingStarted) =>
                this.setState(
                  {
                    loading: true,
                  },
                  afterLoadingStarted
                )
              }
              onLoadingEnded={() => this.setState({ loading: false })}
              afterDeleted={afterDeleted}
            />
          )}
          {mode === "create" && (
            <CreateModalBody
              node={node}
              onCloseButtonClicked={this.closeModal}
              onSwitchToMenu={() => this.setState({ mode: "menu" })}
              onLoadingStarted={(afterLoadingStarted) =>
                this.setState(
                  {
                    loading: true,
                  },
                  afterLoadingStarted
                )
              }
              onLoadingEnded={() => this.setState({ loading: false })}
              afterCreated={afterCreated}
            />
          )}
          {mode === "edit" && (
            <EditModalBody
              node={node}
              onCloseButtonClicked={this.closeModal}
              onSwitchToMenu={() => this.setState({ mode: "menu" })}
              onLoadingStarted={(afterLoadingStarted) =>
                this.setState(
                  {
                    loading: true,
                  },
                  afterLoadingStarted
                )
              }
              onLoadingEnded={() => this.setState({ loading: false })}
              afterEdited={afterEdited}
            />
          )}
        </BaseMoal>
      </React.Fragment>
    );
  }
}

ActionModal.propTypes = {
  node: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  showModal: PropTypes.bool.isRequired,
  onCloseButtonClicked: PropTypes.func.isRequired,
  afterCreated: PropTypes.func.isRequired,
  afterEdited: PropTypes.func.isRequired,
  afterDeleted: PropTypes.func.isRequired,
};
ActionModal.defaultProps = {};
export default ActionModal;
