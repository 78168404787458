import PropTypes from 'prop-types';
import React from 'react';

class FileCreationAcceptedView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  render() {
    return (
      <div>
        <p>
          ファイル作成が完了しましたら、メールにて連絡いたします。<br />
          進捗状況の確認・ファイルのダウンロードは以下のページで行えます。
        </p>
        <a href="/user/outputs">ダウンロードページ</a>
      </div>
    );
  }
}
FileCreationAcceptedView.propTypes = {
};
FileCreationAcceptedView.defaultProps = {
};
export default FileCreationAcceptedView;
