import React from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import TextForm from '../../../../shared/form/text_form';
import Button from '../../../../shared/button';
import ClearIcon from '../../../../shared/images/ic_clear.svg';
import HiddenForm from '../../../../shared/form/hidden_form';

class SelectQuestionField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const {
      choice, index, showDeleteButton, choiceRemoved,
    } = this.props;
    if (choice.destroyed) {
      return (
        <React.Fragment>
          <HiddenForm
            scope="question"
            relatedModelName="choices"
            index={index}
            columnName="id"
            value={choice.id}
          />
          <HiddenForm
            scope="question"
            relatedModelName="choices"
            index={index}
            columnName="_destroy"
            value="1"
          />
        </React.Fragment>
      );
    }
    const isOtherChoice = choice.role === 'with_string_role';
    return (
      <React.Fragment>
        {choice.id && (
          <HiddenForm
            scope="question"
            relatedModelName="choices"
            index={index}
            columnName="id"
            value={choice.id}
          />
        )}
        <HiddenForm
          scope="question"
          relatedModelName="choices"
          index={index}
          columnName="role"
          value={choice.role}
        />
        <HiddenForm
          scope="question"
          relatedModelName="choices"
          index={index}
          columnName="position"
          value={index}
        />
        <div className="float-left" style={{ width: '80%' }}>
          <TextForm
            scope="question"
            model={choice}
            relatedModelName="choices"
            columnName="body"
            index={index}
            required
            hideLabel
            readOnly={isOtherChoice}
          />
        </div>
        {showDeleteButton && (
          <Button
            className="btn btn-block btn-light float-left border__lightgray"
            style={{ width: '20%' }}
            onClick={choiceRemoved}
          >
            <ReactSVG
              src={ClearIcon}
              svgStyle={{
                width: 20,
                height: 20,
                marginTop: -3,
              }}
            />
          </Button>
        )}
        <div style={{ clear: 'both' }} />
      </React.Fragment>
    );
  }
}

SelectQuestionField.propTypes = {
  choice: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  index: PropTypes.number.isRequired,
  showDeleteButton: PropTypes.bool,
  choiceRemoved: PropTypes.func.isRequired,
};
SelectQuestionField.defaultProps = {
  showDeleteButton: false,
};
export default SelectQuestionField;
