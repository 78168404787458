import React from "react";
import PropTypes from "prop-types";
import Button from "../../shared/button";
import BaseMoal from "../../shared/base_modal";
import BaseForm, { ajaxSubmit } from "../../shared/base_form";
import { deleteRequest } from "../../../utils/api_client";
import EventEditForm from "./event_edit_modal/event_edit_form";

class EventEditModal extends React.Component {
  constructor(props) {
    super(props);
    const { event } = this.props;
    this.state = {
      loading: false,
      event,
    };
    this.save = this.save.bind(this);
    this.delete = this.delete.bind(this);
  }

  save() {
    const { afterSaved, showLoadingAfterSaved } = this.props;
    const form = document.forms.eventEditForm;
    if (form.checkValidity()) {
      this.setState({ loading: true }, () => {
        ajaxSubmit(form)
          .then((r) => {
            const editedEvent = r.data;
            if (showLoadingAfterSaved) {
              afterSaved(editedEvent);
            } else {
              this.setState({ loading: false }, () => {
                afterSaved(editedEvent);
              });
            }
          })
          .catch((e) => {
            const { data: rtnEvent, status } = e.response;
            if (status === 402) {
              this.setState({
                event: rtnEvent,
                loading: false,
              });
            } else {
              this.setState({ loading: false }, () => {
                window.alert(
                  "エラーが発生しました。しばらく時間をおいて再度操作をお願いします。"
                );
              });
            }
          });
      });
    } else {
      // 検証結果がfalseなのでsubmitしてhtml5のvalidationメッセージを表示させる
      const submit = document.getElementById("submit");
      submit.click();
    }
  }

  delete() {
    const { action } = this.props;
    const { event } = this.state;
    if (window.confirm("本当にイベントを削除してよろしいですか？")) {
      this.setState({ loading: true }, () => {
        deleteRequest(`${action}/${event.id}`)
          .then((r) => {
            const { redirect_url: url } = r.data;
            window.location.href = url;
          })
          .catch((e) => {
            this.setState({ loading: false }, () => {
              window.alert("削除に失敗しました");
            });
          });
      });
    }
  }

  render() {
    const {
      action,
      authenticityToken,
      answerTimingOptions,
      roleOptions,
      questionnaireMethodOptions,
      showModal,
      onCloseButtonClicked,
      workHappinessQuestionnaireUsable,
      profileInputBirthYearOptions,
      profileInputBirthMonthOptions,
      profileInputSexOptions,
      profileInputForResearchOptions,
      surveyTypeOptions,
    } = this.props;
    const { event, loading } = this.state;
    return (
      <React.Fragment>
        <BaseMoal
          show={showModal}
          title={`イベント${event.id ? "編集" : "追加"}`}
          contentLabel="edit_event"
          onCloseModal={() => onCloseButtonClicked()}
          loading={loading}
        >
          <BaseForm
            name="eventEditForm"
            action={event.id ? `${action}/${event.id}` : action}
            method={event.id ? "put" : "post"}
            authenticityToken={authenticityToken}
          >
            <BaseMoal.Body>
              <div className="container-fluid">
                <div
                  style={{
                    padding: "10px 0px",
                    fontSize: 14,
                    color: "#dc3545",
                  }}
                >
                  {event.errors &&
                    event.errors.base &&
                    event.errors.base.map((mes) => <div key={mes}>{mes}</div>)}
                </div>
                <EventEditForm
                  event={event}
                  answerTimingOptions={answerTimingOptions}
                  questionnaireMethodOptions={questionnaireMethodOptions}
                  roleOptions={roleOptions}
                  workHappinessQuestionnaireUsable={
                    workHappinessQuestionnaireUsable
                  }
                  profileInputBirthYearOptions={profileInputBirthYearOptions}
                  profileInputBirthMonthOptions={profileInputBirthMonthOptions}
                  profileInputSexOptions={profileInputSexOptions}
                  profileInputForResearchOptions={
                    profileInputForResearchOptions
                  }
                  surveyTypeOptions={surveyTypeOptions}
                />
              </div>
            </BaseMoal.Body>
            <div className="modal-footer">
              {event.id && (
                <Button
                  title="削除"
                  className="btn btn-link"
                  style={{ position: "absolute", left: 20, color: "gray" }}
                  onClick={this.delete}
                />
              )}
              <button
                type="submit"
                id="submit"
                style={{ border: "none", padding: 0 }}
              />
              <button
                type="button"
                className="btn btn-light"
                onClick={() => onCloseButtonClicked()}
                style={{ zIndex: 10 }}
              >
                閉じる
              </button>
              <Button
                title="保存"
                className="btn button__primary"
                onClick={this.save}
                style={{ zIndex: 10 }}
              />
            </div>
          </BaseForm>
        </BaseMoal>
      </React.Fragment>
    );
  }
}

EventEditModal.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.number,
  }),
  answerTimingOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  questionnaireMethodOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  roleOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  action: PropTypes.string.isRequired,
  authenticityToken: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired,
  onCloseButtonClicked: PropTypes.func.isRequired,
  afterSaved: PropTypes.func.isRequired,
  showLoadingAfterSaved: PropTypes.bool,
  workHappinessQuestionnaireUsable: PropTypes.bool.isRequired,
  profileInputBirthYearOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  profileInputBirthMonthOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  profileInputSexOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  profileInputForResearchOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  surveyTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
};
EventEditModal.defaultProps = {
  event: {},
  showLoadingAfterSaved: false,
};
export default EventEditModal;
