import React from 'react';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import TextareaField from '../../../shared/form/fields/textarea_field';
import Button from '../../../shared/button';

class InstagramView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { questionnaire, shareText, onReturn } = this.props;
    return (
      <React.Fragment>
        <Button
          title={window.I18n.t('javascript.questionnaires.shared.go_back')}
          onClick={onReturn}
          className="btn btn-link"
        />
        <div className="font__size-14 p-2 ml-2">
          {window.I18n.t('javascript.questionnaires.shared.instagram_share_step_1')}
        </div>
        <div className="text-center p-3">
          <img
            src={questionnaire.share_image_url}
            alt={window.I18n.t('javascript.questionnaires.shared.share_image')}
            className="w-50"
          />
        </div>
        <div className="font__size-14 p-2 ml-2">
          {window.I18n.t('javascript.questionnaires.shared.instagram_share_step_2')}
        </div>
        <div className="text-right m-2">
          <CopyToClipboard
            text={`${shareText}\n${questionnaire.share_link}`}
            onCopy={() => window.alert(window.I18n.t('javascript.questionnaires.shared.copied_clipboad'))}
          >
            <Button
              title={window.I18n.t('javascript.questionnaires.shared.copy')}
              className="btn btn-small btn-light"
            />
          </CopyToClipboard>
        </div>
        <div className="mx-2 mb-5">
          <TextareaField
            id=""
            name=""
            value={`${shareText}\n${questionnaire.share_link}?source=instagram`}
            rows={4}
            style={{ fontSize: 13 }}
            readOnly
          />
        </div>
      </React.Fragment>
    );
  }
}

InstagramView.propTypes = {
  questionnaire: PropTypes.shape({
    id: PropTypes.number,
    share_token: PropTypes.string,
    share_image_url: PropTypes.string,
  }).isRequired,
  shareText: PropTypes.string.isRequired,
  onReturn: PropTypes.func.isRequired,
};
InstagramView.defaultProps = {
};
export default InstagramView;
