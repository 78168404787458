import React from 'react';
import PropTypes from 'prop-types';
import DepartmentItem from './shared/department_item';
import DepartmentEditModal from './shared/department_edit_modal';

class DepartmentItemToMember extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  transitionToMember() {
    const { baseUrl, department } = this.props;
    window.location.href = `${baseUrl}/${department.id}/members`;
  }

  openEditModal(e) {
    this.setState({ showModal: true });
    e.stopPropagation();
  }

  closeEditModal() {
    this.setState({ showModal: false });
  }

  transitionToDepartmentIndex() {
    const { baseUrl } = this.props;
    window.location.href = baseUrl;
  }

  render() {
    const {
      department, currentUser, baseUrl, belongingDepartments,
    } = this.props;
    const { showModal } = this.state;
    return (
      <React.Fragment>
        <DepartmentEditModal
          department={department}
          currentUser={currentUser}
          showModal={showModal}
          onCloseButtonClicked={() => this.closeEditModal()}
          afterSaved={() => this.transitionToDepartmentIndex()}
          baseUrl={baseUrl}
        />
        <DepartmentItem
          department={department}
          currentUser={currentUser}
          onClick={() => this.transitionToMember()}
          onEditButtonClicked={e => this.openEditModal(e)}
          belongingDepartments={belongingDepartments}
        />
      </React.Fragment>
    );
  }
}

DepartmentItemToMember.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  department: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  currentUser: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  belongingDepartments: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })),
};
DepartmentItemToMember.defaultProps = {
};
export default DepartmentItemToMember;
