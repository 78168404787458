import React from 'react';
import PropTypes from 'prop-types';
import HiddenForm from '../shared/form/hidden_form';
import SelectWithTextForm from './question_form/select_with_text_form';
import MultiLineText from '../shared/multi_line_text';
import ChildSelectQuestionForm from './child_select_question_form';

class ParentSelectQuestionForm extends React.Component {
  constructor(props) {
    super(props);
    const { answer } = this.props;
    const [answerChoice] = answer.answer_choices || [];
    const selectedChoiceId = answerChoice && answerChoice.choice && Number(answerChoice.choice.id);
    this.state = {
      selectedChoiceId,
    };
  }

  form() {
    const {
      childAnswer, childIndex, question, scope, answer, index,
    } = this.props;
    const { selectedChoiceId } = this.state;
    if (!question.answer_format) {
      return null;
    }
    const [answerChoice] = answer.answer_choices || [];
    if (question.disabled && !answerChoice) {
      return null;
    }
    return (
      <React.Fragment>
        <SelectWithTextForm
          scope={scope}
          answer={answer}
          answerChoice={answerChoice}
          index={index}
          question={question}
          onChange={(e) => {
            const v = Number(e.target.value);
            this.setState({ selectedChoiceId: v === 0 ? null : v });
          }}
        />
        <ChildSelectQuestionForm
          scope={scope}
          index={childIndex}
          answer={childAnswer}
          question={childAnswer.question}
          parentChoiceId={selectedChoiceId}
        />
      </React.Fragment>
    );
  }

  render() {
    const {
      question, scope, answer, index,
    } = this.props;

    return (
      <React.Fragment>
        {answer.id && (
          <HiddenForm
            scope={scope}
            relatedModelName="answers"
            index={index}
            columnName="id"
            value={answer.id}
          />
        )}
        <HiddenForm
          scope={scope}
          relatedModelName="answers"
          index={index}
          columnName="question_id"
          value={question.id}
        />
        <div className="font-weight-bold text-center mt-4">
          <MultiLineText
            text={`${answer.question.body}${answer.question.required_flg ? ` ${window.I18n.t('javascript.questionnaires.happiness_questionnaire.required')}` : ''}`}
          />
        </div>
        <div>
          {this.form()}
        </div>
      </React.Fragment>
    );
  }
}

ParentSelectQuestionForm.propTypes = {
  childAnswer: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  childIndex: PropTypes.number.isRequired,
  question: PropTypes.shape({
    id: PropTypes.number,
  }),
  scope: PropTypes.string.isRequired,
  answer: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  index: PropTypes.number,
};
ParentSelectQuestionForm.defaultProps = {
  question: {},
  answer: {},
  index: -1,
};
export default ParentSelectQuestionForm;
