import React from 'react';
import PropTypes from 'prop-types';
import CheckboxForm from './checkbox_form';

class CheckboxesForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const {
      scope, relatedModelName, columnName,
      options,
    } = this.props;
    return (
      <React.Fragment>
        {options.map((opt, i) => {
          const {
            activeKey, disableKey, title, defaultChecked,
          } = opt;
          const keyIndex = i;
          return (
            <CheckboxForm
              key={keyIndex}
              title={title}
              scope={scope}
              relatedModelName={relatedModelName}
              columnName={columnName}
              index={i}
              defaultChecked={defaultChecked}
              activeKey={activeKey}
              disableKey={disableKey}
            />
          );
        })}
      </React.Fragment>
    );
  }
}

CheckboxesForm.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    defaultChecked: PropTypes.bool,
  })).isRequired,
  scope: PropTypes.string.isRequired,
  relatedModelName: PropTypes.string,
  columnName: PropTypes.string.isRequired,
};
CheckboxesForm.defaultProps = {
  relatedModelName: '',
};
export default CheckboxesForm;
