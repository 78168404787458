import QuestionaryCheckboxInput from "./checkbox_input";
import React, { ReactElement } from "react";
import { WorkHappinessAnswerIndexResponse } from "../../../../models/WorkHappinessAnswerApi";

const choices = [
  window.I18n.t(
    "javascript.questionnaires.happiness_questionnaire.work_happiness_check_label1"
  ),
  window.I18n.t(
    "javascript.questionnaires.happiness_questionnaire.work_happiness_check_label2"
  ),
  window.I18n.t(
    "javascript.questionnaires.happiness_questionnaire.work_happiness_check_label3"
  ),
  window.I18n.t(
    "javascript.questionnaires.happiness_questionnaire.work_happiness_check_label4"
  ),
  window.I18n.t(
    "javascript.questionnaires.happiness_questionnaire.work_happiness_check_label5"
  ),
  window.I18n.t(
    "javascript.questionnaires.happiness_questionnaire.work_happiness_check_label6"
  ),
  window.I18n.t(
    "javascript.questionnaires.happiness_questionnaire.work_happiness_check_label7"
  ),
];

interface Props {
  workHappinessAnswer: WorkHappinessAnswerIndexResponse;
  showQuestionary: boolean;
  index: number;
  activeIndex: number;
  onHappinessAnswerSelected: () => void;
}

export default function WorkHappinessAnswerInput(props: Props): ReactElement {
  const {
    workHappinessAnswer,
    showQuestionary,
    index,
    activeIndex,
    onHappinessAnswerSelected,
  } = props;
  return (
    <div>
      {showQuestionary && (
        <div className="happiness-questionnaire__happiness-question font-weight-bold">
          <div className="happiness-questionnaire__question-index">
            {activeIndex}.
          </div>
          <span className="ml-2">
            {workHappinessAnswer.work_happiness_question.body}
          </span>
        </div>
      )}
      <QuestionaryCheckboxInput
        baseInputId={`questionnaire_work_happiness_answers_attributes_${index}`}
        baseInputName={`questionnaire[work_happiness_answers_attributes][${index}]`}
        questionIdName="work_happiness_question_id"
        questionIdValue={workHappinessAnswer.work_happiness_question.id}
        defaultPoint={workHappinessAnswer.point}
        showCheckbox={showQuestionary}
        onHappinessAnswerSelected={onHappinessAnswerSelected}
        choices={choices}
      />
    </div>
  );
}
