import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactElement, useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { formatForMarkdown } from "../../../utils/markdown_formatter";
import HappyBirdImage from "../../shared/images/happy_bird.png";
import Loading from "../../shared/loading";

interface Props {
  body: string;
  loading?: boolean;
  title?: string;
  option?: {
    paragraphMarginNone?: boolean;
  };
}

export default function Chat(props: Props): ReactElement {
  const { body, loading = false, title, option = {} } = props;
  const [count, setCount] = useState(10);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (loading && count >= 0) {
      timeoutId = setTimeout(() => {
        setCount(count - 1);
      }, 1000);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [loading, count]);

  return (
    <div className="chat-screen__chat-message-container">
      <div className="d-flex justify-content-between">
        <div className="d-flex justify-content-start align-items-center">
          <div className="ml-3">
            <img src={HappyBirdImage} style={{ width: 28 }} />
          </div>
          {title !== undefined && (
            <div className="ml-2 font-weight-bold">{title}</div>
          )}
        </div>
        <div className="mr-3">
          {!loading && (
            <CopyToClipboard text={body}>
              <button className="btn button__light-slim">
                <div className="d-flex justify-content-center align-items-center">
                  <FontAwesomeIcon
                    icon={faClipboard}
                    className="font__size-13 color__gray"
                  />
                  <div className="ml-2">Copy</div>
                </div>
              </button>
            </CopyToClipboard>
          )}
        </div>
      </div>
      <div className="d-flex justify-content-start px-3 pb-3 mt-1">
        <div
          className={`p-3 bgcolor__trivial rounded markdown__container ${
            option.paragraphMarginNone !== undefined &&
            option.paragraphMarginNone
              ? "markdown__paragraph-margin-short"
              : ""
          }`}
          style={{ width: "100%" }}
        >
          {loading && body === "" && (
            <div className="d-flex justify-content-start align-items-center">
              <div className="position-relative" style={{ width: 25 }}>
                <Loading loading={true} width={20} height={20} />
              </div>
              <div className="ml-1 color__gray font__size-13">
                {count > 0
                  ? `あと約${count}秒で回答が開始されます`
                  : "しばらくお待ちください"}
              </div>
            </div>
          )}
          <ReactMarkdown
            children={formatForMarkdown(body)}
            remarkPlugins={[remarkGfm]}
          />
        </div>
      </div>
    </div>
  );
}
