import React from 'react';
import PropTypes from 'prop-types';
import BaseMoal from '../shared/base_modal';
import BaseForm from '../shared/base_form';
import CheckIcon from '../shared/images/ic_check.png';
import Button from '../shared/button';
import QuestionListItem from './question_list_modal/question_list_item';
import { labelBgColor } from '../question_categories/question_category_label';

class QuestionListModal extends React.Component {
  constructor(props) {
    super(props);
    const { selectedQuestionId, selectedQuestionnaireEventAnswerTiming } = this.props;
    this.state = {
      loading: false,
      selectedQuestionId,
      questionnaireNoteSelected: selectedQuestionId < 0,
      selectedQuestionnaireEventAnswerTiming,
    };
    this.selectQuestionnaireNote = this.selectQuestionnaireNote.bind(this);
  }

  selectQuestion(q, eventAnswerTiming = '') {
    this.setState({
      questionnaireNoteSelected: false,
      selectedQuestionId: String(q.id),
      selectedQuestionnaireEventAnswerTiming: eventAnswerTiming,
    });
  }

  selectQuestionnaireNote() {
    this.setState({
      questionnaireNoteSelected: true,
      selectedQuestionId: -1,
    });
  }

  beforeEventQuestionListItem(question) {
    const { selectedQuestionId, selectedQuestionnaireEventAnswerTiming } = this.state;
    return (
      <QuestionListItem
        key={`${question.id}_before_event`}
        labelComponent={
          <span
            className="label__default mr-3"
            style={{ backgroundColor: labelBgColor({ id: 'dummy', key: 'only_before_event' }) }}
          >
            イベント前
          </span>
        }
        question={question}
        onClick={() => this.selectQuestion(question, 'before_event')}
        showCheckIcon={String(question.id) === selectedQuestionId && selectedQuestionnaireEventAnswerTiming === 'before_event'}
      />
    );
  }

  afterEventQuestionListItem(question) {
    const { selectedQuestionId, selectedQuestionnaireEventAnswerTiming } = this.state;
    return (
      <QuestionListItem
        key={`${question.id}_after_event`}
        labelComponent={
          <span
            className="label__default mr-3"
            style={{ backgroundColor: labelBgColor({ id: 'dummy', key: 'only_after_event' }) }}
          >
            イベント後
          </span>
        }
        question={question}
        onClick={() => this.selectQuestion(question, 'after_event')}
        showCheckIcon={String(question.id) === selectedQuestionId && selectedQuestionnaireEventAnswerTiming === 'after_event'}
      />
    );
  }

  defaultQuestionListItem(question) {
    const { selectedQuestionId } = this.state;
    return [
      <QuestionListItem
        key={question.id}
        question={question}
        onClick={() => this.selectQuestion(question)}
        showCheckIcon={String(question.id) === selectedQuestionId}
      />,
    ];
  }
  render() {
    const {
      action, questions, onCloseButtonClicked,
      showModal, additionalFormInputHtml, showQuestionnaireNote,
    } = this.props;
    const {
      loading, selectedQuestionId, questionnaireNoteSelected,
      selectedQuestionnaireEventAnswerTiming,
    } = this.state;

    let questionList = questions.map((q) => {
      if (q.question_category) {
        switch (q.question_category.key) {
          case 'both_before_after_event':
            return [this.beforeEventQuestionListItem(q), this.afterEventQuestionListItem(q)];
          case 'only_before_event':
            return [this.beforeEventQuestionListItem(q)];
          case 'only_after_event':
            return [this.afterEventQuestionListItem(q)];
          default:
            return [this.defaultQuestionListItem(q)];
        }
      }
      return [this.defaultQuestionListItem(q)];
    });
    questionList = Array.prototype.concat.apply([], questionList);

    return (
      <React.Fragment>
        <BaseMoal
          show={showModal}
          title="質問内容"
          contentLabel="questionListModal"
          onCloseModal={() => onCloseButtonClicked()}
          loading={loading}
        >
          <BaseForm
            name="questionSelectForm"
            action={action}
            method="get"
            type="normal"
          >
            <BaseMoal.Body>
              <div dangerouslySetInnerHTML={{ __html: additionalFormInputHtml }} />
              <input type="hidden" name="selected_question_id" value={selectedQuestionId} />
              <input type="hidden" name="questionnaire_note_selected" value={questionnaireNoteSelected ? 'true' : 'false'} />
              <input type="hidden" name="q[questionnaire_event_answer_timing]" value={selectedQuestionnaireEventAnswerTiming} />
              <div className="card">
                <ul className="list-group list-group-flush">
                  {questionList}
                  {showQuestionnaireNote && (
                    <div
                      role="button"
                      tabIndex="0"
                      className="list-group-item"
                      key="questionnaireNote"
                      style={{ cursor: 'pointer' }}
                      onClick={this.selectQuestionnaireNote}
                    >
                      <span className="float-left">振り返りシート</span>
                      {questionnaireNoteSelected && (
                        <img
                          src={CheckIcon}
                          alt="check icon"
                          className="answer-list__question-item-checkicon"
                        />
                      )}
                    </div>
                  )}
                </ul>
              </div>
            </BaseMoal.Body>
            <div className="modal-footer">
              <Button
                title="閉じる"
                className="btn btn-light"
                onClick={() => onCloseButtonClicked()}
              />
              <button
                type="submit"
                id="submit"
                className="btn button__primary"
                onClick={() => this.setState({ loading: true })}
              >
                表示切り替え
              </button>
            </div>
          </BaseForm>
        </BaseMoal>
      </React.Fragment>
    );
  }
}

QuestionListModal.propTypes = {
  action: PropTypes.string.isRequired,
  additionalFormInputHtml: PropTypes.string.isRequired,
  selectedQuestionId: PropTypes.string,
  selectedQuestionnaireEventAnswerTiming: PropTypes.string,
  questions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
  showModal: PropTypes.bool.isRequired,
  onCloseButtonClicked: PropTypes.func.isRequired,
  showQuestionnaireNote: PropTypes.bool,
};
QuestionListModal.defaultProps = {
  showQuestionnaireNote: false,
  selectedQuestionId: '-1',
  selectedQuestionnaireEventAnswerTiming: '',
};
export default QuestionListModal;
