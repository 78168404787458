import React from 'react';
import PropTypes from 'prop-types';
import TextField from './fields/text_field';
import { fieldId, fieldName, i18nKey } from './utils/attribute_utils';
import { errorMessages } from './utils/message_utils';

class TextForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  viewLabel() {
    const {
      label, scope, relatedModelName, columnName,
    } = this.props;
    return label || window.I18n.t(i18nKey(scope, relatedModelName, columnName));
  }

  render() {
    const {
      model, scope, relatedModelName, columnName,
      placeholder, labelColor, index, className,
      required, disabled, value, readOnly, hideLabel,
    } = this.props;
    return (
      <React.Fragment>
        <div className="form-group row">
          {!hideLabel && (
            <label
              className="col-sm-4 col-form-label"
              style={{ color: labelColor }}
              htmlFor={fieldId(scope, relatedModelName, columnName, index)}
            >
              {this.viewLabel()}
            </label>
          )}
          <div className={`col-sm-${hideLabel ? 12 : 8}`}>
            <TextField
              id={fieldId(scope, relatedModelName, columnName, index)}
              name={fieldName(scope, relatedModelName, columnName, index)}
              value={value || model[columnName]}
              className={className}
              placeholder={placeholder}
              required={required}
              disabled={disabled}
              readOnly={readOnly}
            />
            {errorMessages(model, columnName, this.viewLabel()).map((m, i) => (
              <div key={m} className="invalid-feedback d-block">
                <span>{m}</span>
              </div>
            ))}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

TextForm.propTypes = {
  model: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  scope: PropTypes.string.isRequired,
  relatedModelName: PropTypes.string,
  columnName: PropTypes.string.isRequired,
  label: PropTypes.string,
  labelColor: PropTypes.string,
  index: PropTypes.number,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  readOnly: PropTypes.bool,
  hideLabel: PropTypes.bool,
};
TextForm.defaultProps = {
  relatedModelName: '',
  label: '',
  labelColor: '#212529',
  index: -1,
  className: '',
  placeholder: '',
  required: false,
  disabled: false,
  value: null,
  readOnly: false,
  hideLabel: false,
};
export default TextForm;
