import React from 'react';
import PropTypes from 'prop-types';
// import FbImageLibrary from 'react-fb-image-grid';
import MultiLineText from '../../shared/multi_line_text';
import StandardFormat from './answer_item/standard_format';
import UserIcon from '../../users/user_icon';
import UserNameLink from '../../users/user_name_link';

class AnswerItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { answer, keyword } = this.props;
    const { question } = answer;
    const item = () => {
      switch (question.answer_format) {
        case 'text_format':
        case 'string_format':
          if (!answer.body) {
            return null;
          }
          return (
            <StandardFormat
              question={question}
            >
              <MultiLineText text={answer.body} keyword={keyword} />
            </StandardFormat>
          );
        case 'select_format':
        case 'check_format': {
          const activeAnswerChoices = answer.answer_choices
            .filter(ac => ac.choice.role !== 'blank_role');
          if (activeAnswerChoices.length === 0) {
            return null;
          }
          return (
            <StandardFormat
              question={question}
            >
              {activeAnswerChoices.map(ac => (
                <div key={ac.id}>
                  <MultiLineText text={ac.body || ac.choice.body} />
                </div>
              ))}
            </StandardFormat>
          );
        }
        case 'single_user_format':
        case 'multi_users_format':
          if (answer.answer_users.length === 0) {
            return null;
          }
          return (
            <StandardFormat
              question={question}
            >
              {answer.answer_users.map(au => (
                <div key={au.id} className="float-left">
                  <span>
                    <UserIcon
                      user={au.user}
                      className="image__thumbnail-exsmall mr-1"
                    />
                    <UserNameLink
                      user={au.user}
                      className="color__dark-blue link__textd-none"
                    />
                  </span>
                </div>
              ))}
            </StandardFormat>
          );
        case 'single_image_format':
        case 'multi_images_format':
          if (answer.file_resources.length === 0) {
            return null;
          }
          return (
            <StandardFormat
              question={question}
            >
              {/*<FbImageLibrary*/}
              {/*  images={answer.file_resources.map(fr => fr.url)}*/}
              {/*  renderOverlay={() => 'preview'}*/}
              {/*/>*/}
            </StandardFormat>
          );
        default:
          return null;
      }
    };
    return (
      <React.Fragment>
        {item()}
      </React.Fragment>
    );
  }
}

AnswerItem.propTypes = {
  answer: PropTypes.shape({
    id: PropTypes.number,
    answered_user_name: PropTypes.string,
  }).isRequired,
  keyword: PropTypes.string,
};
AnswerItem.defaultProps = {
  keyword: '',
};
export default AnswerItem;
