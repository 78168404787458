import React from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import Button from '../../../../shared/button';
import CategoryButton from './shared/category_button';
import WorkSelectModal from '../../../../works/work_select_modal';
import ClearIcon from '../../../../shared/images/ic_clear.svg';
import SelectedCategory from './shared/selected_category';

class WorkCategoryButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      work: null,
    };
    this.setWork = this.setWork.bind(this);
  }

  setWork(w = null) {
    const { onSelected } = this.props;
    this.setState({ showModal: false, work: w }, () => {
      onSelected(w);
    });
  }

  render() {
    const { showModal, work } = this.state;
    return (
      <React.Fragment>
        <WorkSelectModal
          showModal={showModal}
          onCloseButtonClicked={() => this.setState({ showModal: false })}
          onSelected={this.setWork}
        />
        {!work && (
          <CategoryButton
            title="ワークで絞る"
            onClick={() => this.setState({ showModal: true })}
          />
        )}
        {work && (
          <SelectedCategory
            title="選択済ワーク"
            categoryName={work.name}
            onEdit={() => this.setState({ showModal: true })}
            onClear={() => this.setWork()}
          />
        )}
      </React.Fragment>
    );
  }
}
WorkCategoryButton.propTypes = {
  onSelected: PropTypes.func.isRequired,
};
WorkCategoryButton.defaultProps = {
};
export default WorkCategoryButton;
