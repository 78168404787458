import React from "react";
import PropTypes from "prop-types";
import TextareaForm from "../../../shared/form/textarea_form";
import { happinessCategoryName } from "../../../../utils/happiness_category_utils";
import HiddenForm from "../../../shared/form/hidden_form";
import {
  happinessElementColor,
  happinessElementName,
} from "../../../../utils/happiness_element_utils";

class HighRateHappinessRecipeForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { questionnaire } = this.props;
    return (
      <div>
        <div className="font-weight-bold bgcolor__trivial p-2">
          あなたは↓の幸せ力が特に高かったです！なんでそんなに高いのですか？
        </div>
        <div className="p-3">
          {questionnaire.high_happiness_element_deviations
            .filter((e, i) => i < 3)
            .map(([he, v], i) => {
              const formIndex = i;
              const happinessRecipe = questionnaire.happiness_recipes.filter(
                (hr) =>
                  hr.category === "high_rate_category" &&
                  hr.happiness_element === he
              )[0];
              return (
                <React.Fragment key={he}>
                  {happinessRecipe && (
                    <HiddenForm
                      scope="questionnaire"
                      relatedModelName="happiness_recipes"
                      columnName="id"
                      index={formIndex}
                      value={happinessRecipe.id}
                    />
                  )}
                  <HiddenForm
                    scope="questionnaire"
                    relatedModelName="happiness_recipes"
                    columnName="category"
                    index={formIndex}
                    value="high_rate_category"
                  />
                  <HiddenForm
                    scope="questionnaire"
                    relatedModelName="happiness_recipes"
                    columnName="happiness_element"
                    index={formIndex}
                    value={he}
                  />
                  <TextareaForm
                    scope="questionnaire"
                    model={happinessRecipe}
                    relatedModelName="happiness_recipes"
                    columnName="body"
                    index={formIndex}
                    rows={3}
                    labelAreaStyle={{
                      backgroundColor: happinessElementColor(he),
                    }}
                    subLabel={happinessCategoryName(he)}
                    label={happinessElementName(he)}
                  />
                </React.Fragment>
              );
            })}
        </div>
      </div>
    );
  }
}

HighRateHappinessRecipeForm.propTypes = {
  questionnaire: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};
HighRateHappinessRecipeForm.defaultProps = {};
export default HighRateHappinessRecipeForm;
