import React from 'react';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns';
import { ReactSVG } from 'react-svg';
import { postRequest, deleteRequest } from '../../../../../utils/api_client';
import HeartIcon from '../../../../shared/images/ic_heart.svg';

class CommentReaction extends React.Component {
  constructor(props) {
    super(props);
    const { comment } = this.props;
    this.state = {
      reactedGood: comment.reacted_good,
      reactionCount: comment.good_reactions_count,
    };
    this.react = this.react.bind(this);
  }

  react() {
    const { comment, onGoodButtonClicked } = this.props;
    const { reactedGood, reactionCount } = this.state;
    if (reactedGood) {
      this.setState({ reactedGood: false, reactionCount: reactionCount - 1 }, () => {
        onGoodButtonClicked(false);
        deleteRequest(
          `/wb/user/company/comments/${comment.id}/reactions/destroy_all`,
          {},
        )
          .then(() => {})
          .catch((err) => {
            console.log(err);
          });
      });
      return;
    }
    this.setState({ reactedGood: true, reactionCount: reactionCount + 1 }, () => {
      onGoodButtonClicked(true);
      postRequest(
        `/wb/user/company/comments/${comment.id}/reactions`,
        { reaction: { kind: 'good_kind' } },
      )
        .then(() => {})
        .catch((err) => {
          console.log(err);
        });
    });
  }

  render() {
    const { comment, onReplyButtonClicked, onEditButtonClicked } = this.props;
    const { reactedGood, reactionCount } = this.state;
    return (
      <React.Fragment>
        <span
          role="button"
          className="cursor__pointer color__dark-blue"
          onClick={this.react}
        >
          いいね!
        </span>
        {onReplyButtonClicked && (
          <React.Fragment>
            <span className="color__gray">
              ・
            </span>
            <span
              role="button"
              className="cursor__pointer color__dark-blue"
              onClick={() => onReplyButtonClicked()}
            >
              返信する
            </span>
          </React.Fragment>
        )}
        {onEditButtonClicked && (
          <React.Fragment>
            <span className="color__gray">
              ・
            </span>
            <span
              role="button"
              className="cursor__pointer color__dark-blue"
              onClick={() => onEditButtonClicked()}
            >
              編集
            </span>
          </React.Fragment>
        )}
        <span className="color__gray">
          ・
        </span>
        <span className="color__gray">
          {format(parseISO(comment.created_at), 'MM月dd日 HH:mm')}
        </span>
        {reactionCount > 0 && (
          <div
            style={{
              position: 'absolute',
              top: -25,
              right: 0,
            }}
          >
            <div
              className="d-flex justify-content-center align-items-center"
              style={{
                backgroundColor: 'white',
                borderRadius: 13,
                padding: '0px 5px',
                border: 'solid 1px lightgray',
              }}
            >
              <ReactSVG
                src={HeartIcon}
                beforeInjection={(svg) => {
                  svg.classList.add(`icon__small ${reactedGood ? 'icon__hotpink' : 'icon__gray'}`);
                }}
                style={{ marginTop: -3, marginRight: 3 }}
              />
              <div>
                {reactionCount}
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

CommentReaction.propTypes = {
  comment: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  onReplyButtonClicked: PropTypes.func,
  onEditButtonClicked: PropTypes.func,
  onGoodButtonClicked: PropTypes.func,
};
CommentReaction.defaultProps = {
  onReplyButtonClicked: null,
  onEditButtonClicked: null,
  onGoodButtonClicked: () => {},
};
export default CommentReaction;
