import React, { ReactElement } from "react";
import { Radar } from "react-chartjs-2";
import CategoryLabels from "./well_being_circle/category_labels";
import ElementLabels from "./well_being_circle/element_labels";

const options = (opt: {
  scaleMin: number;
  scaleMax: number;
  stepSize: number;
  labels: string[];
}) => {
  return {
    scale: {
      ticks: {
        display: true,
        min: opt.scaleMin,
        max: opt.scaleMax,
        stepSize: opt.stepSize,
        fontSize: 14,
        minor: {
          callback: function (value) {
            return "";
          },
        },
      },
      angleLines: {
        color: "#F3F3F3",
        // display: false,
      },
      gridLines: {
        color: "#F3F3F3",
        circular: true,
      },
    },
    legend: {
      position: "bottom",
      display: false,
    },
    responsive: true,
    animation: {
      duration: 0,
    },
    tooltips: {
      // Disable the on-canvas tooltip
      enabled: false,
    },
  };
};

interface Props {
  scaleMin: number;
  scaleMax: number;
  stepSize: number;
  labels: string[];
  locale: "ja" | "en";
  datasets: Array<{
    label: string;
    data: number[];
    color: string;
  }>;
}

export default function WellBeingCircleInReport(props: Props): ReactElement {
  const { scaleMin, scaleMax, stepSize, labels, datasets, locale } = props;

  return (
    <div id="WellBeingCircleRadar">
      <div className="well_being_circle_in_report__area">
        <div
          className="well_being_circle_in_report__backgroud__image"
          style={{
            backgroundImage: 'url("/images/radar_chart_background.png")',
          }}
        />
        <CategoryLabels
          locale={locale}
          classNamePrefix="well_being_circle_in_report"
        />
        <ElementLabels
          locale={locale}
          classNamePrefix="well_being_circle_in_report"
        />
        <div
          id="well_being_circle_in_report-container"
          className="well_being_circle_in_report__container"
        >
          <Radar
            width={370}
            height={370}
            data={{
              labels: labels.map((l) => ""),
              datasets: datasets.map((d) => ({
                label: d.label,
                data: d.data,
                fill: false,
                backgroundColor: d.color,
                borderColor: d.color,
                pointBackgroundColor: d.color,
                borderWidth: 3,
                pointRadius: 3,
                // pointHoverRadius: 10,
                pointStyle: "circle",
                // pointHitRadius: 10,
              })),
            }}
            options={options({
              scaleMin,
              scaleMax,
              stepSize,
              labels,
            })}
          />
        </div>
      </div>
    </div>
  );
}
