import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../shared/button';
import QuestionEditModal from '../shared/question_edit_modal';

class HeaderAction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showEditModal: false,
    };
    this.startSorting = this.startSorting.bind(this);
    this.resetSorting = this.resetSorting.bind(this);
  }

  startSorting() {
    const { onSortStarted } = this.props;
    onSortStarted();
  }

  resetSorting() {
    const { onSortReset } = this.props;
    if (window.confirm('本当に並び替えをリセットしてよろしいですか？')) {
      onSortReset();
    }
  }

  render() {
    const {
      canSort, action, questionFormOptions, questionCategoryLabel, questionCategoryOptions,
    } = this.props;
    const { showEditModal } = this.state;
    return (
      <React.Fragment>
        {showEditModal && (
          <QuestionEditModal
            action={action}
            questionFormOptions={questionFormOptions}
            showModal={showEditModal}
            onCloseButtonClicked={() => this.setState({ showEditModal: false })}
            questionCategoryLabel={questionCategoryLabel}
            questionCategoryOptions={questionCategoryOptions}
          />
        )}
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-7">
              <div style={{ fontSize: 14 }}>
                登録したアンケートを幸福度計測時に表示できます<br />
                例：もっと幸せに働くにはどうすれば良いと思いますか？
              </div>
            </div>
            <div className="col-sm-5">
              <Button
                title="追加"
                className="btn question-list__add-question-button"
                disabled={canSort}
                onClick={() => this.setState({ showEditModal: true })}
              />
              {canSort && (
                <Button
                  title="元に戻す"
                  className="btn question-list__sort-button"
                  onClick={this.resetSorting}
                />
              )}
              {!canSort && (
                <Button
                  title="並び替え"
                  className="btn question-list__sort-button"
                  onClick={this.startSorting}
                />
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

HeaderAction.propTypes = {
  action: PropTypes.string.isRequired,
  canSort: PropTypes.bool.isRequired,
  onSortStarted: PropTypes.func.isRequired,
  onSortReset: PropTypes.func.isRequired,
  questionFormOptions: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  questionCategoryLabel: PropTypes.string.isRequired,
  questionCategoryOptions: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
};
HeaderAction.defaultProps = {
};
export default HeaderAction;
