import React from 'react';
import PropTypes from 'prop-types';
import TagLabels from '../tags/tag_labels';

class WorkOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { work, showImage } = this.props;
    return (
      <React.Fragment>
        <h5 className="color__black">
          {work.name}
        </h5>
        <div className="mb-2">
          <TagLabels tags={work.tags} />
        </div>
        {showImage && work.image_url && (
          <img
            src={work.image_url}
            className="card-img-top"
            alt="..."
          />
        )}
        <p className="color__black font__size-14">
          {work.overview}
        </p>
        <div className="p-2" style={{ height: 30 }}>
          <a
            className="float-right font__size-13 color__gray"
            href={`/wb/user/works/${work.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {'詳細説明 >'}
          </a>
        </div>
      </React.Fragment>
    );
  }
}

WorkOverview.propTypes = {
  work: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  showImage: PropTypes.bool,
};
WorkOverview.defaultProps = {
  showImage: false,
};
export default WorkOverview;
