import React from "react";
import PropTypes from "prop-types";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Button from "../../shared/button";
import BaseMoal from "../../shared/base_modal";
import FacebookIcon from "../../shared/images/ic_facebook.png";
import TwitterIcon from "../../shared/images/ic_twitter.png";
import LineIcon from "../../shared/images/ic_line.png";
import InstagramIcon from "../../shared/images/ic_instagram.png";
import LinkIcon from "../../shared/images/ic_link.png";
import ExpandIcon from "../../shared/images/ic_expand.png";
import { getRequest, postRequest } from "../../../utils/api_client";
import InstagramView from "./share_image_view/instagram_view";
import HappinessLookBackSuggestImage from "../../shared/images/happiness_look_back_suggest_image.png";

function trackShareLink(shareType) {
  postRequest("/user/tracks", { share_type: shareType });
  postRequest("/user/sns_share_summaries/count_up");
}

const isJaLocale = window.I18n.locale === "ja";

class ShareImageView extends React.Component {
  constructor(props) {
    super(props);
    const { questionnaire } = this.props;
    this.state = {
      questionnaire,
      instagramSelected: false,
      imageExpanded: false,
    };
    this.load = this.load.bind(this);
  }

  componentDidMount() {
    const { questionnaire } = this.state;
    if (!questionnaire.share_image_url) {
      this.load();
    }
  }

  load() {
    const { beforeLoading, afterLoading } = this.props;
    const { questionnaire } = this.state;
    beforeLoading(() => {
      getRequest(`/questionnaires/${questionnaire.share_token}`)
        .then((r) => {
          const { data } = r;
          this.setState({ questionnaire: data }, afterLoading);
        })
        .catch((e) => {
          afterLoading();
        });
    });
  }

  hashTags() {
    const { questionnaire } = this.state;
    if (!isJaLocale) {
      return ["WellBeingCircle", "HappinessElements"].concat(
        questionnaire.hashtags
      );
    }
    return ["幸福度診断", "WellBeingCircle", "幸せのかたち"].concat(
      questionnaire.hashtags
    );
  }

  shareLinks() {
    const { questionnaire } = this.state;
    return [
      {
        icon: FacebookIcon,
        alt: "facebook share link",
        href: `https://www.facebook.com/sharer/sharer.php?u=${questionnaire.share_link}&source=facebook`,
        onClick: () => trackShareLink("facebook"),
      },
      {
        icon: TwitterIcon,
        alt: "twitter share link",
        href: encodeURI(
          `https://twitter.com/intent/tweet?text=&url=${
            questionnaire.share_link
          }&source=twitter&hashtags=${this.hashTags().join(",")}`
        ),
        onClick: () => trackShareLink("twitter"),
      },
      {
        icon: LineIcon,
        alt: "line share link",
        href: `https://social-plugins.line.me/lineit/share?url=${questionnaire.share_link}&source=line`,
        onClick: () => trackShareLink("line"),
      },
      {
        icon: InstagramIcon,
        alt: "instagram share link",
        onClick: () => {
          this.setState({ instagramSelected: true });
          trackShareLink("instagram");
        },
      },
      {
        icon: LinkIcon,
        alt: "copy link",
        copyText: `${this.hashTags()
          .map((h) => `#${h}`)
          .join(" ")}\n${questionnaire.share_link}&source=copy`,
        onClick: () => trackShareLink("copy"),
      },
    ];
  }

  render() {
    const { questionnaire, instagramSelected, imageExpanded } = this.state;
    const { snsShareCount, showLookBackSuggestMessage } = this.props;
    const sharable = !!questionnaire.share_image_url;

    return (
      <BaseMoal.Body style={{ maxHeight: "70vh" }}>
        {!sharable && (
          <div className="text-center p-3">
            <div>
              {window.I18n.t(
                "javascript.questionnaires.shared.creating_image_message1"
              )}
              <br />
              {window.I18n.t(
                "javascript.questionnaires.shared.creating_image_message2"
              )}
            </div>
            <Button
              title={window.I18n.t(
                "javascript.questionnaires.shared.reload_image"
              )}
              className="btn button__primary m-2"
              onClick={this.load}
            />
          </div>
        )}
        {sharable && !instagramSelected && (
          <>
            {isJaLocale && snsShareCount > 0 && (
              <div className="font-weight-bold">
                {`これまで${snsShareCount}人の方にシェア頂いています`}
                <span role="img" aria-label="">
                  😆
                </span>
              </div>
            )}

            {!imageExpanded && (
              <div className="d-flex justify-content-end">
                <Button
                  className="btn d-none d-sm-block mr-5"
                  onClick={() => this.setState({ imageExpanded: true })}
                >
                  <img src={ExpandIcon} alt="拡大" style={{ width: 23 }} />
                </Button>
              </div>
            )}
            <div className="text-center px-3 p-3">
              <img
                src={questionnaire.share_image_url}
                alt={window.I18n.t(
                  "javascript.questionnaires.shared.share_image"
                )}
                className={imageExpanded ? "w-100" : "width__50_100"}
              />
            </div>
            <div className="mx-2">
              <div
                className="font__size-13 border p-2"
                style={{ wordBreak: "break-all" }}
              >
                {this.hashTags()
                  .map((h) => `#${h}`)
                  .join(" ")}
                <br />
                {questionnaire.share_link}
              </div>
            </div>
          </>
        )}
        {sharable && instagramSelected && (
          <InstagramView
            questionnaire={questionnaire}
            shareText={`${window.I18n.t(
              "javascript.questionnaires.shared.measurement_guide"
            )}→@well_being_circle.official\n${this.hashTags()
              .map((h) => `#${h}`)
              .join(" ")}`}
            onReturn={() => this.setState({ instagramSelected: false })}
          />
        )}
        {!instagramSelected && (
          <div className="d-flex justify-content-around p-3">
            {this.shareLinks().map((l) => (
              <div className="text-center" key={l.alt}>
                {!sharable && (
                  <div className="position-relative">
                    <img src={l.icon} alt={l.alt} style={{ width: 50 }} />
                    <div className="button__cover" />
                  </div>
                )}
                {sharable && l.href && (
                  <a
                    href={l.href}
                    rel="noopener noreferrer"
                    target="_blank"
                    onClick={l.onClick}
                  >
                    <img src={l.icon} alt={l.alt} style={{ width: 50 }} />
                  </a>
                )}
                {sharable && !l.href && !l.copyText && l.onClick && (
                  <Button onClick={l.onClick} className="cursor__pointer">
                    <img src={l.icon} alt={l.alt} style={{ width: 50 }} />
                  </Button>
                )}
                {sharable && l.copyText && (
                  <CopyToClipboard
                    text={l.copyText}
                    onCopy={() => {
                      l.onClick();
                      window.alert(
                        window.I18n.t(
                          "javascript.questionnaires.shared.copied_clipboad"
                        )
                      );
                    }}
                  >
                    <img
                      src={l.icon}
                      alt={l.alt}
                      style={{ width: 50 }}
                      className="cursor__pointer"
                    />
                  </CopyToClipboard>
                )}
              </div>
            ))}
          </div>
        )}
        {isJaLocale && showLookBackSuggestMessage && (
          <React.Fragment>
            <div className="p-3">
              診断結果を確認した後に、是非「
              <span className="font-weight-bold">幸せの振り返り</span>
              」も行ってみて下さい！
              振り返りを行った方は、幸福度がおどろくほど伸びています☺︎
            </div>
            <img
              src={HappinessLookBackSuggestImage}
              alt="幸せの振り返りリンクの場所画像"
              className="width__100"
            />
          </React.Fragment>
        )}
      </BaseMoal.Body>
    );
  }
}

ShareImageView.propTypes = {
  questionnaire: PropTypes.shape({
    id: PropTypes.number,
    share_token: PropTypes.string,
    share_image_url: PropTypes.string,
  }).isRequired,
  beforeLoading: PropTypes.func.isRequired,
  afterLoading: PropTypes.func.isRequired,
  snsShareCount: PropTypes.number.isRequired,
  showLookBackSuggestMessage: PropTypes.bool,
};
ShareImageView.defaultProps = {
  showLookBackSuggestMessage: false,
};
export default ShareImageView;
