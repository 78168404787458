import PropTypes from 'prop-types';
import React from 'react';
import CategorizedContentLink from './async_csv_download_link/categorized_content_link';
import SingleContentLink from './async_csv_download_link/single_content_link';

class AsyncCsvDownloadLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { radioLinkCategories, content, downloadOptions } = this.props;

    if (radioLinkCategories.length > 0) {
      return (
        <CategorizedContentLink
          downloadOptions={downloadOptions}
          radioLinkCategories={radioLinkCategories}
        />
      );
    }
    return (
      <SingleContentLink content={content} />
    );
  }
}
AsyncCsvDownloadLink.propTypes = {
  downloadOptions: PropTypes.arrayOf(PropTypes.shape({
    formType: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    paramKey: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })),
  })).isRequired,
  radioLinkCategories: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    contents: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      link_path: PropTypes.string.isRequired,
    })),
  })).isRequired,
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    link_path: PropTypes.string,
  }).isRequired,
};
AsyncCsvDownloadLink.defaultProps = {
};
export default AsyncCsvDownloadLink;
