import React, { ReactElement } from "react";
import Button from "./button";

interface Props {
  errorMessages: string[];
  onClose: () => void;
}

export default function FixedModalErrorContainer(
  props: Props
): ReactElement | null {
  const { errorMessages, onClose } = props;

  if (errorMessages.length === 0) {
    return null;
  }
  return (
    <div
      style={{
        position: "fixed",
        left: 0,
        right: 0,
        zIndex: 1000,
        padding: 15,
      }}
    >
      <div className="d-flex justify-content-between align-items-center p-2 alert-danger alert">
        <div className="alert-msg">
          {errorMessages.map((mes, i) => {
            const keyIndex = i;
            return (
              <React.Fragment key={keyIndex}>
                <span>{mes}</span>
                <br />
              </React.Fragment>
            );
          })}
        </div>
        <Button className="cursor__pointer p-2" onClick={onClose}>
          <span aria-hidden="true">&times;</span>
        </Button>
      </div>
    </div>
  );
}
