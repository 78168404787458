import React from 'react';
import PropTypes from 'prop-types';
import MenuIcon from '../../shared/images/ic_three_point_leader.png';
import ActionModal from './menu_button/action_modal';

class MenuButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
    this.closeModal = this.closeModal.bind(this);
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  render() {
    const { node,afterCreated, afterEdited, afterDeleted } = this.props;
    const { showModal } = this.state;
    return (
      <React.Fragment>
        {showModal && (
          <ActionModal
            node={node}
            showModal={showModal}
            onCloseButtonClicked={this.closeModal}
            afterCreated={afterCreated}
            afterEdited={afterEdited}
            afterDeleted={afterDeleted}
          />
        )}
        <span
          role="button"
          tabIndex={0}
          style={{
            fontSize: 14, cursor: 'pointer', color: 'gray', marginRight: 5,
          }}
          onClick={() => this.setState({ showModal: true })}
        >
          <img src={MenuIcon} alt="menu icon" style={{ height: 17, marginTop: -2 }} />
        </span>
      </React.Fragment>
    );
  }
}

MenuButton.propTypes = {
  node: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  afterCreated: PropTypes.func.isRequired,
  afterEdited: PropTypes.func.isRequired,
  afterDeleted: PropTypes.func.isRequired,
};
MenuButton.defaultProps = {
};
export default MenuButton;
