import PropTypes from "prop-types";
import React from "react";
import HiddenForm from "../../shared/form/hidden_form";
import SorableIcon from "../../shared/images/ic_sortable.png";
import EditLink from "./duty_list_item/edit_link";

class DutyListItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { duty, action, canSort, position } = this.props;
    return (
      <li
        className="duty-list__duty-item"
        style={{ cursor: canSort ? "pointer" : "" }}
      >
        <HiddenForm
          scope="company"
          relatedModelName="duties"
          index={position}
          columnName="id"
          value={duty.id}
        />
        <HiddenForm
          scope="company"
          relatedModelName="duties"
          index={position}
          columnName="position"
          value={position}
        />
        {duty.name}
        {!canSort && (
          <div style={{ float: "right" }}>
            <EditLink duty={duty} action={action} />
          </div>
        )}
        {canSort && (
          <img
            src={SorableIcon}
            alt="sortable icon"
            style={{ height: 17, marginTop: 2, float: "right" }}
          />
        )}
        <div style={{ float: "right", padding: "3px 15px 0px 15px" }}>
          {`${duty.members_count}人`}
        </div>
      </li>
    );
  }
}

DutyListItem.propTypes = {
  action: PropTypes.string.isRequired,
  duty: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  canSort: PropTypes.bool.isRequired,
  position: PropTypes.number.isRequired,
};
DutyListItem.defaultProps = {};
export default DutyListItem;
