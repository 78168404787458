import React, { ReactElement } from "react";

interface Props {
  isWorkHappinessPage: boolean;
  isFirstPage: boolean;
}

export default function Copyright(props: Props): ReactElement {
  const { isWorkHappinessPage, isFirstPage } = props;
  let text = window.I18n.t(
    "javascript.questionnaires.happiness_questionnaire.right"
  );
  if (isWorkHappinessPage) {
    text = window.I18n.t("view.work_happiness_copyright");
  }

  return (
    <React.Fragment>
      <div className="happiness-questionnaire__copyright-box">
        {text}
        {isFirstPage && (
          <React.Fragment>
            <br />
            <span className="font__size-11">
              {window.I18n.t(
                "javascript.questionnaires.happiness_questionnaire.diener_right"
              )}
            </span>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
}
