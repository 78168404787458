import PropTypes from "prop-types";
import React from "react";
import { ReactSVG } from "react-svg";
import EditIcon from "../../shared/images/edit.svg";
import MemberEditModal from "../shared/member_edit_modal";

class EditLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  render() {
    const {
      member,
      authOptions,
      eventAuthOptions,
      roleOptions,
      managerialPositionOptions,
      dutyOptions,
      memberActionPath,
      userActionPath,
      departmentOptions,
      departments,
      currentUser,
      afterSaved,
      isEventAuthEditable,
      company,
    } = this.props;
    const { showModal } = this.state;
    return (
      <React.Fragment>
        {showModal && (
          <MemberEditModal
            showModal={showModal}
            currentUser={currentUser}
            member={member}
            departments={departments}
            onCloseButtonClicked={() => this.closeModal()}
            memberActionPath={memberActionPath}
            userActionPath={userActionPath}
            roleOptions={roleOptions}
            authOptions={authOptions}
            eventAuthOptions={eventAuthOptions}
            managerialPositionOptions={managerialPositionOptions}
            dutyOptions={dutyOptions}
            departmentOptions={departmentOptions}
            isEventAuthEditable={isEventAuthEditable}
            company={company}
            afterSaved={(m) => {
              this.setState({ showModal: false }, () => {
                afterSaved(m);
              });
            }}
          />
        )}
        <ReactSVG
          src={EditIcon}
          beforeInjection={(svg) => {
            svg.classList.add("member-index__edit-icon");
          }}
          onClick={() => this.setState({ showModal: true })}
        />
      </React.Fragment>
    );
  }
}

EditLink.propTypes = {
  memberActionPath: PropTypes.string.isRequired,
  userActionPath: PropTypes.string.isRequired,
  currentUser: PropTypes.shape({
    id: PropTypes.number,
  }),
  member: PropTypes.shape({
    id: PropTypes.number,
  }),
  roleOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  authOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  eventAuthOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  managerialPositionOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  dutyOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  departmentOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  departments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ).isRequired,
  afterSaved: PropTypes.func.isRequired,
  isEventAuthEditable: PropTypes.bool.isRequired,
  company: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};
EditLink.defaultProps = {
  member: {},
};
export default EditLink;
