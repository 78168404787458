import React, { ReactElement, useEffect, useRef, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { isMobile } from "react-device-detect";
import Button from "../../shared/button";

interface Props {
  disabled?: boolean;
  onSend: (message: string) => void;
}

export default function ChatInput(props: Props): ReactElement {
  const { disabled = false, onSend } = props;
  const [refreshingTextarea, setRefreshingTextarea] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (refreshingTextarea) {
      setRefreshingTextarea(false);
    }
  }, [refreshingTextarea]);

  useEffect(() => {
    if (!disabled) {
      if (textareaRef.current !== null) {
        textareaRef.current.focus();
      }
    }
  }, [disabled]);

  const sendMessage = () => {
    if (textareaRef.current !== null) {
      const val = textareaRef.current?.value;
      const fixedVal = val.trim();
      if (fixedVal !== "") {
        onSend(fixedVal);
        setRefreshingTextarea(true);
      } else {
        window.alert("文字が入力されていません");
      }
    }
  };

  return (
    <>
      <div className="d-flex p-2">
        <Button
          title="次へ"
          disabled={disabled}
          className="btn button__light-slim"
          onClick={() => {
            onSend("次へ");
            setRefreshingTextarea(true);
          }}
        />
      </div>
      <div className="d-flex justify-content-between p-2 mb-2">
        <div className="flex-fill">
          {!refreshingTextarea && (
            <TextareaAutosize
              ref={textareaRef}
              className="form-control shadow-sm"
              style={{ width: "100%", resize: "none" }}
              minRows={2}
              maxRows={10}
              placeholder="回答を入力してください"
              disabled={disabled}
              onKeyDown={(e) => {
                if (
                  !isMobile &&
                  e.code === "Enter" &&
                  !e.nativeEvent.isComposing &&
                  !e.nativeEvent.shiftKey
                ) {
                  e.preventDefault();
                  sendMessage();
                }
              }}
            />
          )}
        </div>
        <div className="d-flex align-items-end ml-2">
          <Button
            title="送信"
            className="btn button__primary"
            onClick={sendMessage}
            disabled={disabled}
          />
        </div>
      </div>
    </>
  );
}
