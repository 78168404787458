import React from 'react';
import PropTypes from 'prop-types';
import SharingActionItem from './sharing_action_slide/sharing_action_item';

class SharingActionSlide extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { sharingActions } = this.props;
    if (sharingActions.length === 0) {
      return null;
    }
    return (
      <div
        id="informationForAll"
        className="carousel slide h-100"
        data-ride="carousel"
      >
        <div className="carousel-inner">
          {sharingActions.map((sa, i) => (
            <div key={sa.id} className={`carousel-item ${i === 0 ? 'active' : ''}`}>
              <SharingActionItem sharingAction={sa} />
            </div>
          ))}
        </div>
        <a
          className="carousel-control-prev"
          href="#informationForAll"
          role="button"
          data-slide="prev"
        >
          <span className="carousel-control-prev-icon" aria-hidden="true" />
          <span className="sr-only">Previous</span>
        </a>
        <a
          className="carousel-control-next"
          href="#informationForAll"
          role="button"
          data-slide="next"
        >
          <span className="carousel-control-next-icon" aria-hidden="true" />
          <span className="sr-only">Next</span>
        </a>
      </div>
    );
  }
}

SharingActionSlide.propTypes = {
  sharingActions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
};
SharingActionSlide.defaultProps = {
};
export default SharingActionSlide;
