import React from "react";
import PropTypes from "prop-types";
import Button from "../../../shared/button";
import { putRequest } from "../../../../utils/api_client";

class PasswordResetForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.reset = this.reset.bind(this);
  }

  reset() {
    const {
      userActionPath,
      member: { user },
      beforeApiRequest,
      afterApiRequest,
    } = this.props;
    if (
      window.confirm(
        `${user.account_name}さん(${user.email})のパスワードをリセットしてよろしいですか？`
      )
    ) {
      beforeApiRequest(() => {
        putRequest(`${userActionPath}/${user.id}/reset_password`)
          .then((r) => {
            afterApiRequest(() => {
              window.alert(
                `${user.account_name}さん(${user.email})のパスワードリセットを完了しました`
              );
            });
          })
          .catch((e) => {
            afterApiRequest(() => {
              window.alert("エラーが発生しました");
            });
          });
      });
    }
  }

  render() {
    const {
      member: { user },
    } = this.props;
    if (!user.dummy_email_flg) {
      return null;
    }
    if (!user.initial_password_for_dummy) {
      return null;
    }
    return (
      <div
        className="alert alert-light d-flex justify-content-between align-items-center"
        role="alert"
        style={{ padding: "0.25rem 0rem" }}
      >
        <div className="font__size-15">
          {`ダミーメールアドレス「${user.email}」を利用しているメンバーです`}
          <br />
          {`(リセットした場合は、初期パスワード「${user.initial_password_for_dummy}」に戻ります)`}
        </div>
        <Button
          title="初期パスワードにリセット"
          className="btn btn-light btn-sm"
          onClick={this.reset}
        />
      </div>
    );
  }
}

PasswordResetForm.propTypes = {
  userActionPath: PropTypes.string.isRequired,
  member: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  beforeApiRequest: PropTypes.func.isRequired,
  afterApiRequest: PropTypes.func.isRequired,
};
PasswordResetForm.defaultProps = {};
export default PasswordResetForm;
