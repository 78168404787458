import React from 'react';
import PropTypes from 'prop-types';
import ReplyBorder from '../../../../shared/reply_border';
import ReplyBody from './shared/reply_body';
import CommentInput from '../../../../comment_input';
import { deleteRequest } from '../../../../../../utils/api_client';
import CommentReaction from '../../shared/comment_reaction';

class MyReply extends React.Component {
  constructor(props) {
    super(props);
    const { comment } = this.props;
    this.state = {
      comment,
      editing: false,
      deleted: false,
    };
    this.delete = this.delete.bind(this);
    this.changeReaction = this.changeReaction.bind(this);
  }

  delete() {
    const { comment } = this.props;
    if (window.confirm('本当にコメントを削除してよろしいですか？')) {
      deleteRequest(`/wb/user/comments/${comment.id}`)
        .then(r => this.setState({ deleted: true }))
        .catch((e) => {
          window.alert('削除に失敗しました。しばらく時間をおいて実行をお願いします');
        });
    }
  }

  changeReaction(reacted) {
    const { comment } = this.state;
    this.setState({
      comment: Object.assign(comment, {
        reacted_good: reacted,
        good_reactions_count: reacted ?
          comment.good_reactions_count + 1 : comment.good_reactions_count - 1,
      }),
    });
  }

  render() {
    const { currentUser } = this.props;
    const { comment, editing, deleted } = this.state;
    if (deleted) {
      return null;
    }
    if (editing) {
      return (
        <React.Fragment>
          <div className="d-flex">
            <ReplyBorder />
            <CommentInput
              currentUser={currentUser}
              comment={comment}
              baseUrl="/wb/user/comments"
              afterSaved={c => this.setState({ comment: c, editing: false })}
            />
          </div>
          <div className="d-flex">
            <ReplyBorder />
            <div style={{ width: 55 }} />
            <div className="flex-fill font__size-14 ml-2 pb-3">
              <span
                role="button"
                className="cursor__pointer color__dark-blue"
                onClick={() => this.setState({ editing: false })}
              >
                キャンセル
              </span>
              <span className="color__gray">
                ・
              </span>
              <span
                role="button"
                className="cursor__pointer color__dark-blue"
                onClick={this.delete}
              >
                削除
              </span>
            </div>
          </div>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <ReplyBody comment={comment} />
        <div className="d-flex">
          <ReplyBorder />
          <div style={{ width: 55 }} />
          <div className="flex-fill font__size-14 ml-2 pb-3">
            <div className="position-relative w-100">
              <CommentReaction
                comment={comment}
                onEditButtonClicked={() => this.setState({ editing: true })}
                onGoodButtonClicked={this.changeReaction}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

MyReply.propTypes = {
  currentUser: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  comment: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};
MyReply.defaultProps = {
};
export default MyReply;
