import React from 'react';
import PropTypes from 'prop-types';

class RecommendWorkList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { recommendWorks, renderItem } = this.props;
    if (recommendWorks.length === 0) {
      return null;
    }
    return (
      <div className="card my-1" style={{ borderLeft: 'none', borderRight: 'none', borderBottom: 'none' }}>
        <div className="card-header border-right border-left" style={{ borderRadius: 10 }}>
          オススメ
        </div>
        <div
          className="d-flex flex-row flex-wrap"
        >
          {recommendWorks.map(rw => renderItem(rw.work))}
        </div>
      </div>
    );
  }
}

RecommendWorkList.propTypes = {
  recommendWorks: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
  renderItem: PropTypes.func.isRequired,
};
RecommendWorkList.defaultProps = {
};
export default RecommendWorkList;
