import React, { ReactElement } from "react";
import QuestionForm from "../../../../questions/question_form";
import MultiLineText from "../../../../shared/multi_line_text";
import ParentSelectQuestionForm from "../../../../questions/parent_select_question_form";
import { QuestionnaireNewAnswerResponse } from "../../../../../models/AnswerApi";

interface Props {
  answers: QuestionnaireNewAnswerResponse[];
}
export default function QuestionsForm(props: Props): ReactElement {
  const { answers } = props;
  const answerList = answers.map((answer, index) => {
    if (answer.question.answer_format === "parent_select_format") {
      const childAnswer = answers.filter(
        (a) =>
          a.question.answer_format === "child_select_format" &&
          a.question.parent_id === answer.question.id
      )[0];
      return (
        <ParentSelectQuestionForm
          key={`answers_${answer.question.id}`}
          scope="questionnaire"
          index={index}
          question={answer.question}
          answer={answer}
          childAnswer={childAnswer}
          childIndex={1000 + index}
        />
      );
    }
    if (answer.question.answer_format === "child_select_format") {
      return null;
    }
    return (
      <React.Fragment key={`answers_${answer.question.id}`}>
        <QuestionForm
          scope="questionnaire"
          index={index}
          question={answer.question}
          answer={answer}
          labelComponent={
            <React.Fragment>
              {["title_format"].includes(answer.question.answer_format) && (
                <div className="font-weight-bold text-center mt-4 font__size-16">
                  <MultiLineText text={answer.question.body} />
                </div>
              )}
              {["description_format"].includes(
                answer.question.answer_format
              ) && (
                <div className="color__gray text-center mt-2 font__size-14">
                  <MultiLineText text={answer.question.body} />
                </div>
              )}
              {["text_format", "select_format", "check_format"].includes(
                answer.question.answer_format
              ) && (
                <div className="font-weight-bold text-center mt-4">
                  <MultiLineText
                    text={`${answer.question.body}${
                      answer.question.required_flg
                        ? ` ${window.I18n.t(
                            "javascript.questionnaires.happiness_questionnaire.required"
                          )}`
                        : ""
                    }`}
                  />
                </div>
              )}
            </React.Fragment>
          }
        />
      </React.Fragment>
    );
  });
  return <React.Fragment>{answerList}</React.Fragment>;
}
