import React from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import Button from '../../button';
import PersonIcon from '../../images/ic_person.svg';
import ClearIcon from '../../images/ic_clear.svg';

class MultipleUserActionView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  render() {
    const { users, onEditButtonClicked, onDeleteButtonClicked } = this.props;
    return (
      <React.Fragment>
        {users.length > 0 && (
          <div className="clearfix">
            <div className="mr-2 float-left p-1 m-1">
              選択済:
            </div>
            {users.map(u => (
              <div key={u.id} className="float-left p-1 m-1 px-2 border__lightgray d-flex">
                {u.account_name}
                <div>
                  <ReactSVG
                    src={ClearIcon}
                    beforeInjection={(svg) => {
                      svg.classList.add('ml-2 cursor__pointer');
                      svg.setAttribute('style', 'width: 15px;height: 15px;margin-top: -3px;');
                    }}
                    onClick={() => onDeleteButtonClicked(u)}
                  />
                </div>
              </div>
            ))}
            <Button
              onClick={() => onEditButtonClicked()}
              className="btn btn-light d-flex p-1 m-1 float-left"
              style={{ width: 68 }}
            >
              <ReactSVG
                src={PersonIcon}
                svgStyle={{ width: 20, height: 20, marginTop: -3 }}
              />
              <span>
                追加
              </span>
            </Button>
          </div>
        )}
        {users.length === 0 && (
          <Button
            onClick={() => onEditButtonClicked()}
            className="btn btn-light d-flex my-2"
            style={{ width: 140 }}
          >
            <ReactSVG src={PersonIcon} />
            <div style={{ padding: 2 }}>
              ユーザ選択
            </div>
          </Button>
        )}
      </React.Fragment>
    );
  }
}
MultipleUserActionView.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
  onEditButtonClicked: PropTypes.func.isRequired,
  onDeleteButtonClicked: PropTypes.func.isRequired,
};
MultipleUserActionView.defaultProps = {
};
export default MultipleUserActionView;
