import PropTypes from "prop-types";
import React from "react";
import axios from "axios";

// form: form object
//  ex: document.forms.{formName} で取得した結果
export function ajaxSubmit(form) {
  const formData = new FormData(form);
  return axios({
    method: form.method,
    url: `${form.action}.json`,
    data: formData,
  });
}

class BaseForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      action,
      method,
      authenticityToken,
      children,
      name,
      type,
      className,
      style,
    } = this.props;

    return (
      <form
        name={name}
        action={action}
        method={method === "get" ? "get" : "post"}
        onSubmit={(e) => {
          if (type === "ajax") {
            e.preventDefault();
          }
        }}
        className={className}
        style={style}
      >
        <input name="utf8" type="hidden" value="✓" />
        {authenticityToken && (
          <input
            type="hidden"
            name="authenticity_token"
            value={authenticityToken}
          />
        )}
        {(method === "put" || method === "patch") && (
          <input type="hidden" name="_method" value="put" />
        )}
        {method === "delete" && (
          <input type="hidden" name="_method" value="delete" />
        )}
        {children}
      </form>
    );
  }
}
BaseForm.propTypes = {
  name: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  method: PropTypes.string.isRequired,
  authenticityToken: PropTypes.string,
  children: PropTypes.node.isRequired,
  type: PropTypes.string, // ajax or normal
  className: PropTypes.string,
  style: PropTypes.shape({}),
};
BaseForm.defaultProps = {
  authenticityToken: axios.defaults.headers["X-CSRF-TOKEN"],
  type: "ajax",
  className: "",
  style: {},
};
export default BaseForm;
