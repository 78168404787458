import React from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import Button from './button';
import SearchIcon from './images/ic_search.svg';

class KeywordInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
    };
    this.changeText = this.changeText.bind(this);
    this.sendCommand = this.sendCommand.bind(this);
    this.commitKeyword = this.commitKeyword.bind(this);
  }

  changeText(e) {
    this.setState({ value: e.target.value });
  }

  sendCommand(e) {
    const ENTER = 13;
    if (e.keyCode === ENTER) {
      e.preventDefault();
      this.commitKeyword();
    }
  }

  commitKeyword() {
    const { onSubmitted } = this.props;
    const { value } = this.state;
    onSubmitted(value);
  }

  render() {
    const { placeholder, className } = this.props;
    return (
      <div
        className={className}
        style={{ borderRadius: 5, overflow: 'hidden', height: 50 }}
      >
        <div className="input-group">
          <input
            type="text"
            className="form-control border__none"
            placeholder={placeholder}
            onChange={this.changeText}
            onKeyDown={this.sendCommand}
          />
          <div>
            <Button
              className="btn"
              onClick={this.commitKeyword}
            >
              <ReactSVG
                src={SearchIcon}
                beforeInjection={(svg) => {
                  svg.classList.add('icon__size-25 icon__path-primary');
                }}
              />
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
KeywordInput.propTypes = {
  onSubmitted: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  className: PropTypes.string,
};
KeywordInput.defaultProps = {
  className: 'w-100 position-relative border__lightgray bgcolor__white p-1',
};
export default KeywordInput;
