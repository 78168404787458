import React from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
import { getRequest } from '../../../utils/api_client';
import UserItem from './user_list/user_item';
import Loading from '../../shared/loading';
import KeywordInput from '../../shared/keyword_input';

class UserList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      loading: false,
      hasMore: true,
      userNameKeyword: '',
      renderCount: 0,
    };
    this.loadMore = this.loadMore.bind(this);
  }

  changeUserNameKeyword(userNameKeyword) {
    const { renderCount } = this.state;
    this.setState({
      users: [],
      loading: false,
      hasMore: true,
      userNameKeyword,
      renderCount: renderCount + 1,
    });
  }

  loadMore(page) {
    const { users: oldUsers, userNameKeyword } = this.state;
    this.setState({ loading: true }, () => {
      getRequest(
        '/wb/user/company/users',
        {
          page,
          q: {
            account_name_cont: userNameKeyword,
          },
        },
      )
        .then((r) => {
          const { data: newUsers } = r;
          this.setState({
            loading: false,
            users: oldUsers.concat(newUsers),
            hasMore: newUsers.length > 0,
          });
        })
        .catch((e) => {
          this.setState({ loading: false, hasMore: false });
        });
    });
  }

  render() {
    const { onSelected } = this.props;
    const {
      loading, hasMore, users, renderCount,
    } = this.state;
    return (
      <React.Fragment>
        <KeywordInput
          onSubmitted={v => this.changeUserNameKeyword(v)}
          placeholder="名前"
          className="w-100 position-relative border__lightgray mb-2 bgcolor__white p-1"
        />
        <InfiniteScroll
          key={renderCount}
          pageStart={0}
          loadMore={this.loadMore}
          hasMore={!loading && hasMore}
          loader={<div className="p-2 color__gray" key={0}>Loading ...</div>}
          useWindow={false}
        >
          {users.map(u => (
            <UserItem
              key={u.id}
              user={u}
              onClick={() => onSelected(u)}
            />
          ))}
        </InfiniteScroll>
        <Loading loading={users.length === 0 && loading} />
        {(users.length === 0 && !loading) && (
          <div className="p-2 color__gray">
            ユーザーが存在しません
          </div>
        )}
      </React.Fragment>
    );
  }
}

UserList.propTypes = {
  onSelected: PropTypes.func.isRequired,
};
UserList.defaultProps = {
};
export default UserList;
