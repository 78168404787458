import PropTypes from 'prop-types';
import React from 'react';
import BaseMoal from '../base_modal';
import RadioList from './shared/radio_list';

class SyncCsvDownloadLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      linkPath: this.initialLikPath(),
    };
  }

  initialLikPath() {
    const { radioLinkCategories } = this.props;
    const category = radioLinkCategories[0];
    if (category) {
      const content = category.contents[0];
      return content.link_path;
    }
    return '';
  }

  openModal() {
    this.setState({ showModal: true });
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  render() {
    const { radioLinkCategories } = this.props;
    const { showModal, linkPath } = this.state;

    if (showModal) {
      return (
        <BaseMoal
          show={showModal}
          title="CSVの種類を選択してください"
          contentLabel="csv_link_select_modal"
          onCloseModal={() => this.closeModal()}
        >
          <BaseMoal.Body>
            <RadioList
              radioLinkCategories={radioLinkCategories}
              linkPath={linkPath}
              onChangeLinkPath={p => this.setState({ linkPath: p })}
            />
          </BaseMoal.Body>
          <div className="modal-footer">
            <button type="button" className="btn btn-light" onClick={() => this.closeModal()}>
              閉じる
            </button>
            <a
              className="btn button__primary button__link"
              href={linkPath}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => this.closeModal()}
            >
              ダウンロード
            </a>
          </div>
        </BaseMoal>
      );
    }
    return (
      <span
        role="button"
        style={{ fontSize: 14, cursor: 'pointer' }}
        onClick={() => this.openModal()}
      >
        CSVダウンロード
      </span>
    );
  }
}
SyncCsvDownloadLink.propTypes = {
  radioLinkCategories: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    contents: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      link_path: PropTypes.string.isRequired,
    })),
  })).isRequired,
};
SyncCsvDownloadLink.defaultProps = {
};
export default SyncCsvDownloadLink;
