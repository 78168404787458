import React from 'react';
import PropTypes from 'prop-types';
import Button from '../button';
import FileField from './file_form/file_field';
import FileUploader from './file_form/file_uploader';

function fileListToArray(fileList) {
  const arr = [];
  for (let i = 0; i < fileList.length; i += 1) {
    arr.push(fileList[i]);
  }
  return arr;
}

// image_formでも当該formと同様にfile_resources_attributesをキーとして利用している。
// file_resources_attributes[i]のiに当たるindexがimage_formのものと重複する可能性があるため、
// file_formでは上記iについて、100から始まるindexを利用することにする。
const baseFileResourceIndex = 100;

class FileForm extends React.Component {
  constructor(props) {
    super(props);
    const { model, innerRef } = this.props;
    let fileResources = [];
    if (model.file_resources) {
      fileResources = model.file_resources.filter(fr => fr.file_type !== 'image_file_type');
    }
    this.state = {
      fileResources,
      uploadingFiles: [],
    };
    this.inputRef = innerRef;
    this.handleChange = this.handleChange.bind(this);
  }

  clickFileInput() {
    if (this.inputRef && this.inputRef.current) {
      this.inputRef.current.click();
    }
  }

  addUrl(fileIndex, url, name) {
    const { afterUpload } = this.props;
    const { fileResources, uploadingFiles } = this.state;
    const files = uploadingFiles.concat();
    files.splice(fileIndex, 1, null);
    const frs = fileResources.concat({ url, file_name: name });
    this.setState({
      fileResources: frs,
      uploadingFiles: files,
    }, () => {
      if (files.filter(f => f).length === 0) {
        afterUpload(frs);
      }
    });
  }

  handleChange(e) {
    const { beforeUpload } = this.props;
    const { uploadingFiles } = this.state;
    const input = e.target;
    const arr = fileListToArray(input.files);
    if (arr.length > 0) {
      if (beforeUpload) {
        beforeUpload(() => {
          this.setState({
            uploadingFiles: uploadingFiles.concat(arr),
          }, () => {
            input.value = null;
          });
        });
      } else {
        this.setState({
          uploadingFiles: uploadingFiles.concat(arr),
        }, () => {
          input.value = null;
        });
      }
    }
  }

  remove(index) {
    const { fileResources } = this.state;
    const frs = fileResources.concat();
    frs.splice(index, 1, null);
    this.setState({ fileResources: frs });
  }

  uploadFailed(index) {
    const { afterUpload } = this.props;
    const { uploadingFiles, fileResources } = this.state;
    const files = uploadingFiles.concat();
    files.splice(index, 1, null);
    this.setState({
      uploadingFiles: files,
    }, () => {
      if (files.filter(f => f).length === 0) {
        afterUpload(fileResources);
      }
    });
  }

  availableFileExists() {
    const { fileResources, uploadingFiles } = this.state;
    const existsFileResource = fileResources.filter(fr => fr).length > 0;
    const existsUploadingFile = uploadingFiles.filter(uf => uf).length > 0;
    if (existsFileResource || existsUploadingFile) {
      return true;
    }
    return false;
  }

  render() {
    const { scope, renderHeader } = this.props;
    const { fileResources, uploadingFiles } = this.state;

    const viewer = () => {
      if (!this.availableFileExists()) {
        return null;
      }
      return (
        <React.Fragment>
          {renderHeader()}
          {fileResources.map((fr, i) => {
            if (!fr) {
              return null;
            }
            const keyIndex = i;
            return (
              <FileField
                key={keyIndex}
                scope={scope}
                fileResource={fr}
                index={baseFileResourceIndex + i}
                onNotSavedImageRemoved={() => this.remove(i)}
              />
            );
          })}
          {uploadingFiles.map((file, i) => {
            if (!file) {
              return null;
            }
            const keyIndex = i;
            return (
              <FileUploader
                key={keyIndex}
                file={file}
                afterUploaded={url => this.addUrl(i, url, file.name)}
                afterFailed={() => this.uploadFailed(i)}
              />
            );
          })}
          <Button
            className="btn color__gray"
            style={{
              float: 'right',
              fontSize: 14,
              paddingTop: 3,
              paddingBottom: 3,
            }}
            onClick={() => this.clickFileInput()}
          >
            ファイル追加
          </Button>
        </React.Fragment>
      );
    };

    return (
      <React.Fragment>
        {viewer()}
        <input
          ref={this.inputRef}
          value=""
          type="file"
          onChange={this.handleChange}
          style={{ display: 'none' }}
          multiple
        />
      </React.Fragment>
    );
  }
}

FileForm.propTypes = {
  renderHeader: PropTypes.func,
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  scope: PropTypes.string.isRequired,
  model: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  beforeUpload: PropTypes.func,
  afterUpload: PropTypes.func,
};
FileForm.defaultProps = {
  renderHeader: () => {},
  innerRef: {},
  model: {},
  beforeUpload: null,
  afterUpload: () => {},
};

export default React.forwardRef((props, ref) => (
  <FileForm {...props} innerRef={ref} />
));
