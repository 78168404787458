import React from 'react';
import PropTypes from 'prop-types';
import MultiLineText from '../../../shared/multi_line_text';

class ParentSelectFormatLabel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  render() {
    const {
      questions,
      question,
    } = this.props;

    const childQuestion = questions.filter(q => (
      q.answer_format === 'child_select_format' &&
      q.parent_id === question.id))[0];

    return (
      <React.Fragment>
        <div>
          <MultiLineText
            text={question.body}
          />
        </div>
        {childQuestion && (
          <div className="ml-2">
            <span className="pr-1">└</span>
            <MultiLineText
              text={childQuestion.body}
            />
          </div>
        )}
      </React.Fragment>
    );
  }
}

ParentSelectFormatLabel.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
  question: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};
ParentSelectFormatLabel.defaultProps = {
};
export default ParentSelectFormatLabel;
