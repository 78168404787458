import React from "react";
import PropTypes from "prop-types";
import Button from "../../shared/button";
import BaseMoal from "../../shared/base_modal";
import BaseForm, { ajaxSubmit } from "../../shared/base_form";
import TextareaForm from "../../shared/form/textarea_form";
import HiddenForm from "../../shared/form/hidden_form";
import CheckboxForm from "../../shared/form/checkbox_form";
import ActionPlanForm from "./looking_back_note_form/looking_back_action_plan_form";
import HighRateHappinessRecipeForm from "./looking_back_note_form/high_rate_happiness_recipe_form";
import RaisedRateHappinessRecipeForm from "./looking_back_note_form/raised_rate_happiness_recipe_form";
import { happinessElementName } from "../../../utils/happiness_element_utils";
import ChallengeForm from "./looking_back_note_form/challenge_form";
import SelectForm from "../../shared/form/select_form";

const baseUrl = "/user/questionnaires";

class LookingBackNoteForm extends React.Component {
  constructor(props) {
    super(props);
    const { questionnaire } = this.props;
    this.state = {};
    this.save = this.save.bind(this);
  }

  save() {
    const { beforeSaved, afterSaved, afterFailed } = this.props;
    const form = document.forms.questionnaireNoteEditForm;
    if (form.checkValidity()) {
      beforeSaved(() => {
        ajaxSubmit(form)
          .then((r) => {
            const { data: questionnaire } = r;
            afterSaved(questionnaire);
          })
          .catch((e) => {
            // const { data, status } = e.response;
            afterFailed();
          });
      });
    } else {
      // 検証結果がfalseなのでsubmitしてhtml5のvalidationメッセージを表示させる
      const submit = document.getElementById("questionnaireNoteEditSubmit");
      submit.click();
    }
  }

  render() {
    const { questionnaire, onCloseButtonClicked, currentUser } = this.props;
    return (
      <BaseForm
        name="questionnaireNoteEditForm"
        action={`${baseUrl}/${questionnaire.id}`}
        method="put"
      >
        <HiddenForm
          scope="questionnaire"
          columnName="id"
          value={questionnaire.id}
        />
        <BaseMoal.Body style={{ padding: 0 }}>
          <RaisedRateHappinessRecipeForm questionnaire={questionnaire} />
          <HighRateHappinessRecipeForm questionnaire={questionnaire} />
          <div className="font-weight-bold bgcolor__trivial p-2">
            今回の結果に対してあなたは、どう感じましたか？
          </div>
          <div className="p-3">
            <TextareaForm
              scope="questionnaire"
              model={questionnaire}
              columnName="looking_back_note"
              rows={4}
              hideLabel
            />
          </div>

          <ChallengeForm questionnaire={questionnaire} />
          <ActionPlanForm
            purpose="increase_strength_purpose"
            questionnaire={questionnaire}
          />
          <ActionPlanForm
            purpose="reduce_weaknesses_purpose"
            questionnaire={questionnaire}
          />
          <div className="px-3 pb-3">
            <div className="font__size-14">
              上記の強みを伸ばすアクションプラン、
              <br />
              幸せになる余地のある項目を伸ばすアクションプラン
              <br />
              を、メールでリマインドしますか？
              <br />
              （2週間後にメールが送信されます）
            </div>
            <div className="mt-2">
              <SelectForm
                model={questionnaire}
                scope="questionnaire"
                options={[
                  { key: "true", value: "はい" },
                  { key: "false", value: "いいえ" },
                ]}
                hideLabel
                selectedKey={
                  questionnaire.looking_back_note_action_plan_remind_mail_flg
                    ? "true"
                    : "false"
                }
                columnName="looking_back_note_action_plan_remind_mail_flg"
              />
            </div>
          </div>
          <div className="font-weight-bold bgcolor__trivial p-2">メモ</div>
          <div className="p-3">
            <TextareaForm
              scope="questionnaire"
              model={questionnaire}
              columnName="note"
              rows={4}
              hideLabel
            />
          </div>
          <div className="p-3">
            {(questionnaire.event_paticipant_id ||
              !currentUser.is_consumer) && (
              <div className="color__gray font__size-14">
                {window.I18n.t(
                  "javascript.questionnaires.questionnaire_notes.publish_scope"
                )}
              </div>
            )}
            {questionnaire.event_paticipant_id && (
              <CheckboxForm
                title={window.I18n.t(
                  "activerecord.attributes.questionnaire.note_event_owner_visible"
                )}
                scope="questionnaire"
                columnName="note_event_owner_visible"
                defaultChecked={questionnaire.note_event_owner_visible}
              />
            )}
            {!currentUser.is_consumer && (
              <CheckboxForm
                title={window.I18n.t(
                  "activerecord.attributes.questionnaire.note_business_admin_visible"
                )}
                scope="questionnaire"
                columnName="note_business_admin_visible"
                defaultChecked={questionnaire.note_business_admin_visible}
              />
            )}
          </div>
        </BaseMoal.Body>
        <div
          className="modal-footer"
          style={{ justifyContent: "space-between" }}
        >
          <button
            type="button"
            className="btn btn-link btn-sm color__gray"
            onClick={() => onCloseButtonClicked()}
          >
            {window.I18n.t(
              "javascript.questionnaires.questionnaire_notes.close_modal"
            )}
          </button>
          <button
            type="submit"
            id="questionnaireNoteEditSubmit"
            style={{ border: "none", padding: 0 }}
          />
          <Button
            title={window.I18n.t(
              "javascript.questionnaires.questionnaire_notes.save_form"
            )}
            className="btn button__primary"
            onClick={this.save}
          />
        </div>
      </BaseForm>
    );
  }
}

LookingBackNoteForm.propTypes = {
  questionnaire: PropTypes.shape({
    id: PropTypes.number,
  }),
  onCloseButtonClicked: PropTypes.func.isRequired,
  beforeSaved: PropTypes.func.isRequired,
  afterSaved: PropTypes.func.isRequired,
  afterFailed: PropTypes.func.isRequired,
  currentUser: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};
LookingBackNoteForm.defaultProps = {
  questionnaire: {},
};
export default LookingBackNoteForm;
