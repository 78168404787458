import React from 'react';
import PropTypes from 'prop-types';
import HiddenForm from '../shared/form/hidden_form';
import SelectWithTextForm from './question_form/select_with_text_form';
import MultiLineText from '../shared/multi_line_text';

class ChildSelectQuestionForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  form() {
    const {
      question, scope, answer, index, parentChoiceId,
    } = this.props;
    if (!question.answer_format) {
      return null;
    }
    const [answerChoice] = answer.answer_choices || [];
    if (question.disabled && !answerChoice) {
      return null;
    }
    return (
      <React.Fragment>
        <SelectWithTextForm
          scope={scope}
          answer={answer}
          answerChoice={answerChoice}
          index={index}
          question={answer.question}
          choices={answer.question.choices.filter(c => c.parent_id === parentChoiceId)}
        />
      </React.Fragment>
    );
  }

  render() {
    const {
      question, scope, answer, index, parentChoiceId,
    } = this.props;

    return (
      <React.Fragment>
        {answer.id && (
          <HiddenForm
            scope={scope}
            relatedModelName="answers"
            index={index}
            columnName="id"
            value={answer.id}
          />
        )}
        <HiddenForm
          scope={scope}
          relatedModelName="answers"
          index={index}
          columnName="question_id"
          value={question.id}
        />
        {parentChoiceId && (
          <React.Fragment>
            <div className="font-weight-bold text-center mt-4">
              <MultiLineText
                text={`${answer.question.body}${answer.question.required_flg ? ` ${window.I18n.t('javascript.questionnaires.happiness_questionnaire.required')}` : ''}`}
              />
            </div>
            <div>
              {this.form()}
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

ChildSelectQuestionForm.propTypes = {
  parentChoiceId: PropTypes.number,
  question: PropTypes.shape({
    id: PropTypes.number,
  }),
  scope: PropTypes.string.isRequired,
  answer: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  index: PropTypes.number,
};
ChildSelectQuestionForm.defaultProps = {
  parentChoiceId: null,
  question: {},
  answer: {},
  index: -1,
};
export default ChildSelectQuestionForm;
