import React from 'react';
import PropTypes from 'prop-types';
import PostItem from '../../../posts/post_item';
import WorkPostItem from '../../../work_posts/work_post_item';

class TimelineItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      currentUser, timeline, userProfile, keyword,
    } = this.props;
    switch (timeline.timeline_registerable_type) {
      case 'WorkPost':
        return (
          <WorkPostItem
            currentUser={currentUser}
            user={timeline.user}
            workPost={timeline.work_post}
            myselfUserProfile={userProfile}
            keyword={keyword}
          />
        );
      case 'Post':
        return (
          <PostItem
            currentUser={currentUser}
            user={timeline.user}
            post={timeline.post}
            keyword={keyword}
          />
        );
      default:
        return null;
    }
  }
}

TimelineItem.propTypes = {
  currentUser: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  timeline: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  userProfile: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  keyword: PropTypes.string,
};
TimelineItem.defaultProps = {
  keyword: '',
};
export default TimelineItem;
