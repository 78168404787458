import React from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import NotificationIcon from '../shared/images/ic_notification.svg';
import NotificationList from './notification_list';
import NotificationAllReadedButton from './notification_all_readed_button';

class NotificationToggleButton extends React.Component {
  constructor(props) {
    super(props);
    const { notificationCount } = this.props;
    this.state = {
      showDialog: false,
      notificationCount,
    };
    this.toggle = this.toggle.bind(this);
    this.refresh = this.refresh.bind(this);
  }

  toggle() {
    const { showDialog } = this.state;
    this.setState({ showDialog: !showDialog, notificationCount: 0 });
  }

  refresh() {
    this.setState({ showDialog: false }, () => {
      this.setState({ showDialog: true });
    });
  }

  render() {
    const { showDialog, notificationCount } = this.state;
    return (
      <React.Fragment>
        <div className="nav-link position-relative">
          <ReactSVG
            src={NotificationIcon}
            beforeInjection={(svg) => {
              svg.classList.add(`
              cursor__pointer application-header__notification-icon
              ${showDialog ? 'application-header__notification-icon__active' : ''}
            `);
            }}
            onClick={this.toggle}
          />
          {notificationCount > 0 && (
            <span
              className="badge badge__notification position-absolute"
              style={{ top: 2, right: 3 }}
            >
              {notificationCount}
            </span>
          )}
        </div>
        <div className="position-relative">
          {showDialog && (
            <React.Fragment>
              <div
                style={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                }}
                onClick={this.toggle}
              />
              <div
                className="position-absolute card"
                style={{
                  top: 0,
                  right: -30,
                  width: 430,
                  maxHeight: 450,
                  overflow: 'auto',
                }}
              >
                <div className="p-2 font__size-14">
                  <span>お知らせ</span>
                  <NotificationAllReadedButton
                    className="float-right"
                    afterSaved={this.refresh}
                  />
                </div>
                <NotificationList />
              </div>
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    );
  }
}

NotificationToggleButton.propTypes = {
  notificationCount: PropTypes.number,
};
NotificationToggleButton.defaultProps = {
  notificationCount: 0,
};
export default NotificationToggleButton;
