import React, { useState } from "react";
import SelectForm from "../../../../../../shared/form/select_form";
import { ReactElement } from "react";
import { UserProfileMyselfResponse } from "../../../../../../../models/UserProfile";
import { IndustryCategoryWithIndustriesResponse } from "../../../../../../../models/IndustryCategoryApi";

interface Props {
  userProfile: UserProfileMyselfResponse | null;
  industryCategories: IndustryCategoryWithIndustriesResponse[];
}

export default function IndustryForm(props: Props): ReactElement {
  const { userProfile, industryCategories } = props;
  const [selectedIndustryCategoryId, setSelectedIndustryCategoryId] = useState(
    userProfile?.industry_category_id !== undefined &&
      userProfile.industry_category_id !== null
      ? String(userProfile.industry_category_id)
      : ""
  );

  const industryCategoryOptions = () => {
    return industryCategories.map((ic) => ({
      key: String(ic.id),
      value: ic.name,
    }));
  };

  const industryOptions = () => {
    if (!selectedIndustryCategoryId) {
      return [];
    }
    const category = industryCategories.filter(
      (ic) => String(ic.id) === selectedIndustryCategoryId
    )[0];
    if (!category) {
      return [];
    }
    return category.industries.map((i) => ({
      key: String(i.id),
      value: i.name,
    }));
  };

  return (
    <React.Fragment>
      <SelectForm
        scope="questionnaire"
        model={userProfile}
        relatedModelName="user_profile"
        columnName="industry_category_id"
        options={industryCategoryOptions()}
        option={{ include_blank: "-- 未選択 --" }}
        onChange={(e) => setSelectedIndustryCategoryId(e.target.value)}
      />
      {selectedIndustryCategoryId && (
        <SelectForm
          scope="questionnaire"
          model={userProfile}
          relatedModelName="user_profile"
          columnName="industry_id"
          options={industryOptions()}
          option={{ include_blank: "-- 未選択 --" }}
        />
      )}
    </React.Fragment>
  );
}
