import PropTypes from "prop-types";
import React from "react";
import BaseForm, { ajaxSubmit } from "../../shared/base_form";
import BaseMoal from "../../shared/base_modal";
import Button from "../../shared/button";
import FixedModalErrorContainer from "../../shared/fixed_modal_error_container";
import InitialSettingMailResendForm from "./member_edit_modal/initial_setting_mail_resend_form";
import MemberEditForm from "./member_edit_modal/member_edit_form";
import PasswordResetForm from "./member_edit_modal/password_reset_form";
import UnlockAccessForm from "./member_edit_modal/unlock_access_form";

class MemberEditModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isFormChanged: false,
      errorMessages: [],
      editingApplyingDate: false,
    };
    this.save = this.save.bind(this);
    this.close = this.close.bind(this);
  }

  save() {
    const { afterSaved } = this.props;
    const form = document.forms.memberEditForm;
    if (form.checkValidity()) {
      this.setState({ loading: true }, () => {
        ajaxSubmit(form)
          .then((r) => {
            const editedMember = r.data;
            this.setState({ loading: false }, () => {
              afterSaved(editedMember);
            });
          })
          .catch((e) => {
            const { data, status } = e.response;
            if (status === 402) {
              const { errors } = data;
              this.setState({
                loading: false,
                errorMessages: errors,
              });
            } else {
              this.setState({ loading: false }, () => {
                window.alert(
                  "エラーが発生しました。しばらく時間をおいて再度操作をお願いします。"
                );
              });
            }
          });
      });
    } else {
      // 検証結果がfalseなのでsubmitしてhtml5のvalidationメッセージを表示させる
      const submit = document.getElementById("submit");
      submit.click();
    }
  }

  initializeState(callback) {
    this.setState(
      {
        loading: false,
        errorMessages: [],
        editingApplyingDate: false,
      },
      callback
    );
  }

  close() {
    const { onCloseButtonClicked } = this.props;
    this.initializeState(() => onCloseButtonClicked());
  }

  render() {
    const {
      memberActionPath,
      userActionPath,
      showModal,
      onCloseButtonClicked,
      currentUser,
      authOptions,
      eventAuthOptions,
      roleOptions,
      departments,
      managerialPositionOptions,
      dutyOptions,
      departmentOptions,
      isEventAuthEditable,
      member,
      company,
    } = this.props;
    const { loading, errorMessages, editingApplyingDate, isFormChanged } =
      this.state;
    return (
      <React.Fragment>
        <BaseMoal
          show={showModal}
          title="メンバー設定"
          contentLabel="edit_member"
          onCloseModal={() => onCloseButtonClicked()}
          loading={loading}
        >
          <BaseForm
            name="memberEditForm"
            action={`${memberActionPath}/${member.id}`}
            method="put"
          >
            <BaseMoal.Body fixed>
              <div
                className="container-fluid position-absolute__all-screen"
                style={{ overflow: editingApplyingDate ? "hidden" : "auto" }}
              >
                <FixedModalErrorContainer
                  errorMessages={errorMessages}
                  onClose={() => {
                    this.setState({ errorMessages: [] });
                  }}
                />
                <PasswordResetForm
                  member={member}
                  userActionPath={userActionPath}
                  beforeApiRequest={(callback) =>
                    this.setState({ loading: true }, callback)
                  }
                  afterApiRequest={(callback) =>
                    this.setState({ loading: false }, callback)
                  }
                />
                <UnlockAccessForm
                  member={member}
                  userActionPath={userActionPath}
                  beforeApiRequest={(callback) =>
                    this.setState({ loading: true }, callback)
                  }
                  afterApiRequest={(callback) =>
                    this.setState({ loading: false }, callback)
                  }
                />
                {company.exists_current_contract_period && (
                  <InitialSettingMailResendForm
                    member={member}
                    userActionPath={userActionPath}
                    beforeApiRequest={(callback) =>
                      this.setState({ loading: true }, callback)
                    }
                    afterApiRequest={(callback) =>
                      this.setState({ loading: false }, callback)
                    }
                  />
                )}
                <MemberEditForm
                  company={company}
                  member={member}
                  currentUser={currentUser}
                  authOptions={authOptions}
                  eventAuthOptions={eventAuthOptions}
                  managerialPositionOptions={managerialPositionOptions}
                  dutyOptions={dutyOptions}
                  departmentOptions={departmentOptions}
                  isAdmin={member.isAdmin}
                  departments={departments}
                  roleOptions={roleOptions}
                  isEventAuthEditable={isEventAuthEditable}
                  applyingDateEditLinkClicked={() =>
                    this.setState({ editingApplyingDate: true })
                  }
                  onFormChanged={() => this.setState({ isFormChanged: true })}
                />
              </div>
            </BaseMoal.Body>
            <div className="modal-footer">
              <button
                type="submit"
                id="memberEditSubmit"
                style={{ border: "none", padding: 0 }}
              />
              <button
                type="button"
                className="btn btn-light"
                onClick={this.close}
              >
                閉じる
              </button>
              <Button
                title="保存"
                className="btn button__primary"
                onClick={this.save}
                disabled={!isFormChanged}
              />
            </div>
          </BaseForm>
        </BaseMoal>
      </React.Fragment>
    );
  }
}

MemberEditModal.propTypes = {
  currentUser: PropTypes.shape({
    id: PropTypes.number,
  }),
  member: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  roleOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  authOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  eventAuthOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  managerialPositionOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  dutyOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  memberActionPath: PropTypes.string.isRequired,
  userActionPath: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired,
  onCloseButtonClicked: PropTypes.func.isRequired,
  departmentOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  departments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ).isRequired,
  afterSaved: PropTypes.func.isRequired,
  isEventAuthEditable: PropTypes.bool.isRequired,
  company: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};
MemberEditModal.defaultProps = {};
export default MemberEditModal;
