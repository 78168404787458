import React from 'react';
import PropTypes from 'prop-types';
import BaseMoal from '../shared/base_modal';
import UserList from './user_select_modal/user_list';

class UserSelectModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const {
      showModal, onCloseButtonClicked, onSelected,
    } = this.props;
    return (
      <React.Fragment>
        <BaseMoal
          show={showModal}
          title="ユーザー選択"
          contentLabel="selectUser"
          onCloseModal={() => onCloseButtonClicked()}
        >
          <BaseMoal.Body fixed>
            <div style={{ height: '100%', overflow: 'auto' }}>
              <UserList
                onSelected={u => onSelected(u)}
              />
            </div>
          </BaseMoal.Body>
        </BaseMoal>
      </React.Fragment>
    );
  }
}

UserSelectModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onCloseButtonClicked: PropTypes.func.isRequired,
  onSelected: PropTypes.func.isRequired,
};
UserSelectModal.defaultProps = {
};
export default UserSelectModal;
