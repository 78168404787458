import React, { ReactElement } from "react";
import { QuestionnaireNewUserResponse } from "../../../../../../models/UserApi";
import { QuestionnaireNewResponse } from "../../../../../../models/QuestionnaireApi";

interface Props {
  user: QuestionnaireNewUserResponse | null;
  isConsumer: boolean;
  questionnaire: QuestionnaireNewResponse;
}

export default function UserForm(props: Props): ReactElement | null {
  const { user, isConsumer, questionnaire } = props;
  if (!isConsumer) {
    return null;
  }
  return (
    <React.Fragment key="userFormBox">
      <div className="form-group row">
        <label
          htmlFor="questionnaire_user_attributes_account_name"
          className="col-sm-4 col-form-label"
        >
          {window.I18n.t("activerecord.attributes.questionnaire.nickname")}
          <span className="ml-2 font__size-13 color__gray">
            {window.I18n.t(
              "javascript.questionnaires.happiness_questionnaire.nickname_limit"
            )}
          </span>
        </label>
        <div className="col-sm-8">
          <input
            type="text"
            className="form-control"
            id="questionnaire_nickname"
            name="questionnaire[nickname]"
            placeholder=""
            maxLength={16}
            defaultValue={questionnaire.nickname || user?.account_name || ""}
          />
        </div>
      </div>
    </React.Fragment>
  );
}
