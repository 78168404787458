import React, { ReactElement } from "react";
import PropTypes from "prop-types";
import { format, differenceInDays, parseISO } from "date-fns";
import Button from "../../../shared/button";
import { QuestionnaireNewResponse } from "../../../../models/QuestionnaireApi";

interface Props {
  previousQuestionnaire: QuestionnaireNewResponse;
  onSelectedInputByMyself: () => void;
  onSelectedUsingPreviousQuestionnaire: () => void;
}

export default function ReuseForm(props: Props): ReactElement {
  const {
    previousQuestionnaire,
    onSelectedInputByMyself,
    onSelectedUsingPreviousQuestionnaire,
  } = props;

  return (
    <React.Fragment>
      <div className="container-fluid" style={{ textAlign: "center" }}>
        <p>
          前回(測定日:
          {format(parseISO(previousQuestionnaire.created_at), "yyyy年MM月dd日")}
          )の結果を"複製して"利用できます。
        </p>
        {differenceInDays(
          new Date(),
          parseISO(previousQuestionnaire.created_at)
        ) > 30 && (
          <p>
            前回計測は1ヶ月以上前に実施されているので、再度診断される事をオススメします。
          </p>
        )}
        <div className="row" style={{ marginTop: 50 }}>
          <div className="col-6">
            <Button
              onClick={() => onSelectedInputByMyself()}
              title={
                <span>
                  新しく
                  <br />
                  測定を行う
                </span>
              }
              className="btn btn-light"
              style={{ width: "80%", maxWidth: 300 }}
            />
          </div>
          <div className="col-6">
            <Button
              onClick={() => onSelectedUsingPreviousQuestionnaire()}
              title={
                <span>
                  前回結果を
                  <br />
                  利用する
                </span>
              }
              className="btn button__primary"
              style={{ width: "80%", maxWidth: 300 }}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
