import React, { ReactElement, useEffect, useState } from "react";
import Button from "../../shared/button";
import { getRequestAsync } from "../../../utils/api_client";
import Loading from "../../shared/loading";
import { InteractiveWorkResponse } from "../../../models/InteractiveWork";
import ChatHistoryItem from "./chat_history_item";
import BaseForm from "../../shared/base_form";

interface Props {
  currentInteractiveWork: InteractiveWorkResponse;
}

export default function ChatScreenMenuArea(props: Props): ReactElement {
  const { currentInteractiveWork } = props;
  const [interactiveWorkHistories, setInteractiveWorkHistories] = useState<
    InteractiveWorkResponse[]
  >([]);

  useEffect(() => {
    loadHistories();
  }, []);

  const loadHistories = async (): Promise<void> => {
    const { result } = await getRequestAsync<InteractiveWorkResponse[]>(
      "/user/interactive_works"
    );
    if (result !== undefined) {
      setInteractiveWorkHistories(result);
    }
  };

  const destroy = async (): Promise<void> => {
    if (window.confirm("このワークを削除してよろしいですか？")) {
      const submit = document.getElementById("chatDestroySubmitOfMobile");
      if (submit !== null) {
        submit.click();
      }
    }
  };

  return (
    <div className="chat-screen__menu-container">
      <div className="chat-screen__menu-top-container">
        <div className="chat-screen__interactive-work-destroy-button-container">
          <BaseForm
            name="chatDestroyFormOfMobile"
            action={`/user/interactive_works/${currentInteractiveWork.id}`}
            method="delete"
            type="normal"
            style={{ height: 20 }}
          >
            <div>
              <Button
                title="削除"
                className="btn button__light"
                onClick={() => {
                  destroy().catch((e) => console.log(e));
                }}
              />
            </div>
            <button
              type="submit"
              id="chatDestroySubmitOfMobile"
              style={{ border: "none", padding: 0 }}
            />
          </BaseForm>
        </div>
        <div>
          <Button
            title="履歴"
            className="btn button__light chat-screen__interactive-work-history-button"
            onClick={() => {
              window.location.href = `/user/interactive_works/${currentInteractiveWork.id}/histories`;
            }}
          />
          <Button
            title="新規対話ワーク"
            className="btn button__light chat-screen__interactive-work-new-button"
            onClick={() => {
              window.location.href = "/user/interactive_works/new";
            }}
          />
        </div>
      </div>
      <div className="chat-screen__menu-history-container">
        <div className="font__size-13 color__gray mt-1">履歴</div>
        {interactiveWorkHistories.length === 0 && (
          <div className="position-relative" style={{ height: 50 }}>
            <Loading loading={true} width={20} height={20} />
          </div>
        )}
        {interactiveWorkHistories.map((iwh) => {
          return (
            <ChatHistoryItem
              key={iwh.id}
              interactiveWork={iwh}
              isCurrentWork={iwh.id === currentInteractiveWork.id}
            />
          );
        })}
        {interactiveWorkHistories.length > 19 && (
          <div className="text-right mt-2">
            <Button
              className="color__link font__size-13 cursor__pointer"
              title="もっとみる"
              onClick={() => {
                window.location.href = `/user/interactive_works/${currentInteractiveWork.id}/histories`;
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}
