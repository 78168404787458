import React from 'react';
import PropTypes from 'prop-types';
import TextareaForm from '../shared/form/textarea_form';
import TextForm from '../shared/form/text_form';
import UserForm from '../shared/form/user_form';
import HiddenForm from '../shared/form/hidden_form';
import ImageForm from '../shared/form/image_form';
import { baseScope } from '../shared/form/utils/attribute_utils';
import CheckboxWithTextForm from './question_form/checkbox_with_text_form';
import SelectWithTextForm from './question_form/select_with_text_form';

class QuestionForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  form() {
    const {
      question, scope, answer, index,
    } = this.props;
    if (!question.answer_format) {
      return null;
    }
    if (question.answer_format === 'text_format') {
      if (question.disabled && !answer.body) {
        return null;
      }
      return (
        <TextareaForm
          scope={scope}
          model={answer}
          relatedModelName="answers"
          columnName="body"
          index={index}
          required={question.required_flg}
          hideLabel
        />
      );
    } else if (question.answer_format === 'string_format') {
      if (question.disabled && !answer.body) {
        return null;
      }
      return (
        <TextForm
          scope={scope}
          model={answer}
          relatedModelName="answers"
          columnName="body"
          index={index}
          required={question.required_flg}
          hideLabel
        />
      );
    } else if (question.answer_format === 'select_format') {
      const [answerChoice] = answer.answer_choices || [];
      if (question.disabled && !answerChoice) {
        return null;
      }
      return (
        <SelectWithTextForm
          scope={scope}
          answer={answer}
          answerChoice={answerChoice}
          index={index}
          question={question}
        />
      );
    } else if (question.answer_format === 'check_format') {
      if (question.disabled && answer.answer_choices.length === 0) {
        return null;
      }
      return (
        <React.Fragment>
          {question.choices.map((c, i) => {
            const [answerChoice] = (answer.answer_choices || [])
              .filter(ac => ac.choice.id === c.id);
            if (question.disabled && !answerChoice) {
              return null;
            }
            return (
              <CheckboxWithTextForm
                key={c.id}
                scope={scope}
                answerChoice={answerChoice}
                choice={c}
                answerIndex={index}
                index={i}
              />
            );
          })}
        </React.Fragment>
      );
    } else if (question.answer_format === 'single_user_format') {
      if (question.disabled && answer.answer_users.length === 0) {
        return null;
      }
      return (
        <UserForm
          scope={baseScope(scope, 'answers', index)}
          model={answer}
          relatedModelName="answer_users"
          columnName="user_id"
        />
      );
    } else if (question.answer_format === 'multi_users_format') {
      if (question.disabled && answer.answer_users.length === 0) {
        return null;
      }
      return (
        <UserForm
          scope={baseScope(scope, 'answers', index)}
          model={answer}
          relatedModelName="answer_users"
          columnName="user_id"
          multiple
        />
      );
    } else if (question.answer_format === 'single_image_format') {
      if (question.disabled && answer.file_resources.length === 0) {
        return null;
      }
      return (
        <ImageForm
          scope={baseScope(scope, 'answers', index)}
          model={answer}
          showFormAlways
        />
      );
    } else if (question.answer_format === 'multi_images_format') {
      if (question.disabled && answer.file_resources.length === 0) {
        return null;
      }
      return (
        <ImageForm
          scope={baseScope(scope, 'answers', index)}
          model={answer}
          multiple
          showFormAlways
        />
      );
    }
    return null;
  }

  render() {
    const {
      question, scope, answer, index,
      labelComponent,
    } = this.props;
    const form = this.form();

    if (!['title_format', 'description_format'].includes(question.answer_format) && !form) {
      return null;
    }

    return (
      <React.Fragment>
        {answer.id && (
          <HiddenForm
            scope={scope}
            relatedModelName="answers"
            index={index}
            columnName="id"
            value={answer.id}
          />
        )}
        <HiddenForm
          scope={scope}
          relatedModelName="answers"
          index={index}
          columnName="question_id"
          value={question.id}
        />
        {labelComponent}
        {!labelComponent && (
          <div className="color__gray">
            {question.body}
            {question.required_flg && (
              <span className="ml-2 font__size-13">
                ※必須
              </span>
            )}
          </div>
        )}
        {answer.errors && answer.errors.base && (
          <div className="invalid-feedback d-block">
            {answer.errors.base.map((msg, i) => {
              const keyIndex = i;
              return (
                <span key={keyIndex}>
                  {msg}
                </span>
              );
            })}
          </div>
        )}
        <div>
          {form}
        </div>
      </React.Fragment>
    );
  }
}

QuestionForm.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number,
  }),
  scope: PropTypes.string.isRequired,
  answer: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  index: PropTypes.number,
  labelComponent: PropTypes.node,
};
QuestionForm.defaultProps = {
  question: {},
  answer: {},
  index: -1,
  labelComponent: null,
};
export default QuestionForm;
