export function happinessCategoryName(he) {
  const happinessCategories = Object.entries(
    window.I18n.t("enums.happiness_question.happiness_category")
  );
  let target = "";
  for (let i = 0; i < happinessCategories.length; i += 1) {
    const [key, name] = happinessCategories[i];
    const regexp = new RegExp(`${key}_.*`);
    if (he.match(regexp)) {
      target = name.replace(/\r?\n/g, "");
      break;
    }
  }
  return target;
}

export default happinessCategoryName;
