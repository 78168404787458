import React from 'react';
import PropTypes from 'prop-types';
import BaseMoal from '../../shared/base_modal';
import LookingBackNoteForm from './looking_back_note_form';

class LookingBackNoteEditModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  render() {
    const {
      showModal, onCloseButtonClicked, questionnaire,
      afterSaved, currentUser,
    } = this.props;
    const { loading } = this.state;
    return (
      <React.Fragment>
        <BaseMoal
          show={showModal}
          title="幸せの振り返り"
          contentLabel="editQuestionnaireLookingBackNote"
          onCloseModal={() => onCloseButtonClicked()}
          loading={loading}
          contentStyle={{ maxWidth: 496, width: '100%' }}
        >
          <LookingBackNoteForm
            questionnaire={questionnaire}
            currentUser={currentUser}
            beforeSaved={callback => this.setState({ loading: true }, callback)}
            afterSaved={q => this.setState({ loading: false }, afterSaved(q))}
            afterFailed={() => this.setState({ loading: false })}
            onCloseButtonClicked={() => onCloseButtonClicked()}
          />
        </BaseMoal>
      </React.Fragment>
    );
  }
}

LookingBackNoteEditModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  questionnaire: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  onCloseButtonClicked: PropTypes.func.isRequired,
  afterSaved: PropTypes.func.isRequired,
  currentUser: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};
LookingBackNoteEditModal.defaultProps = {
};
export default LookingBackNoteEditModal;
