import PropTypes from "prop-types";
import React from "react";
import { labelBgColor } from "../../question_categories/question_category_label";
import QuestionListModal from "../../questions/question_list_modal";
import MultiLineText from "../../shared/multi_line_text";
import OtherAnswersNavigationLink from "../../shared/other_answers_navigation_link";

class QuestionSelectBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  question() {
    const { selectedQuestionId, questions } = this.props;
    return questions.filter((q) => String(q.id) === selectedQuestionId)[0];
  }

  render() {
    const {
      questions,
      action,
      selectedQuestionId,
      additionalFormInputHtml,
      showQuestionnaireNote,
      selectedQuestionnaireEventAnswerTiming,
      compLinkUrl,
      compLinkTitle,
    } = this.props;
    const { showModal } = this.state;
    const question = this.question();
    if (!question) {
      return null;
    }
    return (
      <React.Fragment>
        <QuestionListModal
          showModal={showModal}
          questions={questions}
          action={action}
          additionalFormInputHtml={additionalFormInputHtml}
          selectedQuestionId={selectedQuestionId}
          selectedQuestionnaireEventAnswerTiming={
            selectedQuestionnaireEventAnswerTiming
          }
          onCloseButtonClicked={() => this.setState({ showModal: false })}
          showQuestionnaireNote={showQuestionnaireNote}
        />
        <div className="card">
          <div className="card-body">
            <OtherAnswersNavigationLink
              linkUrl={compLinkUrl}
              linkTitle={compLinkTitle}
            />
            <div className="answer-list__question-container">
              <div className="answer-list__question-label">
                {window.I18n.t(
                  "javascript.answers.happiness_questionnaire_answer_list.question_body"
                )}
              </div>
              <div
                role="button"
                tabIndex="0"
                className="answer-list__question-select-box custom-select"
                onClick={() => this.setState({ showModal: true })}
              >
                {selectedQuestionnaireEventAnswerTiming === "before_event" && (
                  <span
                    className="label__default mr-3"
                    style={{
                      backgroundColor: labelBgColor({
                        id: "dummy",
                        key: "only_before_event",
                      }),
                    }}
                  >
                    イベント前
                  </span>
                )}
                {selectedQuestionnaireEventAnswerTiming === "after_event" && (
                  <span
                    className="label__default mr-3"
                    style={{
                      backgroundColor: labelBgColor({
                        id: "dummy",
                        key: "only_after_event",
                      }),
                    }}
                  >
                    イベント後
                  </span>
                )}
                <MultiLineText text={question.body} />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

QuestionSelectBox.propTypes = {
  action: PropTypes.string.isRequired,
  selectedQuestionId: PropTypes.string,
  selectedQuestionnaireEventAnswerTiming: PropTypes.string.isRequired,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ).isRequired,
  additionalFormInputHtml: PropTypes.string.isRequired,
  showQuestionnaireNote: PropTypes.bool,
  compLinkTitle: PropTypes.string,
  compLinkUrl: PropTypes.string,
};
QuestionSelectBox.defaultProps = {
  showQuestionnaireNote: false,
  selectedQuestionId: "-1",
  compLinkTitle: "",
  compLinkUrl: "",
};
export default QuestionSelectBox;
