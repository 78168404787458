import React from 'react';
import PropTypes from 'prop-types';
import BaseForm from '../shared/base_form';
import TextForm from '../shared/form/text_form';
import TextareaForm from '../shared/form/textarea_form';
import SelectField from '../shared/form/fields/select_field';

class InitialMailEditForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.changeEmailLocale = this.changeEmailLocale.bind(this);
  }

  changeEmailLocale(e) {
    const { editUrl } = this.props;
    window.location.href = `${editUrl}?email_locale=${e.target.value}`;
  }

  render() {
    const {
      mailSubject, mailBody, emailLocale, updateUrl, submitButtonClass,
    } = this.props;

    return (
      <BaseForm
        name="initialMailForm"
        type="normal"
        method="put"
        action={updateUrl}
      >
        <div className="js-edit-page-template__body-header-area">
          <div className="js-edit-page-template__header-action-container">
            <div className="d-flex justify-content-end">
              <SelectField
                id="email_locale_selector"
                name="email_locale_selector"
                options={[{ key: 'ja', value: '日本語' }, { key: 'en', value: '英語' }]}
                selectedKey={emailLocale}
                onChange={this.changeEmailLocale}
              />
              <input
                type="submit"
                name="commit"
                value="更新"
                className={submitButtonClass}
                data-disable-with="保存中..."
              />
            </div>
          </div>
        </div>
        <div className="container">
          <ul className="list-group list-group-flush">
            <li className="list-group-item">
              <div className=" row">
                <div className=" col-sm-2 color__gray">
                  件名
                </div>
                <div className="col-sm-10">
                  <TextForm
                    model={{ [`initial_email_subject_${emailLocale}`]: mailSubject }}
                    scope="company"
                    relatedModelName="company_mail_template"
                    columnName={`initial_email_subject_${emailLocale}`}
                    hideLabel
                    required
                  />
                </div>
              </div>
            </li>
            <li className=" list-group-item">
              <div className=" row">
                <div className=" col-sm-2 color__gray">
                  本文
                </div>
                <div className=" col-sm-10">
                  <div className="p-2 font__size-14">
                    マークダウン記法が利用できます。<br />
                    また、本文には以下の変数(二重の中括弧を含んだ文字列)が利用できます。<br />
                    {'{{company_name}}'}: 会社名<br />
                    {'{{account_name}}'}: ユーザの名前<br />
                    {'{{deadline_date}}'}: 計測終了日<br />
                    {'{{email}}'}: ユーザのメールアドレス<br />
                    {'{{password_setting_link}}'}: ユーザ別のパスワード設定リンク
                  </div>
                  <TextareaForm
                    scope="company"
                    relatedModelName="company_mail_template"
                    columnName={`initial_email_body_template_${emailLocale}`}
                    model={{ [`initial_email_body_template_${emailLocale}`]: mailBody }}
                    rows={30}
                    hideLabel
                    required
                  />
                </div>
              </div>
            </li>
          </ul>
        </div>
      </BaseForm>
    );
  }
}

InitialMailEditForm.propTypes = {
  mailSubject: PropTypes.string.isRequired,
  mailBody: PropTypes.string.isRequired,
  emailLocale: PropTypes.string.isRequired,
  editUrl: PropTypes.string,
  updateUrl: PropTypes.string,
  submitButtonClass: PropTypes.string,
};
InitialMailEditForm.defaultProps = {
  editUrl: '/user/company/edit_initial_email',
  updateUrl: '/user/company/update_initial_email',
  submitButtonClass: 'btn button__primary mx-3',
};
export default InitialMailEditForm;
