import PropTypes from 'prop-types';
import React from 'react';

class TextField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const {
      containerClass, containerStyle, required,
      id, name, value, className, placeholder,
      disabled, inputStyle, dataValue, readOnly,
    } = this.props;

    return (
      <div className={containerClass} style={containerStyle}>
        <input
          className={`form-control ${className}`}
          type="text"
          defaultValue={value}
          name={name}
          id={id}
          placeholder={placeholder}
          required={required}
          disabled={disabled}
          style={inputStyle}
          data-value={dataValue}
          readOnly={readOnly}
        />
      </div>
    );
  }
}
TextField.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  containerClass: PropTypes.string,
  containerStyle: PropTypes.shape({
    styleKey: PropTypes.string,
  }),
  value: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  inputStyle: PropTypes.shape({
    styleKey: PropTypes.string,
  }),
  dataValue: PropTypes.string,
  readOnly: PropTypes.bool,
};

TextField.defaultProps = {
  containerClass: '',
  containerStyle: {},
  value: '',
  className: '',
  placeholder: '',
  required: false,
  disabled: false,
  inputStyle: {},
  dataValue: '',
  readOnly: false,
};
export default TextField;
