import PropTypes from "prop-types";
import React from "react";
import { uploadImage } from "../../../../../utils/aws_s3_service";
import LoadingImage from "../../../../shared/images/spin-gray.svg";
import { cleanUrl } from "../../../../../utils/string_utils";

class CoverImageField extends React.Component {
  constructor(props) {
    super(props);
    const { value } = this.props;
    this.state = {
      uploading: false,
      selectedImageUrl: value,
      selectedImageDataUri: null,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    const { beforeUploading, afterUploading } = this.props;
    const input = e.target;
    const file = input.files[0];
    uploadImage(
      file,
      "user/cover_image",
      () => {
        this.setState({ uploading: true }, () => {
          beforeUploading();
        });
        input.value = null;
      },
      ({ url, dataUri }) => {
        this.setState(
          {
            uploading: false,
            selectedImageUrl: url,
            selectedImageDataUri: dataUri,
          },
          () => {
            afterUploading();
          }
        );
      },
      () => {
        this.setState({ uploading: false }, () => {
          afterUploading();
          window.alert("画像の処理に失敗しました");
        });
      },
      { maxWidth: 500, maxHeight: 500 }
    );
  }

  render() {
    const {
      scope,
      relatedModelName,
      columnName,
      containerClass,
      containerStyle,
    } = this.props;
    const { selectedImageUrl, uploading, selectedImageDataUri } = this.state;

    return (
      <div className={containerClass} style={containerStyle}>
        <input
          type="hidden"
          value={cleanUrl(selectedImageUrl)}
          id={`${scope}_${relatedModelName}_attributes_${columnName}`}
          name={`${scope}[${relatedModelName}_attributes][${columnName}]`}
        />
        <div>
          {uploading && (
            <img
              src={LoadingImage}
              style={{
                width: 80,
                height: 80,
              }}
            />
          )}
          {!uploading && !(selectedImageDataUri || selectedImageUrl) && (
            <div
              style={{
                backgroundColor: "black",
                width: 80,
                height: 80,
                margin: "auto",
              }}
            />
          )}
          {!uploading && (selectedImageDataUri || selectedImageUrl) && (
            <img
              src={selectedImageDataUri || selectedImageUrl}
              alt="coverImage"
              style={{ width: 80, height: 80 }}
            />
          )}
        </div>
        <label
          htmlFor="coverImage"
          style={{
            backgroundColor: "white",
            border: "solid 1px #ced4da",
            borderRadius: 5,
            marginTop: 5,
          }}
        >
          <span className="btn btn-default">
            {window.I18n.t("javascript.user_profiles.form.select_image")}
            <input
              id="coverImage"
              type="file"
              onChange={this.handleChange}
              accept="image/*"
              style={{ display: "none" }}
            />
          </span>
        </label>
      </div>
    );
  }
}
CoverImageField.propTypes = {
  scope: PropTypes.string.isRequired,
  relatedModelName: PropTypes.string.isRequired,
  columnName: PropTypes.string.isRequired,
  containerClass: PropTypes.string,
  containerStyle: PropTypes.shape({
    styleKey: PropTypes.string,
  }),
  beforeUploading: PropTypes.func,
  afterUploading: PropTypes.func,
  value: PropTypes.string,
};
CoverImageField.defaultProps = {
  containerClass: "",
  containerStyle: {},
  value: "",
  beforeUploading: () => {},
  afterUploading: () => {},
};
export default CoverImageField;
