import React from 'react';
import PropTypes from 'prop-types';
import UserOverviewCard from '../../users/user_overview_card';
import TimelineFilter from './timeline_left_container/timeline_filter';

class TimelineLeftContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { onQueryUpdated, currentUser } = this.props;
    return (
      <div className="company-timeline-index__left-container">
        <UserOverviewCard
          user={currentUser}
        />
        <TimelineFilter
          onQueryUpdated={q => onQueryUpdated(q)}
        />
      </div>
    );
  }
}
TimelineLeftContainer.propTypes = {
  onQueryUpdated: PropTypes.func.isRequired,
  currentUser: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};
TimelineLeftContainer.defaultProps = {
};
export default TimelineLeftContainer;
