import React, { ReactElement } from "react";
import { useDrag } from "react-dnd";
import { happinessElementColor } from "../../../utils/happiness_element_utils";

interface Props {
  id: string;
  happinessElement: string;
  title: string;
  left: number;
  top: number;
}

export default function Label(props: Props): ReactElement {
  const { title, happinessElement, id, top, left } = props;

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: "label",
      item: { id, left, top },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [id, left, top]
  );

  return (
    <div
      className="driver-chart__data-label"
      ref={drag}
      style={{
        top,
        left,
        backgroundColor: happinessElementColor(happinessElement, 0.7),
        opacity: isDragging ? 0.6 : 1,
        cursor: "move",
      }}
    >
      {title}
    </div>
  );
}
