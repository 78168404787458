import React from "react";
import PropTypes from "prop-types";
import EventItem from "./event_management_list/event_item";

class EventManagementList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      events,
      answerTimingOptions,
      questionnaireMethodOptions,
      action,
      authenticityToken,
      roleOptions,
      workHappinessQuestionnaireUsable,
      profileInputBirthYearOptions,
      profileInputBirthMonthOptions,
      profileInputSexOptions,
      profileInputForResearchOptions,
      surveyTypeOptions,
    } = this.props;
    return (
      <div className="table-responsive-xl">
        <table className="table event-index__table">
          <thead>
            <tr>
              <td>{window.I18n.t("activerecord.attributes.event.title")}</td>
              <td>{window.I18n.t("activerecord.attributes.event.role")}</td>
              <td className="event-index__td">
                {window.I18n.t("activerecord.attributes.event.start_date")}
              </td>
              <td className="event-index__td">
                {window.I18n.t("activerecord.attributes.event.start_time")}
              </td>
              <td className="event-index__td">
                {window.I18n.t("activerecord.attributes.event.end_date")}
              </td>
              <td className="event-index__td">
                {window.I18n.t("activerecord.attributes.event.end_time")}
              </td>
              {workHappinessQuestionnaireUsable && (
                <td className="event-index__td">
                  {window.I18n.t("activerecord.attributes.event.survey_type")}
                </td>
              )}
              <td className="event-index__td">
                {window.I18n.t("activerecord.attributes.event.answer_timing")}
              </td>
              <td className="event-index__td">カスタムアンケート</td>
              <td className="event-index__td">計測依頼</td>
              <td className="event-index__td" style={{ minWidth: 100 }}>
                回答数
              </td>
              <td className="event-index__td">編集</td>
            </tr>
          </thead>
          <tbody>
            {events.map((e) => (
              <EventItem
                key={e.id}
                event={e}
                answerTimingOptions={answerTimingOptions}
                questionnaireMethodOptions={questionnaireMethodOptions}
                roleOptions={roleOptions}
                action={action}
                authenticityToken={authenticityToken}
                workHappinessQuestionnaireUsable={
                  workHappinessQuestionnaireUsable
                }
                profileInputBirthYearOptions={profileInputBirthYearOptions}
                profileInputBirthMonthOptions={profileInputBirthMonthOptions}
                profileInputSexOptions={profileInputSexOptions}
                profileInputForResearchOptions={profileInputForResearchOptions}
                surveyTypeOptions={surveyTypeOptions}
              />
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

EventManagementList.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ),
  answerTimingOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  questionnaireMethodOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  roleOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  profileInputBirthYearOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  profileInputBirthMonthOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  profileInputSexOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  profileInputForResearchOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  surveyTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  action: PropTypes.string.isRequired,
  authenticityToken: PropTypes.string.isRequired,
  workHappinessQuestionnaireUsable: PropTypes.bool,
};
EventManagementList.defaultProps = {
  events: [],
  workHappinessQuestionnaireUsable: false,
};
export default EventManagementList;
