import React from 'react';
import PropTypes from 'prop-types';
import SelectForm from '../../../../shared/form/select_form';

class IndustryForm extends React.Component {
  constructor(props) {
    super(props);
    const { userProfile } = this.props;
    this.state = {
      selectedIndustryCategoryId: userProfile.industry_category_id ?
        String(userProfile.industry_category_id) : '',
    };
  }

  industryCategoryOptions() {
    const { industryCategories } = this.props;
    return industryCategories.map(ic => ({
      key: String(ic.id),
      value: ic.name,
    }));
  }

  industryOptions() {
    const { industryCategories } = this.props;
    const { selectedIndustryCategoryId } = this.state;
    if (!selectedIndustryCategoryId) {
      return [];
    }
    const category = industryCategories
      .filter(ic => String(ic.id) === selectedIndustryCategoryId)[0];
    if (!category) {
      return [];
    }
    return category.industries.map(i => ({
      key: String(i.id),
      value: i.name,
    }));
  }

  render() {
    const { userProfile } = this.props;
    const { selectedIndustryCategoryId } = this.state;
    return (
      <React.Fragment>
        <SelectForm
          scope="user_profile_form"
          model={userProfile}
          relatedModelName="user_profile"
          columnName="industry_category_id"
          options={this.industryCategoryOptions()}
          option={{ include_blank: window.I18n.t('javascript.user_profiles.form.unselected') }}
          labelColor="gray"
          onChange={e => this.setState({
            selectedIndustryCategoryId: e.target.value,
          })}
        />
        {selectedIndustryCategoryId && (
          <SelectForm
            scope="user_profile_form"
            model={userProfile}
            relatedModelName="user_profile"
            columnName="industry_id"
            options={this.industryOptions()}
            option={{ include_blank: window.I18n.t('javascript.user_profiles.form.unselected') }}
            labelColor="gray"
          />
        )}
      </React.Fragment>
    );
  }
}

IndustryForm.propTypes = {
  userProfile: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  industryCategories: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    industries: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })),
  })).isRequired,
};
IndustryForm.defaultProps = {
};
export default IndustryForm;
