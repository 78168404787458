import PropTypes from "prop-types";
import React from "react";

class ErrorContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { errorMessages, className } = this.props;
    if (!errorMessages) {
      return null;
    }
    return (
      <div className={`invalid-feedback d-block ${className}`}>
        {errorMessages.map((mes, i) => {
          const keyIndex = i;
          return (
            <React.Fragment key={keyIndex}>
              <span>{mes}</span>
              <br />
            </React.Fragment>
          );
        })}
      </div>
    );
  }
}
ErrorContainer.propTypes = {
  errorMessages: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
};
ErrorContainer.defaultProps = {
  errorMessages: [],
  className: "",
};
export default ErrorContainer;
