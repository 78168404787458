import PropTypes from 'prop-types';
import React from 'react';
import axios from 'axios';
import Button from './button';

class FileDownloadButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      downloading: false,
    };
    this.download = this.download.bind(this);
  }

  download() {
    const { downloadUrlFetchPath } = this.props;
    this.setState({ downloading: true }, () => {
      axios({
        method: 'get',
        url: downloadUrlFetchPath,
      })
        .then((result) => {
          const { data: { url } } = result;
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('href', url);
          a.style.cssText = 'display: none';
          a.click();
          setTimeout(() => {
            document.body.removeChild(a);
            this.setState({ downloading: false });
          }, 1000);
        })
        .catch((e) => {
          this.setState({ downloading: false }, () => {
            window.alert('エラーが発生しました。大変申し訳ありませんが、しばらく時間をおいて再実行してください');
          });
        });
    });
  }

  render() {
    const { downloading } = this.state;

    return (
      <div>
        <Button
          title="ダウンロード"
          onClick={this.download}
          loading={downloading}
          className="btn button__primary btn-sm"
        />
      </div>
    );
  }
}
FileDownloadButton.propTypes = {
  downloadUrlFetchPath: PropTypes.string.isRequired,
};
FileDownloadButton.defaultProps = {
};
export default FileDownloadButton;
