import React, { ReactElement } from "react";
import { InteractiveWorkResponse } from "../../../models/InteractiveWork";
import AppStoreBadge from "../../shared/images/appstore-badge.svg";
import GooglePlayBadge from "../../shared/images/google-play-badge.png";
import HappyBirdImage from "../../shared/images/happy_bird.png";

interface Props {
  interactiveWork: InteractiveWorkResponse;
}

export default function LastChat(props: Props): ReactElement {
  const { interactiveWork } = props;

  if (interactiveWork.work_type === "good_things_work_type") {
    return (
      <div className="chat-screen__chat-message-container">
        <div className="d-flex justify-content-start">
          <div className="ml-3">
            <img src={HappyBirdImage} style={{ width: 28 }} />
          </div>
        </div>
        <div className="d-flex justify-content-start px-3 pb-3 mt-1">
          <div
            className="p-3 bgcolor__trivial rounded"
            style={{ maxWidth: "80%" }}
          >
            なお、良かった事に目を向けていく習慣を作っていくためにも、
            <br />
            継続して3つの良かったこと日記を付けていくことをオススメします。
            <br />
            はぴトレという、無償アプリもあるので、是非やってみてください。
            <br />
            こちらもハッピーAIからのコメントがもらえます。
            <br />
            (通常のハッピーAIに加え、大阪のおばちゃんやお坊さんなど種類も選択可能です。)
            <br />
            <div className="d-flex justify-content-end align-items-center mt-3">
              <div className="mx-2">
                <a
                  href="https://itunes.apple.com/jp/app/id1395327487?mt=8"
                  target="_blank"
                >
                  <img src={AppStoreBadge} style={{ height: "1.8rem" }} />
                </a>
              </div>
              <div>
                <a
                  href="https://play.google.com/store/apps/details?id=jp.happy_training.pro&hl=ja"
                  target="_blank"
                >
                  <img src={GooglePlayBadge} style={{ height: "2.6rem" }} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return <></>;
}
