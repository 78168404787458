type WorkType =
  | "good_things_work_type"
  | "four_factors_work_type"
  | "happiness_boost_work_type"
  | "develop_future_strength_work_type";

export interface InteractiveWorkResponse {
  id: number;
  work_type: WorkType;
  finished_at: string | null;
  created_at: string;
  forced_exit_flg: boolean;
  forced_exit_type:
    | "normal_exit_forced_exit_type"
    | "due_to_excess_count_forced_exit_type"
    | "due_to_excess_length_forced_exit_type";
}

export function viewWorkType(workType: WorkType): string {
  switch (workType) {
    case "good_things_work_type":
      return "良かったこと3つワーク";
    case "four_factors_work_type":
      return "幸せの4つの因子ワーク";
    case "happiness_boost_work_type":
      return "幸せブーストワーク";
    case "develop_future_strength_work_type":
      return "幸せ伸びしろワーク";
    default:
      return "-";
  }
}
