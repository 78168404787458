import React from 'react';
import PropTypes from 'prop-types';
import HiddenField from './fields/hidden_field';
import { fieldId, fieldName } from './utils/attribute_utils';

class HiddenForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const {
      scope, relatedModelName, columnName, value,
      index,
    } = this.props;
    return (
      <HiddenField
        id={fieldId(scope, relatedModelName, columnName, index)}
        name={fieldName(scope, relatedModelName, columnName, index)}
        value={value}
      />
    );
  }
}

HiddenForm.propTypes = {
  scope: PropTypes.string.isRequired,
  relatedModelName: PropTypes.string,
  columnName: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  index: PropTypes.number,
};
HiddenForm.defaultProps = {
  relatedModelName: '',
  value: '',
  index: -1,
};
export default HiddenForm;
