import React from 'react';
import PropTypes from 'prop-types';
import CommetBody from './shared/comment_body';
import CommentInput from '../../comment_input';
import { deleteRequest } from '../../../../utils/api_client';
import CommentReaction from './shared/comment_reaction';

class MyComment extends React.Component {
  constructor(props) {
    super(props);
    const { comment } = this.props;
    this.state = {
      comment,
      editing: false,
      deleted: false,
    };
    this.delete = this.delete.bind(this);
    this.changeReaction = this.changeReaction.bind(this);
  }

  delete() {
    const { comment } = this.state;
    if (window.confirm('本当にコメントを削除してよろしいですか？')) {
      deleteRequest(`/wb/user/comments/${comment.id}`)
        .then(r => this.setState({ deleted: true }))
        .catch((e) => {
          window.alert('削除に失敗しました。しばらく時間をおいて実行をお願いします');
        });
    }
  }

  changeReaction(reacted) {
    const { comment } = this.state;
    this.setState({
      comment: Object.assign(comment, {
        reacted_good: reacted,
        good_reactions_count: reacted ?
          comment.good_reactions_count + 1 : comment.good_reactions_count - 1,
      }),
    });
  }

  render() {
    const { currentUser, onReplyButtonClicked } = this.props;
    const { comment, editing, deleted } = this.state;
    if (deleted) {
      return null;
    }
    if (editing) {
      return (
        <React.Fragment>
          <CommentInput
            currentUser={currentUser}
            comment={comment}
            baseUrl="/wb/user/comments/"
            afterSaved={c => this.setState({ comment: c, editing: false })}
          />
          <div className="d-flex">
            <div style={{ width: 55 }} />
            <div className="flex-fill font__size-14 ml-2 pb-3">
              <span
                role="button"
                className="cursor__pointer color__dark-blue"
                onClick={() => this.setState({ editing: false })}
              >
                キャンセル
              </span>
              <span className="color__gray">
                ・
              </span>
              <span
                role="button"
                className="cursor__pointer color__dark-blue"
                onClick={this.delete}
              >
                削除
              </span>
            </div>
          </div>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <CommetBody comment={comment} />
        <div className="d-flex">
          <div style={{ width: 55 }} />
          <div className="flex-fill font__size-14 ml-2 pb-3 position-relative">
            <CommentReaction
              comment={comment}
              onEditButtonClicked={() => this.setState({ editing: true })}
              onReplyButtonClicked={() => onReplyButtonClicked()}
              onGoodButtonClicked={this.changeReaction}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

MyComment.propTypes = {
  comment: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  currentUser: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  onReplyButtonClicked: PropTypes.func.isRequired,
};
MyComment.defaultProps = {
};
export default MyComment;
