import React from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
import WorkItemForPosting from './work_list/work_item_for_posting';
import WorkItemForSelecting from './work_list/work_item_for_selecting';
import ModeType from './work_list/mode_type';
import { getRequest } from '../../utils/api_client';
import Loading from '../shared/loading';
import MissionList from '../missions/mission_list';
import RecommendWorkList from '../recommend_works/recommend_work_list';

class WorkList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      works: [],
      loading: false,
      hasMore: true,
    };
    this.loadMore = this.loadMore.bind(this);
    this.renderCount = 0;
    this.workItem = this.workItem.bind(this);
  }

  loadMore(page) {
    const { works: oldWorks } = this.state;
    this.setState({ loading: true }, () => {
      getRequest(
        '/wb/user/works',
        {
          page,
          q: {},
        },
      )
        .then((r) => {
          const { data: newWorks } = r;
          this.setState({
            loading: false,
            works: oldWorks.concat(newWorks),
            hasMore: newWorks.length > 0,
          });
        })
        .catch((e) => {
          this.setState({ loading: false, hasMore: false });
        });
    });
  }

  workItem(w) {
    const {
      mode, onSelected, userProfile, belongingDepartments,
    } = this.props;
    if (mode === ModeType.SELECT) {
      return (
        <WorkItemForSelecting
          key={w.id}
          work={w}
          onClick={() => onSelected(w)}
        />
      );
    }
    return (
      <WorkItemForPosting
        work={w}
        key={w.id}
        userProfile={userProfile}
        belongingDepartments={belongingDepartments}
      />
    );
  }

  render() {
    const {
      useWindow, missions, recommendWorks,
    } = this.props;
    const {
      works, loading, hasMore,
    } = this.state;

    return (
      <React.Fragment>
        <MissionList
          missions={missions}
          renderItem={this.workItem}
        />
        <RecommendWorkList
          recommendWorks={recommendWorks}
          renderItem={this.workItem}
        />
        <div className="card my-1" style={{ borderLeft: 'none', borderRight: 'none', borderBottom: 'none' }}>
          <div className="card-header border-right border-left" style={{ borderRadius: 10 }}>
            一覧
          </div>
          <div
            className="d-flex flex-row flex-wrap justify-content-center position-relative"
            style={{ minHeight: 200 }}
          >
            <InfiniteScroll
              key={this.renderCount}
              className="d-flex flex-row flex-wrap justify-content-center"
              pageStart={0}
              loadMore={this.loadMore}
              hasMore={!loading && hasMore}
              useWindow={useWindow}
              loader={<div className="p-2 color__gray" key={0}>Loading ...</div>}
            >
              {works.map(this.workItem)}
            </InfiniteScroll>
            <Loading loading={works.length === 0 && loading} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

WorkList.propTypes = {
  missions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })),
  recommendWorks: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })),
  userProfile: PropTypes.shape({
    id: PropTypes.number,
  }),
  mode: PropTypes.string,
  useWindow: PropTypes.bool,
  // ↓ mode: SELECTのみ利用できるprop
  onSelected: PropTypes.func,
  belongingDepartments: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })),
};
WorkList.defaultProps = {
  missions: [],
  recommendWorks: [],
  mode: ModeType.POST,
  useWindow: true,
  onSelected: () => {},
  userProfile: {},
  belongingDepartments: [],
};
export default WorkList;
