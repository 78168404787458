import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../shared/button';
import WorkPostEditModal from '../../work_posts/work_post_edit_modal';
import { redirectToTimeline } from '../../../utils/redirect_path';

class MissionItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  viewFrequency() {
    const { mission } = this.props;
    switch (mission.repeat_type) {
      case 'daily':
        return `1日に${mission.frequency}回以上`;
      case 'weekly':
        return `週(月曜〜日曜)に${mission.frequency}回以上`;
      case 'monthly':
        return `月(1日〜末日)に${mission.frequency}回以上`;
      default:
        return '-';
    }
  }

  render() {
    const { mission, userProfile, belongingDepartments } = this.props;
    const { showModal } = this.state;
    const { work } = mission;
    return (
      <React.Fragment>
        <WorkPostEditModal
          showModal={showModal}
          work={work}
          userProfile={userProfile}
          belongingDepartments={belongingDepartments}
          onCloseButtonClicked={() => this.setState({ showModal: false })}
          afterSaved={() => redirectToTimeline()}
        />
        <div className="d-flex py-2 border-bottom">
          <div style={{ width: '15%' }}>
            <div className="fixed-ratio-box__wrapper-100">
              <div className="fixed-ratio-box__content bgcolor__lightgray" style={{ borderRadius: 10 }}>
                {work.image_url && (
                  <img
                    alt="department_thumbnail"
                    src={work.image_url}
                    className="w-100 h-100"
                    style={{ objectFit: 'cover', borderRadius: 10 }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="flex-fill p-2">
            <div>
              {work.name}
            </div>
            <div className="text-muted font__size-13">
              {`ミッション: ${this.viewFrequency()} 実施済: ${mission.completed_count}回`}
            </div>
          </div>
          <div className="align-self-center">
            <Button
              title="実施"
              className="btn button__primary-slim"
              onClick={() => this.setState({ showModal: true })}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

MissionItem.propTypes = {
  mission: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  userProfile: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  belongingDepartments: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
};
MissionItem.defaultProps = {
};
export default MissionItem;
