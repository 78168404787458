import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../shared/button';
import TagLabels from '../../tags/tag_labels';

class WorkItemForSelecting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { work, onClick } = this.props;

    return (
      <React.Fragment>
        <div className="p-2 card__div-3">
          <Button
            className="link__container cursor__pointer"
            style={{ textDecoration: 'none' }}
            onClick={() => onClick()}
          >
            <div className="card">
              {work.image_url && (
                <img
                  src={work.image_url}
                  className="card-img-top"
                  alt="..."
                />
              )}
              {!work.image_url && (
                <div
                  className="card-img-top bgcolor__lightgray"
                  style={{ height: 170 }}
                />
              )}
              <div className="card-body">
                <div className="mb-2">
                  <TagLabels tags={work.tags} />
                </div>
                <h5 className="card-title color__black">
                  {work.name}
                </h5>
                <p className="card-text color__black">
                  {work.overview}
                </p>
              </div>
            </div>
          </Button>
        </div>
      </React.Fragment>
    );
  }
}

WorkItemForSelecting.propTypes = {
  work: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};
WorkItemForSelecting.defaultProps = {
};
export default WorkItemForSelecting;
