import React from 'react';
import PropTypes from 'prop-types';
import SelectForm from '../shared/form/select_form';

class EditForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      preparing: false,
    };
  }

  render() {
    const {
      userProfile, no1StrengthOptions, no2StrengthOptions, no3StrengthOptions,
      no4StrengthOptions, no5StrengthOptions,
    } = this.props;
    const { preparing } = this.state;
    return (
      <React.Fragment>
        <SelectForm
          scope="user_profile"
          model={userProfile}
          columnName="no1_strength"
          options={no1StrengthOptions}
          labelColor="gray"
          option={{ include_blank: '-- 選択 --' }}
        />
        <SelectForm
          scope="user_profile"
          model={userProfile}
          columnName="no2_strength"
          options={no2StrengthOptions}
          labelColor="gray"
          option={{ include_blank: '-- 選択 --' }}
        />
        <SelectForm
          scope="user_profile"
          model={userProfile}
          columnName="no3_strength"
          options={no3StrengthOptions}
          labelColor="gray"
          option={{ include_blank: '-- 選択 --' }}
        />
        <SelectForm
          scope="user_profile"
          model={userProfile}
          columnName="no4_strength"
          options={no4StrengthOptions}
          labelColor="gray"
          option={{ include_blank: '-- 選択 --' }}
        />
        <SelectForm
          scope="user_profile"
          model={userProfile}
          columnName="no5_strength"
          options={no5StrengthOptions}
          labelColor="gray"
          option={{ include_blank: '-- 選択 --' }}
        />
        <div className="user-user-profile-edit__submit-button-container">
          <input
            type="submit"
            name="commit"
            value="更新"
            className="btn btn-block button__primary"
            data-disable-with="保存中..."
            disabled={preparing}
          />
        </div>
      </React.Fragment>
    );
  }
}

EditForm.propTypes = {
  userProfile: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  no1StrengthOptions: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  no2StrengthOptions: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  no3StrengthOptions: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  no4StrengthOptions: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  no5StrengthOptions: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
};
EditForm.defaultProps = {
};
export default EditForm;
