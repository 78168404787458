import React from 'react';
import PropTypes from 'prop-types';
import MissionItem from './mission_suggest/mission_item';

class MissionSuggest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { missions, userProfile, belongingDepartments } = this.props;
    if (missions.length === 0) {
      return null;
    }
    return (
      <div className="card mb-3">
        <div className="card-header p-2">
          ミッション
        </div>
        <div
          className="card-body"
        >
          {missions.map(m => (
            <MissionItem
              key={m.id}
              mission={m}
              userProfile={userProfile}
              belongingDepartments={belongingDepartments}
            />
          ))}
        </div>
      </div>
    );
  }
}

MissionSuggest.propTypes = {
  missions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
  userProfile: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  belongingDepartments: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
};
MissionSuggest.defaultProps = {
};
export default MissionSuggest;
