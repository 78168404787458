import React, { ReactElement } from "react";
import SelectField from "../shared/form/fields/select_field";

interface Props {
  id: string;
  name: string;
  defaultOptions: Array<{ key: string; value: string }>;
  selectedKey: string;
  submitWhenOnChanged?: boolean;
  submitId?: string;
}

export default function CompanySelectField(props: Props): ReactElement {
  const {
    id,
    name,
    defaultOptions,
    selectedKey,
    submitWhenOnChanged = false,
    submitId,
  } = props;

  const loadOptions = async (
    searchWord: string
  ): Promise<Array<{ key: string; value: string }>> => {
    return defaultOptions.filter((o) => o.value.includes(searchWord));
  };

  return (
    <>
      <SelectField
        id={id}
        name={name}
        options={defaultOptions}
        loadOptions={loadOptions}
        selectedKey={selectedKey}
        option={{
          include_blank: "-- 会社選択 --",
        }}
        noOptionsMessage={({ inputValue }) => {
          return `${inputValue}に該当する会社は存在しません`;
        }}
        asyncSelectOnChange={() => {
          if (submitWhenOnChanged && submitId !== undefined) {
            const submit = document.getElementById(submitId);
            submit?.click();
          }
        }}
      />
    </>
  );
}
