import React from 'react';
import PropTypes from 'prop-types';
import ConsumerShareLink from './questionnaire_share_link/consumer_share_link';
import NonConsumerShareLink from './questionnaire_share_link/non_consumer_share_link';

class QuestionnaireShareLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const {
      isConsumer, questionnaire, linkType, snsShareCount,
    } = this.props;

    if (isConsumer) {
      return (
        <ConsumerShareLink
          questionnaire={questionnaire}
          linkType={linkType}
          snsShareCount={snsShareCount}
        />
      );
    }
    return (
      <NonConsumerShareLink
        questionnaire={questionnaire}
        linkType={linkType}
      />
    );
  }
}

QuestionnaireShareLink.propTypes = {
  isConsumer: PropTypes.bool.isRequired,
  questionnaire: PropTypes.shape({
    id: PropTypes.number,
    share_token: PropTypes.string,
    share_image_url: PropTypes.string,
  }).isRequired,
  linkType: PropTypes.string,
  snsShareCount: PropTypes.number,
};
QuestionnaireShareLink.defaultProps = {
  linkType: 'button',
  snsShareCount: 0,
};
export default QuestionnaireShareLink;
