import PropTypes from "prop-types";
import React from "react";
import LoadingImage from "../../images/spin-gray.svg";
import HiddenForm from "../hidden_form";
import { cleanUrl } from "../../../../utils/string_utils";

class CustomSingleImageForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      scope,
      renderPreview,
      currentUser,
      selectedImageUrl,
      uploading,
      selectedImageDataUri,
    } = this.props;

    return (
      <React.Fragment>
        {currentUser.id && (
          <HiddenForm
            scope={scope}
            relatedModelName="file_resources"
            index={0}
            columnName="user_id"
            value={currentUser.id}
          />
        )}
        <HiddenForm
          scope={scope}
          relatedModelName="file_resources"
          index={0}
          columnName="file_type"
          value="image_file_type"
        />
        <HiddenForm
          scope={scope}
          relatedModelName="file_resources"
          index={0}
          columnName="url"
          value={cleanUrl(selectedImageUrl)}
        />
        {uploading && (
          <img
            src={LoadingImage}
            style={{
              width: 80,
              height: 80,
            }}
          />
        )}
        {!uploading && renderPreview(selectedImageDataUri)}
      </React.Fragment>
    );
  }
}
CustomSingleImageForm.propTypes = {
  scope: PropTypes.string.isRequired,
  selectedImageUrl: PropTypes.string.isRequired,
  selectedImageDataUri: PropTypes.string.isRequired,
  containerStyle: PropTypes.shape({
    styleKey: PropTypes.string,
  }),
  uploading: PropTypes.bool.isRequired,
  renderPreview: PropTypes.func.isRequired,
  currentUser: PropTypes.shape({
    id: PropTypes.number,
  }),
};
CustomSingleImageForm.defaultProps = {
  containerStyle: {},
  currentUser: {},
};
export default CustomSingleImageForm;
