import React from 'react';
import PropTypes from 'prop-types';
import TextField from './form/fields/text_field';
import { setupPickaDate } from '../../utils/datetime_utils';

class PeriodForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    setupPickaDate({
      clear: '',
      onSet: (context) => {
        const { select } = context;
        if (select > 0) {
          const submit = document.getElementById('submit');
          submit.click();
        }
      },
    });
  }

  render() {
    const {
      startDateFieldId, startDateFieldName, endDateFieldId, endDateFieldName,
      startDate, endDate,
    } = this.props;
    return (
      <React.Fragment>
        <div style={{ float: 'left', marginTop: 6 }}>
          期間:
        </div>
        <TextField
          id={startDateFieldId}
          name={startDateFieldName}
          value={startDate}
          dataValue={startDate}
          className="datepicker"
          inputStyle={{ fontSize: 13 }}
          containerStyle={{ width: 125, float: 'left' }}
        />
        <div style={{ float: 'left', marginTop: 6 }}>
          ~
        </div>
        <TextField
          id={endDateFieldId}
          name={endDateFieldName}
          value={endDate}
          dataValue={endDate}
          className="datepicker"
          inputStyle={{ fontSize: 13 }}
          containerStyle={{ width: 125, float: 'left' }}
        />
        <button type="submit" id="submit" style={{ border: 'none', padding: 0 }} />
        <div style={{ clear: 'both' }} />
      </React.Fragment>
    );
  }
}

PeriodForm.propTypes = {
  startDateFieldId: PropTypes.string.isRequired,
  startDateFieldName: PropTypes.string.isRequired,
  endDateFieldId: PropTypes.string.isRequired,
  endDateFieldName: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
};
PeriodForm.defaultProps = {
};
export default PeriodForm;
