import React from 'react';
import PropTypes from 'prop-types';
import TextForm from '../../shared/form/text_form';
import CheckboxForm from '../../shared/form/checkbox_form';
import HiddenForm from '../../shared/form/hidden_form';
import { baseScope } from '../../shared/form/utils/attribute_utils';
import TextareaForm from '../../shared/form/textarea_form';

class CheckboxWithTextForm extends React.Component {
  constructor(props) {
    super(props);
    const { answerChoice } = this.props;
    this.state = {
      checked: !!(answerChoice && answerChoice.choice && answerChoice.choice.id),
    };
  }

  render() {
    const {
      answerChoice, scope, choice, answerIndex, index,
    } = this.props;
    const { checked } = this.state;

    return (
      <React.Fragment>
        {answerChoice && answerChoice.id && (
          <HiddenForm
            scope={baseScope(scope, 'answers', answerIndex)}
            relatedModelName="answer_choices"
            columnName="id"
            index={index}
            value={answerChoice.id}
          />
        )}
        <CheckboxForm
          key={choice.id}
          title={choice.body}
          scope={baseScope(scope, 'answers', answerIndex)}
          relatedModelName="answer_choices"
          columnName="choice_id"
          index={index}
          defaultChecked={!!(answerChoice && answerChoice.choice && answerChoice.choice.id)}
          activeKey={String(choice.id)}
          disableKey=""
          onChange={e => this.setState({ checked: e.target.checked })}
        />
        {(checked && choice.role === 'with_string_role') && (
          <TextForm
            scope={baseScope(scope, 'answers', answerIndex)}
            model={answerChoice || {}}
            relatedModelName="answer_choices"
            columnName="body"
            index={index}
            hideLabel
            placeholder={window.I18n.t('javascript.questions.question_form.free_entry_field')}
          />
        )}
        {(checked && choice.role === 'with_text_role') && (
          <TextareaForm
            scope={baseScope(scope, 'answers', answerIndex)}
            model={answerChoice || {}}
            relatedModelName="answer_choices"
            columnName="body"
            index={index}
            hideLabel
            placeholder={window.I18n.t('javascript.questions.question_form.free_entry_field')}
          />
        )}
      </React.Fragment>
    );
  }
}

CheckboxWithTextForm.propTypes = {
  answerChoice: PropTypes.shape({
    id: PropTypes.number,
  }),
  scope: PropTypes.string.isRequired,
  choice: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  answerIndex: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};
CheckboxWithTextForm.defaultProps = {
  answerChoice: {},
};
export default CheckboxWithTextForm;
