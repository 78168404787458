import { format } from "date-fns";
import ja from "date-fns/locale/ja";
import React, { ReactElement } from "react";
import { ContractPeriodResponse } from "../../models/ContractPeriod";
import BaseForm from "../shared/base_form";
import SelectField from "../shared/form/fields/select_field";
import TextForm from "../shared/form/text_form";
import TextareaForm from "../shared/form/textarea_form";

interface Props {
  mailSubject: string;
  mailBody: string;
  emailLocale: string;
  editUrl: string;
  updateUrl: string;
  submitButtonClass?: string;
  localeOptions?: Array<{ key: string; value: string }>;
  usingActualEndDate: boolean;
  contractPeriod?: ContractPeriodResponse;
}

export default function AutoRemindMailEditForm(props: Props): ReactElement {
  const {
    mailSubject,
    mailBody,
    emailLocale,
    updateUrl,
    submitButtonClass = "btn button__primary mx-3",
    localeOptions = [
      { key: "ja", value: "日本語" },
      { key: "en", value: "英語" },
    ],
    usingActualEndDate,
    contractPeriod,
  } = props;

  const changeEmailLocale = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { editUrl } = this.props;
    window.location.href = `${editUrl}?email_locale=${e.target.value}`;
  };

  return (
    <BaseForm
      name="remindMailForm"
      type="normal"
      method="put"
      action={updateUrl}
    >
      <div className="js-edit-page-template__body-header-area">
        <div className="js-edit-page-template__header-action-container">
          <div className="d-flex justify-content-end">
            {localeOptions.length > 1 && (
              <SelectField
                id="email_locale_selector"
                name="email_locale_selector"
                options={localeOptions}
                selectedKey={emailLocale}
                onChange={changeEmailLocale}
              />
            )}
            <input
              type="submit"
              name="commit"
              value="更新"
              className={submitButtonClass}
              data-disable-with="保存中..."
            />
          </div>
        </div>
      </div>
      <div className="container">
        <ul className="list-group list-group-flush">
          <li className="list-group-item">
            <div className=" row">
              <div className=" col-sm-2 color__gray">件名</div>
              <div className="col-sm-10">
                <TextForm
                  model={{
                    [`auto_remind_email_subject_${emailLocale}`]: mailSubject,
                  }}
                  scope="company"
                  relatedModelName="company_mail_template"
                  columnName={`auto_remind_email_subject_${emailLocale}`}
                  hideLabel
                  required
                />
              </div>
            </div>
          </li>
          <li className=" list-group-item">
            <div className=" row">
              <div className=" col-sm-2 color__gray">本文</div>
              <div className=" col-sm-10">
                <div className="p-2 font__size-14">
                  マークダウン記法が利用できます。
                  <br />
                  また、本文には以下の変数(二重の中括弧を含んだ文字列)が利用できます。
                  <br />
                  {"{{company_name}}"}: 会社名
                  <br />
                  {"{{account_name}}"}: ユーザの名前
                  <br />
                  {"{{deadline_date}}"}: メール記載の計測終了日
                  {contractPeriod !== undefined && (
                    <span className="ml-2">
                      (
                      {format(
                        new Date(
                          contractPeriod.actual_end_date !== null
                            ? contractPeriod.actual_end_date
                            : contractPeriod.end_date
                        ),
                        "yyyy年MM月dd日(E)",
                        { locale: ja }
                      )}
                      )
                    </span>
                  )}
                  {usingActualEndDate && (
                    <>
                      <br />
                      {"{{deadline_real_date}}"}: 計測終了日
                      {contractPeriod !== undefined && (
                        <span className="ml-2">
                          (
                          {format(
                            new Date(contractPeriod.end_date),
                            "yyyy年MM月dd日(E)",
                            { locale: ja }
                          )}
                          )
                        </span>
                      )}
                    </>
                  )}
                  <br />
                  {"{{email}}"}: ユーザのメールアドレス
                  <br />
                  {"{{password_setting_link}}"}: ユーザ別のパスワード設定リンク
                </div>
                <TextareaForm
                  scope="company"
                  relatedModelName="company_mail_template"
                  columnName={`auto_remind_email_body_template_${emailLocale}`}
                  model={{
                    [`auto_remind_email_body_template_${emailLocale}`]:
                      mailBody,
                  }}
                  rows={30}
                  hideLabel
                  required
                />
              </div>
            </div>
          </li>
        </ul>
      </div>
    </BaseForm>
  );
}
