import React from 'react';
import PropTypes from 'prop-types';
import CommetBody from './shared/comment_body';
import CommentReaction from './shared/comment_reaction';

class OtherComment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { comment, onReplyButtonClicked } = this.props;
    return (
      <React.Fragment>
        <CommetBody comment={comment} />
        <div className="d-flex">
          <div style={{ width: 55 }} />
          <div className="flex-fill font__size-14 ml-2 pb-3 position-relative">
            <CommentReaction
              comment={comment}
              onReplyButtonClicked={() => onReplyButtonClicked()}
              onGoodButtonClicked={() => {}}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

OtherComment.propTypes = {
  comment: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  onReplyButtonClicked: PropTypes.func.isRequired,
};
OtherComment.defaultProps = {
};
export default OtherComment;
