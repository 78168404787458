import React from 'react';
import PropTypes from 'prop-types';
import UserSelectModal from '../../users/user_select_modal';
import HiddenForm from './hidden_form';
import SingleUserActionView from './user_form/single_user_action_view';
import MultipleUserActionView from './user_form/multiple_user_action_view';

class UserForm extends React.Component {
  constructor(props) {
    super(props);
    const { model, relatedModelName } = this.props;
    this.state = {
      showModal: false,
      // 新規追加されたユーザの場合はidのkeyは存在しない
      // ユーザの削除が行われた場合はuserのvalueはnullになる
      // ex: [{id: 1, user: {}}, {user: {}}, {id: 2, user: null}]
      relatedModels: model[relatedModelName] || [],
    };
    this.addUser = this.addUser.bind(this);
    this.removeUser = this.removeUser.bind(this);
  }

  addUser(user) {
    const { multiple } = this.props;
    const { relatedModels } = this.state;
    if (multiple) {
      if (relatedModels.findIndex(rm => rm.user.id === user.id) < 0) {
        this.setState({ relatedModels: relatedModels.concat([{ user }]), showModal: false });
      }
    } else {
      this.setState({ relatedModels: [{ user }], showModal: false });
    }
  }

  removeUser(user) {
    const { relatedModels } = this.state;
    this.setState({
      relatedModels: relatedModels.map((rm) => {
        if (rm.user.id === user.id) {
          return Object.assign(rm, { user: null });
        }
        return rm;
      }),
      showModal: false,
    });
  }

  render() {
    const {
      scope, relatedModelName, columnName,
      multiple,
    } = this.props;
    const { showModal, relatedModels } = this.state;
    const activeUsers = relatedModels.map(rm => rm.user).filter(u => u);
    return (
      <React.Fragment>
        <UserSelectModal
          showModal={showModal}
          onCloseButtonClicked={() => this.setState({ showModal: false })}
          onSelected={this.addUser}
        />
        {relatedModels.map((rm, i) => (
          <React.Fragment key={`${rm.id}_${rm.user && rm.user.id}`}>
            {rm.id && (
              <HiddenForm
                scope={scope}
                relatedModelName={relatedModelName}
                columnName="id"
                index={i}
                value={rm.id}
              />
            )}
            {rm.user && (
              <HiddenForm
                scope={scope}
                relatedModelName={relatedModelName}
                columnName={columnName}
                index={i}
                value={rm.user.id}
              />
            )}
          </React.Fragment>
        ))}
        <div className="form-group row">
          <div className="col-sm-12">
            {!multiple && (
              <SingleUserActionView
                users={activeUsers}
                onEditButtonClicked={() => this.setState({ showModal: true })}
                onDeleteButtonClicked={this.removeUser}
              />
            )}
            {multiple && (
              <MultipleUserActionView
                users={activeUsers}
                onEditButtonClicked={() => this.setState({ showModal: true })}
                onDeleteButtonClicked={this.removeUser}
              />
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

UserForm.propTypes = {
  scope: PropTypes.string.isRequired,
  relatedModelName: PropTypes.string, // 基準のモデルとUserモデルを紐付ける中間テーブルの名称
  columnName: PropTypes.string.isRequired, // 中間テーブルにおいて、user.idが格納されるカラム名
  multiple: PropTypes.bool,
  model: PropTypes.shape({ // 中間テーブル名がkeyである中間テーブルのobjectを内包している基準のモデル
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};
UserForm.defaultProps = {
  relatedModelName: '',
  multiple: false,
  model: {},
};
export default UserForm;
