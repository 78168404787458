import React from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import Button from '../shared/button';
import HeartIcon from '../shared/images/ic_heart.svg';
import ChatIcon from '../shared/images/ic_chat.svg';
import { postRequest, deleteRequest } from '../../utils/api_client';

class PostReaction extends React.Component {
  constructor(props) {
    super(props);
    const { reactedGood } = this.props;
    this.state = {
      reactedGood,
    };
    this.react = this.react.bind(this);
  }

  react() {
    const { baseUrl, onGoodReactionButtonClicked } = this.props;
    const { reactedGood } = this.state;
    if (reactedGood) {
      this.setState({ reactedGood: false }, () => {
        onGoodReactionButtonClicked(false);
        deleteRequest(
          `${baseUrl}/reactions/destroy_all`,
          {},
        )
          .then(() => {})
          .catch((err) => {
            console.log(err);
          });
      });
      return;
    }
    this.setState({ reactedGood: true }, () => {
      onGoodReactionButtonClicked(true);
      postRequest(
        `${baseUrl}/reactions`,
        { reaction: { kind: 'good_kind' } },
      )
        .then(() => {})
        .catch((err) => {
          console.log(err);
        });
    });
  }

  render() {
    const { onCommentButtonClicked } = this.props;
    const { reactedGood } = this.state;
    return (
      <div className="row">
        <div className="col-6 text-center px-0">
          <Button
            className="btn btn-light btn-block cursor__pointer company-timeline-index__post-action-button text-left"
            onClick={this.react}
          >
            <div className="d-flex justify-content-center align-items-center">
              <ReactSVG
                src={HeartIcon}
                beforeInjection={(svg) => {
                  svg.classList.add(`icon__small ${reactedGood ? 'icon__hotpink' : 'icon__gray'}`);
                }}
                style={{ marginTop: -3, marginRight: 3 }}
              />
              <div>
                <span>いいね!</span>
              </div>
            </div>
          </Button>
        </div>
        <div className="col-6 text-center px-0">
          <Button
            title="コメント"
            className="btn btn-light btn-block cursor__pointer company-timeline-index__post-action-button"
            onClick={() => onCommentButtonClicked()}
          >
            <div className="d-flex justify-content-center align-items-center">
              <ReactSVG
                src={ChatIcon}
                beforeInjection={(svg) => {
                  svg.classList.add('icon__small icon__gray');
                }}
                style={{ marginRight: 3 }}
              />
              <div>
                <span>コメント</span>
              </div>
            </div>
          </Button>
        </div>
      </div>
    );
  }
}

PostReaction.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  reactedGood: PropTypes.bool.isRequired,
  onGoodReactionButtonClicked: PropTypes.func.isRequired,
  onCommentButtonClicked: PropTypes.func.isRequired,
};
PostReaction.defaultProps = {
};
export default PostReaction;
