import React from 'react';
import PropTypes from 'prop-types';

class MissionList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { missions, renderItem } = this.props;
    if (missions.length === 0) {
      return null;
    }
    return (
      <div className="card my-1" style={{ borderLeft: 'none', borderRight: 'none', borderBottom: 'none' }}>
        <div className="card-header border-right border-left" style={{ borderRadius: 10 }}>
          ミッション
        </div>
        <div
          className="d-flex flex-row flex-wrap"
        >
          {missions.map(m => renderItem(m.work))}
        </div>
      </div>
    );
  }
}

MissionList.propTypes = {
  missions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
  renderItem: PropTypes.func.isRequired,
};
MissionList.defaultProps = {
};
export default MissionList;
