import React from 'react';
import PropTypes from 'prop-types';
// import FbImageLibrary from 'react-fb-image-grid';
import FileName from './file_name';

class DisplayFileResource extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { fileResources } = this.props;
    if (fileResources.length === 0) {
      return null;
    }
    const imageFileResources = fileResources.filter(fr => fr.file_type === 'image_file_type');
    const unknownFileResources = fileResources.filter(fr => fr.file_type !== 'image_file_type');
    return (
      <div>
        {/*<FbImageLibrary*/}
        {/*  images={imageFileResources.map(fr => fr.url)}*/}
        {/*  renderOverlay={() => 'preview'}*/}
        {/*/>*/}
        {unknownFileResources.map(fr => (
          <FileName
            key={fr.id}
            fileResource={fr}
            downloadable
          />
        ))}
      </div>
    );
  }
}

DisplayFileResource.propTypes = {
  fileResources: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
};
DisplayFileResource.defaultProps = {
};
export default DisplayFileResource;
