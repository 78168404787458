import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../shared/button';
import BaseMoal from '../../shared/base_modal';
import BaseForm, { ajaxSubmit } from '../../shared/base_form';
import TextAreaForm from '../../shared/form/textarea_form';
import TextForm from '../../shared/form/text_form';
import { deleteRequest } from '../../../utils/api_client';
import SelectForm from '../../shared/form/select_form';
import SelectQuestionForm from './question_edit_modal/select_question_form';

class QuestionEditModal extends React.Component {
  constructor(props) {
    super(props);
    const { question, questionFormOptions } = this.props;
    this.state = {
      loading: false,
      selectedAnswerFormat: question.answer_format || questionFormOptions[0].key,
    };
    this.save = this.save.bind(this);
    this.delete = this.delete.bind(this);
  }

  save() {
    const form = document.forms.questionEditForm;
    if (form.checkValidity()) {
      this.setState({ loading: true }, () => {
        ajaxSubmit(form)
          .then((r) => {
            const { redirect_url: url } = r.data;
            window.location.href = url;
          })
          .catch((e) => {
            this.setState({ loading: false }, () => {
              window.alert('エラーが発生したため保存に失敗しました');
            });
          });
      });
    } else {
      // 検証結果がfalseなのでsubmitしてhtml5のvalidationメッセージを表示させる
      const submit = document.getElementById('questionEditSubmit');
      submit.click();
    }
  }

  delete() {
    const { action, question } = this.props;
    if (window.confirm('本当に削除してよろしいですか？')) {
      this.setState({ loading: true }, () => {
        deleteRequest(`${action}/${question.id}`)
          .then((r) => {
            const { redirect_url: url } = r.data;
            window.location.href = url;
          })
          .catch((e) => {
            this.setState({ loading: false }, () => {
              window.alert('削除に失敗しました');
            });
          });
      });
    }
  }

  render() {
    const {
      action, showModal, onCloseButtonClicked, question,
      questionFormOptions, questionCategoryLabel, questionCategoryOptions,
    } = this.props;
    const { loading, selectedAnswerFormat } = this.state;

    const isParentChildSelectFormt = ['parent_select_format',
      'child_select_format'].includes(selectedAnswerFormat);

    return (
      <React.Fragment>
        <BaseMoal
          show={showModal}
          title={`アンケート${question.id ? '編集' : '追加'}`}
          contentLabel="edit_question"
          onCloseModal={() => onCloseButtonClicked()}
          loading={loading}
          contentStyle={{ maxWidth: 796, width: '100%' }}
        >
          <BaseForm
            name="questionEditForm"
            action={question.id ? `${action}/${question.id}` : action}
            method={question.id ? 'put' : 'post'}
          >
            <BaseMoal.Body>
              <div className="container-fluid">
                {questionCategoryOptions.length > 0 && (
                  <SelectForm
                    model={question}
                    scope="question"
                    columnName="question_category_id"
                    label={questionCategoryLabel}
                    options={questionCategoryOptions}
                  />
                )}
                {!isParentChildSelectFormt && (
                  <SelectForm
                    model={question}
                    scope="question"
                    columnName="answer_format"
                    defaultKey={selectedAnswerFormat}
                    options={questionFormOptions}
                    onChange={e => this.setState({
                      selectedAnswerFormat: e.target.value,
                    })}
                  />
                )}
                {['title_format'].includes(selectedAnswerFormat) && (
                  <TextForm
                    model={question}
                    scope="question"
                    columnName="body"
                    label="タイトル"
                    required
                    placeholder=""
                  />
                )}
                {['description_format'].includes(selectedAnswerFormat) && (
                  <div>
                    <TextAreaForm
                      model={question}
                      scope="question"
                      columnName="body"
                      label="説明"
                      required
                      placeholder=""
                    />
                  </div>
                )}
                {['text_format', 'select_format', 'check_format'].includes(selectedAnswerFormat) && (
                  <React.Fragment>
                    <SelectForm
                      model={question}
                      scope="question"
                      columnName="required_flg"
                      selectedKey={question.required_flg ? '1' : '0'}
                      options={[{ key: '0', value: '任意' }, { key: '1', value: '必須' }]}
                    />
                    <div>
                      <TextAreaForm
                        model={question}
                        scope="question"
                        columnName="body"
                        label="質問事項"
                        required
                        placeholder="例: もっと幸せに働くにはどうすれば良いと思いますか？"
                      />
                    </div>
                  </React.Fragment>
                )}
                {['select_format', 'check_format'].includes(selectedAnswerFormat) && (
                  <SelectQuestionForm
                    question={question}
                  />
                )}
                {isParentChildSelectFormt && (
                <div>
                  この質問は編集できません。<br />
                  編集が必要な場合は以下メールアドレスまでお問い合わせください。<br /><br />
                  support@happytech.co.jp
                </div>
                )}
              </div>
            </BaseMoal.Body>
            <div className="modal-footer">
              {question.id && !isParentChildSelectFormt && (
                <Button
                  title="削除"
                  className="btn btn-link"
                  style={{ position: 'absolute', left: 20, color: 'gray' }}
                  onClick={this.delete}
                />
              )}
              <button type="submit" id="questionEditSubmit" style={{ border: 'none', padding: 0 }} />
              <button type="button" className="btn btn-light" onClick={() => onCloseButtonClicked()}>
                閉じる
              </button>
              {!isParentChildSelectFormt && (
                <Button
                  title="保存"
                  className="btn button__primary"
                  onClick={this.save}
                />
              )}
            </div>
          </BaseForm>
        </BaseMoal>
      </React.Fragment>
    );
  }
}

QuestionEditModal.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number,
  }),
  action: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired,
  onCloseButtonClicked: PropTypes.func.isRequired,
  questionFormOptions: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  questionCategoryLabel: PropTypes.string.isRequired,
  questionCategoryOptions: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
};
QuestionEditModal.defaultProps = {
  question: {},
};
export default QuestionEditModal;
