import React from 'react';
import PropTypes from 'prop-types';
import CategoryButton from './shared/category_button';
import UserSelectModal from '../../../../users/user_select_modal';
import SelectedCategory from './shared/selected_category';

class UserCategoryButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      user: null,
    };
    this.setUser = this.setUser.bind(this);
  }

  setUser(u = null) {
    const { onSelected } = this.props;
    this.setState({ showModal: false, user: u }, () => {
      onSelected(u);
    });
  }

  render() {
    const { showModal, user } = this.state;
    return (
      <React.Fragment>
        <UserSelectModal
          showModal={showModal}
          onCloseButtonClicked={() => this.setState({ showModal: false })}
          onSelected={this.setUser}
        />
        {!user && (
          <CategoryButton
            title="人で絞る"
            onClick={() => this.setState({ showModal: true })}
          />
        )}
        {user && (
          <SelectedCategory
            title="選択済ユーザー"
            categoryName={user.account_name}
            onEdit={() => this.setState({ showModal: true })}
            onClear={() => this.setUser()}
          />
        )}
      </React.Fragment>
    );
  }
}
UserCategoryButton.propTypes = {
  onSelected: PropTypes.func.isRequired,
};
UserCategoryButton.defaultProps = {
};
export default UserCategoryButton;
