import React from 'react';
import PropTypes from 'prop-types';
import MyReply from './reply_item/my_reply';
import OtherReply from './reply_item/other_reply';

class ReplyList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  isMyComment() {
    const { comment, currentUser } = this.props;
    const { user } = comment;
    return user.id === currentUser.id;
  }

  render() {
    const { comment, currentUser, onReplyButtonClicked } = this.props;
    return (
      <li className="list-group-item py-0 border-0">
        {this.isMyComment() && (
          <MyReply
            currentUser={currentUser}
            comment={comment}
          />
        )}
        {!this.isMyComment() && (
          <OtherReply
            comment={comment}
            onReplyButtonClicked={() => onReplyButtonClicked(comment)}
          />
        )}
      </li>
    );
  }
}

ReplyList.propTypes = {
  currentUser: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  comment: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  onReplyButtonClicked: PropTypes.func.isRequired,
};
ReplyList.defaultProps = {
};
export default ReplyList;
