import React from 'react';
import PropTypes from 'prop-types';
import { fieldId, fieldName, i18nKey } from './utils/attribute_utils';
import { errorMessages } from './utils/message_utils';

class PasswordForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordVisible: false,
    };
  }

  viewLabel() {
    const {
      label, scope, relatedModelName, columnName,
    } = this.props;
    return label || window.I18n.t(i18nKey(scope, relatedModelName, columnName));
  }

  togglePasswordVisible() {
    const { passwordVisible } = this.state;
    this.setState({ passwordVisible: !passwordVisible });
  }

  render() {
    const {
      model, scope, relatedModelName, columnName,
      placeholder, labelColor, index, className,
      required, hideLabel,
    } = this.props;
    const { passwordVisible } = this.state;
    return (
      <React.Fragment>
        <div className="form-group row">
          {!hideLabel && (
            <label
              className="col-sm-4 col-form-label"
              style={{ color: labelColor }}
              htmlFor={fieldId(scope, relatedModelName, columnName, index)}
            >
              {this.viewLabel()}
            </label>
          )}
          <div className={`col-sm-${hideLabel ? 12 : 8}`}>
            <div className="input-group">
              <input
                type={passwordVisible ? 'text' : 'password'}
                className={`form-control ${className}`}
                id={fieldId(scope, relatedModelName, columnName, index)}
                name={fieldName(scope, relatedModelName, columnName, index)}
                placeholder={placeholder}
                required={required}
              />
              <div className="input-group-append">
                <div
                  role="button"
                  style={{ cursor: 'pointer' }}
                  onClick={() => this.togglePasswordVisible()}
                  className={`
                  happiness-questionnaire__user-regist-form-password-icon-container
                  ${passwordVisible ? 'happiness-questionnaire__user-regist-form-password-icon-container__opened' : ''}
                `}
                />
              </div>
            </div>
            {errorMessages(model, columnName, this.viewLabel()).map((m, i) => (
              <div key={m} className="invalid-feedback d-block">
                <span>{m}</span>
              </div>
            ))}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

PasswordForm.propTypes = {
  model: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  scope: PropTypes.string.isRequired,
  relatedModelName: PropTypes.string,
  columnName: PropTypes.string.isRequired,
  label: PropTypes.string,
  labelColor: PropTypes.string,
  index: PropTypes.number,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  hideLabel: PropTypes.bool,
};
PasswordForm.defaultProps = {
  relatedModelName: '',
  label: '',
  labelColor: '#212529',
  index: -1,
  className: '',
  placeholder: '',
  required: false,
  hideLabel: false,
};
export default PasswordForm;
