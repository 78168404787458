import React from 'react';
import PropTypes from 'prop-types';
import QuestionnaireNote from './questionnaire_notes/questionnaire_note';
import {
  getQuestionnaireNoteModalOpen,
  setQuestionnaireNoteQuestionnaire,
  getQuestionnaireNoteQuestionnaire,
  setQuestionnaireNoteCompQuestionnaire,
  getQuestionnaireNoteCompQuestionnaire,
} from '../../utils/global_variable_utils';

class QuestionnaireNotes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { baseQuestionnaire, compQuestionnaire, currentUser } = this.props;
    return (
      <div className="card">
        <div className="card-header card__white-header">
          <h4 className="card-title">
            {window.I18n.t('activerecord.attributes.questionnaire.note')}
          </h4>
        </div>
        <div className="card-body">
          <QuestionnaireNote
            headlineTitle={`${window.I18n.t('javascript.questionnaires.questionnaire_notes.latest')}(${window.I18n.toTime('time.formats.default', baseQuestionnaire.created_at)})`}
            buttonTitle={window.I18n.t('javascript.questionnaires.questionnaire_notes.add_latest_note')}
            questionnaire={getQuestionnaireNoteQuestionnaire() || baseQuestionnaire}
            currentUser={currentUser}
            showModal={getQuestionnaireNoteModalOpen()}
            afterSaved={q => setQuestionnaireNoteQuestionnaire(q)}
          />
          {compQuestionnaire.id && (
            <div className="mt-3">
              <QuestionnaireNote
                headlineTitle={`${window.I18n.t('javascript.questionnaires.questionnaire_notes.comparison')}(${window.I18n.toTime('time.formats.default', compQuestionnaire.created_at)})`}
                buttonTitle={window.I18n.t('javascript.questionnaires.questionnaire_notes.add_note')}
                questionnaire={getQuestionnaireNoteCompQuestionnaire() || compQuestionnaire}
                currentUser={currentUser}
                afterSaved={q => setQuestionnaireNoteCompQuestionnaire(q)}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

QuestionnaireNotes.propTypes = {
  baseQuestionnaire: PropTypes.shape({
    note: PropTypes.string,
  }).isRequired,
  compQuestionnaire: PropTypes.shape({
    note: PropTypes.string,
  }).isRequired,
  currentUser: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};
QuestionnaireNotes.defaultProps = {
};
export default QuestionnaireNotes;
