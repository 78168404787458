import React from 'react';
import PropTypes from 'prop-types';
import MenuDialog from '../shared/menu_dailog';
import Button from '../shared/button';
import PostEditModal from './post_edit_modal';
import { deleteRequest } from '../../utils/api_client';

const baseUrl = '/wb/user/posts';

class PostActionMenuIcon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      showDialog: false,
      loading: false,
    };
    this.deletePost = this.deletePost.bind(this);
    this.toggleDialog = this.toggleDialog.bind(this);
  }

  deletePost() {
    const { post, afterDeleted } = this.props;
    if (!post.id) {
      return;
    }
    if (window.confirm('本当に投稿を削除してよろしいですか？')) {
      this.setState({ loading: true }, () => {
        deleteRequest(`${baseUrl}/${post.id}`)
          .then((r) => {
            afterDeleted();
          })
          .catch((e) => {
            this.setState({ loading: false }, () => {
              window.alert('削除に失敗しました');
            });
          });
      });
    }
  }

  toggleDialog() {
    const { showDialog } = this.state;
    this.setState({ showDialog: !showDialog });
  }

  render() {
    const { showModal, loading, showDialog } = this.state;
    const {
      currentUser, postUser, post, afterSaved,
    } = this.props;
    if (postUser.id !== currentUser.id) {
      return null;
    }
    return (
      <React.Fragment>
        <PostEditModal
          showModal={showModal}
          post={post}
          afterSaved={(editedPost) => {
            this.setState({ showModal: false, showDialog: false }, () => {
              afterSaved(editedPost);
            });
          }}
          onCloseButtonClicked={() => this.setState({ showModal: false })}
          stopLoadingAfterSaved
        />
        <MenuDialog
          loading={loading}
          showDialog={showDialog}
          onMenuClicked={this.toggleDialog}
        >
          <Button
            title="投稿を編集"
            className="dropdown-item"
            onClick={() => this.setState({ showModal: true })}
          />
          <Button
            title="投稿を削除"
            className="dropdown-item"
            onClick={this.deletePost}
          />
        </MenuDialog>
      </React.Fragment>
    );
  }
}

PostActionMenuIcon.propTypes = {
  currentUser: PropTypes.shape({
    id: PropTypes.number,
  }),
  postUser: PropTypes.shape({
    id: PropTypes.number,
  }),
  post: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  afterSaved: PropTypes.func.isRequired,
  afterDeleted: PropTypes.func.isRequired,
};
PostActionMenuIcon.defaultProps = {
  currentUser: { id: 0 },
  postUser: { id: 0 },
};
export default PostActionMenuIcon;
