import React from 'react';

class ReplyBorder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <div className="d-flex align-items-stretch">
        <div className="mr-2" style={{ width: 15, borderRight: 'solid 1px #F79F81' }} />
      </div>
    );
  }
}

ReplyBorder.propTypes = {
};
ReplyBorder.defaultProps = {
};
export default ReplyBorder;
