import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../shared/button';
import PostEditModal from '../../../posts/post_edit_modal';
import UserIcon from '../../../users/user_icon';
import { redirectToTimeline } from '../../../../utils/redirect_path';

class NewPost extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  render() {
    const { currentUser, belongingDepartments } = this.props;
    const { showModal } = this.state;
    return (
      <React.Fragment>
        <PostEditModal
          showModal={showModal}
          belongingDepartments={belongingDepartments}
          onCloseButtonClicked={() => this.setState({ showModal: false })}
          afterSaved={() => redirectToTimeline()}
        />
        <div className="card mb-3">
          <Button
            className="card-body text-muted clearfix cursor__pointer d-flex bgcolor__trivial p-2"
            onClick={() => this.setState({ showModal: true })}
          >
            <div style={{ width: 50 }}>
              <UserIcon
                user={currentUser}
                className="image__thumbnail-small"
              />
            </div>
            <div
              className="flex-fill bgcolor__white"
              style={{ lineHeight: '40px', borderRadius: 20 }}
            >
              <span className="pl-2">コメントを投稿</span>
            </div>
          </Button>
        </div>
      </React.Fragment>
    );
  }
}

NewPost.propTypes = {
  currentUser: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  belongingDepartments: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
};
NewPost.defaultProps = {
};
export default NewPost;
