import React from "react";
import PropTypes from "prop-types";
import TextareaField from "./fields/textarea_field";
import { fieldId, fieldName, i18nKey } from "./utils/attribute_utils";
import { errorMessages } from "./utils/message_utils";

class TextAreaForm extends React.Component {
  constructor(props) {
    super(props);
    const { innerRef } = this.props;
    this.inputRef = innerRef;
    this.state = {};
  }

  viewLabel() {
    const { label, scope, relatedModelName, columnName } = this.props;
    return label || window.I18n.t(i18nKey(scope, relatedModelName, columnName));
  }

  render() {
    const {
      scope,
      relatedModelName,
      columnName,
      label,
      rows,
      index,
      labelColor,
      model,
      required,
      hideLabel,
      placeholder,
      subLabel,
      labelAreaStyle,
    } = this.props;
    return (
      <React.Fragment>
        <div className="form-group row">
          {!hideLabel && (
            <label
              className="col-sm-4 col-form-label"
              htmlFor={fieldId(scope, relatedModelName, columnName, index)}
              style={Object.assign({}, { color: labelColor }, labelAreaStyle)}
            >
              {subLabel && (
                <div className="color__gray font__size-13">{subLabel}</div>
              )}
              {this.viewLabel()}
            </label>
          )}
          <div className={`col-sm-${hideLabel ? 12 : 8} mt-3 mt-md-0`}>
            <TextareaField
              ref={this.inputRef}
              id={fieldId(scope, relatedModelName, columnName, index)}
              name={fieldName(scope, relatedModelName, columnName, index)}
              value={model[columnName]}
              rows={rows}
              required={required}
              placeholder={placeholder}
            />
            {errorMessages(model, columnName, this.viewLabel()).map((m, i) => (
              <div key={m} className="invalid-feedback d-block">
                <span>{m}</span>
              </div>
            ))}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

TextAreaForm.propTypes = {
  scope: PropTypes.string.isRequired,
  model: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  relatedModelName: PropTypes.string,
  columnName: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  subLabel: PropTypes.string,
  rows: PropTypes.number,
  index: PropTypes.number,
  labelColor: PropTypes.string,
  labelAreaStyle: PropTypes.shape({}),
  required: PropTypes.bool,
  hideLabel: PropTypes.bool,
  placeholder: PropTypes.string,
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};
TextAreaForm.defaultProps = {
  relatedModelName: "",
  subLabel: "",
  label: "",
  model: {},
  rows: 3,
  index: -1,
  labelColor: "#212529",
  labelAreaStyle: {},
  required: false,
  hideLabel: false,
  placeholder: "",
  innerRef: {},
};
export default React.forwardRef((props, ref) => (
  <TextAreaForm {...props} innerRef={ref} />
));
