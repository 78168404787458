import axios, { AxiosRequestConfig } from "axios";
import { serialize } from "object-to-formdata";
import qs from "qs";

export function getRequest(url, params = {}, format = "json") {
  return axios({
    method: "get",
    url: `${url}${format ? `.${format}` : ""}`,
    params: Object.assign(params),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    paramsSerializer: (p) => window.jQuery.param(p),
  });
}

export function putRequest(url, data = {}, format = "json") {
  return axios({
    method: "put",
    url: `${url}${format ? `.${format}` : ""}`,
    data: serialize(Object.assign(data)),
  });
}

export function postRequest(url, data = {}, format = "json") {
  return axios({
    method: "post",
    url: `${url}${format ? `.${format}` : ""}`,
    data: serialize(Object.assign(data)),
  });
}

export function deleteRequest(url, data = {}, format = "json") {
  return axios({
    method: "delete",
    url: `${url}${format ? `.${format}` : ""}`,
    data: serialize(Object.assign(data)),
  });
}

async function execRequest<T>(
  config: AxiosRequestConfig
): Promise<{ result?: T; error?: any }> {
  try {
    const res = await axios(config);
    return { result: res.data };
  } catch (error) {
    // タイムアウトの場合
    const isTimeout = error.code === "ECONNABORTED";
    if (isTimeout) {
      // alert("タイムアウトしました。しばらく時間をおいて再実行してください。")
      return {};
    }
    // ネットワークエラーの場合
    if (error.response === null || error.response === undefined) {
      console.log(error);
      return {};
    }
    const { data } = error.response;
    if (data.message !== null && data.message !== undefined) {
      if (Array.isArray(data.message)) {
        await window.alert(data.message.join(","));
      } else {
        await window.alert(data.message);
      }
    } else if (data.error !== undefined && data.error !== null) {
      await window.alert(data.error);
    }
    return { error: data };
  }
}

export async function getRequestAsync<T>(
  url,
  params = {},
  format = "json"
): Promise<{ result: T | undefined; error: any }> {
  const { result, error } = await execRequest<T>({
    method: "get",
    url: `${url}${format ? `.${format}` : ""}`,
    params: Object.assign(params),
    paramsSerializer: (p) => qs.stringify(p),
  });
  return { result, error };
}

export async function putRequestAsync<T>(
  url,
  data = {},
  format = "json"
): Promise<{ result: T | undefined; error: any }> {
  const { result, error } = await execRequest<T>({
    method: "put",
    url: `${url}${format ? `.${format}` : ""}`,
    data: serialize(Object.assign(data)),
  });
  return { result, error };
}

export async function postRequestAsync<T>(
  url,
  data = {},
  format = "json"
): Promise<{ result: T | undefined; error: any }> {
  const { result, error } = await execRequest<T>({
    method: "post",
    url: `${url}${format ? `.${format}` : ""}`,
    data: serialize(Object.assign(data)),
  });
  return { result, error };
}

export async function deleteRequestAsync<T>(
  url,
  data = {},
  format = "json"
): Promise<{ result: T | undefined; error: any }> {
  const { result, error } = await execRequest<T>({
    method: "delete",
    url: `${url}${format ? `.${format}` : ""}`,
    data: serialize(Object.assign(data)),
  });
  return { result, error };
}

export default getRequest;
