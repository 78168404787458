import PropTypes from 'prop-types';
import React from 'react';
import axios from 'axios';
import BaseMoal from '../../base_modal';
import Button from '../../button';
import FileCreationAcceptedModalContent from '../../file_creation_accepted_modal_content';
import RadioList from '../shared/radio_list';
import SelectForm from '../../form/select_form';

function addParameterToURL(url, key, value) {
  let u = url;
  u += `${u.split('?')[1] ? '&' : '?'}${key}=${value}`;
  return u;
}

class CategorizedContentLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      loading: false,
      creationRequestSuccessed: false,
      linkPath: this.initialLikPath(),
      params: {},
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.requestCsvCreation = this.requestCsvCreation.bind(this);
  }

  initialLikPath() {
    const { radioLinkCategories } = this.props;
    const category = radioLinkCategories[0];
    if (category) {
      const content = category.contents[0];
      return content.link_path;
    }
    return '';
  }

  openModal() {
    this.setState({ showModal: true });
  }

  closeModal() {
    this.setState({ showModal: false, creationRequestSuccessed: false });
  }

  requestCsvCreation() {
    const { linkPath, params } = this.state;
    let url = linkPath;
    const paramKeys = Object.keys(params);
    for (let i = 0; i < paramKeys.length; i += 1) {
      const key = paramKeys[i];
      url = addParameterToURL(url, key, params[key]);
    }
    this.setState({ loading: true }, () => {
      axios({
        method: 'get',
        url,
        params: {
          format: 'csv',
        },
      })
        .then((result) => {
          this.setState({ loading: false, creationRequestSuccessed: true });
        })
        .catch((e) => {
          this.setState({ loading: false }, () => {
            window.alert('エラーが発生しました。大変申し訳ありませんが、しばらく時間をおいて再実行してください');
          });
        });
    });
  }

  render() {
    const { radioLinkCategories, downloadOptions } = this.props;
    const {
      showModal, loading, creationRequestSuccessed, linkPath, params,
    } = this.state;

    if (showModal) {
      return (
        <BaseMoal
          show={showModal}
          title={creationRequestSuccessed ? '作成依頼を受け付けました' : 'CSVの種類を選択してください'}
          contentLabel="csv_link_select_modal"
          onCloseModal={this.closeModal}
          loading={loading}
        >
          <BaseMoal.Body>
            {creationRequestSuccessed && (
              <FileCreationAcceptedModalContent />
            )}
            {!creationRequestSuccessed && (
              <React.Fragment>
                <div className="csv-download-link__category-container">
                  {downloadOptions.map((opt) => {
                    if (opt.formType === 'select') {
                      return (
                        <SelectForm
                          key={opt.paramKey}
                          model={{}}
                          scope=""
                          label={opt.label}
                          columnName={opt.paramKey}
                          options={opt.options}
                          onChange={e => this.setState({
                            params: Object.assign({}, params, {
                              [opt.paramKey]: e.target.value,
                            }),
                          })}
                        />
                      );
                    }
                    return null;
                  })}
                </div>
                <RadioList
                  radioLinkCategories={radioLinkCategories}
                  linkPath={linkPath}
                  onChangeLinkPath={p => this.setState({ linkPath: p })}
                />
              </React.Fragment>
            )}
          </BaseMoal.Body>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={this.closeModal}
            >
              閉じる
            </button>
            {!creationRequestSuccessed && (
              <Button
                title="作成"
                className="btn button__primary button__link"
                onClick={this.requestCsvCreation}
              />
            )}
          </div>
        </BaseMoal>
      );
    }
    return (
      <span
        role="button"
        style={{ fontSize: 14, cursor: 'pointer' }}
        onClick={this.openModal}
      >
        CSVダウンロード
      </span>
    );
  }
}
CategorizedContentLink.propTypes = {
  downloadOptions: PropTypes.arrayOf(PropTypes.shape({
    formType: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    paramKey: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })),
  })).isRequired,
  radioLinkCategories: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    contents: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      link_path: PropTypes.string.isRequired,
    })),
  })).isRequired,
};
CategorizedContentLink.defaultProps = {
};
export default CategorizedContentLink;
