import React from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import EditIcon from '../../shared/images/edit.svg';
import Button from '../../shared/button';
import QuestionnaireNoteEditModal from './questionnaire_note_edit_modal';
import MultiLineText from '../../shared/multi_line_text';
import { setQuestionnaireNoteModalOpen } from '../../../utils/global_variable_utils';

class QuestionnaireNote extends React.Component {
  constructor(props) {
    super(props);
    const { questionnaire, showModal } = this.props;
    this.state = {
      questionnaire,
      showModal,
    };
  }

  render() {
    const {
      headlineTitle, buttonTitle, currentUser, afterSaved,
    } = this.props;
    const { showModal, questionnaire } = this.state;
    return (
      <React.Fragment>
        <QuestionnaireNoteEditModal
          showModal={showModal}
          questionnaire={questionnaire}
          onCloseButtonClicked={() => {
            this.setState({ showModal: false }, () => {
              setQuestionnaireNoteModalOpen(false);
            });
          }}
          afterSaved={(q) => {
            this.setState({ questionnaire: q, showModal: false }, () => {
              setQuestionnaireNoteModalOpen(false);
              afterSaved(q);
            });
          }}
          stopLoadingAfterSaved
          currentUser={currentUser}
        />
        <div className="color__gray font__size-14">
          {headlineTitle}
        </div>
        {questionnaire.note && (
          <div className="p-2 pr-5 border__lightgray position-relative">
            <MultiLineText text={questionnaire.note} />
            <ReactSVG
              src={EditIcon}
              beforeInjection={(svg) => {
                svg.classList.add('icon__gray');
                svg.setAttribute('style', 'width: 20px;height: 20px;');
              }}
              className="cursor__pointer position-absolute"
              style={{ top: 0, right: 3 }}
              onClick={() => this.setState({ showModal: true })}
            />
          </div>
        )}
        {!questionnaire.note && (
          <Button
            title={buttonTitle}
            className="btn btn-light btn-block"
            onClick={() => this.setState({ showModal: true })}
          />
        )}
      </React.Fragment>
    );
  }
}

QuestionnaireNote.propTypes = {
  headlineTitle: PropTypes.string.isRequired,
  buttonTitle: PropTypes.string.isRequired,
  questionnaire: PropTypes.shape({
    note: PropTypes.string,
  }).isRequired,
  currentUser: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  showModal: PropTypes.bool,
  afterSaved: PropTypes.func,
};
QuestionnaireNote.defaultProps = {
  showModal: false,
  afterSaved: () => {},
};
export default QuestionnaireNote;
