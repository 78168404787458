import React from "react";
import PropTypes from "prop-types";
import TextForm from "../../../shared/form/text_form";
import SelectForm from "../../../shared/form/select_form";
import CheckboxForm from "../../../shared/form/checkbox_form";
import {
  setupPickaDate,
  setupPickaDateWithCallback,
  setupPickaTime,
  setupPickaTimeWithCallback,
} from "../../../../utils/datetime_utils";

class EventEditForm extends React.Component {
  constructor(props) {
    super(props);
    const { event, answerTimingOptions } = props;
    this.state = {
      hasEndDate: !!event.end_date,
      hasEndTime: !!event.end_time,
      checkboxRefreshing: false,
      answerTiming: event.answer_timing || answerTimingOptions[0].key,
    };
  }

  componentDidMount() {
    setupPickaDate();
    setupPickaTime();
    setupPickaDateWithCallback(
      {},
      () => {
        const startDate = $(".start_date_picker").val();
        const endDate = $(".end_date_picker").val();
        if (!endDate) {
          $(".end_date_picker").val(startDate);
          $('input[name="event[end_date]"]').val(startDate);
        }
      },
      "start_date_picker"
    );
    setupPickaDateWithCallback(
      {},
      (context) => {
        const { select } = context;
        if (select) {
          this.setState({ hasEndDate: true });
        } else {
          this.setState({ hasEndDate: false, checkboxRefreshing: true }, () => {
            this.setState({ checkboxRefreshing: false });
          });
        }
      },
      "end_date_picker"
    );
    setupPickaTimeWithCallback(
      {},
      () => {
        const startTime = $(".start_time_picker").val();
        const endTime = $(".end_time_picker").val();
        if (!endTime) {
          const hour = startTime.split(":")[0];
          const minutes = startTime.split(":")[1];
          const afterTime = `${Number(hour) + 1}:${minutes}`;
          $(".end_time_picker").val(afterTime);
          $('input[name="event[end_time]"]').val(afterTime);
        }
      },
      "start_time_picker"
    );
    setupPickaTimeWithCallback(
      {},
      (context) => {
        const { select } = context;
        if (select) {
          this.setState({ hasEndTime: true });
        } else {
          this.setState({ hasEndTime: false, checkboxRefreshing: true }, () => {
            this.setState({ checkboxRefreshing: false });
          });
        }
      },
      "end_time_picker"
    );
  }

  render() {
    const {
      event,
      answerTimingOptions,
      roleOptions,
      workHappinessQuestionnaireUsable,
      profileInputBirthYearOptions,
      profileInputBirthMonthOptions,
      profileInputSexOptions,
      profileInputForResearchOptions,
      surveyTypeOptions,
    } = this.props;
    const { hasEndDate, hasEndTime, checkboxRefreshing, answerTiming } =
      this.state;

    const endTimeMailFlgEditable = hasEndDate && hasEndTime;
    const isAnswered = !!(
      event.unset_questionnaires_count ||
      event.before_event_questionnaires_count ||
      event.after_event_questionnaires_count
    );

    return (
      <React.Fragment>
        <TextForm model={event} scope="event" columnName="title" />
        <SelectForm
          model={event}
          scope="event"
          columnName="role"
          options={roleOptions}
        />
        {workHappinessQuestionnaireUsable && (
          <SelectForm
            model={event}
            scope="event"
            columnName="survey_type"
            disabled={isAnswered}
            message={
              isAnswered ? "すでに診断済の方がいるため変更できません" : ""
            }
            options={surveyTypeOptions}
          />
        )}
        <SelectForm
          model={event}
          scope="event"
          columnName="answer_timing"
          options={answerTimingOptions}
          onChange={(e) =>
            this.setState({
              answerTiming: e.target.value,
            })
          }
        />
        {answerTiming === "one_time_answer_timing" && (
          <SelectForm
            model={event}
            scope="event"
            columnName="one_time_answer_account_required_flg"
            selectedKey={event.one_time_answer_account_required_flg ? "1" : "0"}
            options={[
              { key: "1", value: "アカウント登録必須" },
              { key: "0", value: "アカウント登録任意" },
            ]}
          />
        )}
        <TextForm
          model={event}
          scope="event"
          columnName="start_date"
          className="start_date_picker"
          placeholder="クリックで入力"
        />
        <TextForm
          model={event}
          scope="event"
          columnName="start_time"
          className="start_time_picker"
          placeholder="クリックで入力"
        />
        <TextForm
          model={event}
          scope="event"
          columnName="end_date"
          className="end_date_picker"
          placeholder="クリックで入力"
        />
        <TextForm
          model={event}
          scope="event"
          columnName="end_time"
          className="end_time_picker"
          placeholder="クリックで入力"
        />
        <div
          style={{
            display: answerTiming === "one_time_answer_timing" ? "" : "none",
          }}
        >
          <TextForm
            model={event}
            scope="event"
            columnName="start_date_of_linking_past_result"
            className="datepicker"
            placeholder="クリックで入力"
          />
          <TextForm
            model={event}
            scope="event"
            columnName="start_time_of_linking_past_result"
            className="timepicker"
            placeholder="クリックで入力"
          />
        </div>
        <SelectForm
          model={event}
          scope="event"
          columnName="profile_input_birth_year"
          options={profileInputBirthYearOptions}
        />
        <SelectForm
          model={event}
          scope="event"
          columnName="profile_input_birth_month"
          options={profileInputBirthMonthOptions}
        />
        <SelectForm
          model={event}
          scope="event"
          columnName="profile_input_sex"
          options={profileInputSexOptions}
        />
        <SelectForm
          model={event}
          scope="event"
          columnName="profile_input_for_research"
          options={profileInputForResearchOptions}
        />
        {answerTiming === "before_after_answer_timing" && (
          <div className="form-group row">
            <div className="col-sm-4 col-form-label" />
            <div className="col-sm-8">
              {!checkboxRefreshing && (
                <CheckboxForm
                  title="イベント終了時に参加者に計測依頼メールを送信"
                  scope="event"
                  columnName="end_time_measurement_mail_flg"
                  defaultChecked={
                    endTimeMailFlgEditable &&
                    event.end_time_measurement_mail_flg
                  }
                  disabled={!endTimeMailFlgEditable}
                />
              )}
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

EventEditForm.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.number,
  }),
  answerTimingOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  roleOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  profileInputBirthYearOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  profileInputBirthMonthOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  profileInputSexOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  workHappinessQuestionnaireUsable: PropTypes.bool.isRequired,
  profileInputForResearchOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  surveyTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
};
EventEditForm.defaultProps = {
  event: {},
};
export default EventEditForm;
