import React from "react";
import PropTypes from "prop-types";
import LoadingImage from "./images/spin-gray.svg";

class LoadingMask extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { loading } = this.props;
    if (!loading) {
      return null;
    }

    return (
      <div
        style={{
          backgroundColor: "rgba(159, 159, 159, 0.1)",
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 2000,
        }}
      >
        <img
          src={LoadingImage}
          style={{
            width: 80,
            height: 80,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      </div>
    );
  }
}
LoadingMask.propTypes = {
  loading: PropTypes.bool.isRequired,
};
LoadingMask.defaultProps = {};
export default LoadingMask;
