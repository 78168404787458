import React, { ReactElement, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import GraphContainer from "./drivers_chart/graph_container";

interface Props {
  dataset: Array<{
    happinessElement: string;
    value: number; // 一般平均との差分
    correlation: number;
  }>;
}

function createXAxisScales(minValue: number, maxValue: number): number[] {
  const scaleWidth = 0.02;
  const min = Math.floor(minValue * 10) / 10;
  let max = Math.ceil(maxValue * 10) / 10;
  if (max < 0.5) {
    max = 0.5;
  }
  const scales: number[] = [];
  const count = Math.round((max - min) / scaleWidth);
  for (let i = 0; i <= count; i++) {
    // 浮動小数点対策
    scales.push(Math.round((min + i * scaleWidth) * 100) / 100);
  }
  return scales;
}

function createYAxisScales(minValue: number, maxValue: number): number[] {
  const scaleHeight = 1;
  const min = Math.floor(minValue / 5) * 5;
  const max = Math.ceil(maxValue / 5) * 5;
  const scales: number[] = [];
  const count = Math.round((max - min) / scaleHeight);
  for (let i = 0; i <= count; i++) {
    scales.push(min + i * scaleHeight);
  }
  return scales;
}

export default function DriversChart(props: Props): ReactElement {
  const { dataset } = props;
  const minXValue = Math.min(...dataset.map((d) => d.correlation));
  const maxXValue = Math.max(...dataset.map((d) => d.correlation));
  const minYValue = Math.min(...dataset.map((d) => d.value));
  const maxYValue = Math.max(...dataset.map((d) => d.value));
  const xAxisScales = createXAxisScales(minXValue, maxXValue);
  const yAxisScales = createYAxisScales(minYValue, maxYValue);
  const [scaleHeight, setScaleHeight] = useState(15);
  const [scaleWidth, setScaleWidth] = useState(20);

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="p-2">
        <div className="d-flex align-items-center">
          <div>目盛高さ:</div>
          <div className="ml-2">
            <input
              defaultValue={scaleHeight}
              type="number"
              onChange={(e) => setScaleHeight(Number(e.target.value))}
            />
          </div>
        </div>
        <div className="d-flex align-items-center mt-2">
          <div>{"目盛幅　:"}</div>
          <div className="ml-2">
            <input
              defaultValue={scaleWidth}
              type="number"
              onChange={(e) => setScaleWidth(Number(e.target.value))}
            />
          </div>
        </div>
      </div>
      <div className="driver-chart__container mt-4">
        <div className="driver-chart__y-axis-container">
          <div className="driver-chart__y-axis-label">一般平均との差分</div>
        </div>
        <div>
          {[...yAxisScales].reverse().map((y, i, arr) => {
            if (i === arr.length - 1) {
              return null;
            }
            let yScaleValue = y;
            const scaleClassNameArr = ["driver-chart__y-axis-scale"];
            if (i === arr.length - 2) {
              yScaleValue = arr[arr.length - 1];
              scaleClassNameArr.push("driver-chart__y-axis-scale__last");
            }
            return (
              <div
                key={yScaleValue}
                className="driver-chart__y-axis-scale-box"
                style={{ height: scaleHeight }}
              >
                {yScaleValue % 5 === 0 && (
                  <div className={scaleClassNameArr.join(" ")}>
                    {yScaleValue}
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <div className="driver-chart__table-and-x-axis-container">
          <GraphContainer
            dataset={dataset}
            xAxisScales={xAxisScales}
            yAxisScales={yAxisScales}
            scaleHeight={scaleHeight}
            scaleWidth={scaleWidth}
          />
          <div className="driver-chart__x-axis-container">
            {xAxisScales.map((x, i, arr) => {
              if (i === arr.length - 1) {
                return null;
              }
              let xScaleValue = x;
              const scaleClassNameArr = ["driver-chart__x-axis-scale"];
              if (i === arr.length - 2) {
                xScaleValue = arr[arr.length - 1];
                scaleClassNameArr.push("driver-chart__x-axis-scale__last");
              }
              return (
                <div
                  key={xScaleValue}
                  style={{ width: scaleWidth }}
                  className="driver-chart__x-axis-scale-box"
                >
                  {(xScaleValue * 100) % 10 === 0 && (
                    <div className={scaleClassNameArr.join(" ")}>
                      {xScaleValue.toFixed(1)}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          <div className="driver-chart__x-axis-container">
            <div className="driver-chart__x-axis-label">重要度(相関係数)</div>
          </div>
        </div>
      </div>
    </DndProvider>
  );
}
