import PropTypes from 'prop-types';
import React from 'react';
import SyncCsvDownloadLink from './csv_download_link/sync_csv_download_link';
import AsyncCsvDownloadLink from './csv_download_link/async_csv_download_link';

class CsvDownloadLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  render() {
    const { mode } = this.props;
    if (mode === 'async') {
      return (
        <AsyncCsvDownloadLink {...this.props} />
      );
    }
    return (
      <SyncCsvDownloadLink {...this.props} />
    );
  }
}
CsvDownloadLink.propTypes = {
  radioLinkCategories: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    contents: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      link_path: PropTypes.string.isRequired,
    })),
  })).isRequired,
  mode: PropTypes.string,
};
CsvDownloadLink.defaultProps = {
  mode: 'sync',
};
export default CsvDownloadLink;
