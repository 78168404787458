import React, { ReactElement } from "react";
import { Line } from "react-chartjs-2";

const options = (opt: {
  chartId: string;
  scaleMin: number;
  scaleMax: number;
  stepSize: number;
  labels: string[];
  tooltipLabels: string[];
}) => {
  return {
    scales: {
      yAxes: [
        {
          ticks: {
            min: opt.scaleMin,
            max: opt.scaleMax,
            stepSize: opt.stepSize,
          },
        },
      ],
    },
    legend: {
      // position: "bottom",
      display: false,
    },
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          return opt.tooltipLabels[tooltipItem.index] || "";
        },
      },
    },
  };
};

interface Props {
  chartId: string;
  scaleMin: number;
  scaleMax: number;
  stepSize?: number;
  labels: string[];
  tooltipLabels: string[];
  datasets: Array<{
    label: string;
    data: number[];
    color: string;
  }>;
}

export default function LineChart(props: Props): ReactElement {
  const {
    chartId,
    scaleMin,
    scaleMax,
    stepSize = 5,
    labels,
    tooltipLabels,
    datasets,
  } = props;
  return (
    <div id={chartId}>
      <Line
        width={450}
        height={250}
        data={{
          labels: labels,
          datasets: datasets.map((d) => ({
            label: d.label,
            data: d.data,
            fill: false,
            backgroundColor: d.color,
            borderColor: d.color,
            pointBackgroundColor: d.color,
          })),
          lineAtIndex: 30,
        }}
        options={options({
          chartId,
          scaleMin,
          scaleMax,
          stepSize,
          labels,
          tooltipLabels,
        })}
      />
    </div>
  );
}
