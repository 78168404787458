import React from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import Button from '../shared/button';
import EditIcon from '../shared/images/edit.svg';
import LookingBackNoteEditModal from './questionnaire_looking_back_note/looking_back_note_edit_modal';
import { happinessCategoryName } from '../../utils/happiness_category_utils';
import { happinessElementName } from '../../utils/happiness_element_utils';
import { getRequest } from '../../utils/api_client';
import LookingBackNoteView from './shared/looking_back_note_view';

class QuestionnaireLookingBackNote extends React.Component {
  constructor(props) {
    super(props);
    const { questionnaire, showModal } = this.props;
    this.state = {
      loading: false,
      isComparisonNote: false,
      showModal,
      questionnaire,
    };
    this.changeTargetQuestionnaire = this.changeTargetQuestionnaire.bind(this);
  }

  changeTargetQuestionnaire() {
    const { latestQuestionnaireId, comparisonQuestionnaireId } = this.props;
    const { isComparisonNote } = this.state;
    const targetQuestionnaireId = isComparisonNote ? latestQuestionnaireId : comparisonQuestionnaireId;
    this.setState({ loading: true }, () => {
      getRequest(`/user/questionnaires/${targetQuestionnaireId}`)
        .then((r) => {
          const { data } = r;
          this.setState({
            questionnaire: data, loading: false, isComparisonNote: !isComparisonNote,
          });
        })
        .catch((e) => {
          // todo: エラーハンドリング
          this.setState({ loading: false });
        });
    });
  }

  render() {
    const { currentUser, comparisonQuestionnaireId } = this.props;
    const {
      showModal, questionnaire, isComparisonNote, loading,
    } = this.state;
    const existsComparison = comparisonQuestionnaireId > 0;
    return (
      <div>
        <LookingBackNoteEditModal
          showModal={showModal}
          questionnaire={questionnaire}
          afterSaved={q => this.setState({ questionnaire: q, showModal: false })}
          currentUser={currentUser}
          onCloseButtonClicked={() => this.setState({ showModal: false })}
        />
        <div className="card">
          {existsComparison && (
            <Button
              title={isComparisonNote ? '最新の振り返り結果' : '比較時点の振り返り結果'}
              className="btn btn-link btn-sm text-right"
              onClick={this.changeTargetQuestionnaire}
            />
          )}
          <div className="card-header card__white-header">
            {existsComparison && (
              <div className="color__gray font__size-13">
                {isComparisonNote ? `比較時点(${window.I18n.l('time.formats.default', questionnaire.created_at)})` : '最新'}
              </div>
            )}
            <h4 className="card-title position-relative">
              <span>
                幸せの振り返り
              </span>
              <ReactSVG
                src={EditIcon}
                beforeInjection={(svg) => {
                  svg.classList.add('icon__gray');
                  svg.setAttribute('style', 'width: 20px;height: 20px;');
                }}
                className="cursor__pointer position-absolute"
                style={{ top: -5, right: 3 }}
                onClick={() => this.setState({ showModal: true })}
              />
            </h4>
          </div>
          <div className="card-body pt-1">
            {loading && (
              <div>
                ロード中です...
              </div>
            )}
            {!loading && (
              <LookingBackNoteView
                questionnaire={questionnaire}
                emptyComponent={<Button
                  id="questionnaire_note_button"
                  title="振り返りを行う"
                  className="btn btn-light btn-block"
                  onClick={() => this.setState({ showModal: true })}
                />}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

QuestionnaireLookingBackNote.propTypes = {
  questionnaire: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  currentUser: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  latestQuestionnaireId: PropTypes.number.isRequired,
  comparisonQuestionnaireId: PropTypes.number,
  showModal: PropTypes.bool,
};
QuestionnaireLookingBackNote.defaultProps = {
  comparisonQuestionnaireId: 0,
  showModal: false,
};
export default QuestionnaireLookingBackNote;
