import React from 'react';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';

class ShareLinkItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  render() {
    const { shareUrl, description } = this.props;
    return (
      <div>
        <div style={{ fontSize: 13 }}>
          {description}
        </div>
        <div style={{ height: 50 }}>
          <div
            style={{
              float: 'left',
              width: 'calc(100% - 100px)',
              overflow: 'hidden',
              lineHeight: '38px',
            }}
          >
            <input
              id="event-url-box"
              type="text"
              disabled={false}
              defaultValue={shareUrl}
              style={{ border: 'none', width: '100%' }}
            />
          </div>
          <div style={{ float: 'right' }}>
            <CopyToClipboard
              text={shareUrl}
            >
              <button
                className="btn btn-light"
                style={{ width: 80 }}
              >
                Copy
              </button>
            </CopyToClipboard>
          </div>
        </div>
      </div>
    );
  }
}

ShareLinkItem.propTypes = {
  description: PropTypes.string.isRequired,
  shareUrl: PropTypes.string.isRequired,
};
ShareLinkItem.defaultProps = {
};
export default ShareLinkItem;
