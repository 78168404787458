import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import MultiLineText from '../../shared/multi_line_text';

class AnswerItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const {
      answer, showUserName, showDatetime, showDepartment,
    } = this.props;
    return (
      <React.Fragment>
        <tr>
          {showDatetime && (
            <React.Fragment>
              <td>
                {window.I18n.l('time.formats.default', answer.created_at)}
              </td>
            </React.Fragment>
          )}
          <td>
            <MultiLineText text={answer.body} />
          </td>
          {showUserName && (
            <td>
              {answer.answered_user_name}
            </td>
          )}
          {showDepartment && (
            <td>
              <div>{answer.department_names}</div>
            </td>
          )}
        </tr>
      </React.Fragment>
    );
  }
}

AnswerItem.propTypes = {
  answer: PropTypes.shape({
    id: PropTypes.number,
    answered_user_name: PropTypes.string,
  }).isRequired,
  showDatetime: PropTypes.bool.isRequired,
  showUserName: PropTypes.bool.isRequired,
  showDepartment: PropTypes.bool.isRequired,
};
AnswerItem.defaultProps = {
};
export default AnswerItem;
