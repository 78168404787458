import React from 'react';
import PropTypes from 'prop-types';
import EditLink from './managerial_position_list_item/edit_link';
import SorableIcon from '../../shared/images/ic_sortable.png';
import HiddenForm from '../../shared/form/hidden_form';

class ManagerialPositionListItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const {
      managerialPosition, action, canSort, position,
    } = this.props;
    return (
      <li className="managerial-position-list__managerial-position-item" style={{ cursor: canSort ? 'pointer' : '' }}>
        <HiddenForm
          scope="company"
          relatedModelName="managerial_positions"
          index={position}
          columnName="id"
          value={managerialPosition.id}
        />
        <HiddenForm
          scope="company"
          relatedModelName="managerial_positions"
          index={position}
          columnName="position"
          value={position}
        />
        <span>
          {managerialPosition.name}
        </span>
        {!canSort && (
          <div style={{ float: 'right' }}>
            <EditLink
              managerialPosition={managerialPosition}
              action={action}
            />
          </div>
        )}
        {canSort && (
          <img
            src={SorableIcon}
            alt="sortable icon"
            style={{ height: 17, marginTop: 2, float: 'right' }}
          />
        )}
        <div style={{ float: 'right', padding: '3px 15px 0px 15px' }}>
          {`${managerialPosition.members_count}人`}
        </div>
      </li>
    );
  }
}

ManagerialPositionListItem.propTypes = {
  action: PropTypes.string.isRequired,
  managerialPosition: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  canSort: PropTypes.bool.isRequired,
  position: PropTypes.number.isRequired,
};
ManagerialPositionListItem.defaultProps = {
};
export default ManagerialPositionListItem;
