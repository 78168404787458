import { format, parseISO, startOfToday } from "date-fns";
import PropTypes from "prop-types";
import React from "react";
import { setupPickaDate } from "../../../../utils/datetime_utils";
import BaseForm, { ajaxSubmit } from "../../../shared/base_form";
import BaseMoal from "../../../shared/base_modal";
import Button from "../../../shared/button";
import TextForm from "../../../shared/form/text_form";

const todayStr = format(startOfToday(), "yyyy-MM-dd");

class EditModalBody extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.save = this.save.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.backToMenu = this.backToMenu.bind(this);
  }

  componentDidMount() {
    setupPickaDate();
  }

  save() {
    const {
      onLoadingStarted,
      onLoadingEnded,
      afterEdited,
      onCloseButtonClicked,
    } = this.props;
    const form = document.forms.departmentEditForm;
    if (form.checkValidity()) {
      onLoadingStarted(() => {
        ajaxSubmit(form)
          .then((r) => {
            const editedDepartment = r.data;
            afterEdited(editedDepartment);
            onCloseButtonClicked();
            window.Alert.success(
              `部署「${editedDepartment.name}」の変更が完了しました`
            );
          })
          .catch((e) => {
            onLoadingEnded();
          });
      });
    } else {
      // 検証結果がfalseなのでsubmitしてhtml5のvalidationメッセージを表示させる
      const submit = document.getElementById("departmentEditSubmit");
      submit.click();
    }
  }

  closeModal() {
    const { onCloseButtonClicked } = this.props;
    onCloseButtonClicked();
  }

  backToMenu() {
    const { onSwitchToMenu } = this.props;
    onSwitchToMenu();
  }

  render() {
    const { node } = this.props;
    const startApplyingDateStr = format(
      parseISO(node.start_applying_date),
      "yyyy-MM-dd"
    );
    return (
      <React.Fragment>
        <BaseForm
          name="departmentEditForm"
          action={`/user/departments/${node.id}`}
          method="put"
        >
          <BaseMoal.Body>
            <Button
              title="戻る"
              onClick={this.backToMenu}
              className="btn btn-link"
              style={{ fontSize: 13, marginTop: -20 }}
            />
            <div className="container-fluid">
              <div>
                <TextForm
                  model={{ name: node.name }}
                  scope="department"
                  columnName="name"
                  label="名称"
                  required
                />
              </div>
            </div>
          </BaseMoal.Body>
          <div className="modal-footer">
            <button
              type="submit"
              id="departmentEditSubmit"
              style={{ border: "none", padding: 0 }}
            />
            <button
              type="button"
              className="btn btn-light"
              onClick={this.closeModal}
            >
              閉じる
            </button>
            <Button
              title="更新"
              className="btn button__primary"
              onClick={this.save}
            />
          </div>
        </BaseForm>
      </React.Fragment>
    );
  }
}

EditModalBody.propTypes = {
  onSwitchToMenu: PropTypes.func.isRequired,
  onCloseButtonClicked: PropTypes.func.isRequired,
  onLoadingStarted: PropTypes.func.isRequired,
  onLoadingEnded: PropTypes.func.isRequired,
  node: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  afterEdited: PropTypes.func.isRequired,
};
EditModalBody.defaultProps = {};
export default EditModalBody;
