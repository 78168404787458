import PropTypes from 'prop-types';
import React from 'react';
import { ReactSVG } from 'react-svg';
import ClearIcon from '../../images/ic_clear.svg';
import HiddenForm from '../hidden_form';
import Button from '../../button';

class DepartmentFilterField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.remove = this.remove.bind(this);
  }

  remove() {
    const {
      onNotSavedDepartmentRemoved, departmentFilter, onSavedDepartmentRemoved,
    } = this.props;
    if (departmentFilter.id) {
      onSavedDepartmentRemoved();
    } else {
      onNotSavedDepartmentRemoved();
    }
  }

  render() {
    const {
      scope, departmentFilter, index,
    } = this.props;
    const { department } = departmentFilter;
    return (
      <React.Fragment>
        {departmentFilter.id && (
          <HiddenForm
            scope={scope}
            relatedModelName="department_filters"
            index={index}
            columnName="id"
            value={departmentFilter.id}
          />
        )}
        <HiddenForm
          scope={scope}
          relatedModelName="department_filters"
          index={index}
          columnName="level"
          value={departmentFilter.level}
        />
        <HiddenForm
          scope={scope}
          relatedModelName="department_filters"
          index={index}
          columnName="department_id"
          value={department.id}
        />
        {departmentFilter.destroyed && (
          <HiddenForm
            scope={scope}
            relatedModelName="department_filters"
            index={index}
            columnName="_destroy"
            value="1"
          />
        )}
        {!departmentFilter.destroyed && (
          <div className="d-flex align-items-center p-1">
            <ReactSVG
              src={ClearIcon}
              className="mr-2 cursor__pointer color__gray"
              beforeInjection={(svg) => {
                svg.classList.add('');
                svg.setAttribute('style', 'width: 17px;height: 17px;margin-top: -2px;');
              }}
              onClick={this.remove}
            />
            <Button
              className="color__black font__size-14 font-weight-bold flex-fill border p-2"
              style={{ lineHeight: '17px' }}
            >
              {department.name}
            </Button>
          </div>
        )}
      </React.Fragment>
    );
  }
}
DepartmentFilterField.propTypes = {
  scope: PropTypes.string.isRequired,
  departmentFilter: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  index: PropTypes.number.isRequired,
  onNotSavedDepartmentRemoved: PropTypes.func.isRequired,
  onSavedDepartmentRemoved: PropTypes.func.isRequired,
};
DepartmentFilterField.defaultProps = {
};
export default DepartmentFilterField;
