import React from 'react';
import PropTypes from 'prop-types';
import RightArrowIcon from '../../../shared/images/ic_right_arrow.png';
import DownArrowIcon from '../../../shared/images/ic_down_arrow.png';

class ReplyToggleButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { showReply, onClick } = this.props;
    return (
      <div className="d-flex">
        <div style={{ width: 16 }} />
        <div className="flex-fill font__size-14 ml-2 pb-3">
          <span
            role="button"
            className="cursor__pointer color__dark-blue"
            onClick={() => onClick()}
          >
            <img src={showReply ? DownArrowIcon : RightArrowIcon} alt="closed_icon" style={{ width: 25, marginTop: -3 }} />
            {showReply ? '返信を非表示' : '返信を表示'}
          </span>
        </div>
      </div>
    );
  }
}

ReplyToggleButton.propTypes = {
  showReply: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};
ReplyToggleButton.defaultProps = {
};
export default ReplyToggleButton;
