import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../shared/button';
import { ajaxSubmit } from '../../shared/base_form';

class BodyAction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.save = this.save.bind(this);
  }

  save() {
    const { beforeSaved, afterSaved } = this.props;
    const form = document.forms.allDutiesEditForm;
    beforeSaved(() => {
      ajaxSubmit(form)
        .then((r) => {
          const { redirect_url: url } = r.data;
          window.location.href = url;
        })
        .catch((e) => {
          afterSaved(() => {
            window.alert('エラーが発生したため保存に失敗しました');
          });
        });
    });
  }

  render() {
    const { canSort } = this.props;
    if (!canSort) {
      return null;
    }
    return (
      <div className="question-list__suggest-text-container">
        <span className="question-list__suggest-saving-text-container">
          右記保存ボタンを押して並び替えを完了させてください
        </span>
        <Button
          title="保存"
          className="btn button__primary-slim"
          style={{ float: 'right', marginRight: 10 }}
          onClick={this.save}
        />
      </div>
    );
  }
}

BodyAction.propTypes = {
  canSort: PropTypes.bool.isRequired,
  beforeSaved: PropTypes.func.isRequired,
  afterSaved: PropTypes.func.isRequired,
};
BodyAction.defaultProps = {
};
export default BodyAction;
