import React, { ReactElement, useState } from "react";
import MenuButton from "../../shared/menu_button";
import { EventRelationshipIndexResponse } from "../../../models/EventRelationshipApi";
import { deleteRequest } from "../../../utils/api_client";
import { EventResponse } from "../../../models/Event";

interface Props {
  event: EventResponse;
  eventRelationship: EventRelationshipIndexResponse;
}

export default function RelatedEventManagementListItem(
  props: Props
): ReactElement {
  const { event, eventRelationship } = props;
  const [isDeleted, setIsDeleted] = useState(false);

  if (isDeleted) {
    return <></>;
  }

  const destroy = () => {
    if (window.confirm("本当に関連イベントを削除してよろしいですか？")) {
      deleteRequest(
        `/companies/events/${event.id}/event_relationships/${eventRelationship.id}`
      )
        .then((r) => {
          setIsDeleted(true);
          window.Alert.success(
            `${eventRelationship.related_event.title}を関連イベントから削除しました`
          );
        })
        .catch((e) => {
          window.alert(
            "削除に失敗しました。しばらく時間をおいて実行をお願いします"
          );
        });
    }
  };

  return (
    <tr className="border-bottom">
      <td>{eventRelationship.related_event.title}</td>
      <td>{eventRelationship.related_event.start_date || "-"}</td>
      <td>{eventRelationship.related_event.start_time || "-"}</td>
      <td>{eventRelationship.related_event.end_date || "-"}</td>
      <td>{eventRelationship.related_event.end_time || "-"}</td>
      <td className="text-right">
        <MenuButton
          contents={[
            {
              title: "削除",
              onClick: destroy,
            },
          ]}
        />
      </td>
    </tr>
  );
}
