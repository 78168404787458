export function happinessElementName(he) {
  return window.I18n.t(
    `enums.happiness_question.happiness_element.${he}`
  ).replace(/\r?\n/g, "");
}

// he: happiness_element
export function happinessElementColor(he, alpha = 1) {
  switch (he) {
    case "well_being_satisfaction":
    case "well_being_positive_emotion":
      return `rgba(255, 222, 216, ${alpha})`;
    case "try_drawing_vision":
    case "try_strength":
    case "try_immersion":
    case "try_enjoying":
    case "try_growth_motivation":
    case "try_creativity":
    case "try_self_affirmation":
      return `rgba(255, 228, 226, ${alpha})`;
    case "thanks_appreciation":
    case "thanks_altruistic":
    case "thanks_allowance":
    case "thanks_building_trust_relationship":
    case "thanks_communication":
      return `rgba(255, 232, 210, ${alpha})`;
    case "work_out_challenge":
    case "work_out_optimistic":
      return `rgba(255, 246, 226, ${alpha})`;
    case "as_is_my_own_pace":
      return `rgba(254, 253, 215, ${alpha})`;
    case "big_five_energetic":
    case "big_five_friendly":
    case "big_five_serious":
    case "big_five_emotional_stability":
    case "big_five_interest":
      return `rgba(219, 231, 209, ${alpha})`;
    case "health_subjective_health_view":
      return `rgba(210, 232, 219, ${alpha})`;
    case "stress_low_stress":
      return `rgba(212, 233, 232, ${alpha})`;
    case "social_happiness_safe_and_secure":
    case "social_happiness_trusted_region":
      return `rgba(205, 219, 232, ${alpha})`;
    case "workplace_happiness_safe_and_secure":
    case "workplace_happiness_trusted_workplace":
    case "workplace_happiness_recommend_challenge":
    case "workplace_happiness_net_promoter_score":
      return `rgba(219, 208, 233, ${alpha})`;
    case "status_material_income":
    case "status_material_property":
    case "status_material_social_status":
    case "status_material_actual_results":
      return `rgba(234, 210, 232, ${alpha})`;
    default:
      return "lightgray";
  }
}

// he: happiness_element
export function happinessElementGuidePath(he) {
  switch (he) {
    case "well_being_satisfaction":
      return "01_well_being_satisfaction";
    case "well_being_positive_emotion":
      return "02_well_being_positive_emotion";
    case "try_drawing_vision":
      return "03_try_drawing_vision";
    case "try_strength":
      return "04_try_strength";
    case "try_immersion":
      return "05_try_immersion";
    case "try_enjoying":
      return "06_try_enjoying";
    case "try_growth_motivation":
      return "07_try_growth_motivation";
    case "try_creativity":
      return "08_try_creativity";
    case "try_self_affirmation":
      return "09_try_self_affirmation";
    case "thanks_appreciation":
      return "10_thanks_appreciation";
    case "thanks_altruistic":
      return "11_thanks_altruistic";
    case "thanks_allowance":
      return "12_thanks_allowance";
    case "thanks_building_trust_relationship":
      return "13_thanks_building_trust_relationship";
    case "thanks_communication":
      return "14_thanks_communication";
    case "work_out_challenge":
      return "15_work_out_challenge";
    case "work_out_optimistic":
      return "16_work_out_optimistic";
    case "as_is_my_own_pace":
      return "17_as_is_my_own_pace";
    case "big_five_energetic":
      return "18_big_five_energetic";
    case "big_five_friendly":
      return "19_big_five_friendly";
    case "big_five_serious":
      return "20_big_five_serious";
    case "big_five_emotional_stability":
      return "21_big_five_emotional_stability";
    case "big_five_interest":
      return "22_big_five_interest";
    case "health_subjective_health_view":
      return "23_health_subjective_health_view";
    case "stress_low_stress":
      return "24_stress_low_stress";
    case "social_happiness_safe_and_secure":
      return "25_social_happiness_safe_and_secure";
    case "social_happiness_trusted_region":
      return "26_social_happiness_trusted_region";
    case "workplace_happiness_safe_and_secure":
      return "27_workplace_happiness_safe_and_secure";
    case "workplace_happiness_trusted_workplace":
      return "28_workplace_happiness_trusted_workplace";
    case "workplace_happiness_recommend_challenge":
      return "29_workplace_happiness_recommend_challenge";
    case "workplace_happiness_net_promoter_score":
      return "30_workplace_happiness_net_promoter_score";
    case "status_material_income":
      return "31_status_material_income";
    case "status_material_property":
      return "32_status_material_property";
    case "status_material_social_status":
      return "33_status_material_social_status";
    case "status_material_actual_results":
      return "34_status_material_actual_results";
    default:
      return "#";
  }
}

export default happinessElementName;
