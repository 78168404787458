import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../shared/button';
import BaseMoal from '../../shared/base_modal';
import { getRequest, postRequest } from '../../../utils/api_client';
import HappinessStructureImageView from '../shared/happiness_structure_image_view';
import ShareImageView from '../shared/share_image_view';

class NonConsumerShareLink extends React.Component {
  constructor(props) {
    super(props);
    const { questionnaire } = this.props;
    this.state = {
      questionnaire,
      showModal: false,
      loading: false,
    };
    this.load = this.load.bind(this);
  }

  tracking() {
    const { linkType } = this.props;
    postRequest('/user/tracks', { happiness_structure_link_type: linkType });
  }

  load() {
    const { questionnaire } = this.state;
    this.setState({ loading: true }, () => {
      getRequest(`/questionnaires/${questionnaire.share_token}`)
        .then((r) => {
          const { data } = r;
          this.setState({ questionnaire: data, loading: false });
        })
        .catch((e) => {
          this.setState({ loading: false });
        });
    });
  }


  render() {
    const { linkType } = this.props;
    const { questionnaire, showModal, loading } = this.state;
    const sharable = !!questionnaire.share_image_url;
    return (
      <React.Fragment>
        <BaseMoal
          show={showModal}
          loading={loading}
          title={window.I18n.t('javascript.questionnaires.questionnaire_share_link.happiness_structure')}
          contentLabel="shareQuestionnaire"
          onCloseModal={() => this.setState({ showModal: false })}
          contentStyle={{ maxWidth: 650, width: '100%' }}
        >
          <HappinessStructureImageView
            questionnaire={questionnaire}
            beforeLoading={callback => this.setState({ loading: true }, callback)}
            afterLoading={() => this.setState({ loading: false })}
          />
        </BaseMoal>
        {linkType === 'link' && (
          <Button
            title={window.I18n.t('javascript.questionnaires.questionnaire_share_link.happiness_structure')}
            className="btn btn-link font__size-14"
            onClick={() => this.setState({ showModal: true }, () => {
              if (!sharable) {
                this.load();
              }
              this.tracking();
            })}
          />
        )}
        {linkType !== 'link' && (
          <Button
            title={window.I18n.t('javascript.questionnaires.questionnaire_share_link.happiness_structure')}
            className="btn button__highlignt mt-2 w-100"
            onClick={() => this.setState({ showModal: true }, () => {
              if (!sharable) {
                this.load();
              }
              this.tracking();
            })}
          />
        )}
      </React.Fragment>
    );
  }
}

NonConsumerShareLink.propTypes = {
  questionnaire: PropTypes.shape({
    id: PropTypes.number,
    share_token: PropTypes.string,
    share_image_url: PropTypes.string,
  }).isRequired,
  linkType: PropTypes.string.isRequired,
};
NonConsumerShareLink.defaultProps = {
};
export default NonConsumerShareLink;
