import React from 'react';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns';
import Button from '../../shared/button';
import { putRequest } from '../../../utils/api_client';

class NotificationItem extends React.Component {
  constructor(props) {
    super(props);
    const { notification } = this.props;
    this.state = {
      readed: notification.state === 'readed',
    };
    this.transition = this.transition.bind(this);
  }

  transition() {
    const { notification } = this.props;
    const { transition_url: transitionUrl } = notification;
    const { readed } = this.state;
    if (!readed) {
      this.setState({ readed: true }, () => {
        // readedにstateを更新
        putRequest(
          `/wb/user/notifications/${notification.id}`,
          { notification: { state: 'readed' } },
        )
          .then(r => console.log(r))
          .catch(e => console.log(e));
      });
    }
    if (transitionUrl) {
      window.location.href = transitionUrl;
    }
  }

  render() {
    const { notification } = this.props;
    const { readed } = this.state;
    const { action_message: actionMessage, created_at: createdAt } = notification;
    return (
      <React.Fragment>
        <Button
          className={`
            list-group-item
            ${readed ? '' : 'bgcolor__unread'}
            cursor__pointer
            position-relative
          `}
          onClick={this.transition}
        >
          <div className="font__size-15">
            {actionMessage.title}
          </div>
          <div className="font__size-14">
            {actionMessage.body}
          </div>
          <div
            className="mt-2 color__gray font__size-12 text-right"
          >
            {format(parseISO(createdAt), 'yyyy年MM月dd日 HH:mm')}
          </div>
        </Button>
      </React.Fragment>
    );
  }
}

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};
NotificationItem.defaultProps = {
};
export default NotificationItem;
