import React from 'react';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns';

class SharingActionItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { sharingAction } = this.props;
    return (
      <div className="card">
        <div className="bgcolor__lightgray" style={{ height: 187, overflow: 'hidden' }}>
          {sharingAction.image_url && (
            <img
              src={sharingAction.image_url}
              className="card-img-top h-100 slide" // slideを指定すると自動でスライドする
              style={{ objectFit: 'cover' }}
              alt="..."
            />
          )}
        </div>
        <div className="card-footer">
          <div
            className="font__size-15 font-weight-bold"
            style={{ minHeight: 50 }}
          >
            {sharingAction.title}
          </div>
          <div className="row">
            <div
              className="col-6 font__size-14 color__gray text-center"
              style={{ lineHeight: '38px' }}
            >
              {`更新日: ${format(parseISO(sharingAction.update_date), 'MM月dd日')}`}
            </div>
            <a
              className="col-6 font__size-14 text-center btn color__red color__red__hover-same cursor__pointer link__textd-none"
              href={sharingAction.transition_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              確認する
            </a>
          </div>
        </div>
      </div>
    );
  }
}

SharingActionItem.propTypes = {
  sharingAction: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};
SharingActionItem.defaultProps = {
};
export default SharingActionItem;
