import React, { CSSProperties, ReactElement, useState } from "react";
import { SingleValue } from "react-select";
import AsyncSelect from "react-select/async";
import HiddenField from "./hidden_field";

interface Props {
  id: string;
  name: string;
  containerClass?: string;
  containerStyle?: CSSProperties;
  options: Array<{ key: string; value: string }>;
  loadOptions?: (
    searchWord: string
  ) => Promise<Array<{ key: string; value: string }>>;
  selectedKey: string | number;
  option?: {
    include_blank?: string;
  };
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  disabled?: boolean;
  required?: boolean;
  noOptionsMessage?: ({ inputValue }: { inputValue: string }) => string | null;
  asyncSelectOnChange?: (
    value: SingleValue<{
      label: string;
      value: string;
    }>
  ) => void;
}

export default function SelectField(props: Props): ReactElement {
  const {
    containerClass,
    containerStyle,
    id,
    name,
    options,
    loadOptions,
    selectedKey,
    option = {},
    onChange,
    disabled = false,
    required = false,
    noOptionsMessage,
    asyncSelectOnChange,
  } = props;
  const [asyncSelectValue, setAsyncSelectValue] = useState<string | undefined>(
    String(selectedKey)
  );

  if (loadOptions !== undefined) {
    let selectedOption:
      | SingleValue<{
          label: string;
          value: string;
        }>
      | undefined = undefined;
    const tempSelectedOption = options.filter(
      (o) => String(o.key) === String(asyncSelectValue || selectedKey)
    )[0];
    if (tempSelectedOption !== undefined) {
      selectedOption = {
        label: tempSelectedOption.value,
        value: tempSelectedOption.key,
      };
    }
    return (
      <div className={containerClass} style={containerStyle}>
        <AsyncSelect
          cacheOptions
          placeholder={option.include_blank}
          defaultOptions={options.map((o) => ({
            label: o.value,
            value: o.key,
          }))}
          loadOptions={async (word) => {
            const temp = await loadOptions(word);
            return temp.map((o) => ({
              label: o.value,
              value: o.key,
            }));
          }}
          value={selectedOption}
          noOptionsMessage={noOptionsMessage}
          onChange={(selectedOption) => {
            setAsyncSelectValue(selectedOption?.value);
            if (asyncSelectOnChange !== undefined) {
              // Hiddenに値をセットしてから関数が呼び出されるように、呼び出しタイミングを遅らせている
              setTimeout(() => {
                asyncSelectOnChange(selectedOption);
              }, 200);
            }
          }}
        />
        <HiddenField id={id} name={name} value={asyncSelectValue} />
      </div>
    );
  }

  return (
    <div className={containerClass} style={containerStyle}>
      <select
        className="form-control"
        name={name}
        id={id}
        defaultValue={String(selectedKey)}
        onChange={(e) => {
          if (onChange !== undefined) {
            onChange(e);
          }
        }}
        disabled={disabled}
        required={required}
      >
        {option.include_blank && (
          <option key="include_blank" label={option.include_blank} value="" />
        )}
        {options.map(({ key, value }) => (
          <option key={key} value={key}>
            {value}
          </option>
        ))}
        <optgroup label="" />
      </select>
    </div>
  );
}
