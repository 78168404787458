import React from 'react';
import PropTypes from 'prop-types';

class DepartmentFilterNames extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { departmentFilters, caption } = this.props;
    return (
      <React.Fragment>
        <div className="font__size-13 color__gray">
          {`${caption}: ${departmentFilters.map(df => df.department.name).join('・')}`}
        </div>
      </React.Fragment>
    );
  }
}

DepartmentFilterNames.propTypes = {
  caption: PropTypes.string.isRequired,
  departmentFilters: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
};
DepartmentFilterNames.defaultProps = {
};
export default DepartmentFilterNames;
