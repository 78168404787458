import React, { ReactElement } from "react";
import UserForm from "./user_attributes_form/user_form";
import UserProfileForm from "./user_attributes_form/user_profile_form";
import { QuestionnaireNewResponse } from "../../../../../models/QuestionnaireApi";
import { QuestionnaireNewUserResponse } from "../../../../../models/UserApi";
import { UserProfileMyselfResponse } from "../../../../../models/UserProfile";
import { EventResponse } from "../../../../../models/Event";
import { MemberResponse } from "../../../../../models/Member";
import { DepartmentResponse } from "../../../../../models/Department";
import { UserSettingResponse } from "../../../../../models/UserSetting";
import { IndustryCategoryWithIndustriesResponse } from "../../../../../models/IndustryCategoryApi";

interface Props {
  isConsumer: boolean;
  questionnaire: QuestionnaireNewResponse;
  user: QuestionnaireNewUserResponse | null;
  isProfileInputAvailable: boolean;
  userProfile: UserProfileMyselfResponse | null;
  birthYearOptions: Array<{ key: string; value: string }>;
  birthMonthOptions: Array<{ key: string; value: string }>;
  sexOptions: Array<{ key: string; value: string }>;
  finalEducationOptions: Array<{ key: string; value: string }>;
  professionOptions: Array<{ key: string; value: string }>;
  personalAnnualIncomeOptions: Array<{ key: string; value: string }>;
  householdAnnualIncomeOptions: Array<{ key: string; value: string }>;
  maritalStatusOptions: Array<{ key: string; value: string }>;
  childStatusOptions: Array<{ key: string; value: string }>;
  prefectureOptions: Array<{ key: string; value: string }>;
  countryOptions: Array<{ key: string; value: string }>;
  event: EventResponse | null;
  isLoggedIn: boolean;
  selfIntroductionQuestions: unknown;
  selfIntroductionAnswers: unknown;
  departmentOptions: Array<{ key: string; value: string }>;
  managerialPositionOptions: Array<{ key: string; value: string }>;
  members: MemberResponse[];
  departments: DepartmentResponse[];
  userSetting: UserSettingResponse;
  industryCategories: IndustryCategoryWithIndustriesResponse[];
}

export default function UserAttributesForm(props: Props): ReactElement {
  const {
    isConsumer,
    questionnaire,
    user,
    isProfileInputAvailable,
    userProfile,
    birthYearOptions,
    birthMonthOptions,
    sexOptions,
    finalEducationOptions,
    professionOptions,
    personalAnnualIncomeOptions,
    householdAnnualIncomeOptions,
    maritalStatusOptions,
    childStatusOptions,
    prefectureOptions,
    countryOptions,
    event,
    isLoggedIn,
    userSetting,
    industryCategories,
  } = props;

  return (
    <React.Fragment key="profileBox">
      <div className="my-2 text-center">
        {!isLoggedIn && (
          <span className="font-weight-bold">
            {window.I18n.t(
              "javascript.questionnaires.happiness_questionnaire.profile_input_start_message"
            )}
          </span>
        )}
      </div>
      <UserForm
        isConsumer={isConsumer}
        user={user}
        questionnaire={questionnaire}
      />
      <UserProfileForm
        isConsumer={isConsumer}
        user={user}
        questionnaire={questionnaire}
        isProfileInputAvailable={isProfileInputAvailable}
        userProfile={userProfile}
        birthYearOptions={birthYearOptions}
        birthMonthOptions={birthMonthOptions}
        sexOptions={sexOptions}
        finalEducationOptions={finalEducationOptions}
        professionOptions={professionOptions}
        personalAnnualIncomeOptions={personalAnnualIncomeOptions}
        householdAnnualIncomeOptions={householdAnnualIncomeOptions}
        maritalStatusOptions={maritalStatusOptions}
        childStatusOptions={childStatusOptions}
        prefectureOptions={prefectureOptions}
        countryOptions={countryOptions}
        event={event}
        isLoggedIn={isLoggedIn}
        userSetting={userSetting}
        industryCategories={industryCategories}
      />
    </React.Fragment>
  );
}
