import PropTypes from "prop-types";
import React from "react";
import Button from "../../../shared/button";
import InitialSettingMailResendModal from "./initial_setting_mail_resend_form/initial_setting_mail_resend_modal";

class InitialSettingMailResendForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMailTemplate: false,
    };
  }

  render() {
    const {
      member: { user },
      userActionPath,
    } = this.props;
    const { showMailTemplate } = this.state;
    if (user.dummy_email_flg) {
      return null;
    }
    return (
      <React.Fragment>
        {showMailTemplate && (
          <InitialSettingMailResendModal
            user={user}
            userActionPath={userActionPath}
            showModal={showMailTemplate}
            onClose={() => this.setState({ showMailTemplate: false })}
          />
        )}
        {user.bounced_flg && (
          <div>
            <div className="font__size-14 color__red">
              診断依頼メールの配信が失敗しています。
              <br />
              {`「${user.email}」が有効か確認後、はぴテックサポート（support@happytech.co.jp）までご連絡ください`}
            </div>
          </div>
        )}
        <div
          className="alert alert-light d-flex justify-content-between align-items-center"
          role="alert"
          style={{ padding: "0.25rem 0rem" }}
        >
          <div className="font__size-15" />
          <div>
            <Button
              title="診断依頼メール再送信"
              className="btn btn-light btn-sm"
              disabled={user.bounced_flg}
              onClick={() => this.setState({ showMailTemplate: true })}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

InitialSettingMailResendForm.propTypes = {
  userActionPath: PropTypes.string.isRequired,
  member: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  beforeApiRequest: PropTypes.func.isRequired,
  afterApiRequest: PropTypes.func.isRequired,
};
InitialSettingMailResendForm.defaultProps = {};
export default InitialSettingMailResendForm;
