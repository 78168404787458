import React from 'react';
import PropTypes from 'prop-types';
import DepartmentItem from './shared/department_item';

class DepartmentItemForSelecting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { department, onClick } = this.props;
    return (
      <DepartmentItem
        department={department}
        onClick={() => onClick()}
      />
    );
  }
}

DepartmentItemForSelecting.propTypes = {
  department: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};
DepartmentItemForSelecting.defaultProps = {
};
export default DepartmentItemForSelecting;
