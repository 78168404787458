import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { getRequest } from './api_client';
import { parse } from './image_parser';

export function getS3PostUrls(count, path) {
  return getRequest(
    '/aws/s3_direct_post_urls',
    {
      count,
      path,
      // 同一パラメータのリクエストだとsafariでcacheが消えず、連続でリクエスト投げても1リクエストしか処理してくれない問題の対応
      nocache: uuidv4(),
    },
  );
}

export function putImagetoS3(imageFile, postUrl) {
  const formData = new FormData();
  formData.append('file', imageFile);
  return axios({
    method: 'put',
    url: postUrl,
    data: imageFile,
    headers: {
      'Content-Type': imageFile.type,
    },
  });
}

export function putFiletoS3(file, postUrl) {
  const formData = new FormData();
  formData.append('file', file);
  return axios({
    method: 'put',
    url: postUrl,
    data: file,
    headers: {
      'Content-Type': file.type,
    },
  });
}

export function uploadImage(file, path, beforeUploading, afterUploading, afterFailed, option = {}) {
  if (!file) {
    return;
  }
  const maxWidth = option.maxWith || 1000;
  const maxHeight = option.maxHeight || 1000;
  const opt = {
    crop: false,
    maxWidth,
    maxHeight,
  };
  beforeUploading();
  parse(file, opt, (parsedFile) => {
    getS3PostUrls(1, path)
      .then((results) => {
        const { urls } = results.data;
        return putImagetoS3(parsedFile, urls[0]);
      })
      .then((results) => {
        const { config: { url } } = results;
        const reader = new FileReader();
        reader.onload = (evt) => {
          afterUploading({ url, dataUri: evt.target.result });
        };
        reader.readAsDataURL(parsedFile);
      })
      .catch((error) => {
        afterFailed();
      });
  });
}

export function uploadFile(file, path, beforeUploading, afterUploading, afterFailed) {
  if (!file) {
    return;
  }
  beforeUploading();
  getS3PostUrls(1, path)
    .then((results) => {
      const { urls } = results.data;
      return putFiletoS3(file, urls[0]);
    })
    .then((results) => {
      const { config: { url } } = results;
      afterUploading({ url });
    })
    .catch((error) => {
      afterFailed();
    });
}
