import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../shared/button';
import TopLevelDepartmentCreateButton from './header_action/top_level_department_create_button'

class HeaderAction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.expandAll = this.expandAll.bind(this);
    this.closeAll = this.closeAll.bind(this);
  }

  expandAll() {
    const { onExpandAll } = this.props;
    onExpandAll();
  }

  closeAll() {
    const { onCloseAll } = this.props;
    onCloseAll();
  }

  render() {
    const {afterCreated} = this.props;
    return (
      <div className="row">
        <div className="col-sm-12">
          <Button
            title="全て展開"
            onClick={this.expandAll}
            className="btn react-department-list__expand-button"
          />
          <Button
            title="全て折りたたむ"
            onClick={this.closeAll}
            className="btn react-department-list__close-button"
          />
          <TopLevelDepartmentCreateButton afterCreated={afterCreated} />
        </div>
      </div>
    );
  }
}

HeaderAction.propTypes = {
  onExpandAll: PropTypes.func.isRequired,
  onCloseAll: PropTypes.func.isRequired,
  afterCreated:PropTypes.func.isRequired
};
HeaderAction.defaultProps = {
};
export default HeaderAction;
