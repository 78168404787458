import React from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import { uploadFile } from '../../../../utils/aws_s3_service';
import SpinGray from '../../images/spin-gray.svg';
import FileName from '../../../file_resources/file_name';

class FileUploader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uploaded: false,
    };
  }

  componentDidMount() {
    this.upload();
  }

  upload() {
    const { file, afterUploaded, afterFailed } = this.props;
    uploadFile(
      file,
      'user/file_resource',
      () => {},
      ({ url }) => {
        this.setState({ uploaded: true }, () => {
          afterUploaded(url);
        });
      },
      () => afterFailed()
    );
  }

  render() {
    const { file } = this.props;
    const { uploaded } = this.state;
    if (uploaded) {
      return null;
    }
    return (
      <div className="d-flex align-items-center p-1">
        <ReactSVG
          src={SpinGray}
          className="mr-2 cursor__pointer color__gray"
          beforeInjection={(svg) => {
            svg.classList.add('');
            svg.setAttribute('style', 'width: 17px;height: 17px;margin-top: -2px;');
          }}
        />
        <FileName name={file.name} />
      </div>
    );
  }
}

FileUploader.propTypes = {
  file: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  afterUploaded: PropTypes.func.isRequired,
  afterFailed: PropTypes.func.isRequired,
};
FileUploader.defaultProps = {
};
export default FileUploader;
