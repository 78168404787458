import React, { ReactElement, useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import TextareaAutosize from "react-textarea-autosize";
import Button from "../../shared/button";
import MultiLineText from "../../shared/multi_line_text";

interface Props {
  disabled?: boolean;
  onSend: (message: string) => void;
  showExampleButtons: boolean;
}
function ExampleInputButton(props: {
  questionMessage: string;
  onClick: () => void;
}): ReactElement {
  const { questionMessage, onClick } = props;
  return (
    <div className="p-2">
      <Button
        className="p-2 button__trivial cursor__pointer text-center color__gray"
        onClick={onClick}
      >
        <div>
          <MultiLineText text={questionMessage} />
        </div>
      </Button>
    </div>
  );
}

export default function ChatInput(props: Props): ReactElement {
  const { disabled = false, onSend, showExampleButtons } = props;
  const [refreshingTextarea, setRefreshingTextarea] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (refreshingTextarea) {
      setRefreshingTextarea(false);
    }
  }, [refreshingTextarea]);

  useEffect(() => {
    if (!disabled) {
      if (textareaRef.current !== null) {
        textareaRef.current.focus();
      }
    }
  }, [disabled]);

  const sendMessage = () => {
    if (textareaRef.current !== null) {
      const val = textareaRef.current?.value;
      const fixedVal = val.trim();
      if (fixedVal !== "") {
        onSend(fixedVal);
        setRefreshingTextarea(true);
      } else {
        window.alert("文字が入力されていません");
      }
    }
  };

  return (
    <>
      {showExampleButtons && (
        <div
          className="py-3 bgcolor__white"
          style={{ borderTop: "solid 1px lightgray" }}
        >
          <div className="text-center color__gray">例</div>
          <div>
            <div className="container">
              <div className="row">
                <div className="col-sm">
                  <ExampleInputButton
                    questionMessage={`ウェルビーイングって\nなんなんですか？`}
                    onClick={() => {
                      onSend("ウェルビーイングってなんなんですか？");
                    }}
                  />
                </div>
                <div className="col-sm">
                  <ExampleInputButton
                    questionMessage={`自己肯定力を高めるには\nどうしたら良いですか？`}
                    onClick={() => {
                      onSend("自己肯定力を高めるにはどうしたら良いですか？");
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm">
                  <ExampleInputButton
                    questionMessage={`職場のコミュニケーションを\n活発にする方法は？`}
                    onClick={() => {
                      onSend("職場のコミュニケーションを活発にする方法は？");
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="d-flex justify-content-between p-2 mb-2">
        <div className="flex-fill">
          {!refreshingTextarea && (
            <TextareaAutosize
              ref={textareaRef}
              className="form-control shadow-sm"
              style={{ width: "100%", resize: "none" }}
              minRows={2}
              maxRows={10}
              placeholder={`質問や相談を入力して下さい。\nその際に、より詳しく状況や内容を記載頂くと、より精緻な回答が可能です。`}
              disabled={disabled}
              onKeyDown={(e) => {
                if (
                  !isMobile &&
                  e.code === "Enter" &&
                  !e.nativeEvent.isComposing &&
                  !e.nativeEvent.shiftKey
                ) {
                  e.preventDefault();
                  sendMessage();
                }
              }}
            />
          )}
        </div>
        <div className="d-flex align-items-end ml-2">
          <Button
            title="送信"
            className="btn button__primary"
            onClick={sendMessage}
            disabled={disabled}
          />
        </div>
      </div>
    </>
  );
}
