import PropTypes from "prop-types";
import React from "react";
import { deleteRequest } from "../../../utils/api_client";
import BaseForm, { ajaxSubmit } from "../../shared/base_form";
import BaseMoal from "../../shared/base_modal";
import Button from "../../shared/button";
import TextForm from "../../shared/form/text_form";

class DutyEditModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
    this.save = this.save.bind(this);
    this.delete = this.delete.bind(this);
  }

  save() {
    const form = document.forms.memberEditForm;
    if (form.checkValidity()) {
      this.setState({ loading: true }, () => {
        ajaxSubmit(form)
          .then((r) => {
            const { redirect_url: url } = r.data;
            window.location.href = url;
          })
          .catch((e) => {
            this.setState({ loading: false }, () => {
              window.alert("エラーが発生したため保存に失敗しました");
            });
          });
      });
    } else {
      // 検証結果がfalseなのでsubmitしてhtml5のvalidationメッセージを表示させる
      const submit = document.getElementById("dutyEditSubmit");
      submit.click();
    }
  }

  delete() {
    const { action, duty } = this.props;
    if (duty.members_count > 0) {
      window.alert(
        `この職務のメンバーが${duty.members_count}人います。他の職務に変更後に削除してください。`
      );
      return;
    }
    if (window.confirm("本当に職務を削除してよろしいですか？")) {
      this.setState({ loading: true }, () => {
        deleteRequest(`${action}/${duty.id}`)
          .then((r) => {
            const { redirect_url: url } = r.data;
            window.location.href = url;
          })
          .catch((e) => {
            this.setState({ loading: false }, () => {
              window.alert("削除に失敗しました");
            });
          });
      });
    }
  }

  render() {
    const { action, showModal, onCloseButtonClicked, duty } = this.props;
    const { loading } = this.state;
    return (
      <React.Fragment>
        <BaseMoal
          show={showModal}
          title={`職務${duty.id ? "編集" : "追加"}`}
          contentLabel="edit_duty"
          onCloseModal={() => onCloseButtonClicked()}
          loading={loading}
        >
          <BaseForm
            name="memberEditForm"
            action={duty.id ? `${action}/${duty.id}` : action}
            method={duty.id ? "put" : "post"}
          >
            <BaseMoal.Body>
              <div className="container-fluid">
                <div>
                  <TextForm
                    model={duty}
                    scope="duty"
                    columnName="name"
                    label="名称"
                    required
                  />
                </div>
              </div>
            </BaseMoal.Body>
            <div className="modal-footer">
              {duty.id && duty.role === "basic_role" && (
                <Button
                  title="削除"
                  className="btn btn-link"
                  style={{ position: "absolute", left: 20, color: "gray" }}
                  onClick={this.delete}
                />
              )}
              <button
                type="submit"
                id="dutyEditSubmit"
                style={{ border: "none", padding: 0 }}
              />
              <button
                type="button"
                className="btn btn-light"
                onClick={() => onCloseButtonClicked()}
              >
                閉じる
              </button>
              <Button
                title="保存"
                className="btn button__primary"
                onClick={this.save}
              />
            </div>
          </BaseForm>
        </BaseMoal>
      </React.Fragment>
    );
  }
}

DutyEditModal.propTypes = {
  duty: PropTypes.shape({
    id: PropTypes.number,
  }),
  action: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired,
  onCloseButtonClicked: PropTypes.func.isRequired,
};
DutyEditModal.defaultProps = {
  duty: {},
};
export default DutyEditModal;
