import React from 'react';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns';
import WorkPostActionMenuIcon from './work_post_action_menu_icon';
import PostReaction from '../reactions/post_reaction';
import CommentList from '../comments/comment_list';
import CommentInput from '../comments/comment_input';
import AnswerList from '../answers/answer_list';
import WorkOveview from '../works/work_overview';
import UserNameLink from '../users/user_name_link';
import UserIcon from '../users/user_icon';
import DepartmentFilterNames from '../department_filters/department_filter_names';
import ProfileList from '../display_profiles/profile_list';

class WorkPostItem extends React.Component {
  constructor(props) {
    super(props);
    const { workPost } = this.props;
    const { comments } = workPost;
    this.state = {
      workPost,
      comments,
      reactionCount: workPost.good_reactions_count,
    };
    this.commentInputRef = React.createRef();
    this.addComment = this.addComment.bind(this);
  }

  addComment(comment) {
    const { comments } = this.state;
    this.setState({
      comments: comments.concat([comment]),
    });
  }

  render() {
    const {
      user, currentUser, myselfUserProfile, keyword,
    } = this.props;
    const { reactionCount, workPost, comments } = this.state;
    if (!workPost) {
      return null;
    }
    const {
      organizational_departments: organizationalDepartments,
      user_profile: displayUserProfile,
    } = user;
    const { work, answers } = workPost;
    return (
      <React.Fragment>
        <div className="card mb-3">
          <ul className="list-group list-group-flush">
            <li className="list-group-item border-bottom-0">
              <div className="d-flex">
                <div>
                  <UserIcon
                    user={user}
                    className="image__thumbnail-small"
                  />
                </div>
                <div className="flex-fill pl-2" style={{ wordBreak: 'break-all' }}>
                  <div className="font__size-13 color__gray">
                    {`所属部署: ${organizationalDepartments.map(d => d.name).join('・')}`}
                  </div>
                  <div>
                    <UserNameLink
                      user={user}
                      className="color__dark-blue link__textd-none"
                    />
                  </div>
                </div>
                <div style={{ width: 80 }} />
              </div>
              <div className="mt-2 color__gray font__size-12">
                {format(parseISO(workPost.actual_time), 'MM月dd日 HH:mm')}
              </div>
              <DepartmentFilterNames
                caption="公開範囲"
                departmentFilters={workPost.department_filters}
              />
            </li>
            <li className="list-group-item border-top-0">
              <div className="font__size-13 company-timeline-index__post-work-title-container">
                <WorkOveview work={work} showImage />
                <ProfileList
                  displayProfiles={work.display_profiles}
                  userProfile={displayUserProfile}
                />
              </div>
              <div className="mt-3" style={{ wordBreak: 'break-all' }}>
                <AnswerList
                  answers={answers}
                  keyword={keyword}
                />
              </div>
              <div className="font__size-13 color__gray mt-2">
                <span>
                  {`いいね ${reactionCount}件`}
                </span>
                <span className="float-right">
                  {`コメント件数 ${workPost.comments_count}件`}
                </span>
              </div>
            </li>
            <li className="list-group-item py-0 mb-2">
              <PostReaction
                baseUrl={`/wb/user/company/work_posts/${workPost.id}`}
                reactedGood={workPost.reacted_good}
                onGoodReactionButtonClicked={isReacted => this.setState({
                  reactionCount: isReacted ? reactionCount + 1 : reactionCount - 1,
                })}
                onCommentButtonClicked={() => this.commentInputRef.current.focus()}
              />
            </li>
            {(comments.length > 0) && (
              <CommentList
                comments={comments}
                currentUser={currentUser}
              />
            )}
            <CommentInput
              ref={this.commentInputRef}
              currentUser={currentUser}
              baseUrl={`/wb/user/company/work_posts/${workPost.id}/comments`}
              afterSaved={this.addComment}
            />
          </ul>
          <div
            style={{
              position: 'absolute',
              top: 15,
              right: 20,
              zIndex: 1,
            }}
          >
            <WorkPostActionMenuIcon
              currentUser={currentUser}
              postUser={user}
              workPost={workPost}
              work={work}
              afterSaved={editedWp => this.setState({ workPost: editedWp })}
              afterDeleted={() => this.setState({ workPost: null })}
              userProfile={myselfUserProfile}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

WorkPostItem.propTypes = {
  currentUser: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  workPost: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  myselfUserProfile: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  keyword: PropTypes.string.isRequired,
};
WorkPostItem.defaultProps = {
};
export default WorkPostItem;
