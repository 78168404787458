import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../shared/button';
import BaseMoal from '../../shared/base_modal';
import BaseForm, { ajaxSubmit } from '../../shared/base_form';
import TextareaForm from '../../shared/form/textarea_form';
import CheckboxForm from '../../shared/form/checkbox_form';

const baseUrl = '/user/questionnaires';

class QuestionnaireNoteForm extends React.Component {
  constructor(props) {
    super(props);
    const { questionnaire } = this.props;
    this.state = {
      questionnaire,
    };
    this.save = this.save.bind(this);
  }

  save() {
    const { beforeSaved, afterSaved, afterFailed } = this.props;
    const form = document.forms.questionnaireNoteEditForm;
    if (form.checkValidity()) {
      beforeSaved(() => {
        ajaxSubmit(form)
          .then((r) => {
            const { data: questionnaire } = r;
            afterSaved(questionnaire);
          })
          .catch((e) => {
            const { data, status } = e.response;
            if (status === 402) {
              this.setState({ questionnaire: data }, () => afterFailed());
            } else {
              afterFailed();
            }
          });
      });
    } else {
      // 検証結果がfalseなのでsubmitしてhtml5のvalidationメッセージを表示させる
      const submit = document.getElementById('questionnaireNoteEditSubmit');
      submit.click();
    }
  }

  render() {
    const { onCloseButtonClicked, currentUser } = this.props;
    const { questionnaire } = this.state;

    return (
      <BaseForm
        name="questionnaireNoteEditForm"
        action={`${baseUrl}/${questionnaire.id}`}
        method="put"
      >
        <BaseMoal.Body>
          <div>
            <TextareaForm
              scope="questionnaire"
              model={questionnaire}
              columnName="note"
              rows={7}
              placeholder={window.I18n.t('javascript.questionnaires.questionnaire_notes.note_placeholder')}
              hideLabel
            />
            {(questionnaire.event_paticipant_id || !currentUser.is_consumer) && (
              <div className="color__gray font__size-14">
                {window.I18n.t('javascript.questionnaires.questionnaire_notes.publish_scope')}
              </div>
            )}
            {questionnaire.event_paticipant_id && (
              <CheckboxForm
                title={window.I18n.t('activerecord.attributes.questionnaire.note_event_owner_visible')}
                scope="questionnaire"
                columnName="note_event_owner_visible"
                defaultChecked={questionnaire.note_event_owner_visible}
              />
            )}
            {!currentUser.is_consumer && (
              <CheckboxForm
                title={window.I18n.t('activerecord.attributes.questionnaire.note_business_admin_visible')}
                scope="questionnaire"
                columnName="note_business_admin_visible"
                defaultChecked={questionnaire.note_business_admin_visible}
              />
            )}
          </div>
        </BaseMoal.Body>
        <div className="modal-footer">
          <button type="submit" id="questionnaireNoteEditSubmit" style={{ border: 'none', padding: 0 }} />
          <button type="button" className="btn btn-light" onClick={() => onCloseButtonClicked()}>
            {window.I18n.t('javascript.questionnaires.questionnaire_notes.close_modal')}
          </button>
          <Button
            title={window.I18n.t('javascript.questionnaires.questionnaire_notes.save_form')}
            className="btn button__primary"
            onClick={this.save}
          />
        </div>
      </BaseForm>
    );
  }
}

QuestionnaireNoteForm.propTypes = {
  questionnaire: PropTypes.shape({
    id: PropTypes.number,
  }),
  onCloseButtonClicked: PropTypes.func.isRequired,
  beforeSaved: PropTypes.func.isRequired,
  afterSaved: PropTypes.func.isRequired,
  afterFailed: PropTypes.func.isRequired,
  currentUser: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};
QuestionnaireNoteForm.defaultProps = {
  questionnaire: {},
};
export default QuestionnaireNoteForm;
