import React from 'react';
import PropTypes from 'prop-types';
import BaseMoal from '../shared/base_modal';
import WorkList from './work_list';
import WorkListModeType from './work_list/mode_type';

class WorkSelectModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  render() {
    const {
      showModal, onCloseButtonClicked, onSelected,
    } = this.props;
    const { loading } = this.state;
    return (
      <React.Fragment>
        <BaseMoal
          show={showModal}
          title="ワーク選択"
          contentLabel="editWorkPost"
          onCloseModal={() => onCloseButtonClicked()}
          loading={loading}
        >
          <BaseMoal.Body fixed>
            <div style={{ height: '100%', overflow: 'auto' }}>
              <WorkList
                mode={WorkListModeType.SELECT}
                onSelected={w => onSelected(w)}
                useWindow={false}
              />
            </div>
          </BaseMoal.Body>
        </BaseMoal>
      </React.Fragment>
    );
  }
}

WorkSelectModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onCloseButtonClicked: PropTypes.func.isRequired,
  onSelected: PropTypes.func.isRequired,
};
WorkSelectModal.defaultProps = {
};
export default WorkSelectModal;
