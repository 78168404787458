import React from 'react';
import PropTypes from 'prop-types';
import BaseMoal from '../shared/base_modal';
import NotificationSettingForm from './notification_setting_form';
import { redirectToNotificationSetting } from '../../utils/redirect_path';

class NotificationSettingEditModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  render() {
    const {
      showModal, onCloseButtonClicked, user,
    } = this.props;
    const { loading } = this.state;
    return (
      <React.Fragment>
        <BaseMoal
          show={showModal}
          title="部署投稿の通知"
          contentLabel="editDepartmentUserNotificationSetting"
          onCloseModal={() => onCloseButtonClicked()}
          loading={loading}
          contentStyle={{ maxWidth: 496, width: '100%' }}
        >
          <NotificationSettingForm
            user={user}
            onCloseButtonClicked={() => onCloseButtonClicked()}
            beforeSaved={callback => this.setState({ loading: true }, callback)}
            afterSaved={() => redirectToNotificationSetting()}
            afterFailed={() => this.setState({ loading: false })}
          />
        </BaseMoal>
      </React.Fragment>
    );
  }
}

NotificationSettingEditModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onCloseButtonClicked: PropTypes.func.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
  }),
};
NotificationSettingEditModal.defaultProps = {
  user: {},
};
export default NotificationSettingEditModal;
