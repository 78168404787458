import React, { ReactElement } from "react";
import Multi_line_text from "../multi_line_text";

interface Props {
  locale: "ja" | "en";
  classNamePrefix?: "well_being_circle" | "well_being_circle_in_report";
}
export default function ElementLabels(props: Props): ReactElement {
  const { locale, classNamePrefix = "well_being_circle" } = props;
  return (
    <div className={`${classNamePrefix}__label-container`}>
      <div className={`${classNamePrefix}__label-wrapper`}>
        <span
          className={`${classNamePrefix}__label-1-per-34th ${classNamePrefix}__label-1-per-34th__adjust-${locale}`}
        >
          <Multi_line_text
            text={window.I18n.t(
              "enums.happiness_question.happiness_element.well_being_satisfaction"
            )}
          />
        </span>
        <span
          className={`${classNamePrefix}__label-2-per-34th ${classNamePrefix}__label-2-per-34th__adjust-${locale}`}
        >
          <Multi_line_text
            text={window.I18n.t(
              "enums.happiness_question.happiness_element.well_being_positive_emotion"
            )}
          />
        </span>
        <span
          className={`${classNamePrefix}__label-3-per-34th ${classNamePrefix}__label-3-per-34th__adjust-${locale}`}
        >
          <Multi_line_text
            text={window.I18n.t(
              "enums.happiness_question.happiness_element.try_drawing_vision"
            )}
          />
        </span>
        <span
          className={`${classNamePrefix}__label-4-per-34th ${classNamePrefix}__label-4-per-34th__adjust-${locale}`}
        >
          <Multi_line_text
            text={window.I18n.t(
              "enums.happiness_question.happiness_element.try_strength"
            )}
          />
        </span>
        <span
          className={`${classNamePrefix}__label-5-per-34th ${classNamePrefix}__label-5-per-34th__adjust-${locale}`}
        >
          <Multi_line_text
            text={window.I18n.t(
              "enums.happiness_question.happiness_element.try_immersion"
            )}
          />
        </span>
        <span
          className={`${classNamePrefix}__label-6-per-34th ${classNamePrefix}__label-6-per-34th__adjust-${locale}`}
        >
          <Multi_line_text
            text={window.I18n.t(
              "enums.happiness_question.happiness_element.try_enjoying"
            )}
          />
        </span>
        <span
          className={`${classNamePrefix}__label-7-per-34th ${classNamePrefix}__label-7-per-34th__adjust-${locale}`}
        >
          <Multi_line_text
            text={window.I18n.t(
              "enums.happiness_question.happiness_element.try_growth_motivation"
            )}
          />
        </span>
        <span
          className={`${classNamePrefix}__label-8-per-34th ${classNamePrefix}__label-8-per-34th__adjust-${locale}`}
        >
          <Multi_line_text
            text={window.I18n.t(
              "enums.happiness_question.happiness_element.try_creativity"
            )}
          />
        </span>
        <span
          className={`${classNamePrefix}__label-9-per-34th ${classNamePrefix}__label-9-per-34th__adjust-${locale}`}
        >
          <Multi_line_text
            text={window.I18n.t(
              "enums.happiness_question.happiness_element.try_self_affirmation"
            )}
          />
        </span>
        <span
          className={`${classNamePrefix}__label-10-per-34th ${classNamePrefix}__label-10-per-34th__adjust-${locale}`}
        >
          <Multi_line_text
            text={window.I18n.t(
              "enums.happiness_question.happiness_element.thanks_appreciation"
            )}
          />
        </span>
        <span
          className={`${classNamePrefix}__label-11-per-34th ${classNamePrefix}__label-11-per-34th__adjust-${locale}`}
        >
          <Multi_line_text
            text={window.I18n.t(
              "enums.happiness_question.happiness_element.thanks_altruistic"
            )}
          />
        </span>
        <span
          className={`${classNamePrefix}__label-12-per-34th ${classNamePrefix}__label-12-per-34th__adjust-${locale}`}
        >
          <Multi_line_text
            text={window.I18n.t(
              "enums.happiness_question.happiness_element.thanks_allowance"
            )}
          />
        </span>
        <span
          className={`${classNamePrefix}__label-13-per-34th ${classNamePrefix}__label-13-per-34th__adjust-${locale}`}
        >
          <Multi_line_text
            text={window.I18n.t(
              "enums.happiness_question.happiness_element.thanks_building_trust_relationship"
            )}
          />
        </span>
        <span
          className={`${classNamePrefix}__label-14-per-34th ${classNamePrefix}__label-14-per-34th__adjust-${locale}`}
        >
          <Multi_line_text
            text={window.I18n.t(
              "enums.happiness_question.happiness_element.thanks_communication"
            )}
          />
        </span>
        <span
          className={`${classNamePrefix}__label-15-per-34th ${classNamePrefix}__label-15-per-34th__adjust-${locale}`}
        >
          <Multi_line_text
            text={window.I18n.t(
              "enums.happiness_question.happiness_element.work_out_challenge"
            )}
          />
        </span>
        <span
          className={`${classNamePrefix}__label-16-per-34th ${classNamePrefix}__label-16-per-34th__adjust-${locale}`}
        >
          <Multi_line_text
            text={window.I18n.t(
              "enums.happiness_question.happiness_element.work_out_optimistic"
            )}
          />
        </span>
        <span
          className={`${classNamePrefix}__label-17-per-34th ${classNamePrefix}__label-17-per-34th__adjust-${locale}`}
        >
          <Multi_line_text
            text={window.I18n.t(
              "enums.happiness_question.happiness_element.as_is_my_own_pace"
            )}
          />
        </span>
        <span
          className={`${classNamePrefix}__label-18-per-34th ${classNamePrefix}__label-18-per-34th__adjust-${locale}`}
        >
          <Multi_line_text
            text={window.I18n.t(
              "enums.happiness_question.happiness_element.big_five_energetic"
            )}
          />
        </span>
        <span
          className={`${classNamePrefix}__label-19-per-34th ${classNamePrefix}__label-19-per-34th__adjust-${locale}`}
        >
          <Multi_line_text
            text={window.I18n.t(
              "enums.happiness_question.happiness_element.big_five_friendly"
            )}
          />
        </span>
        <span
          className={`${classNamePrefix}__label-20-per-34th ${classNamePrefix}__label-20-per-34th__adjust-${locale}`}
        >
          <Multi_line_text
            text={window.I18n.t(
              "enums.happiness_question.happiness_element.big_five_serious"
            )}
          />
        </span>
        <span
          className={`${classNamePrefix}__label-21-per-34th ${classNamePrefix}__label-21-per-34th__adjust-${locale}`}
        >
          <Multi_line_text
            text={window.I18n.t(
              "enums.happiness_question.happiness_element.big_five_emotional_stability"
            )}
          />
        </span>
        <span
          className={`${classNamePrefix}__label-22-per-34th ${classNamePrefix}__label-22-per-34th__adjust-${locale}`}
        >
          <Multi_line_text
            text={window.I18n.t(
              "enums.happiness_question.happiness_element.big_five_interest"
            )}
          />
        </span>
        <span
          className={`${classNamePrefix}__label-23-per-34th ${classNamePrefix}__label-23-per-34th__adjust-${locale}`}
        >
          <Multi_line_text
            text={window.I18n.t(
              "enums.happiness_question.happiness_element.health_subjective_health_view"
            )}
          />
        </span>
        <span
          className={`${classNamePrefix}__label-24-per-34th ${classNamePrefix}__label-24-per-34th__adjust-${locale}`}
        >
          <Multi_line_text
            text={window.I18n.t(
              "enums.happiness_question.happiness_element.stress_low_stress"
            )}
          />
        </span>
        <span
          className={`${classNamePrefix}__label-25-per-34th ${classNamePrefix}__label-25-per-34th__adjust-${locale}`}
        >
          <Multi_line_text
            text={window.I18n.t(
              "enums.happiness_question.happiness_element.social_happiness_safe_and_secure"
            )}
          />
        </span>
        <span
          className={`${classNamePrefix}__label-26-per-34th ${classNamePrefix}__label-26-per-34th__adjust-${locale}`}
        >
          <Multi_line_text
            text={window.I18n.t(
              "enums.happiness_question.happiness_element.social_happiness_trusted_region"
            )}
          />
        </span>
        <span
          className={`${classNamePrefix}__label-27-per-34th ${classNamePrefix}__label-27-per-34th__adjust-${locale}`}
        >
          <Multi_line_text
            text={window.I18n.t(
              "enums.happiness_question.happiness_element.workplace_happiness_safe_and_secure"
            )}
          />
        </span>
        <span
          className={`${classNamePrefix}__label-28-per-34th ${classNamePrefix}__label-28-per-34th__adjust-${locale}`}
        >
          <Multi_line_text
            text={window.I18n.t(
              "enums.happiness_question.happiness_element.workplace_happiness_trusted_workplace"
            )}
          />
        </span>
        <span
          className={`${classNamePrefix}__label-29-per-34th ${classNamePrefix}__label-29-per-34th__adjust-${locale}`}
        >
          <Multi_line_text
            text={window.I18n.t(
              "enums.happiness_question.happiness_element.workplace_happiness_recommend_challenge"
            )}
          />
        </span>
        <span
          className={`${classNamePrefix}__label-30-per-34th ${classNamePrefix}__label-30-per-34th__adjust-${locale}`}
        >
          <Multi_line_text
            text={window.I18n.t(
              "enums.happiness_question.happiness_element.workplace_happiness_net_promoter_score"
            )}
          />
        </span>
        <span
          className={`${classNamePrefix}__label-31-per-34th ${classNamePrefix}__label-31-per-34th__adjust-${locale}`}
        >
          <Multi_line_text
            text={window.I18n.t(
              "enums.happiness_question.happiness_element.status_material_income"
            )}
          />
        </span>
        <span
          className={`${classNamePrefix}__label-32-per-34th ${classNamePrefix}__label-32-per-34th__adjust-${locale}`}
        >
          <Multi_line_text
            text={window.I18n.t(
              "enums.happiness_question.happiness_element.status_material_property"
            )}
          />
        </span>
        <span
          className={`${classNamePrefix}__label-33-per-34th ${classNamePrefix}__label-33-per-34th__adjust-${locale}`}
        >
          <Multi_line_text
            text={window.I18n.t(
              "enums.happiness_question.happiness_element.status_material_social_status"
            )}
          />
        </span>
        <span
          className={`${classNamePrefix}__label-34-per-34th ${classNamePrefix}__label-34-per-34th__adjust-${locale}`}
        >
          <Multi_line_text
            text={window.I18n.t(
              "enums.happiness_question.happiness_element.status_material_actual_results"
            )}
          />
        </span>
      </div>
    </div>
  );
}
