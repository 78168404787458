import React from 'react';
import PropTypes from 'prop-types';
import SelectForm from '../form/select_form';
import BaseForm, { ajaxSubmit } from '../base_form';

class LinkAuthForm extends React.Component {
  constructor(props) {
    super(props);
    const { model } = this.props;
    this.state = {
      mode: '',
      model,
    };
    this.save = this.save.bind(this);
  }

  save(e) {
    const form = document.forms.linkAuthEditForm;
    this.setState({ mode: 'saving' }, () => {
      ajaxSubmit(form)
        .then((r) => {
          const editedModel = r.data;
          this.setState({
            mode: 'saved',
            model: editedModel,
          });
        });
    });
  }

  render() {
    const {
      modelName, linkAuthOptions, authUpdatePath,
    } = this.props;
    const { mode, model } = this.state;
    let saveStateName = '';
    if (mode === 'saving') {
      saveStateName = '保存中...';
    } else if (mode === 'saved') {
      saveStateName = '保存完了';
    }
    return (
      <React.Fragment>
        <BaseForm
          name="linkAuthEditForm"
          action={authUpdatePath}
          method="put"
        >
          <SelectForm
            model={model}
            scope={modelName}
            label="閲覧権限"
            columnName="link_auth"
            options={linkAuthOptions}
            onChange={this.save}
          />
        </BaseForm>
        <div style={{ marginTop: -10 }}>
          <div className="float-right font__size-13 color__gray">
            {saveStateName}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

LinkAuthForm.propTypes = {
  modelName: PropTypes.string.isRequired,
  model: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  linkAuthOptions: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  authUpdatePath: PropTypes.string.isRequired,
};
LinkAuthForm.defaultProps = {
};
export default LinkAuthForm;
