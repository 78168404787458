export function chunkArrayInGroups(arr, size) {
  const newArray = [];
  for (let i = 0; i < arr.length; i += size) {
    const j = arr.slice(i, i + size);
    newArray.push(j);
  }
  return newArray;
}

export default chunkArrayInGroups;
