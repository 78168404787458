import PropTypes from 'prop-types';
import React from 'react';
import { uploadImage } from '../../../utils/aws_s3_service';
import NewFileResourceForm from './single_image_form/new_file_resource_form';

// file_resouceに画像を追加するcomponent
// 既存のfile_resourceの更新は行わず、常に新しいfile_resourceを作成する
class SingleImageForm extends React.Component {
  constructor(props) {
    super(props);
    const { defaultUrl } = this.props;
    this.state = {
      uploading: false,
      defaultUrl,
      selectedImageUrl: '',
      selectedImageDataUri: null,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    const {
      beforeUploading, afterUploading, uploadPath, maxSize,
    } = this.props;
    const input = e.target;
    const file = input.files[0];
    uploadImage(
      file,
      uploadPath,
      beforeUploading,
      ({ url, dataUri }) => {
        this.setState({
          uploading: false,
          selectedImageUrl: url,
          selectedImageDataUri: dataUri,
        }, () => {
          afterUploading();
        });
      },
      () => {
        this.setState({ uploading: false }, () => {
          afterUploading();
          window.alert('画像の処理に失敗しました');
        });
      },
      { maxWidth: maxSize, maxHeight: maxSize },
    );
  }

  render() {
    const {
      scope, renderPreview, currentUser,
    } = this.props;
    const {
      selectedImageUrl, uploading, defaultUrl, selectedImageDataUri,
    } = this.state;
    return (
      <React.Fragment>
        {selectedImageUrl && (
          <NewFileResourceForm
            scope={scope}
            renderPreview={renderPreview}
            currentUser={currentUser}
            selectedImageUrl={selectedImageUrl}
            selectedImageDataUri={selectedImageDataUri}
            uploading={uploading}
          />
        )}
        {!selectedImageUrl && renderPreview(defaultUrl)}
        <label
          htmlFor="profileImage"
          style={{
            backgroundColor: 'white',
            border: 'solid 1px #ced4da',
            borderRadius: 5,
            marginTop: 5,
          }}
        >
          <span className="btn btn-default">
            画像選択
            <input
              id="profileImage"
              type="file"
              onChange={this.handleChange}
              accept="image/*"
              style={{ display: 'none' }}
            />
          </span>
        </label>
      </React.Fragment>
    );
  }
}
SingleImageForm.propTypes = {
  scope: PropTypes.string.isRequired,
  uploadPath: PropTypes.string.isRequired,
  beforeUploading: PropTypes.func,
  afterUploading: PropTypes.func,
  defaultUrl: PropTypes.string,
  renderPreview: PropTypes.func.isRequired,
  maxSize: PropTypes.number,
  currentUser: PropTypes.shape({
    id: PropTypes.number,
  }),
};
SingleImageForm.defaultProps = {
  defaultUrl: '',
  beforeUploading: () => {},
  afterUploading: () => {},
  maxSize: 1000,
  currentUser: {},
};
export default SingleImageForm;
