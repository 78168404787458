import React from "react";
import PropTypes from "prop-types";
import QuestionnaireItem from "./questionnaire_list/questionnaire_item";
import QuestionSelectBox from "./questionnaire_list/question_select_box";

class QuestionnaireList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      action,
      questionnaires,
      questions,
      additionalFormInputHtml,
      showUser,
      showDatetime,
      showDepartment,
      compLinkUrl,
      compLinkTitle,
    } = this.props;
    return (
      <React.Fragment>
        <QuestionSelectBox
          questions={questions}
          action={action}
          additionalFormInputHtml={additionalFormInputHtml}
          showQuestionnaireNote
          compLinkUrl={compLinkUrl}
          compLinkTitle={compLinkTitle}
        />
        <table className="table user-answer-index__table">
          <thead>
            <tr>
              {showDatetime && <td>日時</td>}
              <td>内容</td>
              {showUser && <td>回答者</td>}
              {showDepartment && <td>所属部署</td>}
            </tr>
          </thead>
          <tbody>
            {questionnaires.map((questionnaire) => (
              <QuestionnaireItem
                key={questionnaire.id}
                questionnaire={questionnaire}
                showDatetime={showDatetime}
                showUser={showUser}
                showDepartment={showDepartment}
              />
            ))}
          </tbody>
        </table>
        {questionnaires.length === 0 && (
          <div style={{ padding: 10, color: "gray" }}>回答がありません</div>
        )}
      </React.Fragment>
    );
  }
}

QuestionnaireList.propTypes = {
  action: PropTypes.string.isRequired,
  additionalFormInputHtml: PropTypes.string,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ).isRequired,
  questionnaires: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ).isRequired,
  showDatetime: PropTypes.bool,
  showUser: PropTypes.bool,
  showDepartment: PropTypes.bool,
  compLinkTitle: PropTypes.string,
  compLinkUrl: PropTypes.string,
};
QuestionnaireList.defaultProps = {
  additionalFormInputHtml: "",
  showDatetime: false,
  showUser: false,
  showDepartment: false,
  compLinkTitle: "",
  compLinkUrl: "",
};
export default QuestionnaireList;
