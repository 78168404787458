import { differenceInMilliseconds, format, parseISO } from "date-fns";
import ja from "date-fns/locale/ja";

export function setupPickaDate(opt = {}) {
  // enable datepicker of pickadate.js
  $(".datepicker").pickadate(
    Object.assign(
      {
        format: "yyyy/mm/dd(ddd)",
        formatSubmit: "yyyy-mm-dd",
        hiddenPrefix: undefined,
        hiddenSuffix: "",
        hiddenName: true,
      },
      opt
    )
  );
}

export function setupPickaDateWithCallback(
  opt = {},
  callback = () => {},
  className = "datepicker_withcallback"
) {
  // enable datepicker of pickadate.js
  $(`.${className}`).pickadate(
    Object.assign(
      {
        format: "yyyy/mm/dd(ddd)",
        formatSubmit: "yyyy-mm-dd",
        hiddenPrefix: undefined,
        hiddenSuffix: "",
        hiddenName: true,
        onSet: callback,
      },
      opt
    )
  );
}

export function setupPickaTime(opt = {}) {
  // enable timepicker of pickadate.js
  $(".timepicker").pickatime(
    Object.assign(
      {
        format: "HH:i",
        formatSubmit: "HH:i",
        hiddenPrefix: undefined,
        hiddenSuffix: "",
        min: [7, 0],
        max: [22, 0],
      },
      opt
    )
  );
}

export function setupPickaTimeWithCallback(
  opt = {},
  callback = () => {},
  className = "timepicker_withcallback"
) {
  // enable timepicker of pickadate.js
  $(`.${className}`).pickatime(
    Object.assign(
      {
        format: "HH:i",
        formatSubmit: "HH:i",
        hiddenPrefix: undefined,
        hiddenSuffix: "",
        min: [7, 0],
        max: [22, 0],
        onSet: callback,
      },
      opt
    )
  );
}

export function convRelativeTime(dateTimeStr) {
  const date = parseISO(dateTimeStr);
  const diff = differenceInMilliseconds(new Date(), date);
  const oneMin = 1000 * 60;
  if (diff < oneMin) {
    return "1分以内";
  }
  const oneHour = oneMin * 60;
  if (diff < oneHour) {
    const min = Math.floor(diff / oneMin);
    return `${min}分前`;
  }
  const oneDay = oneHour * 24;
  if (diff < oneDay) {
    const hour = Math.floor(diff / oneHour);
    return `${hour}時間前`;
  }
  const oneWeek = oneDay * 7;
  if (diff < oneWeek) {
    const day = Math.floor(diff / oneDay);
    return `${day}日前`;
  }
  return format(date, "yyyy年MM月dd日", { locale: ja });
}

export function jaFormat(dateTimeStr, formatStr = "yyyy年MM月dd日") {
  const date = parseISO(dateTimeStr);
  return format(date, formatStr, { locale: ja });
}
