import React from 'react';
import PropTypes from 'prop-types';
import SelectForm from '../../../../shared/form/select_form';
import { getRequest } from '../../../../../utils/api_client';

function codeFormatter(prefectureCode) {
  return `00${prefectureCode}`.slice(-2);
}

class LivingAreaForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cityLoading: false,
      cityOptions: [],
    };
  }

  componentDidMount() {
    const { userProfile } = this.props;
    if (userProfile.prefecture_code) {
      this.loadCityOptions(userProfile.prefecture_code);
    }
  }

  loadCityOptions(prefectureCode) {
    const pc = codeFormatter(prefectureCode);
    this.setState({ cityLoading: true }, () => {
      getRequest(
        '/cities',
        { q: { prefecture_code_eq: pc } },
      )
        .then((r) => {
          const cityOptions = r.data.map(d => ({ key: String(d.id), value: d.name }));
          this.setState({
            cityLoading: false,
            cityOptions,
          });
        })
        .catch((e) => {
          this.setState({
            cityLoading: false,
          }, () => {
            window.alert('エラーが発生しました');
          });
        });
    });
  }

  render() {
    const { userProfile, prefectureOptions } = this.props;
    const { cityLoading, cityOptions } = this.state;
    return (
      <React.Fragment>
        <SelectForm
          scope="user_profile_form"
          model={userProfile}
          relatedModelName="user_profile"
          columnName="prefecture_code"
          options={prefectureOptions}
          option={{ include_blank: window.I18n.t('javascript.user_profiles.form.prefecture') }}
          labelColor="gray"
          onChange={e => this.loadCityOptions(e.target.value)}
        />
        {(!cityLoading && cityOptions.length > 0) && (
          <SelectForm
            scope="user_profile_form"
            model={userProfile}
            relatedModelName="user_profile"
            columnName="city_id"
            options={cityLoading ? [] : cityOptions}
            option={{ include_blank: window.I18n.t('javascript.user_profiles.form.city') }}
            labelColor="gray"
            label=" "
          />
        )}
      </React.Fragment>
    );
  }
}

LivingAreaForm.propTypes = {
  userProfile: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  prefectureOptions: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
};
LivingAreaForm.defaultProps = {
};
export default LivingAreaForm;
