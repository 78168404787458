import React from 'react';
import PropTypes from 'prop-types';
import AddButtonBox from './image_form/add_button_box';
import PreviewFieldBox from './image_form/preview_field_box';
import UploaderBox from './image_form/uploader_box';

function fileListToArray(fileList) {
  const arr = [];
  for (let i = 0; i < fileList.length; i += 1) {
    arr.push(fileList[i]);
  }
  return arr;
}

class ImageForm extends React.Component {
  constructor(props) {
    super(props);
    const { model, innerRef } = this.props;
    let fileResources = [];
    if (model.file_resources) {
      fileResources = model.file_resources.filter(fr => fr.file_type === 'image_file_type');
    }
    this.state = {
      fileResources,
      uploadingFiles: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.inputRef = innerRef || React.createRef();
  }

  clickFileInput() {
    if (this.inputRef && this.inputRef.current) {
      this.inputRef.current.click();
    }
  }

  addUrl(fileIndex, url, dataUri) {
    const { afterUpload } = this.props;
    const { fileResources, uploadingFiles } = this.state;
    const files = uploadingFiles.concat();
    files.splice(fileIndex, 1, null);
    const frs = fileResources.concat({ url, dataUri });
    this.setState({
      fileResources: frs,
      uploadingFiles: files,
    }, () => {
      if (files.filter(f => f).length === 0) {
        afterUpload(frs);
      }
    });
  }

  handleChange(e) {
    const { beforeUpload } = this.props;
    const { uploadingFiles } = this.state;
    const input = e.target;
    const arr = fileListToArray(input.files);
    if (arr.length > 0) {
      if (beforeUpload) {
        beforeUpload(() => {
          this.setState({
            uploadingFiles: uploadingFiles.concat(arr),
          }, () => {
            input.value = null;
          });
        });
      } else {
        this.setState({
          uploadingFiles: uploadingFiles.concat(arr),
        }, () => {
          input.value = null;
        });
      }
    }
  }

  remove(index) {
    const { fileResources } = this.state;
    const frs = fileResources.concat();
    frs.splice(index, 1, null);
    this.setState({ fileResources: frs });
  }

  uploadFailed(index) {
    const { afterUpload } = this.props;
    const { uploadingFiles, fileResources } = this.state;
    const files = uploadingFiles.concat();
    files.splice(index, 1, null);
    this.setState({
      uploadingFiles: files,
    }, () => {
      if (files.filter(f => f).length === 0) {
        afterUpload(fileResources);
      }
    });
  }

  addDestroyFlg(index) {
    const { fileResources } = this.state;
    const frs = fileResources.concat();
    frs.splice(index, 1, Object.assign({}, frs[index], { destroyed: true }));
    this.setState({ fileResources: frs });
  }

  render() {
    const {
      scope, multiple, showFormAlways, renderHeader,
    } = this.props;
    const { fileResources, uploadingFiles } = this.state;
    const uploadingFileExists = uploadingFiles.some(uf => uf);
    const editingFileExists = fileResources.some(fr => fr);
    const availableFileExists = fileResources.some(fr => fr && !fr.destroyed);
    const viewer = () => {
      if (!showFormAlways && !uploadingFileExists && !editingFileExists) {
        return null;
      }
      const showAddButton = () => {
        if (multiple) {
          return true;
        } else if (!uploadingFileExists) {
          if (!availableFileExists) {
            return true;
          }
        }
        return false;
      };
      return (
        <React.Fragment>
          {renderHeader()}
          <div className="form-group row">
            <div className="col-sm-12 scroll-bar-x__wrapper">
              <div className="d-flex scroll-bar-x__box border__lightgray">
                <div className="flex-fill">
                  <div className="d-flex">
                    {fileResources.map((fr, i) => {
                      if (!fr) {
                        return null;
                      }
                      const keyIndex = i;
                      return (
                        <PreviewFieldBox
                          key={keyIndex}
                          scope={scope}
                          fileResource={fr}
                          index={i}
                          onNotSavedImageRemoved={() => this.remove(i)}
                          onSavedDepartmentRemoved={() => this.addDestroyFlg(i)}
                        />
                      );
                    })}
                    {uploadingFiles.map((file, i) => {
                      if (!file) {
                        return null;
                      }
                      const keyIndex = i;
                      return (
                        <UploaderBox
                          key={keyIndex}
                          file={file}
                          afterUploaded={(url, dataUri) => this.addUrl(i, url, dataUri)}
                          afterFailed={() => this.uploadFailed(i)}
                        />
                      );
                    })}
                    {showAddButton() && (
                      <AddButtonBox onClick={() => this.clickFileInput()} />
                    )}
                  </div>
                </div>
                <div
                  className="scroll-bar-x__cover-bar"
                  style={{
                    bottom: 1,
                    left: 16,
                    right: 16,
                    width: 'unset',
                  }}
                />
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    };
    return (
      <React.Fragment>
        {viewer()}
        <input
          ref={this.inputRef}
          type="file"
          onChange={this.handleChange}
          accept="image/*"
          style={{ display: 'none' }}
          multiple={multiple}
          disabled={uploadingFileExists && availableFileExists && !multiple}
        />
      </React.Fragment>
    );
  }
}

ImageForm.propTypes = {
  scope: PropTypes.string.isRequired,
  model: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  beforeUpload: PropTypes.func,
  afterUpload: PropTypes.func,
  multiple: PropTypes.bool,
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  showFormAlways: PropTypes.bool,
  renderHeader: PropTypes.func,
};
ImageForm.defaultProps = {
  model: { file_resources: [] },
  multiple: false,
  beforeUpload: null,
  afterUpload: () => {},
  innerRef: null,
  showFormAlways: false,
  renderHeader: () => {},
};

export default React.forwardRef((props, ref) => (
  <ImageForm {...props} innerRef={ref} />
));
