import React from "react";
import PropTypes from "prop-types";
import "@nosferatu500/react-sortable-tree/style.css";
import SortableTree, {
  toggleExpandedForAll,
  map,
  addNodeUnderParent,
  removeNodeAtPath,
  insertNode,
} from "@nosferatu500/react-sortable-tree";
import HeaderAction from "./department_list/header_action";
import MenuButton from "./department_list/menu_button";

function setLinkTitle(treeData) {
  return map({
    treeData,
    callback: ({ node }) => ({
      ...node,
      title: (
        <a
          href={`/user/departments/${node.id}/members`}
        >{`${node.name} (${node.members_count}人)`}</a>
      ),
    }),
    getNodeKey: ({ node }) => String(node.id),
    ignoreCollapsed: false,
  });
}

function editNodeInTree(treeData, department) {
  return map({
    treeData,
    callback: ({ node }) => {
      if (node.id === department.id) {
        return {
          ...node,
          name: department.name,
          start_applying_date: department.start_applying_date,
          title: (
            <a
              href={`/user/departments/${node.id}/members`}
            >{`${department.name} (${node.members_count}人)`}</a>
          ),
        };
      }
      return node;
    },
    getNodeKey: ({ node }) => String(node.id),
    ignoreCollapsed: false,
  });
}

function addNodeInTree(treeData, department, isRoot) {
  if (isRoot) {
    const { treeData: newTreeData } = insertNode({
      treeData,
      depth: 0,
      minimumTreeIndex: 0,
      newNode: {
        children: [],
        expanded: true,
        id: department.id,
        members_count: 0,
        name: department.name,
        start_applying_date: department.start_applying_date,
        title: (
          <a
            href={`/user/departments/${department.id}/members`}
          >{`${department.name} (0人)`}</a>
        ),
      },
      getNodeKey: ({ node }) => String(node.id),
      ignoreCollapsed: false,
    });
    return newTreeData;
  }
  const { treeData: newTreeData } = addNodeUnderParent({
    treeData,
    newNode: {
      children: [],
      expanded: true,
      id: department.id,
      members_count: 0,
      name: department.name,
      start_applying_date: department.start_applying_date,
      title: (
        <a
          href={`/user/departments/${department.id}/members`}
        >{`${department.name} (0人)`}</a>
      ),
    },
    parentKey: String(department.parent_id),
    getNodeKey: ({ node }) => String(node.id),
    ignoreCollapsed: false,
  });
  return newTreeData;
}

function deleteNodeInTree(treeData, nodePath) {
  return removeNodeAtPath({
    treeData,
    path: nodePath.map((id) => String(id)),
    getNodeKey: ({ node }) => String(node.id),
    ignoreCollapsed: false,
  });
}

class DepartmentList extends React.Component {
  constructor(props) {
    super(props);
    const { departmentHierarchies } = this.props;
    const treeData = setLinkTitle(departmentHierarchies);
    this.state = {
      treeData,
    };
    this.expandAll = this.expandAll.bind(this);
    this.closeAll = this.closeAll.bind(this);
  }

  expandAll() {
    const { treeData: oldTreeData } = this.state;
    const treeData = toggleExpandedForAll({
      treeData: oldTreeData,
      expanded: true,
    });
    this.setState({ treeData });
  }

  closeAll() {
    const { treeData: oldTreeData } = this.state;
    const treeData = toggleExpandedForAll({
      treeData: oldTreeData,
      expanded: false,
    });
    this.setState({ treeData });
  }

  registNode(department, isRoot = false) {
    const { treeData: oldTreeData } = this.state;
    const treeData = addNodeInTree(oldTreeData, department, isRoot);
    this.setState({ treeData });
  }

  editNode(department) {
    const { treeData: oldTreeData } = this.state;
    const treeData = editNodeInTree(oldTreeData, department);
    this.setState({ treeData });
  }

  deleteNode(nodePath) {
    const { treeData: oldTreeData } = this.state;
    const treeData = deleteNodeInTree(oldTreeData, nodePath);
    this.setState({ treeData });
  }

  render() {
    const { treeData } = this.state;

    return (
      <React.Fragment>
        <div>
          <div className="react-department-list__header-action-container">
            <HeaderAction
              onExpandAll={this.expandAll}
              onCloseAll={this.closeAll}
              afterCreated={(department) => this.registNode(department, true)}
            />
          </div>
          <div className="react-department-list__nodes-container">
            <SortableTree
              getNodeKey={({ node }) => String(node.id)}
              treeData={treeData}
              canDrag={false}
              onChange={(d) => this.setState({ treeData: d })}
              generateNodeProps={({ node }) => ({
                buttons: [
                  <MenuButton
                    node={node}
                    afterCreated={(department) => this.registNode(department)}
                    afterEdited={(department) => this.editNode(department)}
                    afterDeleted={(nodePath) => this.deleteNode(nodePath)}
                  />,
                ],
              })}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

DepartmentList.propTypes = {
  departmentHierarchies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      expanded: PropTypes.bool,
    })
  ).isRequired,
};
DepartmentList.defaultProps = {};
export default DepartmentList;
