import PropTypes from "prop-types";
import React from "react";
import MemberItem from "./member_list/member_item";

class MemberList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      members,
      roleOptions,
      authOptions,
      memberActionPath,
      userActionPath,
      eventAuthOptions,
      managerialPositionOptions,
      dutyOptions,
      departmentOptions,
      departments,
      currentUser,
      isEditable,
      activeColumns,
      showUserLink,
      isEventEditable,
      company,
    } = this.props;
    return (
      <React.Fragment>
        <div className="member-index__table-container">
          <table className="table member-index__table">
            <thead>
              <tr>
                {activeColumns.includes("department_name") && (
                  <td>
                    {window.I18n.t(
                      "activerecord.attributes.member.department_id"
                    )}
                  </td>
                )}
                {activeColumns.includes("user_account_name") && (
                  <td>
                    {window.I18n.t("activerecord.attributes.user.account_name")}
                  </td>
                )}
                {activeColumns.includes("managerial_position_name") && (
                  <td className="member-index__td">
                    {window.I18n.t("activerecord.models.managerial_position")}
                  </td>
                )}
                {activeColumns.includes("duty_name") && (
                  <td className="member-index__td">
                    {window.I18n.t("activerecord.models.duty")}
                  </td>
                )}
                {activeColumns.includes("member_ref_auth") && (
                  <td className="member-index__td">
                    {window.I18n.t("activerecord.attributes.member.ref_auth")}
                  </td>
                )}
                {activeColumns.includes("member_event_auth") && (
                  <td className="member-index__td">
                    {window.I18n.t("activerecord.attributes.member.event_auth")}
                  </td>
                )}
                {isEditable && <td className="member-index__td">編集</td>}
              </tr>
            </thead>
            <tbody>
              {members.map((m) => (
                <MemberItem
                  key={m.id}
                  memberActionPath={memberActionPath}
                  userActionPath={userActionPath}
                  currentUser={currentUser}
                  member={m}
                  departments={departments}
                  roleOptions={roleOptions}
                  authOptions={authOptions}
                  eventAuthOptions={eventAuthOptions}
                  managerialPositionOptions={managerialPositionOptions}
                  dutyOptions={dutyOptions}
                  departmentOptions={departmentOptions}
                  activeColumns={activeColumns}
                  showUserLink={showUserLink}
                  isEditable={isEditable}
                  company={company}
                />
              ))}
            </tbody>
          </table>
        </div>
      </React.Fragment>
    );
  }
}

MemberList.propTypes = {
  memberActionPath: PropTypes.string.isRequired,
  userActionPath: PropTypes.string.isRequired,
  currentUser: PropTypes.shape({
    id: PropTypes.number,
  }),
  members: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ).isRequired,
  isEditable: PropTypes.bool.isRequired,
  roleOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  authOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  eventAuthOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  managerialPositionOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  dutyOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  departmentOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  departments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ).isRequired,
  activeColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
  showUserLink: PropTypes.bool.isRequired,
  company: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};
MemberList.defaultProps = {
  roleOptions: [],
  authOptions: [],
  eventAuthOptions: [],
  managerialPositionOptions: [],
  dutyOptions: [],
  departmentOptions: [],
};
export default MemberList;
