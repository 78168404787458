import React from "react";
import PropTypes from "prop-types";
import InfiniteScroll from "react-infinite-scroller";
import { getRequest } from "../../utils/api_client";
import LoadingImage from "../shared/images/spin-gray.svg";
import NotificationItem from "./notification_list/notification_item";

class NotificationList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notifications: [],
      loading: false,
      hasMore: true,
    };
    this.loadMore = this.loadMore.bind(this);
  }

  loadMore(page) {
    const { notifications: oldNotifications } = this.state;
    this.setState({ loading: true }, () => {
      getRequest("/wb/user/notifications", {
        page,
        q: {},
      })
        .then((r) => {
          const { data: newNotifications } = r;
          this.setState({
            loading: false,
            notifications: oldNotifications.concat(newNotifications),
            hasMore: newNotifications.length > 0,
          });
        })
        .catch((e) => {
          this.setState({ loading: false, hasMore: false });
        });
    });
  }

  render() {
    const { notifications, loading, hasMore } = this.state;
    return (
      <React.Fragment>
        <ul
          className="list-group list-group-flush"
          style={{ overflow: "auto" }}
        >
          <InfiniteScroll
            key={this.renderCount}
            pageStart={0}
            loadMore={this.loadMore}
            hasMore={!loading && hasMore}
            loader={
              <div className="p-2 color__gray" key={0}>
                Loading ...
              </div>
            }
          >
            {notifications.map((n) => (
              <NotificationItem key={n.id} notification={n} />
            ))}
          </InfiniteScroll>
          {notifications.length === 0 && loading && (
            <div className="w-100 text-center py-2">
              <img
                src={LoadingImage}
                style={{
                  width: 30,
                  height: 30,
                }}
              />
            </div>
          )}
          {notifications.length === 0 && !loading && (
            <div className="p-2 color__gray">お知らせはありません</div>
          )}
        </ul>
      </React.Fragment>
    );
  }
}

NotificationList.propTypes = {};
NotificationList.defaultProps = {};
export default NotificationList;
