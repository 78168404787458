import React, { ReactElement } from "react";
import ReuseForm from "./questionnaire_select_form/reuse_form";
import { QuestionnaireNewResponse } from "../../../models/QuestionnaireApi";

interface Props {
  questionnaire: QuestionnaireNewResponse;
  previousQuestionnaire: QuestionnaireNewResponse;
  onSelectReuseMode: (
    mode: "notReuse" | "reuseAll",
    mergedQuestionnaire: QuestionnaireNewResponse
  ) => void;
}

function mergePreviousQuestionnaire(
  questionnaire: QuestionnaireNewResponse,
  previousQuestionnaire: QuestionnaireNewResponse
): QuestionnaireNewResponse {
  const mergedQuestionnaire = Object.assign({}, questionnaire, {
    happiness_answers: questionnaire.happiness_answers.map((ha) => {
      if (previousQuestionnaire !== null) {
        const prevHappinessAnswer =
          previousQuestionnaire.happiness_answers.filter(
            (prevHa) =>
              prevHa.happiness_question.id === ha.happiness_question.id
          )[0];
        if (prevHappinessAnswer) {
          return prevHappinessAnswer;
        }
      }
      return ha;
    }),
    answers: questionnaire.answers,
    work_happiness_questionnaire: {
      work_happiness_answers:
        questionnaire.work_happiness_questionnaire?.work_happiness_answers ===
        undefined
          ? []
          : questionnaire.work_happiness_questionnaire.work_happiness_answers.map(
              (wha) => {
                if (
                  previousQuestionnaire?.work_happiness_questionnaire?.id ===
                  undefined
                ) {
                  return wha;
                }
                const prevHappinessAnswer =
                  previousQuestionnaire.work_happiness_questionnaire.work_happiness_answers.filter(
                    (prevWha) =>
                      prevWha.work_happiness_question.id ===
                      wha.work_happiness_question.id
                  )[0];
                if (prevHappinessAnswer) {
                  return prevHappinessAnswer;
                }
                return wha;
              }
            ),
    },
  });
  return mergedQuestionnaire;
}

export default function QuestionnaireSelectForm(props: Props): ReactElement {
  const { questionnaire, previousQuestionnaire, onSelectReuseMode } = props;

  return (
    <ReuseForm
      previousQuestionnaire={previousQuestionnaire}
      onSelectedInputByMyself={() =>
        onSelectReuseMode("notReuse", questionnaire)
      }
      onSelectedUsingPreviousQuestionnaire={() =>
        onSelectReuseMode(
          "reuseAll",
          mergePreviousQuestionnaire(questionnaire, previousQuestionnaire)
        )
      }
    />
  );
}
