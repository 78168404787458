import React, { ReactElement } from "react";
import { HappinessAnswerIndexResponse } from "../../../../models/HappinessAnswerApi";
import { WorkHappinessAnswerIndexResponse } from "../../../../models/WorkHappinessAnswerApi";
import HappinessAnswerInput from "./happiness_answer_input";
import WorkHappinessAnswerInput from "./work_happiness_answer_input";
import { QuestionnaireNewResponse } from "../../../../models/QuestionnaireApi";
import { List } from "immutable";
interface Props {
  currentPage: number;
  data: QuestionnaireInputDataType;
  isConsumer: boolean;
  onSelect: () => void;
}

interface TempQuestionnaireInputDataType {
  surveyType: "happiness" | "workHappiness";
  page: number;
  isSelected: boolean;
  index: number;
  activeIndex?: number;
  happinessAnswer?: HappinessAnswerIndexResponse;
  workHappinessAnswer?: WorkHappinessAnswerIndexResponse;
}

export interface QuestionnaireInputDataType
  extends TempQuestionnaireInputDataType {
  activeIndex: number;
}

// 例: countPerPageが[2,2]の場合、indexが0,1だったらpage:1、2,4だったらpage:2を返却
// countPerPageの要素がない場合はpage:0を返却
function getPage(countPerPage: number[], index: number): number {
  let page = 0;
  for (let i = 0; i < countPerPage.length; i += 1) {
    const formCnt = countPerPage
      .slice(0, i + 1)
      .reduce((sum, cnt) => sum + cnt);
    if (index < formCnt) {
      page = i + 1;
      break;
    }
  }
  return page;
}

export function createFormData(
  questionnaire: QuestionnaireNewResponse,
  isReused: boolean
): List<QuestionnaireInputDataType> {
  let happinessFormData: List<TempQuestionnaireInputDataType> = List([]);
  if (
    questionnaire.survey_type === "happiness_survey" ||
    questionnaire.survey_type === "happiness_and_work_happiness_survey"
  ) {
    const happinessCountPerPage = isReused ? [] : [8, 15, 18, 31];
    happinessFormData = List(
      questionnaire.happiness_answers.map((happinessAnswer, i, haArr) => {
        const page = getPage(happinessCountPerPage, i);
        return {
          surveyType: "happiness",
          page,
          isSelected: happinessAnswer.point > 0,
          index: i,
          activeIndex: undefined,
          happinessAnswer,
        };
      })
    );
  }
  let workHappinessFormData: List<TempQuestionnaireInputDataType> = List([]);
  if (
    questionnaire.survey_type === "work_happiness_survey" ||
    questionnaire.survey_type === "happiness_and_work_happiness_survey"
  ) {
    const happinessFormPages = happinessFormData.map((d) => d.page).toArray();
    const happinessFormMaxPage =
      happinessFormPages.length > 0 ? Math.max(...happinessFormPages) : 0;
    if (questionnaire.work_happiness_questionnaire !== null) {
      workHappinessFormData = List(
        questionnaire.work_happiness_questionnaire.work_happiness_answers.map(
          (workHappinessAnswer, i) => {
            const page = getPage([42], i);
            return {
              surveyType: "workHappiness",
              page: happinessFormMaxPage + page,
              isSelected: workHappinessAnswer.point > 0,
              index: i,
              activeIndex: undefined,
              workHappinessAnswer,
            };
          }
        )
      );
    }
  }
  let formData = happinessFormData.concat(workHappinessFormData);
  let activeIndex = 0;
  formData = formData.map((d, i) => {
    if (d.page !== 0) {
      activeIndex += 1;
    }
    return Object.assign({}, d, {
      activeIndex,
    });
  });
  return formData as unknown as List<QuestionnaireInputDataType>;
}

export default function QuestionnaireInput(props: Props): ReactElement {
  const { currentPage, data, onSelect, isConsumer } = props;
  if (
    data.surveyType === "workHappiness" &&
    data.workHappinessAnswer !== undefined
  ) {
    return (
      <WorkHappinessAnswerInput
        workHappinessAnswer={data.workHappinessAnswer}
        showQuestionary={currentPage === data.page}
        index={data.index}
        activeIndex={data.activeIndex}
        onHappinessAnswerSelected={onSelect}
      />
    );
  }
  if (data.surveyType === "happiness" && data.happinessAnswer !== undefined) {
    return (
      <HappinessAnswerInput
        happinessAnswer={data.happinessAnswer}
        showQuestionary={currentPage === data.page}
        index={data.index}
        activeIndex={data.activeIndex}
        isConsumer={isConsumer}
        onHappinessAnswerSelected={onSelect}
      />
    );
  }
  return <></>;
}
